import { IPageTitleSection } from "src/models/smarttypes";
import { MainTitleWrapper } from "src/components/pages/MainTitleWrapper";

import getLanguages from 'src/utils/language';
import generalFxs from 'src/utils/general_fx';

import { useEffect } from "react";
import { TitleSeparator } from "src/components/elements/titleseparator/TitleSeparator";

const languages = getLanguages();
const appFxs = generalFxs();
const isEn = appFxs.getLocalLanguage() === 'en';

export default function CookiesPolicyPage() {
  document.title = `${languages.cookie_policy} | ${languages.schoolName}`;
  const oElt: IPageTitleSection = {
    classes: "legal-pages",
    currentText: languages.cookie_policy,
    pageTitle: languages.cookie_policy,
  };

  useEffect(() => {
    window.scrollTo(0,0);
  }, []);

  return (
    <>
      <MainTitleWrapper {...oElt} />
      <section className="py-[25px] md:py-[70px]">
        <div className="w-container">
          <div className="box-inner">
            <div className="mb:20px md:mb-[60px] text-center">
              {isEn && <h2 className="text-[30px] md:text-[46px] leading-[1.2]">Cookies Policy for La Colombière School</h2>}
              {!isEn && <h2 className="text-[30px] md:text-[46px] leading-[1.2]">Politique de Cookies - Ecole La Colombière</h2>}
              <TitleSeparator titleColor="#ff6d9d" />
            </div>
          </div>

          <div className="policy-body">
            <div className="box-inner">
              {isEn ? <EnglishPolicyContent /> : <FrenchPolicyContent/>}
            </div>
          </div>
          
        </div>
      </section>
    </>
  );
}

const EnglishPolicyContent = () => {
  return (
    <div className="lang-en">
      <div className="flex flex-col">
        <h4>1. Introduction</h4>
        <p>
          Welcome to the <strong>La Colombière</strong> website. This Cookies
          Policy is designed to provide you with clear information about the
          cookies we use on our website and their purpose.
        </p>

        <h4>2. What are Cookies?</h4>
        <p>
          Cookies are small text files that are stored on your device (computer,
          tablet, or mobile phone) when you visit a website. They are widely
          used to enhance your online experience by remembering your preferences
          and improving website performance.
        </p>

        <h4 className="with-sub">3. Types of Cookies We Use</h4>
        <h5>a. Strictly Necessary Cookies:</h5>
        <ul>
          <li>
            These cookies are essential for the proper functioning of our
            website.
          </li>
          <li>They enable you to navigate our site and use its features.</li>
        </ul>
        <h5>b.Performance Cookies:</h5>
        <ul>
          <li>
            These cookies collect information about how visitors use our
            website.
          </li>
          <li>
            They help us improve the user experience by identifying areas that
            need maintenance or enhancement.
          </li>
        </ul>
        <h5>c.Functionality Cookies:</h5>
        <ul>
          <li>
            These cookies remember choices you make on our website, such as your
            preferred language or region.
          </li>
        </ul>
        <h5>d.Targeting Cookies:</h5>
        <ul>
          <li>
            These cookies track your browsing habits and are used to deliver
            targeted advertising.
          </li>
          <li>We do not use targeting cookies on our website.</li>
        </ul>

        <h4 className="with-sub">4. How We Use Cookies</h4>
        <h5>a. Session Cookies:</h5>
        <ul>
          <li>
            We use session cookies to maintain your session while you navigate
            our website.
          </li>
          <li>These cookies are deleted once you close your browser.</li>
        </ul>
        <h5>b. Persistent Cookies:</h5>
        <ul>
          <li>
            Persistent cookies remain on your device for a set period,
            facilitating a personalized experience on your subsequent visits.
          </li>
        </ul>
        <h4>5. Managing Cookies</h4>
        <p>
          You can manage or disable cookies through your browser settings.
          However, please note that some features of our website may not
          function properly if you disable cookies.
        </p>
        <h4>6. Third-Party Cookies</h4>
        <p>
          We may use third-party services, such as Google Analytics, which may
          set their own cookies to track website usage. These cookies are
          governed by the respective privacy policies of these third parties.
        </p>
        <h4>7. Changes to Our Cookies Policy</h4>
        <p>
          We may update this Cookies Policy from time to time to reflect changes
          in technology or legislation. Please check this page periodically for
          any updates.
        </p>
        <h4>8. Contact Us</h4>
        <p>
          If you have any questions or concerns about our Cookies Policy, please
          contact us at <a href="mailto:info@lacolombiere.ac.rw">info@lacolombiere.ac.rw</a>.          
        </p>
      </div>
    </div>
  );
}
const FrenchPolicyContent = () => {
  return (
    <div className="lang-fr">
      <div className="flex flex-col">
        <h4>1. Introduction</h4>
        <p>
          Bienvenue sur le site de <strong>La Colombière</strong>. Cette politique de 
          cookies est conçue pour vous fournir des informations claires 
          sur les cookies que nous utilisons sur notre site Web et leur 
          objectif.
        </p>

        <h4>2. Que sont les cookies ?</h4>
        <p>
          Les cookies sont de petits fichiers texte stockés sur votre appareil 
          (ordinateur, tablette ou téléphone mobile) lorsque vous visitez un 
          site Web. Ils sont largement utilisés pour améliorer votre expérience 
          en ligne en mémorisant vos préférences et en améliorant les performances 
          du site Web.
        </p>

        <h4 className="with-sub">3. Types de cookies que nous utilisons</h4>
        <h5>a. Cookies strictement nécessaires :</h5>
        <ul>
          <li>Ces cookies sont indispensables au bon fonctionnement de notre site Internet.</li>
          <li>Ils vous permettent de naviguer sur notre site et d'utiliser ses fonctionnalités.</li>
        </ul>
        <h5>b.Cookies de performances:</h5>
        <ul>
          <li>
            Ces cookies collectent des informations sur la manière dont les visiteurs utilisent notre site Web.
          </li>
          <li>
            Ils nous aident à améliorer l’expérience utilisateur en identifiant les domaines qui nécessitent une maintenance ou une amélioration.
          </li>
        </ul>
        <h5>c. Cookies de fonctionnalité :</h5>
        <ul>
          <li>
          Ces cookies mémorisent les choix que vous faites sur notre site Web, tels que votre langue ou région préférée.
          </li>
        </ul>
        <h5>d. Cookies de ciblage :</h5>
        <ul>
          <li>
            Ces cookies suivent vos habitudes de navigation et sont utilisés pour diffuser des publicités ciblées.
          </li>
          <li>Nous n'utilisons pas de cookies de ciblage sur notre site Web.</li>
        </ul>

        <h4 className="with-sub">4. Comment nous utilisons les cookies</h4>
        <h5>a. Cookies de session:</h5>
        <ul>
          <li>
            Nous utilisons des cookies de session pour maintenir votre session pendant que vous naviguez sur notre site Web.
          </li>
          <li>Ces cookies sont supprimés une fois que vous fermez votre navigateur.</li>
        </ul>
        <h5>b. Cookies persistants:</h5>
        <ul>
          <li>
            Les cookies persistants restent sur votre appareil pendant une période déterminée, facilitant une expérience personnalisée lors de vos visites ultérieures.
          </li>
        </ul>
        <h4>5. Gestion des cookies</h4>
        <p>
          Vous pouvez gérer ou désactiver les cookies via les paramètres de 
          votre navigateur. Veuillez toutefois noter que certaines 
          fonctionnalités de notre site Web peuvent ne pas fonctionner 
          correctement si vous désactivez les cookies.
        </p>
        <h4>6. Cookies tiers</h4>
        <p>
          Nous pouvons utiliser des services tiers, tels que Google Analytics, 
          qui peuvent définir leurs propres cookies pour suivre l'utilisation 
          du site Web. Ces cookies sont régis par les politiques de 
          confidentialité respectives de ces tiers.
        </p>
        <h4>7. Modifications de notre politique en matière de cookies</h4>
        <p>
          Nous pouvons mettre à jour cette politique en matière de cookies de 
          temps à autre pour refléter les changements technologiques ou 
          législatifs. Veuillez consulter cette page périodiquement pour 
          toute mise à jour.
        </p>
        <h4>8. Nous Contacter</h4>
        <p>
          Si vous avez des questions ou des préoccupations concernant notre 
          politique en matière de cookies, veuillez nous contacter 
          à <a href="mailto:info@lacolombiere.ac.rw">info@lacolombiere.ac.rw</a>.          
        </p>
      </div>
    </div>
  );
}
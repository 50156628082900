import { Link } from 'react-router-dom';
import { IWebShopProduct, InnerCss } from 'src/models/smarttypes';
import generalFxs from 'src/utils/general_fx';
import getLanguages from 'src/utils/language';

const appFxs = generalFxs();
const languages = getLanguages();

export const ShopProductItem = ({item, typeDisplay}:IWebShopProduct) => {
  const {discountedPrice, imagePath, productId, productName, productPrice} = item;

  //const finalImagePath = `${appDeclarations.apiUrlTest}/cloud_data/shop_products/${imagePath}`

  const productPath = `/shop/product/${productId}`;

  const gridCss = 
    typeDisplay === 'oneline' ? 'basis-full':
    typeDisplay === 'twoline' ? 'basis-full md:basis-[50%]':
    typeDisplay === 'threeline' ? 'basis-full md:basis-[33.33%]':
    typeDisplay === 'fourline' ? 'basis-full md:basis-[25%]':
    typeDisplay === 'imageleft' ? 'basis-full wrapper-img-left':
    typeDisplay === 'imageright' ? 'basis-full wrapper-img-right':
    'basis-full';
  
  const gridViewInnerCss = typeDisplay === 'oneline' ? 'h-[667px]':
    typeDisplay === 'twoline' ? 'h-[667px]':
    typeDisplay === 'threeline' ? 'h-[528px]':
    typeDisplay === 'fourline' ? 'h-[459px]':
    typeDisplay === 'imageleft' ? 'h-auto':
    typeDisplay === 'imageright' ? 'h-auto':
    'h-auto';
  
  const productWrapperCss = typeDisplay === 'oneline' ? '':
    typeDisplay === 'twoline' ? '':
    typeDisplay === 'threeline' ? '':
    typeDisplay === 'fourline' ? '':
    typeDisplay === 'imageleft' ? 'flex flex-col md:flex-row':
    typeDisplay === 'imageright' ? 'flex flex-col md:flex-row':
    '';
  
  const prodThumbCss = typeDisplay === 'oneline' ? '':
    typeDisplay === 'twoline' ? '':
    typeDisplay === 'threeline' ? '':
    typeDisplay === 'fourline' ? '':
    typeDisplay === 'imageleft' ? 'md:order-1 md:basis-[33%]':
    typeDisplay === 'imageright' ? 'md:order-2 md:basis-[33%]':
    '';
  
  const prodDetailsCss = typeDisplay === 'oneline' ? '':
    typeDisplay === 'twoline' ? '':
    typeDisplay === 'threeline' ? '':
    typeDisplay === 'fourline' ? '':
    typeDisplay === 'imageleft' ? 'md:order-2 md:basis-[67%]':
    typeDisplay === 'imageright' ? 'md:order-1 md:basis-[67%]':
    '';



  const applyMainGridCss = `product-grid-view ${gridCss}`;
  const applyGridInnerCss = `grid-view-inner px-[15px] ${gridViewInnerCss}`;
  const applyProductWrapperCss = `product-wrapper mb-[35xp] rounded-[10px] relative bg-white ${productWrapperCss}`;

  const applyProdThumbCss = `product-thumb ${prodThumbCss}`;
  const applyProdDetailsbCss = `product-details ${prodDetailsCss}`;

  return (
    <div className={applyMainGridCss}>
        <div className={applyGridInnerCss}>
            <div className={applyProductWrapperCss}>
                <div className={applyProdThumbCss}>
                  <Link className='image' to={productPath} title={productName}>
                    <div className='product-thumb-overlay'></div>
                    <div className='product-labels'>
                      <span className='onsale'>
                        <span>{languages.sale}</span>
                      </span>
                    </div>
                    <div className='primary-image'
                    style={{'--bgImage': `url(${imagePath[0]})`} as InnerCss}></div>
                  </Link>
                  <div className='product-thumb-content'>
                    <div className='product-buttons-wrapper-icons'>
                      <div className='wc_inline_buttons'>
                        <div className='wcct_btn_wrapper'>
                          <Link className='dt-sc-button' 
                            to={productPath}>
                              <i className='fa fa-cart-plus'></i>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={applyProdDetailsbCss}>
                  <div className='product-title'>
                    <h5>
                      <Link className='hover:text-[#fff]' to={productPath}>{productName}</Link>
                    </h5>
                  </div>
                  <div className='product-price'>
                    <span className='price'>
                      {discountedPrice > 0 && <del>
                        <span className='price-amount'>
                          <span className='bdi'>
                            <span className='price-currency-symbol'>Rwf</span>
                            {appFxs.formatNumbers(productPrice)}
                          </span>
                        </span>
                      </del>}
                      <ins>
                        <span className='price-amount'>
                          <span className='bdi'>
                            <span className='price-currency-symbol'>Rwf</span>
                            {appFxs.formatNumbers(discountedPrice > 0 ? discountedPrice: productPrice )}
                          </span>
                        </span>
                      </ins>
                    </span>
                  </div>
                </div>
            </div>
        </div>
    </div>
  )
}

import { BrowserRouter, Routes, Route } from "react-router-dom";
import MainPageLayout from "./pages/_layout";


import SchoolHomePage from './pages/home/index';
import AboutPage from './pages/about/index';

import FacilitiesPage from './pages/facilities/index';
import { FacilityTypePage } from "./pages/facilities/facilities";

import EventsPage from './pages/events/index';
import AlumniPage from './pages/alumni/index';
import ShopPage from './pages/shop/index';
import ContactPage from './pages/contact/index';

import TermsPage from './pages/legal/TermsPage';
import CookiesPage from './pages/legal/CookiesPolicyPage';
import PrivacyPage from './pages/legal/PrivacyPolicyPage';
import { ShopProductPage } from "./pages/shop/ShopProductPage";
import { ShopCartPage } from "./pages/shop/ShopCartPage";
import { ShopCheckout } from "./pages/shop/ShopCheckout";
import { OurTeachersPage } from "./pages/misc/OurTeachersPage";
import { SchoolHistoryPage } from "./pages/misc/SchoolHistoryPage";
import { SpecializedProgrPage } from "./pages/misc/SpecializedProgrPage";
import { SpecialLangTrain } from "./pages/misc/SpecialLangTrain";
import { UnsubscribePage } from "./pages/unsubscribe/Unsubscribe";
import SingleBlogPage from "./pages/alumni/SingleBlogPage";
import { SingleSocialEventPage } from "./pages/alumni/SingleSocialEventPage";
import KidsEnrollment from "./pages/misc/KidsEnrollment";

function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<MainPageLayout />}>
            <Route index element={<SchoolHomePage />} />
            <Route path="home" element={<SchoolHomePage />} />
            <Route path="aboutus" element={<AboutPage />} />
            <Route path="facilities" element={<FacilitiesPage />} />
            <Route path="facilities/:facilityType" element={<FacilityTypePage />} />

            <Route path="events" element={<EventsPage />} />
            
            <Route path="alumni" element={<AlumniPage />} />
            <Route path="alumni/blog/:blogkey" element={<SingleBlogPage />} />
            <Route path="alumni/socialevent/:socialKey" element={<SingleSocialEventPage />} />

            <Route path="shop" element={<ShopPage />} />
            <Route path="shop/product/:productId" element={<ShopProductPage />} />
            <Route path="shop/cart" element={<ShopCartPage />} />
            <Route path="shop/checkout" element={<ShopCheckout />} />

            <Route path="contactus" element={<ContactPage />} />
            <Route path="history" element={<SchoolHistoryPage />} />
            <Route path="team" element={<OurTeachersPage />} />

            
            <Route path="legal/terms" element={<TermsPage />} />
            <Route path="legal/cookies" element={<CookiesPage />} />
            <Route path="legal/privacy" element={<PrivacyPage />} />

            <Route path="misc/enrollment" element={<KidsEnrollment />} />
            <Route path="misc/ourteachers" element={<OurTeachersPage />} />
            <Route path="misc/ourhistory" element={<SchoolHistoryPage />} />
            <Route path="misc/specialized" element={<SpecializedProgrPage />} />
            <Route path="misc/specialtrainlang" element={<SpecialLangTrain />} />

            
            <Route path="unsubscribe/:subscribeKey" element={<UnsubscribePage />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;

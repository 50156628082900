
import getLanguages from 'src/utils/language';

/* import { AskAbtKids } from "./AskAbtKids";
import { ClientTestimonials } from "./ClientTestimonials";
import { QualifiedStaffs } from "./QualifiedStaffs"; */


import { HomeCurricular } from "./HomeCurricular";
import { WebCarouselImageslider } from "./HomeSlider"
import { HomeSpecs } from "./HomeSpecs";
import { LastBlog } from "./LastBlog";
import { NewWorldSchool } from "./NewWorldSchool";
import { OurPartners } from "./OurPartners";
import { RightSchool } from "./RightSchool";
import { useEffect, useRef } from 'react';
import { ClientTestimonials } from './ClientTestimonials';

const languages = getLanguages();

function SchoolHomePage() {
  document.title = `${languages.home} | ${languages.schoolName}`;
  const bLoaded = useRef(false);

  //const oPos: number = (Math.random()>=0.5)? 1 : 0;


  useEffect(() => {
    if(bLoaded.current)return;
    window.scrollTo(0,0)
    bLoaded.current = true;
  }, []);
  
  return (
    <div className="flex flex-col">
      {/* Slider */}
      {/* <HomeSliderDaisy /> */}
      
      <WebCarouselImageslider />
      {/* elemnts - team, kids, alumni, amenities */}
      

      <HomeSpecs />
      {/* standard curiculum */}
      <HomeCurricular />
      {/* right school */}
      <RightSchool />
      {/* ask about kids */}
      {/* <AskAbtKids /> */}
      {/* qualified staffs */}
      {/* <QualifiedStaffs /> */}
      {/* Client testimonials */}
      <ClientTestimonials />
      {/* last blogs */}
      <LastBlog />
      {/* partners */}
      <OurPartners />
      {/* new world */}
      <NewWorldSchool />
    </div>
  )
}

export default SchoolHomePage;


import { WiseListWithIcon } from 'src/components/elements/ListWithIcon/WiseListWithIcon';
import { SectionParagraph, SectionTitle } from 'src/components/elements/sectiontitle/SectionTitle';

import multimediaImg1 from 'src/assets/images/schoolimgs/room_multimedia_1.jpg';
import multimediaImg2 from 'src/assets/images/schoolimgs/room_multimedia_2.jpg';
import multimediaImg3 from 'src/assets/images/schoolimgs/room_multimedia_3.jpg';

import getLang from 'src/utils/language';

const languages = getLang();



export const FacilitiesMultimedia = () => {
  return (
    <div className="flex flex-col">
      {/* image */}
      <div className="mb-[20px]">
        <div className="mb-[30px]">
          <img className="rounded-[10px]" src={multimediaImg1} alt="" />
        </div>
      </div>
      <SectionTitle title={languages.multimedia} />
      <SectionParagraph message={languages.multimedia_room_long} />
      
      <div className="feature-images">
        <div className="flex flex-wrap md:justify-between">
          <div className="img-left basis-full md:basis-[49%]">
            <div className="box-inner flex flex-col">
              <div className="overflow-hidden">
                <img className='rounded-[25px] h-full w-full' alt="" src={multimediaImg2} />
              </div>
            </div>
          </div>
          <div className="img-right basis-full md:basis-[49%]">
            <div className="box-inner flex flex-col">
              <div className="overflow-hidden">
                <img className='rounded-[25px]  h-full w-full' alt="" src={multimediaImg3} />
              </div>              
            </div>
          </div>
        </div>
      </div>
      <div>
        <ul>
          <WiseListWithIcon
            fawesomeIcon="fa fa-university"
            message={languages.multimediOne}
            isHtml={true}
          />
          <WiseListWithIcon
            fawesomeIcon="fa fa-graduation-cap"
            message={languages.multimediTwo}
            isHtml={true}
          />
          <WiseListWithIcon
            fawesomeIcon="fa fa-book"
            message={languages.multimediThree}
            isHtml={true}
          />
        </ul>
      </div>
      
    </div>
  );
}

import { IFacilityAmenity, IFacilityFeature } from 'src/models/smarttypes';




import {ReactComponent as FacAirCondImg} from 'src/assets/images/icons/svg/fac-aircond.svg';
import {ReactComponent as FacFoodStayImg} from 'src/assets/images/icons/svg/fac-food.svg';
import {ReactComponent as FacInfrastImg} from 'src/assets/images/icons/svg/fac-infrastructure.svg';
import {ReactComponent as FacNetworkedImg} from 'src/assets/images/icons/svg/fac-networked.svg';
import {ReactComponent as FacTransportImg} from 'src/assets/images/icons/svg/fac-transport.svg';

import multimediaImg from 'src/assets/images/schoolimgs/room_multimedia_1.jpg';
import playgroundImg from 'src/assets/images/schoolimgs/room_playground_1.jpg';
import labAndLibraryImg from 'src/assets/images/schoolimgs/room_lab_libr_1.jpg';
import brightClassImg from 'src/assets/images/schoolimgs/room_modernclass_1.jpg';

import sportsClubImg from 'src/assets/images/schoolimgs/room_spoertsclub_1.jpg';
import meetHallImg from 'src/assets/images/schoolimgs/room_meethall_1.jpg';
import { LinkAchorRound } from './elements/linkanchorround956/LinkAchorRound';


import getLanguages from 'src/utils/language';
import generalFxs from 'src/utils/general_fx';


const languages = getLanguages();
const appFxs = generalFxs();


export const FacilityAmenityComp = ({iconType, subTitle, title}: IFacilityAmenity) => {
    const OIcon = 
      iconType === "aircondition" ? FacAirCondImg:
      iconType === "foodstay" ? FacFoodStayImg:
      iconType === "infrastructure" ? FacInfrastImg:
      iconType === "networked" ? FacNetworkedImg:
      iconType === "transport" ? FacTransportImg:
      FacAirCondImg;
  
    return (
      <div className="comp-facil basis-full md:basis-[20%]">
        <div className="box-inner">
          <div className="x-container flex-flex-col">
            <div className="w-icon">
              <span className="fac-icon-wrapper">
                <OIcon />
              </span>
            </div>
            <div className="w-text">
              <h4><span>{title}</span></h4>
              <p className="w-detail">
                {subTitle}
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
  
  export const FacilityFeatureComp = ({id, type, title, detail,  href}: IFacilityFeature) => {
    const bOdd = appFxs.isNbrOdd(id);
  
    const oImg = 
      type === "brightclass" ? brightClassImg:
      type === "multimedia" ? multimediaImg:
      type === "playground" ? playgroundImg:
      type === "lablibrary" ? labAndLibraryImg:
      type === "sportsclub" ? sportsClubImg:
      type === "meetinghall" ? meetHallImg:
      brightClassImg;
    
    return (
      <section className="facility-feature-c py-[30px]">
        <div className="flex flex-wrap">
          {/* image */}
          <div className={`basis-full md:basis-1/2 order-2 ${bOdd ? 'md:order-1': 'md:order-2'}`}>
            <div className={`${bOdd ? 'md:pr-[5%]' : 'md:pl-[5%]'}`}>
              <img src={oImg} alt=""/>
            </div>
          </div>
          {/* text */}
          <div className={`flex flex-col justify-center basis-full md:basis-1/2 order-1 ${bOdd ? 'md:order-2': 'md:order-1'}`}>
            <div className={`${bOdd ? 'md:pl-[5%]' : 'md:pr-[5%]'}`}>
              <div className="flex flex-col justify-center">
                {/* title */}
                <div className="mb-5">
                  <div className="mb-[10px]">
                    <h4>{title}</h4>
                  </div>
                </div>
                {/* detail */}
                <div className="mb-5">
                  <div className="mb-[10px]">
                    <div>{detail}</div>
                  </div>
                </div>
                {/* button */}
                <div>
                  <LinkAchorRound href={href} text={languages.viewmore} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }

  interface IDiffImgPlaceholder{
    src: any,
    cls: string
  }
  export const DiffImgPlaceholder = ({cls, src}: IDiffImgPlaceholder) => {
    return (
      <img src={src} className={cls}  alt="" />
    );
  }
import { useState } from 'react';

import { IFacqElt, IFaqItem } from 'src/models/smarttypes';

import styles from './WiseAccordion.module.css';

export const WiseAccordion = ({lstQuest, title}:IFacqElt) => {    
  const [activeIndex, setActiveIndex] = useState(1);

  const renderedQuestionsAnswers = lstQuest.map((item, index) => {
    const showDescription = index === activeIndex ? "show-description" : "";
    const fontWeightBold = index === activeIndex ? "font-weight-bold" : "";
    const ariaExpanded = index === activeIndex ? "true" : "false";

    const oData:IFaqItem = {
        ariaExpanded,
        fontWeightBold,
        showDescription,
        item,
        index,
        onClick: () => {
            setActiveIndex(index);
        }
    }

    return (
        <AccordionItem {...oData} key={item.id} />
    );
  });

  return (
    <div className={styles.faq}>
      <h1 className={styles.faq__title} style={{textAlign: 'left'}}>{title}</h1>
      <dl className={styles.faq__list}>{renderedQuestionsAnswers}</dl>
    </div>
  );
}

const AccordionItem = ({
    ariaExpanded, fontWeightBold, 
    index, item, 
    onClick, 
    showDescription
}: IFaqItem) => {
    return (
        <div className={styles.faq__question} key={item.id}>
            <dt>
                <button 
                    type='button'
                    aria-expanded={ariaExpanded}
                    aria-controls={`faq${index + 1}_desc`}
                    data-qa="faq__question-button"
                    className={`${styles.faq__question_button} ${fontWeightBold && styles.font_weight_bold} text-left`}
                    onClick={() => {
                        onClick();
                    }}>
                    {item.title}
                </button>
            </dt>
            <dd>
                <p id={`faq${index + 1}_desc`}
                    data-qa="faq__desc"
                    className={`${styles.faq__desc} ${showDescription && styles.show_description}`}>
                    {item.message}
                </p>
            </dd>
        </div>
    );
}
import { Link } from "react-router-dom";
import {IMenuColombElt } from "src/models/smarttypes";

export const  MainMenuLink = ({menu, onClick}:IMenuColombElt) => {
    const {id, color, href, label, selected} = menu;

    const isActive = selected ?? false;
    
    const onLinkclick = () => {
        if(onClick == null)return;
        onClick(menu);
    }

    return (
        <li className={`relative inline-bloc ${id}`} onClick={onLinkclick}>
            <Link className={`${isActive ? 'active ' : ''} align-middle relative text-base px-[10px] pt-[30px] pb-[10px] inline-block`}
                style={{color: color}} 
                to={href}>
                {label}
            </Link>
        </li>
    );
}


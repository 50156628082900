export const COLORS = {
    primary: '#28a745',
    airForceBlue: '#5d8aa8',
    aliceBlue: '#f0f8ff',
    amber: '#ffbf00',
    americanRose: '#ff033e',
    androidGreen: '#a4c639',
    ao: '#008000',
    appleGreen: '#8db600',
    armyGreen: '#4b5320',
    awesome: '#ff2052',
    azure: '#007fff',
    babyBlue: '#89cff0',
    babyBlueEyes: '#a1caf1',
    ballBlue: '#21abcd',
    banaYellow: '#ffe135',
    beige: '#f5f5dc',
    bostonUnivRed: '#cc0000',
    white: '#fff',
    black: '#000',
    teal: '#008080',
    aquaMarine: '#7fffd4',
    turquoise: '#40e0d0',
    tealGreen: '#00827f',
    skobeloff: '#007474',
    olive: '#808000',
    yellowGreen: '#9acd32',
}
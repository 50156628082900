/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState } from "react";
import ReactPaginate from 'react-paginate';

import { 
  IPageTitleSection, 
  IProductWithPagination, 
  IProductWithPaginationAll, 
  IShopMainData 
} from "src/models/smarttypes";
import { MainTitleWrapper } from "src/components/pages/MainTitleWrapper";

import {ReactComponent as Grid4} from 'src/assets/images/icons/svg/grid-4.svg';
import {ReactComponent as Grid6} from 'src/assets/images/icons/svg/grid-6.svg';
import {ReactComponent as Grid9} from 'src/assets/images/icons/svg/grid-9.svg';
import {ReactComponent as ListImg} from 'src/assets/images/icons/svg/list-image.svg';

import getLanguages from 'src/utils/language';
import getFxs from 'src/utils/general_fx';



import { ShopProductItem } from "src/components/utils/shopproductitem/ShopProductItem";
import { listOfShopProductsDb } from "src/utils/tempodata";
import { LinkAchorRound } from "src/components/elements/linkanchorround956/LinkAchorRound";
import { LoadingElements } from "src/components/utils/LoadingElements";
import NetServices from "src/utils/netservices";

const languages = getLanguages();
const appFxs = getFxs();
const lang = appFxs.getLocalLanguage();
const isEng = lang === 'en';

export default function ShopPage() {
  document.title = `${languages.shop} | ${languages.schoolName}`;
  const oElt: IPageTitleSection = {
    classes: "shop-app",
    currentText: languages.shop,
    pageTitle: languages.shop,
  };
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [hasNetError, setHasNetError] = useState<boolean>(false);
  const [netwokError, setNetworkError] = useState<string>('');

  const [shopData, setShopData] = useState<IShopMainData>(listOfShopProductsDb);
  const itemsPerPage = 10;

  //const finalItems = shopData.items.map(o => o.item);

  const [pageStart, setPageStart] = useState<number>(0);
  const [pageEnd, setPageEnd] = useState<number>(0);

  const bLoaded = useRef(false);


  const changeTypeDisplay = (oD: 'grid'|'list') => {

    setShopData({...shopData, typeDisplay: oD, gridDisplay: oD === 'grid' ? 'twoline': 'imageleft'});
  }
  const changeGridDisplay = (oD: 'oneline'|'twoline'|'threeline'|'fourline'|'imageleft'|'imageright') => {
    setShopData({...shopData, gridDisplay: oD});
  }
  const onPaginateChange = (iPage: number) => {
    const fStart = (itemsPerPage * iPage) + 1
    const fEnd = (fStart + itemsPerPage) - 1;
    const realEnd = fEnd > shopData.items.length ? shopData.items.length: fEnd;

    setPageStart(fStart);
    setPageEnd(realEnd);
  }

  const onReloadData = () => {
    
    populateData();
  }

  const populateData = async () => {
    setIsLoading(true);
    setHasNetError(false);
    setNetworkError('');

    const oper = await NetServices.requestGet('web/getallactiveproducts', false);

    if(!oper.bReturn){
      setHasNetError(true);
      setNetworkError(oper.msgBody)
      setIsLoading(false);
      return;
    }

    const f = appFxs.transformToList(oper.lstShopProducts!);
    setShopData(f);

    setHasNetError(false);
    setNetworkError('');
    setIsLoading(false);
  }

  useEffect(() => {
    onPaginateChange(0);
    window.scrollTo(0,0);
    setIsLoading(false);
  }, []);

  useEffect(() => {
    if(bLoaded.current)return;
    populateData();
    bLoaded.current = true;
  }, []);

  if(isLoading) return <LoadingElements />

  return (
    <>
      <MainTitleWrapper {...oElt} />
      <section className="py-[70px]">
        <div className="w-container">
          <div className="flex flex-col">
            {/* tools */}
            <div className="shop-tools-filter">
              <div className="flex flex-wrap items-center">
                <div className="filter-selector my-[10px] basis-full md:basis-[20%]">
                  <div className="px-[10px]">
                    <div className="selection-box">
                      <select name="orderby" className="orderby">
                        <option value="">{languages.defaultSorting}</option>
                        <option value="rating">
                          {languages.defaultSorting}
                        </option>
                        <option value="date">{languages.sortByDate}</option>
                        <option value="latest">{languages.sortByLatest}</option>
                        <option value="priceLH">
                          {languages.sortByPriceLowHigh}
                        </option>
                        <option value="priceHL">
                          {languages.sortByPriceHighLow}
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
                <div className="filter-reducer my-[10px] basis-full md:basis-[60%] flex items-center justify-center">
                  <div className="filter-reducer-item">
                    <div className="reducer-layout-controller">
                      <ul className="prod-change-displayer">
                        <li>
                          <span
                            data-display="grid"
                            className={
                              shopData.typeDisplay === "grid" ? "active" : ""
                            }
                            onClick={() => changeTypeDisplay("grid")}
                          >
                            {languages.grid}
                          </span>
                        </li>
                        <li>
                          <span
                            data-display="list"
                            className={
                              shopData.typeDisplay === "list" ? "active" : ""
                            }
                            onClick={() => changeTypeDisplay("list")}
                          >
                            {languages.list}
                          </span>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className={`filter-reducer-item ${shopData.typeDisplay === 'grid' ? 'flex': 'hidden'}`}>
                    <div className="reducer-layout-controller">
                      <ul className="prod-change-displayer flex items-center">
                        <li className="data-column">
                          <span
                            data-column="2"
                            className={
                              shopData.gridDisplay === "twoline" ? "active" : ""
                            }
                            onClick={() => changeGridDisplay("twoline")}
                          >
                            <Grid4 width={45} height={45} />
                          </span>
                        </li>
                        <li className="data-column">
                          <span
                            data-column="2"
                            className={
                              shopData.gridDisplay === "threeline"
                                ? "active"
                                : ""
                            }
                            onClick={() => changeGridDisplay("threeline")}
                          >
                            <Grid6 width={45} height={45} />
                          </span>
                        </li>
                        <li className="data-column">
                          <span
                            data-column="3"
                            className={
                              shopData.gridDisplay === "fourline"
                                ? "active"
                                : ""
                            }
                            onClick={() => changeGridDisplay("fourline")}
                          >
                            <Grid9 width={25} height={25} />
                          </span>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className={`filter-reducer-item  ${shopData.typeDisplay === 'list' ? 'flex': 'hidden'}`}>
                    <div className="reducer-layout-options">
                      <ul className="prod-change-displayer flex">
                        <li className="data-column">
                          <span
                            data-column="2"
                            className={
                              shopData.gridDisplay === "imageleft"
                                ? "active"
                                : ""
                            }
                            onClick={() => changeGridDisplay("imageleft")}
                          >
                            <ListImg width={45} height={45} />
                          </span>
                        </li>
                        <li className="data-column">
                          <span
                            data-column="2"
                            className={
                              shopData.gridDisplay === "imageright"
                                ? "active"
                                : ""
                            }
                            onClick={() => changeGridDisplay("imageright")}
                          >
                            <ListImg width={45} height={45} className="rotate-180" />
                          </span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                {(!isLoading && shopData.items.length) > 0 && <div className="filter-result my-[10px] basis-full md:basis-[20%]">
                  {isEng && <p>Showing {pageStart}-{pageEnd} of {shopData.items.length} results</p>}
                  {!isEng && <p>Affichage de {pageStart} à {pageEnd} sur {shopData.items.length} résultats</p>}
                </div>}
              </div>
            </div>
            {/* content */}
            <div className="shop-content-area">
              <div className="box-inner">
                {isLoading && <LoadingElements height={150} />}
                {(!isLoading && shopData.items.length === 0) && 
                  <NoProdFoundComp onClick={onReloadData} />
                }
                {(!isLoading && hasNetError) && 
                  <ErrorSpaceFndSpace onClick={onReloadData} 
                    messageDispl={netwokError} />
                }
                {(!isLoading && shopData.items.length > 0)  && 
                  <PaginatedItems 
                    gridDisplay={shopData.gridDisplay} 
                    items={shopData.items.map(x => x.item)}
                    itemsPerPage={itemsPerPage}
                    onChange={(iPage => onPaginateChange(iPage))}
                  />}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

const ActualItemsWrapper = ({items, gridDisplay}: IProductWithPagination) => {
  return (
    <div className="flex flex-wrap">
      {items.map(oProduct => <ShopProductItem 
        item={oProduct}
        typeDisplay={gridDisplay}
      key={oProduct.productId}/>)}
    </div>
  );
}
const PaginatedItems = (
    {itemsPerPage, items, gridDisplay, onChange}: 
    IProductWithPaginationAll) => {
    
    const [itemOffset, setItemOffset] = useState(0);
    const endOffset = itemOffset + itemsPerPage;
    const currentItems = items.slice(itemOffset, endOffset);
    const pageCount = Math.ceil(items.length / itemsPerPage);
      
  
    const handlePageClick = (event:any) => {
      const newOffset = (event.selected * itemsPerPage) % items.length;
      setItemOffset(newOffset);
      if(onChange === null)return;
      onChange!(Number(event.selected));
    };

    return (
      <div className="flex flex-col gap-2">
        <ActualItemsWrapper gridDisplay={gridDisplay} items={currentItems} />
        <div className="flex justify-center">
          <ReactPaginate
            breakLabel="..."
            nextLabel={`${languages.next.toLowerCase()} >`}
            onPageChange={handlePageClick}
            pageRangeDisplayed={5}
            pageCount={pageCount}
            previousLabel={`< ${languages.previous.toLowerCase()}`}
            renderOnZeroPageCount={null}
            containerClassName="wise-pagination-c"
            activeClassName="wise-p-active"
          />
        </div>
      </div>
    );
}

const NoProdFoundComp = ({onClick}:{onClick: () => void}) => {
  return (
    <div className="flex items-center justify-center h-[250px]">
      <div className="flex flex-col">
        <p className="span-info my-3 max-w-[500px] mx-auto text-center">{languages.noProdFoundExplore}</p>
        <LinkAchorRound type="button" text={languages.reload}
        onClick={onClick} />
      </div>
    </div>
  );
}

const ErrorSpaceFndSpace = ({onClick, messageDispl}:{onClick: () => void, messageDispl: string}) => {
  return (
    <div className="flex items-center justify-center h-[250px]">
      <div className="flex flex-col">
        <h3 className="span-info my-3">{messageDispl}</h3>
        <LinkAchorRound type="button" text={languages.reload}
        onClick={onClick} />
      </div>
    </div>
  );
}
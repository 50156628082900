/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";

import { ArrowBigLeft, ArrowBigRight, Circle, CircleDot } from "lucide-react";

import img1 from "src/assets/images/schoolimgs/shool_fun.jpg";
import img2 from "src/assets/images/schoolimgs/girls_dancing.jpg";
import img3 from "src/assets/images/schoolimgs/school_focus.jpg";

import img4 from "src/assets/images/schoolimgs/bg-title-alumni.jpg";
import img5 from "src/assets/images/schoolimgs/room_multimedia_2.jpg";
import img6 from "src/assets/images/schoolimgs/room_playground_1.jpg";

import cropPrincipal from "src/assets/images/schoolimgs/croped_principal.jpg";
import cropMaryse from "src/assets/images/schoolimgs/croped_maryse.jpg";
import staff1 from "src/assets/images/schoolimgs/croped_staff1.jpg";
import staff2 from "src/assets/images/schoolimgs/croped_staff2.jpg";
import staff3 from "src/assets/images/schoolimgs/croped_staff3.jpg";
import staff4 from "src/assets/images/schoolimgs/croped_staff4.jpg";
import staff5 from "src/assets/images/schoolimgs/croped_staff5.jpg";
import staff6 from "src/assets/images/schoolimgs/croped_staff6.jpg";

import buildImg from "src/assets/images/schoolimgs/shool_fun.jpg";

import getLang from "src/utils/language";
import generalFxs from "src/utils/general_fx";
import { InnerCss } from "src/models/smarttypes";

const languages = getLang();
const appFxs = generalFxs();
const isEn = appFxs.getLocalLanguage() === "en";

const IMAGES: {
  url: string;
  alt: string;
}[] = [
  {
    url: img1,
    alt: "Two kids in a school library",
  },
  {
    url: img2,
    alt: "Kids playing in a school playground",
  },
  {
    url: img3,
    alt: "Primary school students during a test",
  },
  {
    url: img4,
    alt: "Playtime",
  },
  {
    url: img5,
    alt: "Lab time",
  },
  {
    url: img6,
    alt: "Kids playing basktball",
  },
];

export const HomeSlider = () => {
  return (
    <>
      <div
        id="animation-carousel"
        className="relative w-full"
        data-carousel="static"
      >
        <div className="relative h-[300px] overflow-hidden rounded-lg md:h-[600px]">
          {/* 1 */}
          <div className="hidden duration-200 ease-linear" data-carousel-item>
            <div className="absolute block w-full h-full -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2">
              <div className="w-full h-full relative">
                <img
                  src={img1}
                  className="w-full h-full object-cover object-bottom"
                  alt="..."
                />
                <div className="w-[70%] md:w-[450px] mx-auto absolute bottom-[10px] left-[70px] bg-slate-300/80  rounded-lg p-3  min-h-[60px]">
                  <h4 className="title-slider first text-lg md:text-xl font-bold family-q-sand mb-2">
                    <span className="text text-[rgb(111,26,183)]">
                      {isEn ? "Practical teaching" : "Enseignement pratique"} &
                    </span>
                    <br />
                    <span>
                      {isEn ? "Social Development" : "Dévelopement social"}
                    </span>
                  </h4>

                  <p className="text-sm md:text-lg mb-1">
                    {isEn && (
                      <React.Fragment>
                        We aim at success by creating skills necessary for kids
                        to enrich and empower in studies, sports and personal
                        skills.
                      </React.Fragment>
                    )}
                    {!isEn && (
                      <React.Fragment>
                        Nous visons la réussite en créant les compétences
                        nécessaires pour que les enfants s'enrichissent et
                        s'autonomisent dans les études, les sports et les
                        compétences personnelles.
                      </React.Fragment>
                    )}
                  </p>
                </div>
              </div>
            </div>
          </div>

          {/* 2 */}
          <div className="hidden duration-200 ease-linear" data-carousel-item>
            <div className="absolute block w-full h-full -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2">
              <div className="w-full h-full relative">
                <img
                  src={img2}
                  className="w-full h-full object-cover object-top"
                  alt="..."
                />
                <div className="w-[80%] md:w-[450px] mx-auto absolute bottom-2 left-12 md:bottom-auto md:top-[10px] md:left-auto md:right-[20px]  bg-slate-300/80  rounded-lg p-3  min-h-[60px]">
                  <h4 className="title-slider first text-lg md:text-xl font-bold family-q-sand mb-2">
                    <span className="text text-[rgb(111,26,183)]">
                      {isEn && (
                        <React.Fragment>
                          <span className="text-[rgb(26,76,183)]">
                            Empowering students
                          </span>
                          &nbsp; from
                          <br />
                          small age&nbsp;
                          <span className="text-[rgb(243,210,103)]">
                            towards vision
                          </span>
                          .
                        </React.Fragment>
                      )}
                      {!isEn && (
                        <React.Fragment>
                          <span className="text-[rgb(26,76,183)]">
                            Donner aux enfants les moyens d'agir
                          </span>
                          &nbsp; et les accompagner pour &nbsp;
                          <span className="text-[rgb(243,210,103)]">
                            vision épanouie.
                          </span>
                        </React.Fragment>
                      )}
                    </span>
                  </h4>

                  <p className="text-sm md:text-lg mb-1">
                    {isEn && (
                      <React.Fragment>
                        With the courage, Confidence, Creativity and Compassion
                        to make their Unique Contribution in a diverse and
                        dynamic world.
                      </React.Fragment>
                    )}
                    {!isEn && (
                      <React.Fragment>
                        Avec le courage, la confiance, la créativité et la
                        compassion nécessaires pour apporter leur contribution
                        unique dans un monde diversifié et dynamique.
                      </React.Fragment>
                    )}
                  </p>
                </div>
              </div>
            </div>
          </div>

          {/* 3 */}
          <div className="hidden duration-200 ease-linear" data-carousel-item>
            <div className="absolute block w-full h-full -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2">
              <div className="w-full h-full relative">
                <img
                  src={img3}
                  className="w-full h-full object-cover object-bottom"
                  alt="..."
                />
                <div className="w-[70%] md:w-[450px] mx-auto absolute bottom-[10px] right-[20px] bg-black/40  rounded-lg p-3  min-h-[60px]">
                  <h4 className="title-slider first text-lg md:text-xl font-bold family-q-sand mb-2">
                    <span className="text-white italic">
                      <span>'</span>
                      {languages.funSchool}
                      <span>'</span>
                    </span>
                    <br />
                    <span className="text-green-400">
                      {languages.creativeLearning}
                    </span>
                  </h4>

                  <p className="text-sm md:text-lg mb-1 text-white">
                    {languages.apprenMsg}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <button
          type="button"
          className="absolute top-0 start-0 z-30 flex items-center justify-center h-full px-4 cursor-pointer group focus:outline-none"
          data-carousel-prev
        >
          <span className="inline-flex items-center justify-center w-10 h-10 rounded-full bg-white/30 dark:bg-gray-800/30 group-hover:bg-white/50 dark:group-hover:bg-gray-800/60 group-focus:ring-4 group-focus:ring-white dark:group-focus:ring-gray-800/70 group-focus:outline-none">
            <svg
              className="w-4 h-4 text-white dark:text-gray-800 rtl:rotate-180"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 6 10"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M5 1 1 5l4 4"
              />
            </svg>
            <span className="sr-only">Previous</span>
          </span>
        </button>
        <button
          type="button"
          className="absolute top-0 end-0 z-30 flex items-center justify-center h-full px-4 cursor-pointer group focus:outline-none"
          data-carousel-next
        >
          <span className="inline-flex items-center justify-center w-10 h-10 rounded-full bg-white/30 dark:bg-gray-800/30 group-hover:bg-white/50 dark:group-hover:bg-gray-800/60 group-focus:ring-4 group-focus:ring-white dark:group-focus:ring-gray-800/70 group-focus:outline-none">
            <svg
              className="w-4 h-4 text-white dark:text-gray-800 rtl:rotate-180"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 6 10"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="m1 9 4-4-4-4"
              />
            </svg>
            <span className="sr-only">Next</span>
          </span>
        </button>
      </div>

      {/* <div id="slider">
      <div id="dt-sc-rev-slider" className="dt-sc-main-slider">
        <div className="rs-fullwidth-wrap">
          <div className="slider-layer-wrapper">
            <div className="real-slider">
              <div className="dotted-are">
                <span
                  onClick={() => setSliderAtPos(0)}
                  className={`dotted-graph ${sliderPos === 0 ? "active" : ""}`}
                ></span>
                <span
                  onClick={() => setSliderAtPos(1)}
                  className={`dotted-graph ${sliderPos === 1 ? "active" : ""}`}
                ></span>
                <span
                  onClick={() => setSliderAtPos(2)}
                  className={`dotted-graph ${sliderPos === 2 ? "active" : ""}`}
                ></span>
              </div>
              <div className="slider-zone">
                <SliderContainerElement
                  children={<SliderPage1 />}
                  hidden={sliderPos !== 0}
                />
                <SliderContainerElement
                  children={<SliderPage2 />}
                  hidden={sliderPos !== 1}
                />
                <SliderContainerElement
                  children={<SliderPage3 />}
                  hidden={sliderPos !== 2}
                />
              </div>
            </div>
          </div>
          <div
            className="slider-layer-forcer hidden"
            style={{ display: "none" }}
          ></div>
        </div>
      </div>
    </div> */}
    </>
  );
};

export const HomeSliderDaisy = () => {
  const lstImages: string[] = [img1, img2, img3];

  return (
    <div className="carousel w-full">
      {lstImages.map((img, idx) => (
        <CarousselImageWrapper
          img={img}
          len={lstImages.length}
          pos={idx}
          key={idx}
        />
      ))}
    </div>
  );
};

const CarousselImageWrapper = ({
  img,
  pos,
}: {
  img: string;
  pos: number;
  len: number;
}) => {
  const curPos = pos;
  /* const prevPos = pos === 0 ? len - 1:
  pos = pos - 1;
  const nextPos = pos === len - 1 ? 0:
  pos = pos + 1; */

  return (
    <div id={`slide${curPos}`} className="carousel-item relative w-full">
      <img
        src={img}
        className="w-full h-[350px] md:h-[500px] object-cover object-center"
        alt=""
      />
      {/* <div className="absolute flex justify-between transform -translate-y-1/2 left-5 right-5 top-1/2">
        <a href={`"#slide${prevPos}"`} className="btn btn-circle">
          ❮
        </a>
        <a href={`#slide${nextPos}`} className="btn btn-circle">
          ❯
        </a>
      </div> */}
    </div>
  );
};

export const WebCarouselImageslider = () => {
  return (
    <div
      style={{
        /* maxWidth: '1200px', */
        width: "100%",
        height: "500px",
        margin: "0 auto",
        aspectRatio: "10 / 6",
      }}
    >
      <ImageSlider />
    </div>
  );
};

interface IImageSlider {
  items?: {url: string, alt: string}[]
}
const ImageSlider = ({items}: IImageSlider) => {
  const [imageIndex, setImageIndex] = useState(0);
  const [moveTimming, setMoveTimming] = useState(1);

  const fImages = items ?? IMAGES;

  const showPrevImage = () => {
    setImageIndex((index) => {
      if (index === 0) return fImages.length - 1;
      return index - 1;
    });

    
    setMoveTimming(0);
  };

  const showNextImage = () => {
    setImageIndex((index) => {
      if (index === fImages.length - 1) return 0;
      return index + 1;
    });

    setMoveTimming(0)
  };

  useEffect(() => {
    const timeoutId = setInterval(() => {
      setMoveTimming(moveTimming + 1);
      if(moveTimming >= 3) showNextImage();
    }, 1000);
    


    return () => clearInterval(timeoutId);
  }, [moveTimming])

  return (
    <section
      aria-label="Image Slider"
      style={{ width: "100%", height: "100%", position: "relative" }}
    >
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          overflow: "hidden",
        }}
      >
        {fImages.map((oImg, idx) => (
          <img
            key={idx}
            className="img-slider-img basis-full"
            src={oImg.url}
            alt={oImg.alt}
            style={{ translate: `${-100 * imageIndex}%` }}
          />
        ))}
      </div>
      <button
        onClick={showPrevImage}
        className="img-slider-btn"
        style={{ left: 0 }}
        aria-label="View previous Image"
      >
        <ArrowBigLeft />
      </button>
      <button
        onClick={showNextImage}
        className="img-slider-btn"
        style={{ right: 0 }}
        aria-label="View next Image"
      >
        <ArrowBigRight />
      </button>
      <div
        style={{
          position: "absolute",
          bottom: ".5rem",
          left: "50%",
          transform: "-50%",
          display: "flex",
          gap: ".25rem",
        }}
      >
        {fImages.map((_, index) => (
          <button
            aria-label={`View previous Imag ${index + 1}"`}
            className="img-slider-dot-btn"
            key={index}
            onClick={() => setImageIndex(index)}
            type="button"
          >
            {index === imageIndex ? <CircleDot /> : <Circle />}
          </button>
        ))}
      </div>
    </section>
  );
};

export const RotatingSlider = () => {
  return (
    <div
      className="rot-cover h-[500px] w-full flex justify-center items-center bg-black relative"
      style={{ backgroundImage: `url(${buildImg})`,backgroundPosition: 'center'}}
    >
      <div className="absolute z-0 bg-black/60 w-full h-full"></div>
      <div className="rot-box z-[1]">
        <span style={{ "--i": 1 } as InnerCss}>
          <img src={cropPrincipal} alt="" />
        </span>
        <span style={{ "--i": 2 } as InnerCss}>
          <img src={cropMaryse} alt="" />
        </span>
        <span style={{ "--i": 3 } as InnerCss}>
          <img src={staff1} alt="" />
        </span>
        <span style={{ "--i": 4 } as InnerCss}>
          <img src={staff2} alt="" />
        </span>
        <span style={{ "--i": 5 } as InnerCss}>
          <img src={staff3} alt="" />
        </span>
        <span style={{ "--i": 6 } as InnerCss}>
          <img src={staff4} alt="" />
        </span>
        <span style={{ "--i": 7 } as InnerCss}>
          <img src={staff5} alt="" />
        </span>
        <span style={{ "--i": 8 } as InnerCss}>
          <img src={staff6} alt="" />
        </span>
      </div>
    </div>
  );
};

interface IWebCarouselSliderItems {
  items: any[]
}
export const WebCarouselSliderItems = ({items}: IWebCarouselSliderItems) => {
  return (
    <div
      style={{
        /* maxWidth: '1200px', */
        width: "100%",
        height: "500px",
        margin: "0 auto",
        aspectRatio: "10 / 6",
      }}
    >
      <ImageSlider items={items} />
    </div>
  );
}

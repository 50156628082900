import getLanguages  from 'src/utils/language';

import {ReactComponent as NetworkErrorSvg} from 'src/assets/images/svg/network-error.svg'

const languages = getLanguages();

export const LoadingElements = ({
  height, 
  textToDisplay,
}:{
  height?: number, 
  textToDisplay?: string,
}) => {

  return (
    <div className='flex flex-col items-center justify-center py-7 w-full' style={
      {
        height: height ?? '100vh'
      }
    }>
        <i className='fa fa-spinner fa-spin fa-3x'></i>
        <h3 className='my-3 font-semibold text-lg'>{textToDisplay ?? languages.loading}...</h3>
    </div>
  )
}


export const ErrorDisplayer = ({height, textToDisplay,}:{height?: number, textToDisplay?: string,}) => {

  return (
    <div className='flex flex-col items-center justify-center py-7 w-full' style={
      {
        height: height ?? '100vh'
      }
    }>
        <NetworkErrorSvg width={50} height={50} />
        <h3 className='my-3 font-semibold text-lg'>{textToDisplay ?? languages.networkError}...</h3>
    </div>
  )
}

import { useRef, useState } from 'react';
import { IRealAbtUsElt } from 'src/models/smarttypes';
import getLanguages from 'src/utils/language';
import generalFxs from 'src/utils/general_fx';
import { SectionParagraph } from '../sectiontitle/SectionTitle';
import { LinkAchorRound } from '../linkanchorround956/LinkAchorRound';

import defaultBgImage from 'src/assets/images/schoolimgs/student_with_white.jpg';

const languages = getLanguages();
const appFxs = generalFxs();

export const RealAboutUsComp = (
    {
        ytbLink, 
        parentClasses, 
        hasTopBody, 
        topBodyText, 
        bottomBodyText,
        hasButtonLink,
        navigationLinkRef,
        hideImgBg,
        linkText,
        linkType,
      }: IRealAbtUsElt) => {

    const bTopBody = hasTopBody ?? !appFxs.wiseStrings(topBodyText ?? '').isEmpty;
    const bBottomBody = !appFxs.wiseStrings(bottomBodyText ?? '').isEmpty;
    const bButton = hasButtonLink || false;
    const btnLink = navigationLinkRef || '/';
    const btnType = linkType ?? 'dark';
    const btnLinkText = linkText || languages.scheduleVisit;

    const bgImg = hideImgBg ?? defaultBgImage;

    const bgColor = btnType==='dark' ? '#000' : '#ff236c';
    const bgHoverColor = btnType==='dark' ? '#5d58ef' : '#000';

    const iFrame = useRef<HTMLIFrameElement>(null);
    const [isPlaying, setIsPlaying] = useState(false);

    const startPlaying = () => {
        setIsPlaying(true);
    }

  return (
    <div className={parentClasses}>
      <div className="p-[10px]">
        <div className="flex flex-col">
          {bTopBody && (
            <div className="text-center mb-8">{topBodyText ?? ""}</div>
          )}
          <div className="mb-5 mt-5">
            <div className="ws-video-cover">
              <iframe
                ref={iFrame}
                className="w-jet-video-iframe"
                src={`${ytbLink}?&mute=1`}
                allowFullScreen
                title="youtube Video Player"
              ></iframe>
              <div
              style={{
                backgroundImage: `url(${bgImg})`,
              }}
                className={`w-jet-video-overlay ${
                  isPlaying ? "hidden" : "flex"
                }`}
              >
                <div className="w-jet-video-button">
                  <span
                    className="w-jet-video-button-icon"
                    onClick={startPlaying}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      x="0px"
                      y="0px"
                      width="82px"
                      height="82px"
                      viewBox="0 0 82 82"
                    >
                      <g>
                        <path d="M51.5,41.5l-18,10.393V31.107L51.5,41.5z"></path>
                      </g>
                      <g>
                        <path d="M40.5,10.5c17.094,0,31,13.907,31,31s-13.906,31-31,31c-17.093,0-31-13.906-31-31S23.407,10.5,40.5,10.5    M40.5,6.5c-19.33,0-35,15.67-35,35s15.67,35,35,35s35-15.67,35-35S59.83,6.5,40.5,6.5L40.5,6.5z"></path>
                      </g>
                    </svg>
                  </span>
                  <span className="w-screen-only">{languages.playVideo}</span>
                </div>
              </div>
            </div>
          </div>
          {bBottomBody &&
            <SectionParagraph message={bottomBodyText} />
            }
          {bButton && 
          <div className='mt-5'>
            <LinkAchorRound 
                href={btnLink}
                text={btnLinkText}
                bgColor={bgColor}
                hoverBgcolor={bgHoverColor}
                 />
          </div>}
        </div>
      </div>
    </div>
  );
}

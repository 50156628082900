import { IGeneralType, InnerCss } from "src/models/smarttypes"

export const WiseListWithIcon = ({iconColor, message, fawesomeIcon, isHtml}: IGeneralType) => {
  const icColor = iconColor ?? '#ff236c';
  const fIcon = fawesomeIcon ?? 'fa fa-bank';
  const hasHtml  = isHtml ?? false;
  
  return (
    <li className='listwithicon' style={{'--iconColor': icColor} as InnerCss}>
        <span className='list-icon'>
            <i className={fIcon}></i>
        </span>
        <div className='list-text'>
            {!hasHtml && message}
            {hasHtml && <div dangerouslySetInnerHTML={{__html: message!}} />}
        </div>
    </li>
  )
}

import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';

import './assets/fonts/SundayFunday.otf';
import './assets/fonts/SundayFunday.ttf';

import './assets/styles/index.css';
import './assets/styles/colombiere.fonts.css';
import './assets/styles/colombiere.main.css';
import './assets/styles/colombiere.main.media.css';



const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import { useEffect, useRef, useState } from "react";

import { ILastBlogElt, IManagtBlog, IPageTitleSection } from "src/models/smarttypes";
import { MainTitleWrapper } from "src/components/pages/MainTitleWrapper";

import getLanguages from 'src/utils/language';
import { BlogWrapperElt } from "../home/LastBlog";
import { LoadingElements } from "src/components/utils/LoadingElements";
import NetServices from "src/utils/netservices";

const languages = getLanguages();

const oElt: IPageTitleSection = {
  classes: "our-blog",
  currentText: languages.events,
  pageTitle: languages.eventsAndNews,
};

export default function EventsPage() {
  document.title = `${languages.eventsAndNews} | ${languages.schoolName}`;
  const bLoaded = useRef(false);
  
  const [lstManagtBlogs, setlstManagtBlogs] = useState<IManagtBlog[]>([])
  const [isLoading, setLoading] = useState(true);
  const [hasNetError, setHasNetError] = useState(false);
  const [netMessage, setNetMessage] = useState('');

  const populateBlogsList = async () => {
    setLoading(true);
    setHasNetError(false);
    setNetMessage('');


    const oper = await NetServices.requestGet('web/getlistofallblogs', false);
    if(!oper.bReturn){
      setHasNetError(true);
      setNetMessage(oper.msgBody);
      setLoading(false);
      return;
    }

    setlstManagtBlogs(oper.lstManagtBlogs!);
    
    setHasNetError(false);
    setNetMessage('');
    setLoading(false);
  }

  useEffect(() => {
    window.scrollTo(0,0);
  }, []);

  useEffect(() => {
    if(bLoaded.current)return;
    populateBlogsList();
    bLoaded.current = true;
  }, [])


  return (
    <>
      <MainTitleWrapper {...oElt} />
      <div className="pagebody-wrapper pt-[70px]">
        <div className="w-container">
          <div className="blog-posts">
            <div className="p-[10px]">
            {isLoading && <LoadingElements height={350} />}            
            {(!isLoading && hasNetError) && (
              <div className="flex flex-col items-center justify-center max-w-[500px] mx-auto">
                <h3 className="font-semibold text-2xl text-center">{netMessage}</h3>
                <button className="flex items-center gap-1 mt-3 border border-blue-400 py-2 px-3 rounded-md hover:bg-blue-600 hover:text-white"
                  onClick={() => populateBlogsList()}>
                  <i className="fa fa-spinner fa-spin"></i>
                  <span>{languages.tryAgain}</span>
                </button>
              </div>
            )}
            {((!isLoading && !hasNetError) && lstManagtBlogs.length === 0) &&
              <div className="flex flex-col items-center justify-center max-w-[500px] mx-auto h-[300px]">
                <p className="font-semibold text-2xl text-center">{languages.noBlogFoundMsgV2}</p>
                <button className="flex items-center gap-1 mt-3 border border-blue-400 py-2 px-3 rounded-md hover:bg-blue-600 hover:text-white"
                  onClick={() => populateBlogsList()}>
                  <i className="fa fa-arrow-up"></i>
                  <span>{languages.reload}</span>
                </button>
              </div>
            }

            {(!isLoading && !hasNetError)  && <ListBlogsComp blogs={lstManagtBlogs} />}



              
              <div className="wise-pagination"></div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}


const ListBlogsComp = ({blogs}: {blogs: IManagtBlog[]}) => {
  const lstBlogs = blogs.map((bl, idx) => {
    const oRet: ILastBlogElt = {
        classes: 'basis-full sm:basis-1/2 md:basis-1/3',
        isMainBlog: false,
        eltPros: {
          color: bl.blogColor,
          href: bl.blogPathUrl,
          id: idx,
          imagePath:bl.blogImageUrl,
          postContent: bl.blogDescription,
          postDate: bl.blogDate,
          postTitle: bl.blogTitle
        },
    }

    return oRet;
  })

  if(blogs.length===0)return null;
  return (
    <div className="flex flex-col">
      <div className="mb-3"></div>
      <div className="flex flex-wrap">
        {lstBlogs.map((oBlog) => (
          <BlogWrapperElt
            key={oBlog.eltPros.id.toString()}
            classes={oBlog.classes}
            eltPros={oBlog.eltPros}
            isMainBlog={true}
            hasBgColor={true}
          />
        ))}
      </div>
    </div>
  );
}
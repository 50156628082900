/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";

import { IImageElt } from "src/models/smarttypes";

import { LoadingElements } from "src/components/utils/LoadingElements";
//import generalFx from "src/utils/general_fx";
import getLanguages from "src/utils/language";

//const appFxs = generalFx();
const languages = getLanguages();
//const isEng = appFxs.getLocalLanguage() === 'en';

export const OurPartners = () => {
  const [isLoading, setIsLoading] = useState(true);

  const partners: IImageElt[] = [
    {
      id: 0,
      image:
        "https://academicbridge.xyz/packages/assets/ab_new_website/img/logo.png",
      label: "Academic Bridge",
      href: "https://academicbridge.xyz/",
    },
    {
      id: 1,
      image:
        "https://www.reb.gov.rw/index.php?eID=dumpFile&t=f&f=24422&token=226e0a6d1be425664ffd59e24d5d4aad9f43f87b",
      label: "REB",
      href: "https://www.reb.gov.rw/home",
    },
    {
      id: 3,
      image: "https://www.institutfrancais.com/themes/custom/if/logo.svg",
      label: "Institut Francais",
      href: "https://www.institutfrancais.com/fr",
    },
  ];

  useEffect(() => {
    setIsLoading(false);
  }, []);

  if (isLoading) return <LoadingElements />;

  return (
    <div className="py-[100px] relative">
      <div className="w-container">
        <div className="p-[10px]">
          <div className="flex flex-col">
            <div className="mb-[20px]">
              <h2 className="text-center">{languages.ourPartners}</h2>
            </div>
            {/* <div className='text-center max-w-full w-[700px] mx-auto text-2xl'>
              {!isEng ? 
                <>Nos partenaires sont des institutions et organisations internationalement reconnues dans le domaine de l'enseignement primaire, secondaire et universitaire.</>: 
                <>Our partners are internationally recognized institutions and organizations in the field of primary, secondary and university education.</>
              }
            </div> */}
            <div className="mt-10 flex flex-wrap items-center justify-between">
              {partners.map((oImg) => (
                <PartnerImgComp
                  id={oImg.id}
                  href={oImg.href}
                  image={oImg.image}
                  label={oImg.label}
                  key={oImg.id}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const PartnerImgComp = ({ href, image, label }: IImageElt) => {
  return (
    <div className="p-[10px] basis-[100%] md:basis-2/4 lg:basis-1/4">
      <div className="px-[15px]">
        <a
          href={href}
          target="_blank"
          rel="noreferrer"
          className="decoration-transparent text-[#f0aa00] flex justify-center"
        >
          <img
            loading="lazy"
            alt={label}
            src={image}
            className="partner-img w-full max-w-full"
          />
        </a>
      </div>
    </div>
  );
};

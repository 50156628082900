import {ReactComponent as ShapeBottom} from 'src/assets/images/svg/bottom_shape.svg';

interface IShapeBottom {
    fillColor?: string
}
export const ShapeBottomDivider = ({fillColor}: IShapeBottom) => {
    const oFillColor = fillColor ?? '#fff';

  return (
    <div className="element-shape-bottom">
        <ShapeBottom  fill={oFillColor} />
    </div>
  )
}

/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import { useParams, useNavigate, Link } from "react-router-dom";

import NetServices from "src/utils/netservices";
import generalFxs from "src/utils/general_fx";
import getLanguages from "src/utils/language";
import appDecl from "src/utils/declarations";
import { IAlumniBlogPostComment, IBlogRecord } from "src/models/alumni_models";
import { LoadingElements } from "src/components/utils/LoadingElements";
import ReactPaginate from "react-paginate";

const appFxs = generalFxs();
const languages = getLanguages();

const isEng = appFxs.getLocalLanguage() === 'en';

const SingleBlogPage = () => {
  const [blogRecord, setBlogRecord] = useState<IBlogRecord | null>(null);
  const [blogTitle, setBlogTitle] = useState(languages.loading);

  const [lstComments, setLstcomments] = useState<IAlumniBlogPostComment[]>([])

  document.title = `${blogTitle} | ${languages.schoolName}`;
  const [isLoading, setLoading] = useState(true);

  const { blogkey } = useParams();
  const navigate = useNavigate();

  const populateRecord = async () => {
    const blogOper = await NetServices.requestGet(
      `alumni/getanyoneblogwithdetailbykey/${blogkey}`,
      false
    );

    if (!blogOper.bReturn) {
      await appFxs.showAlert(blogOper.msgTitle, blogOper.msgBody);
      navigate("/alumni");
      return;
    }

    setBlogRecord(blogOper.oneBlogPostRecord!);
    setBlogTitle(blogOper.oneBlogPostRecord!.post.postTitle);
    setLstcomments(blogOper.oneBlogPostRecord!.comments);
    setLoading(false);
  };

  useEffect(() => {
    window.scrollTo(0,0);
    populateRecord();
  }, []);

  if (isLoading) return <LoadingElements />;

  return (
    <article className="bg-[#abb8c3]">
      <div className="py-[0px] min-[576px]:py-[30px] min-[900px]:py-[50px]">
        <div className="w-container">
          <div className="flex flex-col">
            <div className="py-3 flex justify-end">
                <Link to={'/alumni'} className="btn btn-square btn-outline"
                title={languages.allblogs}>
                    <i className="fa fa-list text-white"></i>
                </Link>
            </div>
            <div className="blog-header bg-white py-0  md:py-[3em] md:px-[4em] lg:py-[5.34em] lg:px-[6.67em]">
              <header className="mb-[2em]">
                <div className="post-thumb-img">
                  <img
                    className="w-full"
                    src={
                      blogRecord?.post.featuredImageType === "url"
                        ? blogRecord?.post.featuredImage
                        : `${appDecl.apiUrlTest}/cloud_data/alumni_blogs_images/${blogRecord?.post.featuredImage}`
                    }
                    alt={blogRecord?.post.postTitle}
                  />
                </div>
                <div className="post-meta mt-[2em] px-4 md:px-0">
                  <h1 className="text-[1.875rem] leading-[1.2] uppercase mb-[.2em] font-semibold">
                    {blogRecord?.post.postTitle}
                  </h1>
                  <div className="leading-[1.45] text-[#fb2056]">
                    <a className="text-blue-500" href="#comment-zone">{languages.leaveCmt}</a>&nbsp;/&nbsp;
                    {languages.by}:&nbsp;
                    <strong>{`${blogRecord?.publisher.firstName} ${blogRecord?.publisher.lastName}`}</strong>
                    &nbsp;/&nbsp;
                    {blogRecord?.post.tags.map((oTag, idx) => (
                      <BlogTag sTag={oTag} key={idx} />
                    ))}
                  </div>
                </div>
              </header>
              <HTMLContentComponent isBlog={true} postContent={blogRecord?.post.postContent!} />
            </div>

            <ListPostCommentsComp lstComments={lstComments} />
            <CommentAreaZoneComp postKey={blogRecord?.post.postKey!} afterSubmit={(lst) => setLstcomments(lst)} />
          </div>
        </div>
      </div>
    </article>
  );
};

export default SingleBlogPage


const BlogTag = ({sTag}: {sTag: string}) => {
    return (
        <span>
            #<span>{sTag}</span>
        </span>
    );
}

export const HTMLContentComponent = ({postContent, isBlog, cssStyle}: 
  {
    postContent: string, 
    isBlog: boolean,
    cssStyle?: string,
  }) => {
    const bBlog = isBlog;
    const finalClasses = bBlog ? 'blog-content px-4 md:px-0' : (!cssStyle ? '': cssStyle!);


    const openInNewTab = (url: string) => {
      window.open(url, "_blank", "noopener,noreferrer");
    };

    const clickcontentHandler = (e: any) => {
      const targetLink = e.target.closest("a");
      if (!targetLink) return;
      e.preventDefault();
      openInNewTab(targetLink.href);
    };
    return (
      <div
        className={finalClasses}
        onClick={clickcontentHandler}
        dangerouslySetInnerHTML={{ __html: postContent }}
      />
    );
}


const ListPostCommentsComp = ({lstComments}: {lstComments: IAlumniBlogPostComment[]}) => {
    const itemsPerPage = 5;
    const [pageStart, setPageStart] = useState<number>(0);
    const [pageEnd, setPageEnd] = useState<number>(0);

    const onPageChanged = (iPage: number) => {        
        const fStart = (itemsPerPage * iPage) + 1
        const fEnd = (fStart + itemsPerPage) - 1;
        const realEnd = fEnd > lstComments.length ? lstComments.length: fEnd;

        setPageStart(fStart);
        setPageEnd(realEnd);
    } 


    return (
      <>
        
        <PaginatedCommentsComp
            items={lstComments}
            itemsPerPage={itemsPerPage}
            onChange={onPageChanged}
            pageStart={pageStart} 
            pageEnd={pageEnd} />
      </>
    );
}

const PostCommentComp = ({comment}: {
    comment: IAlumniBlogPostComment,
}) => {
    return (
      <div className="blog-comments-list mt-[40px] bg-white py-0  md:py-[3em] md:px-[4em] lg:py-[5.34em] lg:px-[6.67em]">
        <div className="flex flex-col">
            <div className="flex items-center gap-3 mb-3">
                <img src="https://secure.gravatar.com/avatar/b6abdb61046e5bf43bbced0a8a85f5c4?s=50&d=mm&r=g"
                    alt=""
                    className="rounded-full w-[50px] h-[50px]" />
                <div className="text-red-500">
                    <div className="leading-[1] mb-1">{comment.commenterName}</div>
                    <div className="leading-[1]">{appFxs.formatDateWithHrs(comment.commentDate)}</div>
                </div>
            </div>
            <section>
                <p className="leading-[1.5]">{comment.comment}</p>
            </section>
        </div>
      </div>
    );
}

interface ICommentRecord {
    postKey: string,
    postComment: string,
    authorName: string,
    authorEmail: string,
    authorWebsite: string,
}
interface ICommentRecordErr {
    postComment?: string,
    authorName?: string,
    authorEmail?: string,
    authorWebsite?: string,
}
const formRecordTempo = {
    postKey: '',
    postComment: '',
    authorName: '',
    authorEmail: '',
    authorWebsite: '',
}

const CommentAreaZoneComp = ({postKey, afterSubmit}: {postKey: string, afterSubmit: (lstCmts: IAlumniBlogPostComment[]) => void}) => {
    const [consent, setConsent] = useState(false);
    const [isSaving, setSaving] = useState(false);
    const [serverError, setServerError] = useState('');

    let bFormValid = false;

    const [formData, setFormData] = useState<ICommentRecord>({
        ...formRecordTempo,
        postKey: postKey,
    });
    const [fieldErrors, setFieldErrors] = useState<ICommentRecordErr>({
        authorEmail: '',
        authorName: '',
        authorWebsite: '',
        postComment: ''
    });

    const validateForm = () => {
        const errors:ICommentRecordErr = {};

        if(!formData.authorEmail){
            errors.authorEmail = languages.required
        }else if(!appFxs.isEmailValid(formData.authorEmail)){
            errors.authorEmail = languages.emailFormat
        }

        if(!formData.authorName){
            errors.authorName = languages.required
        }
        if(!formData.postComment){
            errors.postComment = languages.required
        }

        bFormValid = Object.keys(errors).length === 0;

        setFieldErrors(errors);
    }
    const popAuthorData = () => {
        const isChecked = appFxs.getLocalStorageByKey(appDecl.consentData, '0') === '1';
        const consName = appFxs.getLocalStorageByKey(appDecl.consentName, '');
        const consEmail = appFxs.getLocalStorageByKey(appDecl.consentEmail, '');
        const consWeb = appFxs.getLocalStorageByKey(appDecl.consentWeb, '');

        setConsent(isChecked);

        setFormData({...formData, authorEmail: consEmail, authorName: consName, authorWebsite:consWeb});
    }

    const saveComment = async () => {
        validateForm();
        if(!bFormValid) return;
        setSaving(true);

        const oData: ICommentRecord = {
            authorEmail: formData.authorEmail,
            authorName: formData.authorName,
            authorWebsite: formData.authorWebsite,
            postComment: formData.postComment,
            postKey,
        }

        var saveOper = await NetServices.requestPost('alumni/postblogpostcomment',{
            blogPostComment: oData
        }, false);

        if(!saveOper.bReturn){
            setServerError(saveOper.msgBody);
        }else{
            afterSubmit(saveOper.lstOfBlogComments!);
            appFxs.setLocalStorageByKey(appDecl.consentData, consent ? '1':'0');
            if(consent){
                appFxs.setLocalStorageByKey(appDecl.consentEmail, formData.authorEmail);
                appFxs.setLocalStorageByKey(appDecl.consentName, formData.authorName);
                appFxs.setLocalStorageByKey(appDecl.consentWeb, formData.authorWebsite);
            }else{
                appFxs.setLocalStorageByKey(appDecl.consentEmail, '');
                appFxs.setLocalStorageByKey(appDecl.consentName, '');
                appFxs.setLocalStorageByKey(appDecl.consentWeb, '');
            }

            const cName = formData.authorName;
            const cEmail = formData.authorEmail;
            const cWeb = formData.authorWebsite;

            setFormData({
                ...formRecordTempo,
                postKey: postKey,
                authorEmail: !consent ? '' :cEmail,
                authorName:  !consent ? '' :cName,
                authorWebsite:  !consent ? '' :cWeb,
            });
        }

        setSaving(false);
    }
    const onChangeValue = (e: any) => setFormData({...formData, [e.target.name]: e.target.value});
    const clearStorageIdentity = () => {
        appFxs.setLocalStorageByKey(appDecl.consentData, '0');
        appFxs.setLocalStorageByKey(appDecl.consentEmail, '');
        appFxs.setLocalStorageByKey(appDecl.consentName, '');
        appFxs.setLocalStorageByKey(appDecl.consentWeb, '');

        setConsent(false);
    }

    useEffect(() => {
        popAuthorData();
    }, []);



    return (
      <div
        id="comment-zone"
        className="blog-comments-zone mt-[40px] bg-white py-4  md:py-[3em] md:px-[4em] lg:py-[5.34em] lg:px-[6.67em]"
      >
        <h3 className="px-4 text-[1.625rem] leading-[1.65] font-semibold">
          {languages.leaveCmt}
        </h3>
        <form className="px-4">
          <p className="mb-4">{languages.cmtEmailWarn}</p>
          <div className="mb-[1.5em] flex flex-col">
            <textarea
              name="postComment"
              id="postComment"
              cols={45}
              rows={8}
              placeholder={`${languages.typeHere}...`}
              value={formData.postComment}
              onChange={onChangeValue}
              disabled={isSaving}
            ></textarea>
            {fieldErrors.postComment && (
              <FormErrorComp message={fieldErrors.postComment} />
            )}
          </div>
          <div className="mb-[1.5em] flex flex-wrap">
            <div className="basis-full md:basis-1/3 mb-3">
              <div className="pr-0 flex flex-col">
                <input
                  type="text"
                  id="authorName"
                  name="authorName"
                  placeholder={`${languages.name}*`}
                  value={formData.authorName}
                  onChange={onChangeValue}
                  disabled={isSaving}
                />
                {fieldErrors.authorName && (
                  <FormErrorComp message={fieldErrors.authorName} />
                )}
              </div>
            </div>
            <div className="basis-full md:basis-1/3 mb-3">
              <div className="px-0 md:px-3 flex flex-col">
                <input
                  type="email"
                  id="authorEmail"
                  name="authorEmail"
                  placeholder={`${languages.email}*`}
                  value={formData.authorEmail}
                  onChange={onChangeValue}
                  disabled={isSaving}
                />
                {fieldErrors.authorEmail && (
                  <FormErrorComp message={fieldErrors.authorEmail} />
                )}
              </div>
            </div>
            <div className="basis-full md:basis-1/3 mb-3">
              <div className="px-0">
                <input
                  type="url"
                  id="authorWebsite"
                  name="authorWebsite"
                  placeholder={`${languages.website}`}
                  value={formData.authorWebsite}
                  onChange={onChangeValue}
                  disabled={isSaving}
                />
              </div>
            </div>
          </div>
          <div className="mb-[1.75em] flex flex-wrap">
            <p className="flex items-start justify-start gap-5 grow">
                <input
                type="checkbox"
                name="authorWebsite"
                id="authorWebsite"
                checked={consent}
                onChange={(_) => {
                    setConsent(!consent);
                }}
                className="consent mt-2 inline-block"
                disabled={isSaving}
                />
                <label className="" htmlFor="authorWebsite">
                {languages.commentConsentText}
                </label>
            </p>
            <button className="btn btn-warning" 
                type="button"
                onClick={clearStorageIdentity}>
                <i className="fa fa-trash"></i>&nbsp;{languages.clear}
            </button>
          </div>
          
          <p className="mb-[1.75em] flex items-center gap-5">
            <button
              type="button"
              className="post-cmt-btn flex items-center"
              onClick={saveComment}
              disabled={isSaving}
            >
              {isSaving && (
                <span>
                  <i className="loading loading-spinner"></i>&nbsp;
                </span>
              )}
              {`${languages.postComment} »`}
            </button>
          </p>
          {serverError && <FormErrorComp message={serverError} />}
        </form>
      </div>
    );
}


const FormErrorComp = ({message}: {message: string}) => {
    return (
        <span className="py-1 inline-block text-red-500 text-sm">{message}</span>
    );
}





const ActualCommentsDisplayer = ({allItems, items, pageEnd, pageStart}: {
    allItems: IAlumniBlogPostComment[], 
    items: IAlumniBlogPostComment[], 
    pageStart: number,
    pageEnd: number,}) => {

        const MessageDisplayer = () => {
          return (
            <div className="filter-result my-[10px] flex justify-end">
              {isEng && (
                <p>
                  Showing {pageStart}-{pageEnd} of {allItems.length} results
                </p>
              )}
              {!isEng && (
                <p>
                  Affichage de {pageStart} à {pageEnd} sur {allItems.length}&nbsp;
                  résultats
                </p>
              )}
            </div>
          );
        };

    return (
        <>
        {items.map((o, idx) => <PostCommentComp comment={o} key={idx} />)}
        {items.length > 0 && <MessageDisplayer />}
        </>

    );
}


interface ICommentPage {
    itemsPerPage: number,
    items: IAlumniBlogPostComment[], 
    pageStart: number,
    pageEnd: number,
    onChange:(pagePos: number) => void,
}
const PaginatedCommentsComp = ({
    itemsPerPage, 
    items, 
    onChange,
    pageEnd,
    pageStart,
}:ICommentPage) => {
    const [itemOffset, setItemOffset] = useState(0);
    const endOffset = itemOffset + itemsPerPage;
    const currentItems = items.slice(itemOffset, endOffset);
    const pageCount = Math.ceil(items.length / itemsPerPage);    
  
    const handlePageClick = (event:any) => {
        const newOffset = (event.selected * itemsPerPage) % items.length;
        setItemOffset(newOffset);
        if(onChange === null)return;
        onChange!(Number(event.selected));
    };

    

    return (
      <div className="flex flex-col gap-2">
        
        <ActualCommentsDisplayer allItems={items} items={currentItems} pageEnd={pageEnd} pageStart={pageStart} />
        <div className="flex justify-end py-4 mt-2">
          <ReactPaginate
            breakLabel="..."
            nextLabel={`${languages.next.toLowerCase()} >`}
            onPageChange={handlePageClick}
            pageRangeDisplayed={5}
            pageCount={pageCount}
            previousLabel={`< ${languages.previous.toLowerCase()}`}
            renderOnZeroPageCount={null}
            containerClassName="wise-pagination-c"
            activeClassName="wise-p-active"
          />
        </div>
      </div>
    );
}



/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */

import { useEffect, useRef, useState } from 'react';
import CountUp from 'react-countup';

import {ReactComponent as courseTrails} from 'src/assets/images/svg/course_trails.svg';
import {ReactComponent as qualifiedStaffs} from 'src/assets/images/svg/qualified_staffs.svg';
import {ReactComponent as schoolEquips} from 'src/assets/images/svg/schoom_equipment.svg';
import {ReactComponent as studentPassed} from 'src/assets/images/svg/student_passed.svg';

import { IParentTestimonial, IParentTestimonialElt, ITestimonialQualif, InnerCss } from 'src/models/smarttypes';

import getLanguages from 'src/utils/language';
import useOnScreen from 'src/utils/specialutils';
import { listOfTestMonialsDb } from 'src/utils/tempodata';

const languages = getLanguages();

const lst:ITestimonialQualif[] = [
  {
    color: '#64C7FE',
    eltNbr: 5000,
    image: studentPassed,
    label: languages.studentPassed,
    id: 0,
  },
  {
    color: '#9BBD33',
    eltNbr: 89,
    image: qualifiedStaffs,
    label: languages.qualifiedStaff,
    id: 1,
  },
  {
    color: '#FF3076',
    eltNbr: 60,
    image: courseTrails,
    label: languages.courses,
    id: 2,
  },
  {
    color: '#8700FF',
    eltNbr: 100,
    image: schoolEquips,
    label: languages.amenities,
    id: 3,
  },
];


export const ClientTestimonials = () => {  
  return (
    <section className="section-wrapper">
      <div className="w-container">
        <div className="p-[10px]">
          <div className="flex flex-col">
            <TestimonialsComp />
            <div className="h-[105px]"/>
            {/* <SchoolResultsComp /> */}
          </div>
        </div>
      </div>
    </section>
  )
}

interface ITetimonial {
  textColor?: string|null,
}

export const TestimonialsComp = ({textColor}: ITetimonial) => {
  const lstElts = listOfTestMonialsDb;

  const tColor = textColor ?? '#000000';

  const [selTest, setSelTest] = useState<IParentTestimonial>(lstElts[0]);
  const [lstTests, setLstTests] = useState<IParentTestimonial[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [primaryColor, setPrimaryColor] = useState(lstElts[0].color);

  const afterSelected = (elt: IParentTestimonial) => {
    const fLists = [...lstTests];
    const fFinal = fLists.map(x => {
      const b = {...x, selected: x.id === elt.id};
      return b;
    });


    setLstTests(fFinal);
    const oSelected = fFinal[elt.id];
    setSelTest(oSelected);
    
    setPrimaryColor(oSelected.color)
  }

  useEffect(() => {
    setLstTests(lstElts);
    setSelTest(lstElts[0]);
    setIsLoading(false);
  }, []);

  if(isLoading) return<div></div>;


  return (
    <div className="client-testimonials" style={{'--textColor': tColor} as InnerCss}>
      <div className="mb-[20px] relative">
        <div className="flex flex-row flex-wrap">
          <div className="relative basis-full md:basis-1/2 mb-8 md:mb-0">
            <div className="box-inner">
              <h2 className="section-title mb-5">
                {languages.clientTestimonials}
              </h2>
              <p className="detail mb-3">{languages.clientTestimonialsMsg}</p>
              <div className="hr-separator-small"></div>
              <div className="separator-clear"></div>
              <div className="testimonial-wrapper">
                <div className="testimonia-elt-area">
                  <div
                    className="inner"
                    style={{ "--testimonial-color": primaryColor } as InnerCss}
                  >
                    <div className="testimonial-author">
                      <h4>{selTest.parentName}</h4>
                      <cite>
                        <small></small>
                      </cite>
                    </div>
                    <div className="testimonial-quote">
                      <blockquote>
                        <p>{selTest.testimonial}</p>
                      </blockquote>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="relative basis-full md:basis-1/2 ">
            <div className="md:pl-[6.5%] mb-[15px]">
              <ul className="testimonials-images flex flex-wrap">
                {lstTests.map((oElt) => (
                  <TestimonialParentElt
                    key={oElt.id.toString()}
                    testimonialElt={oElt}
                    onSelected={() => afterSelected(oElt)}
                  />
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export const SchoolResultsComp = () => {  
  const countUpView = useRef<HTMLDivElement>(null);
  const isOnScreen = useOnScreen(countUpView);

  
  return(
    <div className='relative' ref={countUpView}>
        <div className='flex flex-wrap'>
          {lst.map((oElt, idx) => <TestimonialQualifElt 
            isParentVis={isOnScreen}
            testimonialQual={oElt}
            key={idx} />)}
        </div>
      </div>         
  );
}




export const TestimonialParentElt = ({ testimonialElt, onSelected}: IParentTestimonialElt) => {
  const { color, id, image, selected, label } = testimonialElt;



  return (
    <li id={id.toString()} key={id.toString()} 
      className={selected ? 'selected' : ''} onClick={(e) => {
        e.stopPropagation();
        onSelected();
      }}>
      <div className='relative' style={{'--parent-color': color} as InnerCss}>
        <a href="#">
          <img src={image} alt={label ?? ''}  />
        </a>
      </div>
    </li>
  );
}

type TQualifResult = {
  testimonialQual: ITestimonialQualif;
  isParentVis: boolean;
}
export const TestimonialQualifElt = ({testimonialQual, isParentVis}: TQualifResult) => {
  const {color, eltNbr, image, label} = testimonialQual;
  const OImage = image;
  
  return (
    <div className='qualif-elt basis-full md:basis-1/2 lg:basis-1/4'>
      <div className='qualif-inner'>
        <div className='qualif-wrapper'>
          <div className='qualif-margin'>
            <div className='p-[10px]'>
              <div className='flex flex-col'>
                <div className='mb-[20px] relative'>
                  <div className='mb-[20px]'>
                    <div className='text-center'>
                      <div className='qualif-icon' style={{'--qualif-color': color} as InnerCss}>
                        <OImage />
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <div className='counter-number-wrapper'>
                    <span className='counter-number-wrapper-pref'></span>
                    <CountUp className={`counter-number ${!isParentVis && 'hidden'}`} start={0} end={eltNbr} />
                    <span className='counter-number-wrapper-suff'></span>
                  </div>
                  <div className='counter-title'>{label}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

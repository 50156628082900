import meetingHallImg from 'src/assets/images/schoolimgs/room_modernclass_1.jpg';
import { WiseListWithIcon } from 'src/components/elements/ListWithIcon/WiseListWithIcon';
import { SectionParagraph, SectionTitle } from 'src/components/elements/sectiontitle/SectionTitle';




import getLang from 'src/utils/language';

const languages = getLang();

export const FacilitiesBrightClass = () => {
  return (
    <div className="flex flex-col">
      {/* image */}
      <div className="mb-[20px]">
        <div className="mb-[30px]">
          <img className="rounded-[10px]" src={meetingHallImg} alt="" />
        </div>
      </div>
      <SectionTitle title={languages.modernClassroom} />
      <SectionParagraph message={languages.modernClassroomLongMsg} />
      <div>
        <ul>
          <WiseListWithIcon
            fawesomeIcon="fa fa-university"
            message={languages.modernClassroomShar1}
            isHtml
          />
          <WiseListWithIcon
            fawesomeIcon="fa fa-graduation-cap"
            message={languages.modernClassroomShar2}
            isHtml
          />
          <WiseListWithIcon
            fawesomeIcon="fa fa-book"
            message={languages.modernClassroomShar3}
            isHtml
          />
        </ul>
      </div>
    </div>
  );
}

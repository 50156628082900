//const pathUrl = 'http://localhost:7979';
const pathUrl = 'https://api.lacolombiere.ac.rw';

const appDeclarations = {
    currentLang: 'current_language',
    connectionStatus: 'connect_status',
    apiRootTest: `${pathUrl}/api/`,
    apiUrlTest: pathUrl,

    userAccessKey: 'user_access_key',

    buyerAccessKey: 'buyer___access___key',

    company_code: 'WBTISC8885-LOLWC',
    currentYearbook: 'current_year_book',

    consentData: 'consent___data___',
    consentEmail: 'consent___email___',
    consentName: 'consent___name___',
    consentWeb: 'consent___web___',


    currentDefaultSmsAcct: 'current_def_sms_acct',
    itemsPerPage: 'items_per_page',
    currentDevise: 'current_devise',
    currentBranch: 'current_branch',
    userEmail: 'user_email',
    userPassword: 'userPassword',
    cartZoneArea: 'cart___zone_area____2363',
    mainPhoneNbr: '250 788 510 589',
    displayPrivSett: 'display_priv_sett_____025',
    displayAdmissionDlg: 'displ_ad_dlg____0023',
}
export default appDeclarations;
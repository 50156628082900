import { NetReturn, SenderData } from 'src/models/netreturn';
import generalFxs from 'src/utils/general_fx';
import appLanguages from 'src/utils/language';
import appDeclarations from 'src/utils/declarations';
import Swal from 'sweetalert2';
import axios from 'axios'

const appFxs = generalFxs();
const languages = appLanguages();

async function requestGet(methodName: string, bAjax: boolean = false): Promise<NetReturn> {
    const url = `${appDeclarations.apiRootTest}${methodName}`;
    const headers = appFxs.getManagtDefaultHeaders();

    if(bAjax) appFxs.showAjaxLoader();

    const response  = await window.fetch(url, {
        method: 'GET',
        headers
    });

    if(response.ok){
        const json = await response.json();
        const commits = json as NetReturn;
        if(bAjax) Swal.close();
        return commits;
    }else{
        if(bAjax) Swal.close();
        return {
            bReturn: false,
            msgTitle: languages.networkError,
            msgBody: languages.networkErrorMsg,
            typeReturn: 0,
        }
    }
}

async function requestPost(
    methodName: string, 
    data: SenderData,
    bAjax: boolean = false): Promise<NetReturn> {
    const url = `${appDeclarations.apiRootTest}${methodName}`;
    const fheaders = appFxs.getManagtDefaultHeaders();
    const headers = {...fheaders, 'Content-Type': 'application/json',}

    if(bAjax) appFxs.showAjaxLoader();

    const response  = await window.fetch(url, {
        method: 'POST',
        headers,
        body: JSON.stringify(data)
    });

    if(response.ok){
        const json = await response.json();
        const commits = json as NetReturn;
        if(bAjax) Swal.close();
        return commits;
    }else{
        if(bAjax) Swal.close();
        return {
            bReturn: false,
            msgTitle: languages.networkError,
            msgBody: languages.networkErrorMsg,
            typeReturn: 0,
        }
    }
}

async function requestPostWithFile(
    methodName: string, 
    data: FormData,
    bAjax: boolean = false): Promise<NetReturn> {
    const url = `${appDeclarations.apiRootTest}${methodName}`;
    const fheaders = appFxs.getManagtDefaultHeaders();
    const headers = {...fheaders}

    if(bAjax) appFxs.showAjaxLoader();

    const response  = await window.fetch(url, {
        method: 'POST',
        headers,
        body: data
    });

    if(response.ok){
        const json = await response.json();
        const commits = json as NetReturn;
        if(bAjax) Swal.close();
        return commits;
    }else{
        if(bAjax) Swal.close();
        return {
            bReturn: false,
            msgTitle: languages.networkError,
            msgBody: languages.networkErrorMsg,
            typeReturn: 0,
        }
    }
}

async function requestPostWithFileAxios(
    methodName: string, 
    data: FormData, 
    bAjax: boolean = false): Promise<NetReturn> {
    const url = `${appDeclarations.apiRootTest}${methodName}`;
    const fheaders = appFxs.getManagtDefaultHeaders();
    const headers = {...fheaders, 'Content-Type': 'multipart/form-data'}


    if(bAjax){
        appFxs.showAjaxLoader(languages.waitingforServerResp);
    }

    const response = await axios.post(url, data, {
        headers: headers,
    });

    if(bAjax){
        Swal.close();
    } 

    console.log('response', response);
    

    if(response.status === 200){
        const commits = response.data;
        if(bAjax){
            Swal.close();
        } 
        return commits;
    }else{
        if(bAjax){
            Swal.close();
        } 
        return {
            bReturn: false,
            msgTitle: languages.networkError,
            msgBody: languages.networkErrorMsg,
            typeReturn: 0,
        }
    }

}

const NetServices = {
    requestGet,
    requestPost,
    requestPostWithFile,
    requestPostWithFileAxios,
}

export default NetServices;











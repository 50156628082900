/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';


import getLanguages from 'src/utils/language';
import generalFxs from 'src/utils/general_fx';

import { 
  ICartItemOper, 
  ICartOperation, 
  ICartOperationProd, 
  IPageTitleSection
} from 'src/models/smarttypes';

import { MainTitleWrapper } from 'src/components/pages/MainTitleWrapper';
import { LoadingElements } from 'src/components/utils/LoadingElements';
import { LinkAchorRound } from 'src/components/elements/linkanchorround956/LinkAchorRound';
import { FormInputNumber } from 'src/components/elements/forminputelt/FormInputElt';

import Swal from 'sweetalert2';
import appDeclarations from 'src/utils/declarations';

const languages = getLanguages();
const appFxs = generalFxs();

const oElt: IPageTitleSection = {
  classes: "shop-app-cart",
  currentText: languages.loading,
  pageTitle: languages.loading,
  subPageLink: '/shop',
  subPageTitle: languages.shop
};

export const ShopCartPage = () => {
  document.title = `${languages.cart} | ${languages.schoolName}`;
  const [pageFeature, setPageFeatures] = useState<IPageTitleSection>(oElt);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  
  const [isDataUpdating, setIsDataUpdating] = useState<boolean>(false);

  const [cartRecord, setCartRecord] = useState<ICartOperation>(appFxs.getCartData());
  const navigate = useNavigate();

  const populateCurrentCart = () => {
    setPageFeatures({
      ...pageFeature,
      currentText: languages.cart, 
      pageTitle: languages.cart,
    });

    setIsLoading(false);
  }

  const onItemRemoved = (listPos: number) => {
    appFxs.showAjaxLoader(languages.updatingDataWait);
    const f = cartRecord.products[listPos];
    let copyCart = {...cartRecord} as ICartOperation;

    const prods = copyCart.products.filter(o => o.productName !== f.productName);
    const osubTotal = prods.reduce((a, curr) => a+ curr.subtotal, 0);

    copyCart = {...copyCart, products: prods, subtotal: osubTotal, total: osubTotal};
    setCartRecord(copyCart);

    setTimeout(()=>{
      updateStorage(copyCart);
    }, 700)
  }
  const onItemQtyChanged = (listPos: number, newQty: number) => {
    const copyCart = {...cartRecord} as ICartOperation;
    const currProd:ICartOperationProd|null = copyCart.products[listPos];
    if(currProd===null)return;

    const fProd = {...currProd!} as ICartOperationProd;
    
    const prodPrice = fProd.productPrice;
    const subTotal = prodPrice * newQty;

    const newProd:ICartOperationProd = {
      productId: fProd.productId,
      productName: fProd.productName,
      imagePath: fProd.imagePath,
      productPrice: fProd.productPrice,
      subtotal: subTotal,
      variants: fProd.variants,
      quantity: newQty,
    }
    copyCart.products[listPos] = newProd;
    const fSubTotal = copyCart.products.reduce((a, curr) => a + curr.subtotal, 0);
    copyCart.subtotal = fSubTotal;
    copyCart.total = fSubTotal;

    
    setCartRecord({...copyCart});
    const jsonD = JSON.stringify(copyCart);
    appFxs.setLocalStorageByKey(appDeclarations.cartZoneArea, jsonD);

    setIsDataUpdating(true);
    setTimeout(() => setIsDataUpdating(false), 100);
  }

  const updateStorage = (copyCart: ICartOperation) => {
    const jsonD = JSON.stringify(copyCart);
    appFxs.setLocalStorageByKey(appDeclarations.cartZoneArea, jsonD);
    Swal.close();

    if(copyCart.products.length === 0){
      appFxs.setLocalStorageByKey(appDeclarations.cartZoneArea, '{}');
      navigate('/shop');
    }
  }

  const proceedOnCheckout = () => {
    if(cartRecord.products.length===0)return;
    navigate('/shop/checkout')
  }


  useEffect(() => {
    window.scrollTo(0,0);
    populateCurrentCart()
}, []);


  
  return (
    <>
      <MainTitleWrapper {...pageFeature} />
      {isLoading && <LoadingElements />}
      {!isLoading && (
        <section className="py-[70px]">
          <div className="w-container">
            <form className="cart-form">
              <table className="shop-table cart">
                <thead>
                  <tr>
                    <th className="product-thumbnail">{languages.product}</th>
                    <th className="product-name">{languages.product}</th>
                    <th className="product-price">{languages.price}</th>
                    <th className="product-quantity">{languages.quantity}</th>
                    <th className="product-subtotal">{languages.total}</th>
                    <th className="product-remove">&nbsp;</th>
                  </tr>
                </thead>
                {!isDataUpdating &&
                <tbody>
                  {cartRecord.products.map((oProd, idx) => 
                    <CartProductRow 
                      key={idx}
                      product={oProd}
                      onItemQtyChanged={onItemQtyChanged}
                      onItemRemoved={onItemRemoved}
                      inlistPos={idx} />)}
                </tbody>}
              </table>
              <div className="cart-actions"></div>
              <div className="cart-actions-coupon"></div>
            </form>
            <div className="cart-collaterals">
              <div className="cart-totals">
                <h2>{languages.cartTotals}</h2>
                <table className="shop-table">
                  <tbody>
                    <tr className="cart-subtotal">
                      <th>{languages.subtotal}</th>
                      <td data-title="Subtotal" className="subtotal">
                        <span>
                          <span className="bdi">
                            <span className="price-currency-symbol">Rwf</span>
                            {appFxs.formatNumbers(cartRecord.subtotal)}
                          </span>
                        </span>
                      </td>
                    </tr>
                    <tr className="order-total">
                      <th className="font-bold">{languages.total}</th>
                      <td data-title="Subtotal" className="subtotal font-bold">
                        <span>
                          <span className="bdi">
                            <span className="price-currency-symbol">Rwf</span>
                            {appFxs.formatNumbers(cartRecord.total)}
                          </span>
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div className="check-out-proceed py-2">
                  <LinkAchorRound
                    type="button"
                    text={languages.proceedToCheckout}
                    bgColor="#000"
                    hoverBgcolor="#f1aa00"
                    onClick={proceedOnCheckout}
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
    </>
  );
}


const CartProductRow = ({onItemQtyChanged, onItemRemoved, product, inlistPos}: ICartItemOper) => {
  const [inlineProd, setInlineProd] = useState<ICartOperationProd>(product);

  const onQtyChanged = (e: {field: string; value: any;}) => {
    setInlineProd({...inlineProd, quantity: Number(e.value)});
    onItemQtyChanged(inlistPos, Number(e.value));
  }

  const onProdRemoved = async () => {
    const bAsk =  (await appFxs.showConfirm(languages.remove, languages.removeThisItemMsg)).isConfirmed; 

    if(!bAsk)return;
    onItemRemoved(inlistPos);
  }


  return (
    <tr>
      <td className="product-thumbnail">
        <Link to={`/shop/product/${inlineProd.productId}`}>
          <img src={inlineProd.imagePath} alt="" />
        </Link>
      </td>
      <td className="product-name" data-title="product">
        <Link to={`/shop/product/${inlineProd.productId}`}>{inlineProd.productName}</Link>
      </td>
      <td className="product-price" data-title="price">
        <label>{languages.price}</label>
        <span>
          <span className="bdi">
            <span className="price-currency-symbol">Rwf</span>
            {inlineProd.productPrice}
          </span>
        </span>
      </td>
      <td className="product-quantity" data-title="quantity">
        <label>{languages.quantity}</label>
        <FormInputNumber 
          name="quantity" 
          value={inlineProd.quantity.toString()}
          onChange={onQtyChanged}
          step={1}
          min={1}
          max={100} />
      </td>
      <td className="product-subtotal" data-title="subtotal">
        <label>{languages.total}</label>
        <span>
          <span className="bdi">
            <span className="price-currency-symbol">Rwf</span>
            {appFxs.formatNumbers(inlineProd.subtotal)}
          </span>
        </span>
      </td>
      <td className="product-remove">
        <a
          className="remove flex justify-center items-center"
          data-product-id={inlineProd.productId}
          aria-label={languages.removeThisItem}
          title={languages.removeThisItem}
          onClick={onProdRemoved}
        >
          <span>x</span>
        </a>
      </td>
    </tr>
  );
}
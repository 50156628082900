import { IContactAddress, IPageTitleSection } from "src/models/smarttypes";
import { MainTitleWrapper } from "src/components/pages/MainTitleWrapper";

import getLanguages from 'src/utils/language';
import generalFxs from 'src/utils/general_fx';

import { useEffect } from "react";
import { SectionTitle } from "src/components/elements/sectiontitle/SectionTitle";
import { SubscribeForm } from "src/components/elements/subscribeform/SubscribeForm";
import { ContactAddressComp } from "src/components/elements/contactelementcomp/ContactAddressComp";
import { ColombContaForm } from "src/components/utils/askabtkidsform/AskAbtKidsForm";

const languages = getLanguages();
const appFxs = generalFxs();
const isEn = appFxs.getLocalLanguage() === 'en';

const lstContactAddress:IContactAddress[] = [
  {
    id: 0,
    iconClass: 'fa fa-map-signs',
    iconColor: '#8700ff',
    title: languages.ourLocation,
    lstDetails: [
      {
        type: 'text',
        label: '',
        text: isEn ? 'Kacyiru, 690 Street': 'Rue 690, Kacyiru',
      },
      {
        type: 'text',
        label: '',
        text: isEn ? 'P.O. Box: 3279, Kigali, Rwanda':'Bte Postale: 3279, Kigali, Rwanda',
      },
    ]
  },
  {
    id: 1,
    iconClass: 'fa fa-phone',
    iconColor: '#FF236C',
    title: languages.phoneNbr,
    lstDetails: [
      {
        type: 'phone',
        label: languages.administration,
        text: '(+250) (0) 788 510 589',
      },
      {
        type: 'phone',
        label: languages.techDpt,
        text: '(+250) (0) 789 308 440',
      },
    ]
  },
  
  {
    id: 2,
    iconClass: 'fa fa-envelope',
    iconColor: '#A5C347',
    title: languages.emailAddress,
    lstDetails: [
      {
        type: 'email',
        label: languages.generalInfo,
        text: 'info@lacolombiere.ac.rw',
      },
      {
        type: 'email',
        label: languages.administration,
        text: 'admin@lacolombiere.ac.rw',
      },
    ]
  }
];
const oElt: IPageTitleSection = {
  classes: "contact-us",
  currentText: languages.contact,
  pageTitle: languages.contactus,
};


export default function ContactUsPage() {
  document.title = `${languages.contactus} | ${languages.schoolName}`;
  
  useEffect(() => {
    window.scrollTo(0,0);
  }, []);

  return (
    <>
      <MainTitleWrapper {...oElt} />
      <section className="contact-header">
        <div className="w-container">
          <div className="title-wrapper text-center">
            <SectionTitle 
              title={languages.ourSchNearPlace}
              textColor="#fff"
              fontSize={46}
              fontName="Quicksand"
              leading="60px"
              />
          </div>
          <div className="mt-7">
            <div className="flex items-center justify-center">
              <div className="basis-full md:basis-[52%]">
                <div className="box-inner">
                  <SubscribeForm 
                    placeholder={languages.subscribeHereEmail}
                    submitText={languages.subscribe} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="contact-body py-[70px]">
        <div className="w-container">
          <div className="box-inner">
          <div className="flex flex-col md:flex-row">
            <div className="contact-address basis-full md:basis-[33%]">
              <div className="p-[10px] md:p-[30px]">
                <div className="flex flex-col justify-center">
                  {lstContactAddress.map(oAdr => <ContactAddressComp {...oAdr} key={oAdr.id} />)}
                </div>
              </div>
            </div>
            <div className="contact-form basis-full md:basis-[65%]">
              <div className="rounded-[30px] p-[10px] transition-elt bg-school-img">
                <div className="p-4 md:p-5 lg:p-[60px]">
                  <ColombContaForm />
                </div>
              </div>
            </div>
          </div>     
          </div>     
        </div>
      </section>
      <section className="contsct-map pb-[70px]">
        <div className="w-container">
          <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d997.8711988752874!2d30.074569015469773!3d-1.9426038443947313!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x19dca698f534475f%3A0xabb07cf96a03bf3c!2sLa%20Colombi%C3%A8re!5e0!3m2!1sen!2srw!4v1700141897260!5m2!1sen!2srw" 
            allowFullScreen={true}
            loading="lazy" 
            referrerPolicy="no-referrer-when-downgrade"
            title={languages.schoolName}
            className="w-full h-[450px]"/>
        </div>
      </section>
    </>
  );
}
import { ShapeBottomDivider } from "src/components/elements/shapebottomdivider/ShapeBottomDivider"

export const SpecialLangTrain = () => {
  return (
    <>
      <section className="topSection h-[250px] bg-slate-300 relative">
        <ShapeBottomDivider fillColor='#cecece' />
      </section>
      <section className="bottomSection">
        
      </section>
    </>
  )
}

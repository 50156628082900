import { useEffect } from "react";

import getLanguages from "src/utils/language";
import generalFxs from "src/utils/general_fx";
import { LinkAchorRound } from "src/components/elements/linkanchorround/LinkAchorRound";

import generalKidsPlaying from "src/assets/images/schoolimgs/bg-title-facilities.jpg";
import firstKinderGraten from "src/assets/images/schoolimgs/kindergarten_1.png";
import firstKinderGraten2 from "src/assets/images/schoolimgs/shool_fun.jpg";
import primarySchoolImg from "src/assets/images/schoolimgs/excellence_school.jpg";
import schoolUniform from "src/assets/images/schoolimgs/school_uniform.jpg";
import schoolTransport from "src/assets/images/schoolimgs/school_transport.jpg";

const languages = getLanguages();
const appFxs = generalFxs();
const isEn = appFxs.getLocalLanguage() === "en";

export default function KidsEnrollment() {
  document.title = `${languages.kidsEnrollt} | ${languages.schoolName}`;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <section className="bg-[#f6f9fc]">
      {/* Header */}
      <div className="abtus-header">
        <div className="w-full max-w-[1080px] mx-auto py-7">
          <div className="flex flex-row items-center flex-wrap">
            <div className="w-full md:w-[60%] order-1 md:order-2">
              <div className="p-4">
                <h1 className="text-5xl font-bold leading-[1.25] mb-3">
                  {languages.admReqrts}
                </h1>
                <p>
                  {isEn ? (
                    <>
                      Admission requirements for La Colombière typically
                      encompass a range of criteria designed to assess a
                      candidate's academic preparedness and potential for
                      success. These often include the submission of official
                      transcripts showcasing previous academic performance,
                      school fees, uniforms and transportation.
                    </>
                  ) : (
                    <>
                      Les conditions d'admission à La Colombière englobent
                      généralement une gamme de critères conçus pour évaluer la
                      préparation académique et le potentiel de réussite d'un
                      candidat. Celles-ci incluent souvent la soumission de
                      relevés de notes officiels démontrant les résultats
                      scolaires antérieurs, les frais de scolarité, les
                      uniformes et le transport.
                    </>
                  )}
                </p>
              </div>
            </div>
            <div className="w-full md:w-[40%] order-2 md:order-1">
              <div className="p-4 md:p-7">
                <div className="overflow-hidden rounded-lg border-neutral-400 border-solid">
                  <img
                    src={generalKidsPlaying}
                    alt=""
                    className="w-full object-cover rounded-lg object-top hover:scale-125 transition-transform duration-300"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Nursery */}
      <div className="abtus-mission bg-white">
        <div className="w-full max-w-[1080px] mx-auto py-7">
          <div className="flex flex-row items-center flex-wrap">
            <div className="w-full md:w-[50%] order-2">
              <div className="p-4 md:p-7">
                <div className="overflow-hidden rounded-lg border-neutral-400 border-solid">
                  <img
                    src={firstKinderGraten}
                    alt=""
                    className="w-full object-cover rounded-lg object-top hover:scale-125 transition-transform duration-300"
                  />
                </div>
              </div>
            </div>
            <div className="w-full md:w-[50%] order-1">
              <div className="p-4">
                <h1 className="text-3xl font-bold leading-[1.25] mb-3">
                  {languages.firstKinderg}
                </h1>
                <p className="text-justify">
                  {isEn ? (
                    <>
                      <ul className="list-cond-school">
                        <li>To be 3 years old (or plus)</li>
                        <li>Vaccination card</li>
                        <li>
                          Registration fee: <strong>50.000frw</strong> (non
                          refundable)
                        </li>
                        <li>
                          School fees per term: <strong>400.000frw</strong>
                        </li>
                        <li>1 Passport photo</li>
                      </ul>
                    </>
                  ) : (
                    <>
                      <ul className="list-cond-school">
                        <li>Avoir 3ans (ou plus)</li>
                        <li>Fiche de Vaccination</li>
                        <li>
                          Frais d'inscription : 50,000frw{" "}
                          <strong>(non remboursable)</strong>
                        </li>
                        <li>
                          Minerval par trimestre : <strong>400.000frw</strong>
                        </li>
                        <li>1 Photo passport</li>
                      </ul>
                    </>
                  )}
                </p>
                <p>
                  <LinkAchorRound
                    text={languages.contactus}
                    type="link"
                    href="/contactus"
                  />
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* 2nd nursery */}
      <div className="abtus-mission ">
        <div className="w-full max-w-[1080px] mx-auto py-7">
          <div className="flex flex-row items-center flex-wrap">
            <div className="w-full md:w-[50%] order-2 md:order-1">
              <div className="p-4 md:p-7">
                <div className="overflow-hidden rounded-lg border-neutral-400 border-solid">
                  <img
                    src={firstKinderGraten2}
                    alt=""
                    className="w-full object-cover rounded-lg object-top hover:scale-125 transition-transform duration-300"
                  />
                </div>
              </div>
            </div>
            <div className="w-full md:w-[50%] order-1 md:order-2">
              <div className="p-4">
                <h1 className="text-3xl font-bold leading-[1.25] mb-3">
                  {languages.sec_nurs_first_year}
                </h1>
                <p className="text-justify">
                  {isEn ? (
                    <>
                      <ul className="list-cond-school">
                        <li>School report for 2023- 2024 academic year</li>
                        <li>
                          Paying in slip for Registration fee:
                          <strong>50.000frw</strong> (non refundable)
                        </li>
                        <li>
                          School fees per term:<strong>400.000frw</strong>
                        </li>
                      </ul>
                    </>
                  ) : (
                    <>
                      <ul className="list-cond-school">
                        <li>Bulletin scolaire pour 2023-2024</li>
                        <li>
                          Bordereaux de versement des frais d'inscription :{" "}
                          <strong>50,000frw</strong> (non remboursable)
                        </li>
                        <li>
                          Minerval par trimestre : <strong>400.000frw</strong>
                        </li>
                      </ul>
                    </>
                  )}
                </p>
                <p>
                  <LinkAchorRound
                    text={languages.contactus}
                    type="link"
                    href="/contactus"
                  />
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* primary_school */}
      <div className="abtus-mission bg-white">
        <div className="w-full max-w-[1080px] mx-auto py-7">
          <div className="flex flex-row items-center flex-wrap">
            <div className="w-full md:w-[50%] order-2">
              <div className="p-4 md:p-7">
                <div className="overflow-hidden rounded-lg border-neutral-400 border-solid">
                  <img
                    src={primarySchoolImg}
                    alt=""
                    className="w-full object-cover rounded-lg object-top hover:scale-125 transition-transform duration-300"
                  />
                </div>
              </div>
            </div>
            <div className="w-full md:w-[50%] order-1">
              <div className="p-4">
                <h1 className="text-3xl font-bold leading-[1.25] mb-3">
                  {languages.primary_scool_det}
                </h1>
                <p className="text-justify">
                  {isEn ? (
                    <>
                      <ul className="list-cond-school">
                        <li>School report for 2023- 2024 academic year</li>
                        <li>
                          Paying in slip for Registration fee:
                          <strong>50.000frw</strong> (non refundable)
                        </li>
                        <li>
                          School fees per term:<strong>400.000frw</strong>
                        </li>
                        <li>
                          An entry exam is Scheduled after registration for
                          students registering from P2
                        </li>
                      </ul>
                    </>
                  ) : (
                    <>
                      <ul className="list-cond-school">
                        <li>Bulletin scolaire pour 2023-2024</li>
                        <li>
                          Bordereaux de versement des frais d'inscription :{" "}
                          <strong>50,000frw</strong> (non remboursable)
                        </li>
                        <li>
                          Minerval par trimestre : <strong>400.000frw</strong>
                        </li>
                        <li>
                          Un examen d'entrée est prévu après inscription
                          seulement pour les élèves qui s'inscrivent à partir de
                          2ème année Primaire.
                        </li>
                      </ul>
                    </>
                  )}
                </p>
                <p>
                  <LinkAchorRound
                    text={languages.contactus}
                    type="link"
                    href="/contactus"
                  />
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* school_uniform */}
      <div className="abtus-mission ">
        <div className="w-full max-w-[1080px] mx-auto py-7">
          <div className="flex flex-row items-center flex-wrap">
            <div className="w-full md:w-[50%] order-2 md:order-1">
              <div className="p-4 md:p-7">
                <div className="overflow-hidden rounded-lg border-neutral-400 border-solid">
                  <img
                    src={schoolUniform}
                    alt=""
                    className="w-full object-cover rounded-lg object-top hover:scale-125 transition-transform duration-300"
                  />
                </div>
              </div>
            </div>
            <div className="w-full md:w-[50%] order-1 md:order-2">
              <div className="p-4">
                <h1 className="text-3xl font-bold leading-[1.25] mb-3">
                  {languages.school_uniform}
                </h1>
                <p className="text-justify">
                  {isEn ? (
                    <>
                    <span className="text-base block mb-3">Uniforms are available at school and are paid with Momo</span>
                      <ul className="list-cond-school">
                        <li>Short: <strong>Rwf 8,000</strong></li>
                        <li>Skirt: <strong>Rwf 8,000</strong></li>
                        <li>Tie: <strong>Rwf 4,000</strong></li>
                        <li>Pair of socks: <strong>Rwf 4,000</strong></li>
                        <li>Sport wear (top and Bottom): <strong>Rwf 16,000</strong></li>
                        <li>Sweaters: <strong>Rwf 10,000</strong></li>
                      </ul>
                    </>
                  ) : (
                    <>
                    <span className="text-base block mb-3">Les uniformes sont vendus à l'école et payés avec Momo </span>
                      <ul className="list-cond-school">
                      <li>Culotte: <strong>8.000Rwf</strong></li>
                      <li>Jupe: <strong>8.000Rwf</strong></li>
                      <li>Cravate: <strong>4.000Rwf</strong></li>
                      <li>Chaussettes: <strong>4.000Rwf</strong></li>
                      <li>Tenue de sport (t-shirt and culotte): <strong>16.000Rwf</strong></li>
                      <li>Pulls: <strong>10.000Rwf</strong></li>
                      </ul>
                    </>
                  )}
                </p>
                <p>
                  <LinkAchorRound
                    text={languages.contactus}
                    type="link"
                    href="/contactus"
                  />
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* School transport */}
      <div className="abtus-header">
        <div className="w-full max-w-[1080px] mx-auto py-7">
          <div className="flex flex-row items-center flex-wrap">
            <div className="w-full md:w-[60%] order-1 md:order-2">
              <div className="p-4">
                <h1 className="text-5xl font-bold leading-[1.25] mb-3">
                  {languages.bus_transport}
                </h1>
                <p>
                  {isEn ? (
                    <>
                      School transportation is organised by the School with 12 buses 
                      that can be added depending on new destinations.
                    </>
                  ) : (
                    <>
                      L'école organise le transport des élèves avec 12 buses scolaires 
                      dans la ville de Kigali et à Nyamata Bugesera qui pourront s'ajouter 
                      chaque fois qu'il y a une nouvelle destination.
                    </>
                  )}
                </p>
              </div>
            </div>
            <div className="w-full md:w-[40%] order-2 md:order-1">
              <div className="p-4 md:p-7">
                <div className="overflow-hidden rounded-lg border-neutral-400 border-solid">
                  <img
                    src={schoolTransport}
                    alt=""
                    className="w-full object-cover rounded-lg object-top hover:scale-125 transition-transform duration-300"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

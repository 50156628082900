/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */

import { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";

//import {ReactComponent as MoreHoriz} from 'src/assets/images/svg/more-hor.svg';
import { IAlumniUser, ISocialEvent, ISocialEventCplx, ISocialEventDetail } from "src/models/alumni_models";

import generalFxs from 'src/utils/general_fx';
import generalLang from 'src/utils/language';

import appDecl from 'src/utils/declarations';
import NetServices from "src/utils/netservices";
import { LoadingElements } from "src/components/utils/LoadingElements";
import { HTMLContentComponent } from "./SingleBlogPage";

const appFxs = generalFxs();
const languages = generalLang();

export const SingleSocialEventPage = () => {
  const [socialDetail, setSocialdetail] = useState<ISocialEventCplx | null>(null);
  const [isLoading, setLoading] = useState<boolean>(true);

  const {socialKey} = useParams();
  const navigate = useNavigate();

  const getSocialDetail = async () => {
    const oResultOper = await NetServices.requestGet(`alumni/getonsesocialeventforweb/${socialKey}`)

    if(!oResultOper.bReturn){
      await appFxs.showAlert(oResultOper.msgTitle, oResultOper.msgBody);
      navigate('/alumni')
    }

    if(!oResultOper.socialEventCplx){
      await appFxs.showAlert(languages.missingData, languages.missingDataSocEvtRec);
      navigate('/alumni')
    }

    setSocialdetail(oResultOper.socialEventCplx!);
    setLoading(false);
  }
  useEffect(() => {
    getSocialDetail();
  }, []);

  if(isLoading) return <LoadingElements />

  return (
    <div className="bg-[#f0f2f5]">
      <div className="flex flex-col justify-center max-w-[500px] mx-auto py-6">        
        <ListSocialEventDetail socialDetail={socialDetail!} />
      </div>
    </div>
  );
}

const ListSocialEventDetail = ({socialDetail}: {socialDetail: ISocialEventCplx}) => {
  const {lstDetails, publisher, socialEvent} = socialDetail;

  return (
    <div className="flex flex-col">
      {lstDetails.map((oDetail, idx) => 
        <SocialEventDetElt key={idx} 
          detail={oDetail} 
          publisher={publisher}
          mainElt={socialEvent} />)}
    </div>
  );
}

const SocialEventDetElt = ({detail, publisher, mainElt}: 
  {
    detail: ISocialEventDetail, 
    publisher: IAlumniUser,
    mainElt: ISocialEvent,
  }) => {

    const dayDate = appFxs.formatDateWithHrsSpec(mainElt.eventDate)


    return (
      <div className="bg-white shadow-sm rounded-md mb-5">
        <div className="flex flex-col">
          <div className="event-header m-[12px] flex items-center">
            <div>
                <div className='w-[40px] h-[40px] rounded-full border border-solid border-slate-400' 
                    style={{
                        backgroundImage: `url(${appDecl.apiUrlTest}/cloud_data/alumni_profiles/${publisher.profilePicturePath ?? 'e0f1031e98db40d6b9da0c309f993ab4.png'})`,
                        backgroundSize: 'cover'}}>

                </div>
            </div>
            <div className="flex-1 ml-3">
                <div className='flex flex-col'>
                    <div className=''>
                        <span className='text-sm font-semibold'>{`${publisher.firstName} ${publisher.lastName}`}</span>
                    </div>
                    <div className='text-sm'>
                      <span>{dayDate}</span>
                    </div>
                </div>
            </div>
            <div className="flex items-center gap-1">
              {/* <div>
                <a className="group cursor-pointer relative flex items-center justify-center rounded-[50%] before:absolute before:top-0 before:left-0 before:right-0 before:bottom-0 before:bg-black/20 before:hidden">
                  <span className="w-[20px] h-[20px] flex items-center justify-center">
                    <MoreHoriz width={20} height={20} />
                  </span>
                  <div className="absolute right-0 bottom-0 top-0 rounded-[50%] opacity-0 bg-slate-300 group-hover:opacity-100 w-full h-full -z-[1]"></div>
                </a>
              </div>
              <div>
                <a className="group cursor-pointer relative flex items-center justify-center rounded-[50%] before:absolute before:top-0 before:left-0 before:right-0 before:bottom-0 before:bg-black/20 before:hidden">
                  <span className="w-[20px] h-[20px] flex items-center justify-center">
                    <i className="fa fa-times"></i>
                  </span>
                  <div className="absolute right-0 bottom-0 top-0 rounded-[50%] opacity-0 bg-slate-300 group-hover:opacity-100 w-full h-full -z-[1]"></div>
                </a>
              </div> */}
            </div>
          </div>
          <div className="event-body">
            <HTMLContentComponent 
              postContent={detail.description!} 
              isBlog={false} 
              cssStyle="data-content px-4 pb-4 text-sm leading-[22px]" />
            <div className='data-picture bg-[#A19690]'>
              <div className="inside">
                <div className='pt-[100%] relative overflow-hidden'>
                  <div className='final-inside'>
                    <div className="w-full h-full absolute flex items-center justify-center">
                    <img alt='' 
                      src={`${appDecl.apiUrlTest}/cloud_data/alumni_social_events/${detail.eventKey}/${detail.eventPhotoName}`} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="event-footer"> 
            <div className="ftitle text-sm font-bold p-2">{detail.photoTitle}</div>
            <div className="fetitle text-sm font-mono pb-2 px-2">
              <strong>{languages.in.toLowerCase()}</strong>&nbsp;
              <span className="text-black">{mainElt.eventTitle}</span>
            </div>
          </div>
        </div>
      </div>
    );
}
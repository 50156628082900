import { useEffect} from "react";




import getLanguages from "src/utils/language";
import generalFxs from "src/utils/general_fx";

import indianSmallGirl from "src/assets/images/schoolimgs/student_with_white.jpg";
import teacherJulienne from "src/assets/images/schoolimgs/teacher_julienne_uwingabire.jpg";
import languageStudents from 'src/assets/images/schoolimgs/room_lab_libr_1.jpg';
import socialScienceStudents from 'src/assets/images/schoolimgs/excellence_school_bg.png';
import infrastrureImg from 'src/assets/images/schoolimgs/school_build_10.jpg';
import schoolOrgImg from 'src/assets/images/schoolimgs/room_meethall_1.jpg';
import nationalCurric from 'src/assets/images/schoolimgs/room_lab_libr_1.jpg';

const languages = getLanguages();
const appFxs = generalFxs();



const isEn = appFxs.getLocalLanguage() === "en";

export default function AboutUsPage() {
  document.title = `${languages.aboutUs} | ${languages.schoolName}`;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <section className="bg-[#f6f9fc]">
      <div className="abtus-header">
        <div className="w-full max-w-[1080px] mx-auto py-7">
          <div className="flex flex-row items-center flex-wrap">
            <div className="w-full md:w-[60%]">
              <div className="p-4">
                <h1 className="text-5xl font-bold leading-[1.25] mb-3">
                  {languages.aboutUs}
                </h1>
                <p>
                  {isEn ? (
                    <>
                      La Colombière School and culture are a lot like our
                      product. They're crafted, not cobbled, for a delightful
                      experience.
                    </>
                  ) : (
                    <>
                      L'école et la culture de La Colombière ressemblent
                      beaucoup à notre produit. Ils sont fabriqués, non pavés,
                      pour une expérience délicieuse.
                    </>
                  )}
                </p>
              </div>
            </div>
            <div className="w-full md:w-[40%]">
              <div className="p-4 md:p-7">
                <div className="overflow-hidden rounded-lg border-neutral-400 border-solid">
                  <img
                    src={indianSmallGirl}
                    alt=""
                    className="w-full object-cover rounded-lg object-top hover:scale-125 transition-transform duration-300"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* about-mission */}
      <div className="abtus-mission bg-white">
        <div className="w-full max-w-[1080px] mx-auto py-7">
          <div className="flex flex-row items-center flex-wrap">
            <div className="w-full md:w-[60%] order-2 md:order-1">
              <div className="p-4 md:p-7">
                <div className="overflow-hidden rounded-lg border-neutral-400 border-solid">
                  <img
                    src={schoolOrgImg}
                    alt=""
                    className="w-full object-cover rounded-lg object-top hover:scale-125 transition-transform duration-300"
                  />
                </div>
              </div>
            </div>
            <div className="w-full md:w-[40%] order-1 md:order-2">
              <div className="p-4">
                <h1 className="text-3xl font-bold leading-[1.25] mb-3">
                  {languages.organization_school}
                </h1>
                <p className="text-justify">
                  {isEn ? (
                    <>
                      La Colombière School is an entirely day School with two
                      sections: Pre-primary (Nursery) and Primary following the
                      National curriculum. The school is headed by a 7-member
                      Board of Governors, a principal and two head of Studies.
                      Other than the board of Directors, the board has a
                      Parents' committee constituted of a President, a Vice
                      President and a secretary, linking parents and the school
                      administration.
                    </>
                  ) : (
                    <>
                      L'école La Colombière est une école entièrement de jour
                      composée de deux sections : Pré-primaire (Maternelle) et
                      Primaire suivant le programme national. L'école est
                      dirigée par un conseil d'administration composé de 7
                      membres, d'un directeur et de deux directeurs d'études.
                      Outre le conseil d'administration, le conseil scolaire
                      dispose d'un comité de parents constitué d'un président,
                      d'un vice-président et d'un secrétaire, faisant le lien
                      entre les parents et la direction de l'école.
                    </>
                  )}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* administrative-team */}
      <div className="abtus-administrative bg-teal-100/40">
        <div className="w-full max-w-[1080px] mx-auto py-9">
          <div className="flex flex-row items-center flex-wrap">
            <div className="w-full md:w-[50%] order-2">
              <div className="p-4 md:p-7">
                <div className="overflow-hidden rounded-lg border-neutral-400 border-solid">
                  <img
                    src={teacherJulienne}
                    alt=""
                    className="w-full object-cover rounded-lg object-top hover:scale-125 transition-transform duration-300"
                  />
                </div>
              </div>
            </div>
            <div className="w-full md:w-[50%] order-1">
              <div className="p-4">
                <h1 className="text-3xl font-bold leading-[1.25] mb-3">
                  {languages.team}
                </h1>
                <p className="text-justify">
                  {isEn ? (
                    <>
                      Supported by an administrative team including a head of
                      Finance and Administration, 2 accountants, a secretary and
                      2 librarians. Cleaners and Security guards are people we
                      respect and appreciate a lot. The Nursery section with 12
                      streams, Nursery 1 for the 3 years old, Nursery 2 for the
                      4 years old, and Nursery 3 for the 5years old managed by a
                      team of 28 professionals in Nursery teaching. The Primary
                      section is supported by 64 dedicated and professional
                      teachers.
                    </>
                  ) : (
                    <>
                      Appuyé par une équipe administrative comprenant un
                      responsable des finances et de l'administration, 2
                      comptables, une secrétaire et 2 bibliothécaires. Les
                      agents de nettoyage et les agents de sécurité sont des
                      personnes que nous respectons et apprécions beaucoup. La
                      section Maternelle avec 12 filières, Maternelle 1 pour les
                      3 ans, Maternelle 2 pour les 4 ans et Maternelle 3 pour
                      les 5 ans gérée par une équipe de 28 professionnels de
                      l'enseignement maternel. La section Primaire est soutenue
                      par 64 enseignants dévoués et professionnels.
                    </>
                  )}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* languages */}
      <div className="abtus-administrative">
        <div className="w-full max-w-[1080px] mx-auto py-9">
          <div className="flex flex-row items-start flex-wrap">
            <div className="w-full md:w-[60%] order-2 md:order-1">
              <div className="p-4 md:p-7">
                <div className="overflow-hidden rounded-lg border-neutral-400 border-solid">
                  <img
                    src={languageStudents}
                    alt=""
                    className="w-full object-cover rounded-lg object-top hover:scale-125 transition-transform duration-300"
                  />
                </div>
              </div>
            </div>
            <div className="w-full md:w-[40%] order-1 md:order-2">
              <div className="p-4">
                <h1 className="text-3xl font-bold leading-[1.25] mb-3">
                  {languages.language_skills}
                </h1>
                <p className="text-justify mb-5">
                  {isEn ? (
                    <>
                      La Colombière School has designed itself bilingualism: French and English, in the way of equipping its learners becoming conversant with both French and English: French as a language is made of numerous rules, a child who is enrolled in La Colombière School starts Nusery 1, learning all the topics in French and English as a subject up to Primary 5 whereby the French program ends. Though English is taught as a subject, it is supported by enough material and technics that make the learners assimilate it well.
                    </>
                  ) : (
                    <>
                      L'école de La Colombière a conçu elle-même le bilinguisme : français et anglais, de manière à permettre à ses apprenants de maîtriser à la fois le français et l'anglais : le français comme langue est fait de nombreuses règles, un enfant inscrit à l'école de La Colombière entre en maternelle 1, apprendre tous les sujets en français et en anglais comme matière jusqu'à la 5e primaire où se termine le programme de français. Bien que l'anglais soit enseigné en tant que matière, il est soutenu par suffisamment de matériel et de techniques pour que les apprenants l'assimilent bien.
                    </>
                  )}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* social_sciences */}
      <div className="abtus-administrative bg-teal-100/40">
        <div className="w-full max-w-[1080px] mx-auto py-9">
          <div className="flex flex-row items-start flex-wrap">
            <div className="w-full md:w-[50%] order-2">
              <div className="p-4 md:p-7">
                <div className="overflow-hidden rounded-lg border border-neutral-400 border-solid">
                  <img
                    src={socialScienceStudents}
                    alt=""
                    className="w-full object-cover rounded-lg object-top hover:scale-125 transition-transform duration-300"
                  />
                </div>
              </div>
            </div>
            <div className="w-full md:w-[50%] order-1">
              <div className="p-4">
                <h1 className="text-3xl font-bold leading-[1.25] mb-3">
                  {languages.social_andscience_studeis}
                </h1>
                <p className="text-justify mb-5">
                {isEn ? (
                    <>
                      In that process, science and social studies start being taught in English from Primary 4 to make sure students coming from Primary 3 (lower classes) are familiar with the English terms. This makes possible the Primary 6 learn all the subjects in English and get ready for the National Exam because it is set and examined in English. This has been followed since 2010; The results indicate that regardless of whether pupils start with French as the language of instruction, English and Kinyarwanda as subjects, the performance at the end of Primary level remains excellent. 
                    </>
                  ) : (
                    <>
                      Dans ce processus, les sciences et les études sociales commencent à être enseignées en anglais à partir de la 4e primaire afin de garantir que les élèves de la 3e primaire (classes inférieures) soient familiers avec les termes anglais. Cela permet aux élèves de 6e primaire d'apprendre toutes les matières en anglais et de se préparer à l'examen national car celui-ci est organisé et examiné en anglais. Cela est suivi depuis 2010 ; Les résultats indiquent que, que les élèves débutent avec le français comme langue d'enseignement, l'anglais ou le kinyarwanda comme matières, les résultats à la fin du niveau primaire restent excellents.
                    </>
                  )}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* infrastructure */}
      <div className="abtus-administrative bg-white">
        <div className="w-full max-w-[1080px] mx-auto py-9">
          <div className="flex flex-row items-center flex-wrap">
            <div className="w-full md:w-[70%] order-2 md:order-1">
              <div className="p-4 md:p-7">
                <div className="overflow-hidden rounded-lg border border-neutral-400 border-solid">
                  <img
                    src={infrastrureImg}
                    alt=""
                    className="w-full object-cover rounded-lg object-top hover:scale-125 transition-transform duration-300"
                  />
                </div>
              </div>
            </div>
            <div className="w-full md:w-[30%] order-1 md:order-2">
              <div className="p-4">
                <h1 className="text-3xl font-bold leading-[1.25] mb-3">
                  {languages.infrastructure}
                </h1>
                <p className="text-justify mb-5">
                {isEn ? (
                    <>
                      La Colombière has ample infrastructures that are ample to rhyme with the class sizes of 30 pupils in Nursery and 35 in Primary. The school library, the computer lab stocked with 45 computers, a school demonstration garden are among the supporting facilities. New premises of La Colombière School is being built so that we can have ample space for all the modern facilities.
                    </>
                  ) : (
                    <>
                      La Colombière dispose d'infrastructures nombreuses, suffisantes pour répondre à des classes de 30 élèves en maternelle et 35 en primaire. La bibliothèque scolaire, le laboratoire informatique équipé de 45 ordinateurs et un jardin de démonstration scolaire font partie des installations de soutien. De nouveaux locaux de l'école La Colombière sont en cours de construction afin que nous puissions disposer de suffisamment d'espace pour toutes les installations modernes.
                    </>
                  )}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* curriculum */}
      <div className="abtus-administrative">
        <div className="w-full max-w-[1080px] mx-auto py-9">
          <div className="flex flex-row items-center flex-wrap">
            <div className="w-full md:w-[40%] order-2">
              <div className="p-4 md:p-7">
                <div className="overflow-hidden rounded-lg border border-neutral-400 border-solid">
                  <img
                    src={nationalCurric}
                    alt=""
                    className="w-full object-cover rounded-lg object-top hover:scale-125 transition-transform duration-300"
                  />
                </div>
              </div>
            </div>
            <div className="w-full md:w-[60%] order-1">
              <div className="p-4">
                <h1 className="text-3xl font-bold leading-[1.25] mb-3">
                  {languages.nationalCurric}
                </h1>
                <p className="text-justify mb-5">
                {isEn ? (
                    <>
                      We are following the national curriculum; we have a dynamic team of teachers that has borrowed leaf in the best teaching practices from different countries. We do spare neither resources nor efforts to get teachers and students the best text books, charts and other learning &teaching tools. We have a number of extra curricula activities: we developed a calendar that will be a guiding compass enabling an active involvement of all leaners, balancing the extra curricula activities with the academic work. Regular refresher courses will soon be started so that our teachers can keep abreast with modern teaching methods and practices.
                    </>
                  ) : (
                    <>
                      Nous suivons le programme national ; nous avons une équipe dynamique d’enseignants qui ont emprunté les meilleures pratiques pédagogiques de différents pays. Nous n'épargnons ni les ressources ni les efforts pour offrir aux enseignants et aux étudiants les meilleurs manuels, tableaux et autres outils d'apprentissage et d'enseignement. Nous avons un certain nombre d'activités extra-scolaires : nous avons développé un calendrier qui sera une boussole permettant une implication active de tous les apprenants, en équilibrant les activités extra-scolaires avec le travail académique. Des cours de recyclage réguliers seront bientôt lancés afin que nos enseignants puissent se tenir au courant des méthodes et pratiques pédagogiques modernes.
                    </>
                  )}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

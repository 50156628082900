/* eslint-disable react-hooks/exhaustive-deps */
import { Link } from 'react-router-dom';

import {ReactComponent as CurricBook} from 'src/assets/images/icons/svg/curr-book.svg';
import {ReactComponent as CurricGuitar} from 'src/assets/images/icons/svg/curr-guitar.svg';
import {ReactComponent as CurricCertificate} from 'src/assets/images/icons/svg/curr-certificate.svg';
import {ReactComponent as CurricStaff} from 'src/assets/images/icons/svg/curr-staff.svg';

import kinderGartenImg from 'src/assets/images/schoolimgs/kindergarten_2.png';
import primarySchoolImg from 'src/assets/images/schoolimgs/primary_school_2.png';

//curr-icon-cover-certificate.png


import { TitleSeparator } from 'src/components/elements/titleseparator/TitleSeparator';

import { 
  CurricularType, 
  ICurricular, 
  ICurricularData, 
  ICurricularElt, 
  InnerCss,
 } from 'src/models/smarttypes';


import getLanguages from 'src/utils/language';
import generalFxs from 'src/utils/general_fx';

const languages = getLanguages();
const appFxs = generalFxs();
const isEn = appFxs.getLocalLanguage() === 'en';
const curriculumElts:ICurricularData[] = [
  {
    bgColor: '#def5ff ',
    linkColor: '#64c8ff',
    description: isEn ? 
      "We accept children from 3 to 6 years old. Please follow the instructions on how to enter.":
      "Nous acceptons les enfants de 3 à 6 ans. Veuillez suivre les insctructions sur les modalités d'admission.",
    href: '/',
    iconType: CurricularType.lower,
    label: isEn ? 
      "Kindergarten":
      "Ecole maternelle",
    id: 0
  },
  {
    bgColor: '#fff4dc ',
    linkColor: '#f0aa00',
    description: isEn ? 
    "Above the age of 6, we follow the national rules regarding the admission of children from primary school.":
    "Au-delà de 6 ans, nous suivons les règles nationales concernant l'admission des enfants dès l'école primaire.",
    href: '/',
    iconType: CurricularType.medium,
    label: isEn ? 'Primary school': 'Ecole primaire',
    id: 1
  },
];


export const HomeCurricular = () => {


  return (
    <div className='relative'>
        <section className='home-curricular'>
            <div className='w-container px-5 sm:px-0 md:px-10'>
                <div className="py-[70px]">
                    <div className='mb-[20px] text-center'>
                      <h2>{isEn ? 'National Curriculum': "Programme national"}</h2>
                      <TitleSeparator titleColor='#ff6d9d' />
                    </div>
                    <div className="flex flex-col sm:flex-row sm:flex-wrap sm:justify-between md:justify-around">
                      {curriculumElts.map(oCurr => <CurriculumElemntComp currElt={oCurr} key={oCurr.id} />)}
                    </div>
                </div>
            </div>
        </section>
    </div>
  )
}


export const CurriculumElemntComp = ({currElt}: ICurricularElt) => {
  const {hasLink, circleColor, typeView, bgColor, linkColor, description, href, iconType,label} = currElt;
  const oView = typeView ?? 'normal';
  const oCircleColor = circleColor ?? '#5d58ef';
  const bHasLink = hasLink ?? true;


    const OEltIconImg = 
    iconType === CurricularType.lower ? kinderGartenImg:
    CurricularType.medium ? primarySchoolImg:
    kinderGartenImg;


  return (
    <div style={{'--ws-bg-color': bgColor} as InnerCss} className='curr-element mb-10 basis-full md:basis-[49%] lg:basis-[32%] relative flex flex-col items-center justify-center py-[50px] px-[30px]'>
      <div className='icon-area p-[15px]'>
        {oView === 'normal' && <span className="normal icon-wrapper">
          {/* <OEltIcon width={145} height={145} /> */}
          <img alt='' src={OEltIconImg} className='w-[145px] h-[145px]' />
        </span>}
        {oView === 'circle' && <span className="circle icon-wrapper-d"
        style={{'--bg-color-icon-w': oCircleColor} as InnerCss}>
          {/* <OEltIcon width={145} height={145} /> */}
          <img alt='' src={OEltIconImg} className='w-[145px] h-[145px]' />
        </span>}
      </div>
      <div className='text-area'>
        <h4>
          <span>{label}</span>
        </h4>
        
        <p className='curr-description'>
          {description}
          {bHasLink &&<>
          <br/>
          <Link className='hidden' style={{'--wise-link-color': linkColor, display: 'none'} as InnerCss}  to={href}>{languages.readMore}</Link>
          </>}
        </p>
      </div>
    </div>
  );
}


export const CurricularComp = ({detail, title, type}: ICurricular) => {
  const OIcon = 
  type === 'book' ? CurricBook:
  type === 'certificate' ? CurricCertificate:
  type === 'guitar' ? CurricGuitar:
  type === 'staff' ? CurricStaff:
  CurricBook;

  const oClass = 
    type === 'book' ? 'book':
    type === 'certificate' ? 'certificate':
    type === 'guitar' ? 'skills':
    type === 'staff' ? 'staff':
    'book';

  return (
    <div className='basis-full md:basis-[33%]'>
      <div className='comp-inner p-[15px] flex flex-col'>
        {/* icon */}
        <div className='comp-icon mb-[20px]'>
          <div className={`w-c-inner ${oClass}`}>
            <div className="w-icon-wrapper">
              <div className="comp-icon-img">
                <OIcon />
              </div>
            </div>
          </div>
        </div>
        {/* title */}
        <div className='comp-title'>
          <div className='mb-[15px]'>
            <h4>{title}</h4>
          </div>
        </div>
        {/* details */}
        <div className='comp-detail'>
          <div className='text-center'>
            <p>{detail}</p>
          </div>
        </div>
      </div>
    </div>
  );
}
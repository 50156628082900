import { useEffect, useState } from 'react';

import { FormInputElt } from 'src/components/elements/forminputelt/FormInputElt'
import { LinkAchorRound } from 'src/components/elements/linkanchorround956/LinkAchorRound'
import { IAskAboutKid, IAskAboutKidError, IAskAboutKidSend, IContactAddressForm, IContactAddressFormError, IFieldRecord } from 'src/models/smarttypes';

import getLanguages from 'src/utils/language';
import generalFx from 'src/utils/general_fx';
import { RecaptaField } from 'src/components/elements/recaptafield/RecaptaField';
import NetServices from 'src/utils/netservices';
import { SenderData } from 'src/models/netreturn';
import { Link } from 'react-router-dom';

const languages = getLanguages();
const appFxs = generalFx();


const defaultAskKid:IAskAboutKid = {
    emailAddress: '',
    message: '',
    studies: 'fc',
    telNumber: '',
    userName: '',
    captcthaVal: appFxs.getRandomDigits(5),
}

const classes = [
  {id:'Kindergarten - 1', label: languages.classKind1},
  {id:'Kindergarten - 2', label: languages.classKind2},
  {id:'Kindergarten - 3', label: languages.classKind3},

  {id:'Primary - 1', label: languages.classPrim1},
  {id:'Primary - 2', label: languages.classPrim2},
  {id:'Primary - 3', label: languages.classPrim3},
  {id:'Primary - 4', label: languages.classPrim4},
  {id:'Primary - 5', label: languages.classPrim5},
  {id:'Primary - 6', label: languages.classPrim6},
]
const defaultMsg: IContactAddressForm = {
  contactKey: '',
  firstName: '',
  lastName: '',
  mailAddress: '',
  contactMessage: '',
  lookingForWhat: 0,
  phoneAddress: '',
  priorityType: 0,
  captchaValue: appFxs.getRandomDigits(5),
}


export const AskAbtKidsForm = () => {
    const [askAbtData, setAskAbtData] = useState<IAskAboutKid>(defaultAskKid);  
    const [formErrors, setFormErrors] = useState<IAskAboutKidError>({});
    const [isRequesting, setIsRequesting] = useState(false);
    const [isformSent, setIsFormSent] = useState(false);

    let formRecapVal = "";

    const onChangeField = (e: IFieldRecord) => {
        const dData = {...askAbtData, [e.field]: e.value} as IAskAboutKid;
        setAskAbtData(dData);       
      setAskAbtData({ ...askAbtData, [e.field]: e.value });
    };
    const changeRecaptchaVal = async (e: string) =>{
        formRecapVal = e;
        formRecapVal = e;
    }
    const submitAskAbtKids = async () =>{
      const errors: IAskAboutKidError = {};
      if (!askAbtData.userName) {
        errors.userName = `${languages.userName} ${languages.isRequired}`;
      }
      if (!askAbtData.telNumber || askAbtData.telNumber.length !== 12) {
        errors.telNumber = `${languages.phoneNbr} ${languages.isRequired}`;
      } else if (askAbtData.telNumber.length !== 12) {
        errors.telNumber = `${languages.phoneNbr} ${languages.requires12Dig}`;
      }

      if (!askAbtData.emailAddress || !appFxs.isEmailValid(askAbtData.emailAddress)) {
        errors.emailAddress = !askAbtData.emailAddress
          ? `${languages.emailAddress} ${languages.isRequired}`
          : `${languages.emailAddress} ${languages.hasWrongEmFormat}`;
      }
      if (!askAbtData.message) {
        errors.message = `${languages.message} ${languages.isRequired}`;
      }
      const isCaptchaError = askAbtData.captcthaVal !== formRecapVal;
      if (isCaptchaError) {
        errors.captcthaVal = languages.captchaError;
      }

      setFormErrors(errors);
      const hasErrors = Object.keys(errors).length > 0;
      if(hasErrors)return;

      const bAsk = await appFxs.showConfirm(languages.sendRequest, languages.sendRequestMsg);
      if(!bAsk.isConfirmed)return;
      setIsRequesting(true);

      const oData:IAskAboutKidSend = {
        operationKey: appFxs.generateUUID(),
        userName: askAbtData.userName,
        emailAddress: askAbtData.emailAddress,
        telNumber: askAbtData.telNumber,
        studies: askAbtData.studies,
        message: askAbtData.message,
        captcthaVal: askAbtData.captcthaVal,
      };
      const senderData:SenderData = {
        askAboutKidsData: oData,
      }

      const oRes = await NetServices.requestPost('web/sendrequestaboutkids', senderData);

      if(!oRes.bReturn){
        setIsRequesting(false);
        appFxs.showAlert(oRes.msgTitle, oRes.msgBody);
        return;
      }

      setIsRequesting(false);
      setIsFormSent(true);
    }

    useEffect(() => {
      setAskAbtData({...askAbtData, captcthaVal: appFxs.getRandomDigits(5)})
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


  return (
    <div className="form-body">
      <div role="form">
        {(!isRequesting && isformSent) && 
          <div className='h-[150px] p-[15px] flex flex-col justify-center'>
            <h4 className='text-purple-700' style={{fontSize: '18px'}}>{languages.yrReqAskKidsSent}</h4>
          </div>
        }
        {!isformSent && 
          <form className="ask-form-elt">
            <FormInputElt
              name="userName"
              placeholder={languages.yourName.toLowerCase()}
              type="text"
              value={askAbtData.userName}
              onChange={onChangeField}
              errorMessage={formErrors.userName}
              disabled={isRequesting}
            />

            <FormInputElt
              name="emailAddress"
              placeholder={languages.emailAddress.toLowerCase()}
              type="email"
              value={askAbtData.emailAddress}
              onChange={onChangeField}
              errorMessage={formErrors.emailAddress}
              disabled={isRequesting}
            />
            <FormInputElt
              name="telNumber"
              placeholder={languages.phoneNbr.toLowerCase()}
              type="tel"
              value={askAbtData.telNumber}
              onChange={onChangeField}
              errorMessage={formErrors.telNumber}
              maxLength={12}
              disabled={isRequesting}
            />
            <FormInputElt
              name="studies"
              type="select"
              selectOptions={classes}
              value={askAbtData.studies}
              onChange={onChangeField}
              errorMessage={formErrors.studies}
              disabled={isRequesting}
            />
            <FormInputElt
              name="message"
              type="textarea"
              rows={5}
              placeholder={languages.whatKnow.toLowerCase()}
              value={askAbtData.message}
              onChange={onChangeField}
              errorMessage={formErrors.message}
              maxLength={5000}
              disabled={isRequesting}              
            />
            <RecaptaField 
              startingValue={askAbtData.captcthaVal} 
              onChange={changeRecaptchaVal}
              disabled={isRequesting} />
            

            <div className="bt-align">
              {!isRequesting && <LinkAchorRound
                text={languages.submitNow}
                type="button"
                bgColor="#f0aa00"
                hoverBgcolor="#ff236c"
                onClick={submitAskAbtKids}
              />}
              {isRequesting && 
                <span className='inline-block py-3'>
                  <i className='fa fa-spinner fa-spin fa-3x'></i>
                </span>
              }
            </div>
          </form>
        }
      </div>
    </div>
  );
}

export const ColombContaForm = () => {
  const [formData, setFormData] = useState<IContactAddressForm>(defaultMsg);
  const [formErrors, setFormErrors] = useState<IContactAddressFormError>({});
  const [isformSent, setIsFormSent] = useState(false);
  const [isSending, setIsSending] = useState(false);

  let formRecapVal = "";

  const onChangeField = (e: IFieldRecord) => {
    const dData = { ...formData, [e.field]: e.value } as IContactAddressForm;
    //validateForm(dData);
    setFormData(dData);
  };

  const changeRecaptchaVal = async (e: string) => {
    formRecapVal = e;
    //validateForm(formData);
  };


  const submitContactMessage = async () => {
    const errors: IContactAddressFormError = {};
    if (!formData.firstName) {
      errors.firstName = `${languages.firstName} ${languages.isRequired}`;
    }
    if (!formData.lastName) {
      errors.lastName = `${languages.lastName} ${languages.isRequired}`;
    }
    if (!formData.mailAddress) {
      errors.mailAddress = `${languages.emailAddress} ${languages.isRequired}`;
    } else if (!appFxs.isEmailValid(formData.mailAddress)) {
      errors.mailAddress = `${languages.emailAddress} ${languages.hasWrongEmFormat}`;
    }
    if (!formData.phoneAddress) {
      errors.phoneAddress = `${languages.phoneNbr} ${languages.isRequired}`;
    } else if (formData.phoneAddress.length !== 12) {
      errors.phoneAddress = `${languages.phoneNbr} ${languages.requires12Dig}`;
    }

    if (!formData.contactMessage) {
      errors.contactMessage = `${languages.message} ${languages.isRequired}`;
    }

    if (!formData.priorityType) {
      errors.priorityType = `${languages.priorityType} ${languages.isRequired}`;
    }
    if (!formData.lookingForWhat) {
      errors.lookingForWhat = `${languages.lookingWhat} ${languages.isRequired}`;
    }

    setFormErrors(errors);
    const hasErrors = Object.keys(errors).length > 0;
    const isCaptchaError = formData.captchaValue !== formRecapVal;

    if(hasErrors || isCaptchaError) return;
    
    const bAsk = await appFxs.showConfirm(
      languages.sendMessage,
      languages.sendMessageMsg
    );
    if (!bAsk.isConfirmed) return;
    setIsSending(true);

    const oData: IContactAddressForm = {
      contactKey: appFxs.generateUUID(),
      firstName: formData.firstName,
      lastName: formData.lastName,
      mailAddress: formData.mailAddress,
      phoneAddress: formData.phoneAddress,
      lookingForWhat: formData.lookingForWhat,
      priorityType: formData.priorityType,
      contactMessage: formData.contactMessage,
      captchaValue: formData.captchaValue,
    };
    const senderData: SenderData = {
      contactInfoData: oData,
    };

    const oRes = await NetServices.requestPost(
      "web/sendcontactinfomessage",
      senderData
    );

    if (!oRes.bReturn) {
      appFxs.showAlert(oRes.msgTitle, oRes.msgBody);
      setIsSending(false);
      return;
    }

    setIsFormSent(true);
    setIsSending(false);
  };

  if(isformSent){
    return (
      <div className='p-[10px] rounded-[10px] text-zinc-500 text-[18px]'>
        {languages.thxContactUs}<br/>
        <strong>{languages.schoolName}</strong><br/><br/>
        <Link className='text-blue-500 hover:text-blue-700 transition-colors' to={'/'}>{languages.home}</Link>
      </div>
    );
  }

  return (
    <form className="flex flex-wrap md:justify-between">
      <FormInputElt
        type="text"
        name="firstName"
        placeholder={languages.firstName}
        value={formData.firstName}
        errorMessage={formErrors.firstName}
        otherClasses="basis-full mb-1 md:mb-3 lg:basis-[48%]"
        onChange={onChangeField}
        maxLength={15}
        disabled={isSending}
      />
      <FormInputElt
        type="text"
        name="lastName"
        placeholder={languages.lastName}
        value={formData.lastName}
        errorMessage={formErrors.lastName}
        otherClasses="basis-full mb-1 md:mb-3 lg:basis-[48%]"
        onChange={onChangeField}
        maxLength={15}
        disabled={isSending}
      />
      <FormInputElt
        type="email"
        name="mailAddress"
        placeholder={languages.emailAddress}
        value={formData.mailAddress}
        errorMessage={formErrors.mailAddress}
        otherClasses="basis-full mb-1 md:mb-3 lg:basis-[48%]"
        onChange={onChangeField}
        maxLength={50}
        disabled={isSending}
      />
      <FormInputElt
        type="tel"
        name="phoneAddress"
        placeholder={languages.phoneNbr}
        value={formData.phoneAddress}
        errorMessage={formErrors.phoneAddress}
        otherClasses="basis-full mb-1 md:mb-3 lg:basis-[48%]"
        onChange={onChangeField}
        maxLength={12}
        disabled={isSending}
      />
      <FormInputElt
        type="select"
        name="lookingForWhat"
        placeholder={languages.lookingWhat}
        errorMessage={formErrors.lookingForWhat}
        disabled={isSending}
        selectOptions={[
          {
            id: "0",
            label: languages.job,
          },
          {
            id: "1",
            label: languages.student,
          },
          {
            id: "2",
            label: languages.staff,
          },
        ]}
        value={formData.lookingForWhat}
        otherClasses="basis-full mb-1 md:mb-3 lg:basis-[48%]"
        onChange={onChangeField}
      />
      <FormInputElt
        type="select"
        name="priorityType"
        placeholder={languages.priorityType}
        errorMessage={formErrors.priorityType}
        disabled={isSending}
        selectOptions={[
          {
            id: "0",
            label: languages.low,
          },
          {
            id: "1",
            label: languages.medium,
          },
          {
            id: "2",
            label: languages.highPrior,
          },
        ]}
        value={formData.priorityType}
        otherClasses="basis-full mb-1 md:mb-3 lg:basis-[48%]"
        onChange={onChangeField}
      />
      <FormInputElt
        type="textarea"
        name="contactMessage"
        placeholder={languages.message}
        rows={6}
        maxLength={1500}
        value={formData.contactMessage}
        errorMessage={formErrors.contactMessage}
        otherClasses="basis-full mb-1 md:mb-3"
        onChange={onChangeField}
        disabled={isSending}
      />
      <div className="w-full flex justify-end">
        <RecaptaField
          startingValue={formData.captchaValue}
          onChange={changeRecaptchaVal}
          disabled={isSending}
        />
      </div>

      <div className="basis-full mb-1 md:mb-3 flex justify-center">
        {!isSending && <LinkAchorRound
          type="button"
          text={languages.sendMessage}
          bgColor="#8700ff"
          hoverBgcolor="#000000"
          onClick={submitContactMessage}
          disabled={isSending}
        />}

      {isSending && <span className="loading loading-spinner loading-lg"></span>}
      </div>
    </form>
  );
};
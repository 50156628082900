import { useEffect } from "react";

import {
  IStaffStatusElt,
  IStaffTeam,
  TSocialIcon,
} from "src/models/smarttypes";
//import { MainTitleWrapper } from "src/components/pages/MainTitleWrapper";

import getLanguages from "src/utils/language";
import generalFxs from "src/utils/general_fx";

import teamFounder from "src/assets/images/schoolimgs/founde_img_1.png";
import teamPrincipal from "src/assets/images/schoolimgs/martine_wide.jpg";

import seniorStaffPrefet from "src/assets/images/schoolimgs/staff_prefet.jpg";
import seniorStaffMarina from "src/assets/images/schoolimgs/staff_marina.jpg";
import seniorStaffMaryse from "src/assets/images/schoolimgs/maryse_daf_landscape.jpg";

import seniorStaff1 from "src/assets/images/schoolimgs/staff1.jpg";
import seniorStaff2 from "src/assets/images/schoolimgs/staff2.jpg";
import seniorStaff3 from "src/assets/images/schoolimgs/staff3.jpg";
import seniorStaff4 from "src/assets/images/schoolimgs/staff4.jpg";
import seniorStaff5 from "src/assets/images/schoolimgs/staff5.jpg";
import seniorStaff6 from "src/assets/images/schoolimgs/staff6.jpg";

import teacherStaff1 from "src/assets/images/schoolimgs/teacher_kafeero_charles.jpg";
import teacherStaff2 from "src/assets/images/schoolimgs/teacher_mukarubibi_anne.jpg";
import teacherStaff3 from "src/assets/images/schoolimgs/teacher_mukundwa_viviane.jpg";
import teacherStaff4 from "src/assets/images/schoolimgs/teacher_julienne_uwingabire.jpg";
import teacherStaff5 from "src/assets/images/schoolimgs/teacher_seraphine_mukayiranga.jpg";
import teacherStaff6 from "src/assets/images/schoolimgs/teacher_jules_mbiringi_rugira.jpg";
import teacherStaff7 from "src/assets/images/schoolimgs/teacher_pierre_celestin_habimana.jpg";
import teacherStaff8 from "src/assets/images/schoolimgs/teacher_martine_kazubwenge.jpg";
import teacherStaff9 from "src/assets/images/schoolimgs/teacher_roseline_kampoze.jpg";

import founderSign from "src/assets/images/signatures/founder-signature-img.png";
import principalSign from "src/assets/images/signatures/principal-signature-img.png";
import { TitleSeparator } from "src/components/elements/titleseparator/TitleSeparator";

import { LinkAchorRound } from "src/components/elements/linkanchorround956/LinkAchorRound";
/* import { listOfStaffsDb } from "src/utils/tempodata";
import { StaffInstuctorcomp } from "../home/QualifiedStaffs";
import { TestimonialsComp } from "../home/ClientTestimonials"; */
import { ShapeBottomDivider } from "src/components/elements/shapebottomdivider/ShapeBottomDivider";
import { COLORS } from "src/utils/wcolors";

const languages = getLanguages();
const appFxs = generalFxs();

const lstfounderPrincipalItems: IStaffTeam[] = [
  {
    id: 0,
    title: languages.founder_message_fem,
    headerContent: languages.founderMsgTitle,
    subheaderContent: languages.founder_message_subcontent,
    quotationText:
      "“Genius is in the idea. Impact, however, comes from action.”",
    signatureData: founderSign,
    staffImage: teamFounder,
    staffName: `${languages.ms} Françoise Nyirantagorama`,
    staffJob: languages.founder_fem,
    showQuotation: false,
    showSignature: false,
  },
  {
    id: 1,
    title: languages.principal_message_fem,
    headerContent: languages.principalMsgTitle,
    subheaderContent: languages.principal_message_subcontent,
    quotationText:
      "“Genius is in the idea. Impact, however, comes from action.”",
    signatureData: principalSign,
    staffImage: teamPrincipal,
    staffName: `${languages.ms} Martine Umubyeyi`,
    staffJob: languages.principal_fem,
    showQuotation: false,
    showSignature: false,
  },
];
const lstAdministrativeTeamStaff: IStaffStatusElt[] = [
  {
    id: 0,
    staffName: "NYIRINKWAYA Augustin",
    staffJob: languages.prefet,
    phoneNbr: "",
    twitterAcct: "",
    color: "",
    staffImage: seniorStaffPrefet,
    colorSubtitle: "",
    colorTitle: "",
    fbAcct: "",
    instAcct: "",
    isPhoneVis: false,
    linkedInAcct: "",
    phoneBgColor: "",
  },
  {
    id: 1,
    staffName: "TUMUSIME Jolly Sylvia",
    staffJob: languages.depDirectorSt,
    phoneNbr: "",
    twitterAcct: "",
    color: "",
    staffImage: seniorStaff5,
    colorSubtitle: "",
    colorTitle: "",
    fbAcct: "",
    instAcct: "",
    isPhoneVis: false,
    linkedInAcct: "",
    phoneBgColor: "",
  },
  {
    id: 2,
    staffName: "UMURUNGI Marina",
    staffJob: languages.public_relations,
    phoneNbr: "",
    twitterAcct: "",
    color: "",
    staffImage: seniorStaffMarina,
    colorSubtitle: "",
    colorTitle: "",
    fbAcct: "",
    instAcct: "",
    isPhoneVis: false,
    linkedInAcct: "",
    phoneBgColor: "",
  },
  {
    id: 3,
    staffName: "UWERA Maryse",
    staffJob: languages.daf_financial_officer,
    phoneNbr: "",
    twitterAcct: "",
    color: "",
    staffImage: seniorStaffMaryse,
    colorSubtitle: "",
    colorTitle: "",
    fbAcct: "",
    instAcct: "",
    isPhoneVis: false,
    linkedInAcct: "",
    phoneBgColor: "",
  },
  {
    id: 4,
    staffName: "UWINEZA Joyeuse",
    staffJob: languages.secretaire,
    phoneNbr: "",
    twitterAcct: "",
    color: "",
    staffImage: seniorStaff1,
    colorSubtitle: "",
    colorTitle: "",
    fbAcct: "",
    instAcct: "",
    isPhoneVis: false,
    linkedInAcct: "",
    phoneBgColor: "",
  },
  {
    id: 5,
    staffName: "KATUSHABE Joyce",
    staffJob: languages.bibliothecaire,
    phoneNbr: "",
    twitterAcct: "",
    color: "",
    staffImage: seniorStaff2,
    colorSubtitle: "",
    colorTitle: "",
    fbAcct: "",
    instAcct: "",
    isPhoneVis: false,
    linkedInAcct: "",
    phoneBgColor: "",
  },
  {
    id: 6,
    staffName: "IRAKOZE Liliane",
    staffJob: languages.bibliothecaire,
    phoneNbr: "",
    twitterAcct: "",
    color: "",
    staffImage: seniorStaff3,
    colorSubtitle: "",
    colorTitle: "",
    fbAcct: "",
    instAcct: "",
    isPhoneVis: false,
    linkedInAcct: "",
    phoneBgColor: "",
  },
  {
    id: 7,
    staffName: "UWIMANA Joyce",
    staffJob: languages.comptable,
    phoneNbr: "",
    twitterAcct: "",
    color: "",
    staffImage: seniorStaff4,
    colorSubtitle: "",
    colorTitle: "",
    fbAcct: "",
    instAcct: "",
    isPhoneVis: false,
    linkedInAcct: "",
    phoneBgColor: "",
  },
  {
    id: 8,
    staffName: "UTUJE Patricia",
    staffJob: languages.comptable,
    phoneNbr: "",
    twitterAcct: "",
    color: "",
    staffImage: seniorStaff6,
    colorSubtitle: "",
    colorTitle: "",
    fbAcct: "",
    instAcct: "",
    isPhoneVis: false,
    linkedInAcct: "",
    phoneBgColor: "",
  },
];
const lstTeachersStaff: IStaffStatusElt[] = [
  {
    id: 0,
    staffName: "KAFEERO Charles",
    staffJob: languages.teacher,
    phoneNbr: "",
    twitterAcct: "",
    color: "",
    staffImage: teacherStaff1,
    colorSubtitle: "",
    colorTitle: "",
    fbAcct: "",
    instAcct: "",
    isPhoneVis: false,
    linkedInAcct: "",
    phoneBgColor: "",
  },
  {
    id: 1,
    staffName: "MUKARUBIBI Anne Marie",
    staffJob: languages.teacher,
    phoneNbr: "",
    twitterAcct: "",
    color: "",
    staffImage: teacherStaff2,
    colorSubtitle: "",
    colorTitle: "",
    fbAcct: "",
    instAcct: "",
    isPhoneVis: false,
    linkedInAcct: "",
    phoneBgColor: "",
  },
  {
    id: 2,
    staffName: "MUKUNDWA Viviane",
    staffJob: languages.teacher,
    phoneNbr: "",
    twitterAcct: "",
    color: "",
    staffImage: teacherStaff3,
    colorSubtitle: "",
    colorTitle: "",
    fbAcct: "",
    instAcct: "",
    isPhoneVis: false,
    linkedInAcct: "",
    phoneBgColor: "",
  },
  {
    id: 3,
    staffName: "UWINGABIRE Julienne",
    staffJob: languages.teacher,
    phoneNbr: "",
    twitterAcct: "",
    color: "",
    staffImage: teacherStaff4,
    colorSubtitle: "",
    colorTitle: "",
    fbAcct: "",
    instAcct: "",
    isPhoneVis: false,
    linkedInAcct: "",
    phoneBgColor: "",
  },
  {
    id: 4,
    staffName: "MUKAYIRANGA Seraphine",
    staffJob: languages.teacher,
    phoneNbr: "",
    twitterAcct: "",
    color: "",
    staffImage: teacherStaff5,
    colorSubtitle: "",
    colorTitle: "",
    fbAcct: "",
    instAcct: "",
    isPhoneVis: false,
    linkedInAcct: "",
    phoneBgColor: "",
  },
  {
    id: 5,
    staffName: "MBIRINGI Rugira Jules",
    staffJob: languages.teacher,
    phoneNbr: "",
    twitterAcct: "",
    color: "",
    staffImage: teacherStaff6,
    colorSubtitle: "",
    colorTitle: "",
    fbAcct: "",
    instAcct: "",
    isPhoneVis: false,
    linkedInAcct: "",
    phoneBgColor: "",
  },
  {
    id: 6,
    staffName: "HABIMANA Pierre Celestin",
    staffJob: languages.teacher,
    phoneNbr: "",
    twitterAcct: "",
    color: "",
    staffImage: teacherStaff7,
    colorSubtitle: "",
    colorTitle: "",
    fbAcct: "",
    instAcct: "",
    isPhoneVis: false,
    linkedInAcct: "",
    phoneBgColor: "",
  },
  {
    id: 7,
    staffName: "KAZUBWENGE Martine",
    staffJob: languages.teacher,
    phoneNbr: "",
    twitterAcct: "",
    color: "",
    staffImage: teacherStaff8,
    colorSubtitle: "",
    colorTitle: "",
    fbAcct: "",
    instAcct: "",
    isPhoneVis: false,
    linkedInAcct: "",
    phoneBgColor: "",
  },
  {
    id: 8,
    staffName: "KAMPOZE Roseline",
    staffJob: languages.teacher,
    phoneNbr: "",
    twitterAcct: "",
    color: "",
    staffImage: teacherStaff9,
    colorSubtitle: "",
    colorTitle: "",
    fbAcct: "",
    instAcct: "",
    isPhoneVis: false,
    linkedInAcct: "",
    phoneBgColor: "",
  },
];


/* const oElt: IPageTitleSection = {
  classes: "our-teachers",
  currentText: languages.team,
  pageTitle: languages.team,
}; */

export const OurTeachersPage = () => {
  document.title = `${languages.ourTeachers} | ${languages.schoolName}`;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      {/* Founder */}
      <section className="our-teachers-zone py-[70px]">
        <div className="w-container">
          <div className="flex flex-col">
            {lstfounderPrincipalItems.map((oStaff, idx) => (
              <MainStaffComp {...oStaff} key={idx} />
            ))}
          </div>
        </div>
      </section>

      {/* Teachers */}
      <section className="senior-managt">
        <div className="w-container">
          <div className="mb-[60px] text-center">
            <h2 className="mb-[20px]">{languages.ourTeachers}</h2>
            <TitleSeparator titleColor={COLORS.teal} />
          </div>
          <div className="senior-list relative">
            <div className="flex flex-wrap">
              {lstTeachersStaff.map((oStaff, idx) => (
                <AdministrativeStaffElt key={idx} {...oStaff} />
              ))}
            </div>
          </div>
        </div>
      </section>

      {/* Senior Management */}
      <section className="senior-managt">
        <div className="w-container">
          <div className="mb-[60px] text-center">
            <h2 className="mb-[20px]">{languages.adminTeam}</h2>
            <TitleSeparator titleColor="#ff6d9d" />
          </div>
          <div className="senior-list relative">
            <div className="flex flex-wrap">
              {lstAdministrativeTeamStaff.map((oStaff, idx) => (
                <AdministrativeStaffElt key={idx} {...oStaff} />
              ))}
            </div>
          </div>
        </div>
      </section>

      {/* Staff General */}
      <section className="team-staff-normal">
        <ShapeBottomDivider />
        <div className="staff-area">
          <div className="w-container">
            <div className="box-inner">
              <div className="flex flex-col">
                <section className="staff-general-info">
                  <div className="box-inner">
                    <div className="flex flex-col">
                      <div className="mb-[20px]">
                        <div className="mb-[15px]">
                          <h2>{languages.strengthOurTeam}</h2>
                        </div>
                      </div>
                      <div className="mb-[20px]">
                        <div className="centered-inner">
                          <div className="data-staff-info">
                            {languages.strengthTeam}
                          </div>
                        </div>
                      </div>
                      <div className="mb-[20px] text-center">
                        <LinkAchorRound
                          type="link"
                          href="/contactus"
                          text={languages.scheduleVisit}
                          bgColor="#000"
                          hoverBgcolor="#8700ff"
                        />
                      </div>
                    </div>
                  </div>
                </section>
                <section className="staff-general-list"></section>
              </div>
              <div className="flex flex-wrap">
                {/* {listOfStaffsDb.map(oStaff => <StaffInstuctorcomp 
                    id={oStaff.id}
                    phoneNbr={oStaff.phoneNbr}
                    staffJob={oStaff.staffJob}
                    staffName={oStaff.staffName}
                    fbAcct={oStaff.fbAcct}
                    key={oStaff.id}
                    linkedInAcct={oStaff.linkedInAcct}
                    twitterAcct={oStaff.twitterAcct}
                    instAcct={oStaff.instAcct}
                    color={oStaff.color}
                    staffImage={oStaff.staffImage}
                    colorTitle="black"
                    colorSubtitle="#808080"
                    phoneBgColor={oStaff.color}
                    phoneTextColor="#fff"
                  />)} */}
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Client Testimonials */}
      {/* <section className="py-[70px]">
          <div className="w-container">
            <TestimonialsComp /> 
          </div>
        </section> */}
    </>
  );
};

const MainStaffComp = ({
  id,
  headerContent,
  quotationText,
  staffImage,
  staffJob,
  staffName,
  subheaderContent,
  title,
  signatureData,
  showQuotation,
  showSignature,
}: IStaffTeam) => {
  const isLeft = appFxs.isNbrOdd(id + 1);

  return (
    <div className="main-staff-area flex flex-wrap">
      <div
        className={`staff-area basis-full md:basis-1/2 order-1 ${
          isLeft ? "md:order-1" : "md:order-2"
        }`}
      >
        <div className={`${isLeft ? "md:pr-[5%]" : "md:pl-[5%]"}`}>
          <div className="box-inner">
            <div className="overflow-hidden rounded-xl h-[349px] min-w-[452px]">
              <img
                src={staffImage}
                alt=""
                className="w-full h-full object-cover object-top hover:scale-125 transition-transform duration-300"
              />
            </div>
          </div>
        </div>
      </div>
      <div
        className={`message-area basis-full md:basis-1/2 order-2 ${
          isLeft ? "md:order-2" : "md:order-1"
        }`}
      >
        <div className={`${isLeft ? "md:pl-[5%]" : "md:pr-[5%]"}`}>
          <div className="box-inner">
            <div className="mb-[30px]">
              <h2 className="leading-[1.2]">{title}</h2>
            </div>
            <div className="msg-title mb-[20px]">
              <h4>{headerContent}</h4>
            </div>
            <div className="mb-[20px]">{subheaderContent}</div>
            {showQuotation && <div className="mb-[20px]">{quotationText}</div>}
            {showSignature && (
              <div className="mb-[20px]">
                <img alt="" src={signatureData} />
              </div>
            )}
            <div className="staff-name">
              <h6>
                <span className="staff-name">
                  <span className="staff-label">{staffName}</span>
                </span>
                <span className="staff-space">&nbsp;</span>
                <span className="staff-job">
                  <span className="staff-label">- {staffJob}</span>
                </span>
              </h6>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const AdministrativeStaffElt = ({
  staffName,
  staffJob,
  staffImage,
  fbAcct,
  instAcct,
  linkedInAcct,
  twitterAcct,
}: IStaffStatusElt) => {
  const lstIcons: TSocialIcon[] = [];
  if (fbAcct) lstIcons.push({ href: fbAcct, type: "facebook" });
  if (instAcct) lstIcons.push({ href: instAcct, type: "instagram" });
  if (linkedInAcct) lstIcons.push({ href: linkedInAcct, type: "linkedin" });
  if (twitterAcct) lstIcons.push({ href: twitterAcct, type: "twitter" });

  return (
    <div className="senior-area-comp relative basis-full md:basis-1/3">
      <div className="box-inner">
        <div className="inside-wrapper">
          <div className="flex flex-col">
            <div className="senior-image relative">
              <div className="senior-cover">
                <div className="senior-member-socials">
                  {lstIcons.map((oIcon, idx) => (
                    <SeniorSocialLink
                      key={idx}
                      href={oIcon.href}
                      type={oIcon.type}
                    />
                  ))}
                </div>
              </div>
              <figure className="senior-figure">
                <img
                  src={staffImage}
                  alt=""
                  className="senior-team-img-tag hover:scale-125 transition-transform duration-300"
                />
              </figure>
            </div>
            <div className="senior-details ">
              <h4 className="senior-member-name">
                <span className="member-name">{staffName}</span>
              </h4>
              <div className="senior-member-pos">
                <span>{staffJob}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
const SeniorSocialLink = ({ href, type }: TSocialIcon) => {
  const clasD =
    type === "facebook"
      ? "fa fa-facebook"
      : type === "instagram"
      ? "fa fa-instagram"
      : type === "linkedin"
      ? "fa fa-linkedin"
      : type === "twitter"
      ? "fa fa-twitter"
      : "fa fa-facebook";

  return (
    <div className="senior-meamber-soc-icon">
      <a href={href} target="_blank" rel="noreferrer">
        <div className="icon-wrapper">
          <div className="icon-inner">
            <span className="elt-icon">
              <i className={clasD}></i>
            </span>
          </div>
        </div>
      </a>
    </div>
  );
};

/* eslint-disable react-hooks/exhaustive-deps */
import { Link, useNavigate, useParams } from "react-router-dom";

import { IPageTitleSection } from "src/models/smarttypes";

import getLanguages from 'src/utils/language';
import generalFxs from 'src/utils/general_fx';
import { useEffect, useState } from "react";
import { MainTitleWrapper } from "src/components/pages/MainTitleWrapper";
import { SenderData } from "src/models/netreturn";
import NetServices from "src/utils/netservices";

const languages = getLanguages();
const appFxs = generalFxs();
const isEn = appFxs.getLocalLanguage() === 'en';

export const UnsubscribePage = () => {
  document.title = `${languages.unsubscribe} | ${languages.schoolName}`;
  const oElt: IPageTitleSection = {
    classes: "unsub-pages",
    currentText: languages.unsubscribe,
    pageTitle: languages.unsubscribe,
  };

  const [isLoading, setIsLoading] = useState(true);
  const [subscriberEmail, setSubscriberEmail] = useState("");

  const { subscribeKey } = useParams();

  const navigation = useNavigate();

  const checkValidityOperation = async () => {
    const senderData: SenderData = {
        subscriberKey: subscribeKey,
      };

      const oRes = await NetServices.requestPost(
        "web/unsubscribefrommailsystem",
        senderData
      );

      if (!oRes.bReturn) {
        await appFxs.showAlert(oRes.msgTitle, oRes.msgBody);
        navigation('/home');
        return;
      }

      setSubscriberEmail(oRes.subscriberEmail!);
      setIsLoading(false);
  }

  useEffect(() => {
    window.scrollTo(0, 0);
    checkValidityOperation();
  }, []);

  return (
    <>
      <MainTitleWrapper {...oElt} />
      <section className="py-[25px] md:py-[70px]">
        {isLoading && (
          <div className="py-[15px] flex flex-col items-center justify-center">
            <i className="fa fa-spinner fa-spin fa-3x mb-3"></i>
            <h4>{languages.loadingData}</h4>
          </div>
        )}

        {!isLoading && (
          <div
            className="rounded-md py-4 px-5 my-4 w-full max-w-[600px] mx-auto "
            style={{ boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)" }}
          >
            <h4 className="mb-6">{languages.unsubscribe}</h4>
            {isEn ? (
              <p>
                You (<strong>{subscriberEmail}</strong>) have been unsubscribed
                from our system. We hope that you will no longer receive any of
                our emails, unless we are mistaken.<br/><br/><br/>
                <Link className="w-btn-link" to={'/home'}>{languages.home}</Link>
              </p>
            ) : (
              <p>
                Vous (<strong>{subscriberEmail}</strong>) avez été désabonné de
                notre système. Nous espérons que vous ne recevrez plus aucun de
                nos emails, sauf erreur de notre part.<br/><br/><br/>
                <Link className="w-btn-link" to={'/home'}>{languages.home}</Link>
              </p>
            )}
          </div>
        )}
      </section>
    </>
  );
};

import { useEffect, useRef, useState } from "react";

import { Link } from "react-router-dom";

import logText from 'src/assets/images/logo_with_text_white.png';
import { IManagtBlog, IMenuColomb, ISocialIcon, ISocialIconElt } from "src/models/smarttypes";
import generalFx from 'src/utils/general_fx';
import appLanguages from 'src/utils/language';
import appDecl from 'src/utils/declarations';

import NetServices from "src/utils/netservices";

const appFxs = generalFx();
const languages = appLanguages();
const isEn = appFxs.getLocalLanguage() === 'en';

const oYear = new Date().getFullYear();

  const socials: ISocialIcon[] = [
    {
      color: "#fb8500",
      href: "https://twitter.com/lacolombiere_sc",
      label: languages.contactUsTwitter,
      id: "twitter",
      icon: "fa fa-twitter",
    },
    {
      color: "#283618",
      href: "https://facebook.com/lacolombieresch",
      label: languages.contactUsFb,
      id: "facebook",
      icon: "fa fa-facebook",
    },
    {
      color: "#8338ec",
      href: "https://www.instagram.com/lacolombiere_sc/",
      label: languages.contactUsInst,
      id: "instagram",
      icon: "fa fa-instagram",
    },
    {
      color: "#94d2bd",
      href: "https://www.linkedin.com/in/la-colombi%C3%A8re-school-8a09b62a1/",
      label: languages.contactUsLI,
      id: "linkedin",
      icon: "fa fa-linkedin",
    },
    {
      color: "#118ab2",
      href: "https://api.whatsapp.com/send?phone=250788510589",
      label: languages.contactUsWh,
      id: "whatsapp",
      icon: "fa fa-whatsapp",
    },
  ];

  const menus: IMenuColomb[] = [
    {
      color: "#a5c347",
      href: "/home",
      label: languages.home,
      id: "home",
    },
    {
      color: "#a5c347",
      href: "/aboutus",
      label: languages.aboutUs,
      id: "aboutus",
    },
    {
      color: "#a5c347",
      href: "/history",
      label: languages.historySch,
      id: "history",
    },
    {
      color: "#a5c347",
      href: "/team",
      label: languages.team,
      id: "team",
    },
    {
      color: "#a5c347",
      href: "/facilities",
      label: languages.premises,
      id: "facilities",
    },
    /* {
      color: "#a5c347",
      href: "/misc/specialized",
      label: languages.specializedProgramme,
      id: "specialized",
    },
    {
      color: "#a5c347",
      href: "/misc/specialtrainlang",
      label: languages.languageTrain,
      id: "training",
    }, */
  ];

const LatestPostComp = ({ postElt }: { postElt: IManagtBlog }) => {
  const dateFormatter = (oDate: Date): string => {
    const iDay = oDate.getDate();
    const iMonth = oDate.getMonth();
    const iYear = oDate.getFullYear();

    const months = appFxs.wdMonthsShrt;
    return `${months[iMonth]} ${iDay}, ${iYear}`;
  };

  return (
    <li className="mb-6 last:mb-0">
      <div className="flex gap-1">
        <div className="d-image grow-0">
          <a href={postElt.blogPathUrl} target="_blank" rel="noreferrer">
            <img src={postElt.blogImageUrl} alt="" />
          </a>
        </div>
        <div className="d-content grow flex flex-col gap-3">
          <h4 className="font-bold text-lg">
            <a href={postElt.blogPathUrl} target="_blank" rel="noreferrer">
              {postElt.blogTitle}
            </a>
          </h4>
          <span className="time-zone">
            {dateFormatter(new Date(postElt.blogDate))}
          </span>
        </div>
      </div>
    </li>
  );
};

const SocialIconCom = ({ socialElt }: ISocialIconElt) => {
  return (
    <li className="">
      <a
        href={socialElt.href}
        target="_blank"
        rel="noreferrer"
        className="flex items-center justify-center group w-[50px] h-[50px] rounded-md"
        style={{ backgroundColor: socialElt.color }}
      >
        <i
          className={
            socialElt.icon +
            " font-bold text-lg group-hover:text-3xl transition-all"
          }
        ></i>
      </a>
    </li>
  );
};

export default function MainFooter() {
  

  return (
    <footer className="main-footer w-full clear-both m-0">
      <div className="section-wrapper-2">
        <div className="top-footer">
          <div className="footer-shape footer-shape-top">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 283.5 27.8"
              preserveAspectRatio="none"
            >
              <path
                className="elementor-shape-fill"
                d="M283.5,9.7c0,0-7.3,4.3-14,4.6c-6.8,0.3-12.6,0-20.9-1.5c-11.3-2-33.1-10.1-44.7-5.7	s-12.1,4.6-18,7.4c-6.6,3.2-20,9.6-36.6,9.3C131.6,23.5,99.5,7.2,86.3,8c-1.4,0.1-6.6,0.8-10.5,2c-3.8,1.2-9.4,3.8-17,4.7	c-3.2,0.4-8.3,1.1-14.2,0.9c-1.5-0.1-6.3-0.4-12-1.6c-5.7-1.2-11-3.1-15.8-3.7C6.5,9.2,0,10.8,0,10.8V0h283.5V9.7z M260.8,11.3	c-0.7-1-2-0.4-4.3-0.4c-2.3,0-6.1-1.2-5.8-1.1c0.3,0.1,3.1,1.5,6,1.9C259.7,12.2,261.4,12.3,260.8,11.3z M242.4,8.6	c0,0-2.4-0.2-5.6-0.9c-3.2-0.8-10.3-2.8-15.1-3.5c-8.2-1.1-15.8,0-15.1,0.1c0.8,0.1,9.6-0.6,17.6,1.1c3.3,0.7,9.3,2.2,12.4,2.7	C239.9,8.7,242.4,8.6,242.4,8.6z M185.2,8.5c1.7-0.7-13.3,4.7-18.5,6.1c-2.1,0.6-6.2,1.6-10,2c-3.9,0.4-8.9,0.4-8.8,0.5	c0,0.2,5.8,0.8,11.2,0c5.4-0.8,5.2-1.1,7.6-1.6C170.5,14.7,183.5,9.2,185.2,8.5z M199.1,6.9c0.2,0-0.8-0.4-4.8,1.1	c-4,1.5-6.7,3.5-6.9,3.7c-0.2,0.1,3.5-1.8,6.6-3C197,7.5,199,6.9,199.1,6.9z M283,6c-0.1,0.1-1.9,1.1-4.8,2.5s-6.9,2.8-6.7,2.7	c0.2,0,3.5-0.6,7.4-2.5C282.8,6.8,283.1,5.9,283,6z M31.3,11.6c0.1-0.2-1.9-0.2-4.5-1.2s-5.4-1.6-7.8-2C15,7.6,7.3,8.5,7.7,8.6	C8,8.7,15.9,8.3,20.2,9.3c2.2,0.5,2.4,0.5,5.7,1.6S31.2,11.9,31.3,11.6z M73,9.2c0.4-0.1,3.5-1.6,8.4-2.6c4.9-1.1,8.9-0.5,8.9-0.8	c0-0.3-1-0.9-6.2-0.3S72.6,9.3,73,9.2z M71.6,6.7C71.8,6.8,75,5.4,77.3,5c2.3-0.3,1.9-0.5,1.9-0.6c0-0.1-1.1-0.2-2.7,0.2	C74.8,5.1,71.4,6.6,71.6,6.7z M93.6,4.4c0.1,0.2,3.5,0.8,5.6,1.8c2.1,1,1.8,0.6,1.9,0.5c0.1-0.1-0.8-0.8-2.4-1.3	C97.1,4.8,93.5,4.2,93.6,4.4z M65.4,11.1c-0.1,0.3,0.3,0.5,1.9-0.2s2.6-1.3,2.2-1.2s-0.9,0.4-2.5,0.8C65.3,10.9,65.5,10.8,65.4,11.1	z M34.5,12.4c-0.2,0,2.1,0.8,3.3,0.9c1.2,0.1,2,0.1,2-0.2c0-0.3-0.1-0.5-1.6-0.4C36.6,12.8,34.7,12.4,34.5,12.4z M152.2,21.1	c-0.1,0.1-2.4-0.3-7.5-0.3c-5,0-13.6-2.4-17.2-3.5c-3.6-1.1,10,3.9,16.5,4.1C150.5,21.6,152.3,21,152.2,21.1z"
              ></path>
              <path
                className="elementor-shape-fill"
                d="M269.6,18c-0.1-0.1-4.6,0.3-7.2,0c-7.3-0.7-17-3.2-16.6-2.9c0.4,0.3,13.7,3.1,17,3.3	C267.7,18.8,269.7,18,269.6,18z"
              ></path>
              <path
                className="elementor-shape-fill"
                d="M227.4,9.8c-0.2-0.1-4.5-1-9.5-1.2c-5-0.2-12.7,0.6-12.3,0.5c0.3-0.1,5.9-1.8,13.3-1.2	S227.6,9.9,227.4,9.8z"
              ></path>
              <path
                className="elementor-shape-fill"
                d="M204.5,13.4c-0.1-0.1,2-1,3.2-1.1c1.2-0.1,2,0,2,0.3c0,0.3-0.1,0.5-1.6,0.4	C206.4,12.9,204.6,13.5,204.5,13.4z"
              ></path>
              <path
                className="elementor-shape-fill"
                d="M201,10.6c0-0.1-4.4,1.2-6.3,2.2c-1.9,0.9-6.2,3.1-6.1,3.1c0.1,0.1,4.2-1.6,6.3-2.6	S201,10.7,201,10.6z"
              ></path>
              <path
                className="elementor-shape-fill"
                d="M154.5,26.7c-0.1-0.1-4.6,0.3-7.2,0c-7.3-0.7-17-3.2-16.6-2.9c0.4,0.3,13.7,3.1,17,3.3	C152.6,27.5,154.6,26.8,154.5,26.7z"
              ></path>
              <path
                className="elementor-shape-fill"
                d="M41.9,19.3c0,0,1.2-0.3,2.9-0.1c1.7,0.2,5.8,0.9,8.2,0.7c4.2-0.4,7.4-2.7,7-2.6	c-0.4,0-4.3,2.2-8.6,1.9c-1.8-0.1-5.1-0.5-6.7-0.4S41.9,19.3,41.9,19.3z"
              ></path>
              <path
                className="elementor-shape-fill"
                d="M75.5,12.6c0.2,0.1,2-0.8,4.3-1.1c2.3-0.2,2.1-0.3,2.1-0.5c0-0.1-1.8-0.4-3.4,0	C76.9,11.5,75.3,12.5,75.5,12.6z"
              ></path>
              <path
                className="elementor-shape-fill"
                d="M15.6,13.2c0-0.1,4.3,0,6.7,0.5c2.4,0.5,5,1.9,5,2c0,0.1-2.7-0.8-5.1-1.4	C19.9,13.7,15.7,13.3,15.6,13.2z"
              ></path>
            </svg>
          </div>
          <div className="footer-menu">
            <div className="w-container px-4 sm:px-0">
              <div className="flex flex-wrap">
                {/* Logo */}
                <div className="w-menu-col mb-5 md:basis-2/4 lg:basis-1/4">
                  <div className="px-2">
                    <div className="mb-4">
                      <img src={logText} alt="" className="w-[250px]" />
                    </div>
                    <div className="mb-3">{languages.leaderExcell}</div>
                    <div className="socila-links">
                      <ul className="flex gap-3 flex-wrapper">
                        {socials.map((oSocial) => (
                          <SocialIconCom key={oSocial.id} socialElt={oSocial} />
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
                {/* Menu */}
                <div className="w-menu-col mb-5 md:basis-2/4 lg:basis-1/4">
                  <div className="px-2">
                    <div className="mb-4">
                      <h4 className="font-bold text-2xl">
                        {languages.abtSchool}
                      </h4>
                    </div>
                    <div className="">
                      <ul>
                        {menus.map((oMenu) => (
                          <li key={oMenu.id}>
                            <Link
                              to={oMenu.href}
                              className="pb-2 flex justify-start"
                            >
                              <span className="f-menu-l-icon mr-3">
                                <i className="fa fa-caret-right text-lg"></i>
                              </span>
                              <span className="f-menu-l-text">
                                {oMenu.label}
                              </span>
                            </Link>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
                {/* Posts */}
                <LatestNewsFooter />
                {/* Keep in touch */}
                <div className="w-menu-col mb-5 md:basis-2/4 lg:basis-1/4">
                  <div className="px-2">
                    <div className="mb-4">
                      <h4 className="font-bold text-2xl">
                        {languages.keepInTouch}
                      </h4>
                    </div>
                    <div className="recent-post-menu">
                      {isEn && (
                        <div className="mb-3">
                          <span className="font-bold">La Colombière</span>&nbsp;
                          school is the perfect environment for language
                          studies.
                        </div>
                      )}
                      {!isEn && (
                        <div className="mb-3">
                          L'école&nbsp;
                          <span className="font-bold">La Colombière</span> est
                          l'environnement idéal pour les études de langues.
                        </div>
                      )}

                      {/* Map address */}
                      <div className="flex items-start mb-4">
                        <i className="fa fa-map grow-0 mr-5 mt-2"></i>
                        {isEn && (
                          <span className="grow">
                            Kacyiru 690 street, PO Box: 3279 Kigali, Rwanda
                          </span>
                        )}
                        {!isEn && (
                          <span className="grow">
                            Rue 690, Kacyiru, Bte Postale: 3279 Kigali, Rwanda
                          </span>
                        )}
                      </div>

                      {/* Telephone */}
                      <div className="flex items-start mb-4">
                        <i className="fa fa-phone grow-0 mr-5 mt-2"></i>
                        <span className="grow">
                          <a
                            title={languages.placeUrCallsHere}
                            href={`tel:${appDecl.mainPhoneNbr}`}
                          >
                            +{appDecl.mainPhoneNbr}
                          </a>
                        </span>
                      </div>

                      {/* Telephone */}
                      <div className="flex items-start mb-4">
                        <i className="fa fa-envelope-o grow-0 mr-5 mt-2"></i>
                        <span className="grow">
                          <a
                            title={languages.contactus}
                            href="mailto:info@lacolombiere.ac.rw"
                          >
                            info@lacolombiere.ac.rw
                          </a>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="lower-footer">
          <div className="w-container flex flex-col items-center gap-2 lg:flex-row px-2 py-3 lg:justify-between">
            {isEn && (
              <div>
                <strong>La Colombière</strong> School &copy; {oYear}
              </div>
            )}
            {!isEn && (
              <div>
                Ecole <strong>La Colombière</strong> &copy; {oYear}
              </div>
            )}
            <div className="flex flex-wrap justify-center">
              {isEn && (
                <span>
                  Designed by&nbsp;
                  <a
                    href="https://wiseobjects.com"
                    target="_blank"
                    rel="noreferrer"
                    className="font-semibold"
                  >
                    WiseObjects Ltd
                  </a>
                </span>
              )}
              {!isEn && (
                <span>
                  Développé par&nbsp;
                  <a
                    href="https://wiseobjects.com"
                    target="_blank"
                    rel="noreferrer"
                    className="font-semibold"
                  >
                    WiseObjects Ltd
                  </a>
                </span>
              )}
              &nbsp; •&nbsp;
              <Link className="font-semibold" to={"/legal/cookies"}>
                {languages.cookie_policy}
              </Link>
              &nbsp; •&nbsp;
              <Link className="font-semibold" to={"/legal/privacy"}>
                {languages.priv_policy}
              </Link>
              &nbsp; •&nbsp;
              <Link className="font-semibold" to={"/legal/terms"}>
                {languages.terms}
              </Link>
              &nbsp;
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

const LatestNewsFooter = () => {
  const [lstBlogs, setLstBlogs] = useState<IManagtBlog[]>([]);
  const [isLoading, setLoading] = useState(true);
  const [hasNetError, setHasNetError] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");

  const bLoaded = useRef(false);

  const populateBlogsList = async () => {
    setLoading(true);
    setHasNetError(false);
    setErrorMessage("");

    const oper = await NetServices.requestGet(
      "web/getlistofallblogslatest",
      false
    );
    if (!oper.bReturn) {
      setHasNetError(true);
      setErrorMessage(oper.msgBody);
      setLoading(false);
      return;
    }

    setLstBlogs(oper.lstManagtBlogs!);

    setHasNetError(false);
    setErrorMessage("");
    setLoading(false);
  };

  useEffect(() => {
    if (bLoaded.current) return;
    populateBlogsList();
    bLoaded.current = true;
  }, []);

  return (
    <div className="w-menu-col mb-5 md:basis-2/4 lg:basis-1/4">
      <div className="px-2">
        <div className="mb-4">
          <h4 className="font-bold text-2xl">{languages.latestNews}</h4>
        </div>
        <div className="recent-post-menu">
          {isLoading && (
            <div className="flex flex-col items-start justify-center">
              <i className="fa fa-spin fa-spinner"></i>
              <h3>{languages.loading}...</h3>
            </div>
          )}
          {!isLoading && hasNetError && (
            <div className="flex flex-col items-center justify-center">
              <p className="text-center py-4">{errorMessage}</p>
              <button
                className="flex items-center gap-1 mt-3 border border-blue-400 py-2 px-3 rounded-md hover:bg-blue-600 hover:text-white"
                onClick={() => populateBlogsList()}
              >
                <i className="fa fa-spinner fa-spin"></i>
                <span>{languages.tryAgain}</span>
              </button>
            </div>
          )}
          {!isLoading && !hasNetError && lstBlogs.length === 0 && (
            <div className="flex flex-col">
              <button
                className="w-fit inline-flex items-center gap-1 mt-3 border border-blue-400 py-2 px-3 rounded-md hover:bg-blue-600 hover:text-white"
                onClick={() => populateBlogsList()}
              >
                <i className="fa fa-arrow-up"></i>
                <span>{languages.noNewsFound}</span>
              </button>
            </div>
          )}

          {!isLoading && !hasNetError && lstBlogs.length > 0 && (
            <div className="recent-post-inner">
              <ul>
                {lstBlogs.map((oPost, idx) => (
                  <LatestPostComp postElt={oPost} key={idx} />
                ))}
              </ul>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
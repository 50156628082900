import { ILastBlog, ILastBlogElt, InnerCss } from 'src/models/smarttypes';

import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import NetServices from 'src/utils/netservices';
import { ErrorDisplayer, LoadingElements } from 'src/components/utils/LoadingElements';

import getLang from 'src/utils/language';
import { SectionTitle } from 'src/components/elements/sectiontitle/SectionTitle';
import { COLORS } from 'src/utils/wcolors';

const languages  = getLang();


export const LastBlog = () => {  
  const [lstLastBlogs, setLstLastBlogs] = useState<ILastBlog[]>([]);
  const [isLoading, setLoading] = useState(true);
  const [hasNetError, setHasNetError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const bLoaded = useRef(false);

  const populateBlogsList = async () => {
    setLoading(true);
    setHasNetError(false);
    setErrorMessage('');


    const oper = await NetServices.requestGet('web/getlistofallblogslatest', false);
    if(!oper.bReturn){
      setHasNetError(true);
      setErrorMessage(oper.msgBody);
      setLoading(false);
      return;
    }

    const lst = oper.lstManagtBlogs!.map((oBlog, idx) => {

      const oRet:ILastBlog =  {
        id: idx,
        href: oBlog.blogPathUrl,
        imagePath: oBlog.blogImageUrl,
        postContent: oBlog.blogDescription,
        postDate: oBlog.blogDate,
        postTitle: oBlog.blogTitle,
        color: oBlog.blogColor,
      };

      return oRet;
    })
    setLstLastBlogs(lst);
    
    setHasNetError(false);
    setErrorMessage('');
    setLoading(false);
  }

  useEffect(() => {
    if(bLoaded.current)return;
    populateBlogsList();
    bLoaded.current = true;
  }, [])

  

  return (
    <section className="last-blog">
      <div className="overlay"></div>
      <div className="w-container">
        <div className="p-[10px] flex flex-col space-y-4 justify-center">
          <SectionTitle title={languages.latestNews} textColor={COLORS.azure} textAlign='center'  />
          <div className="flex flex-wrap">
            {isLoading && (
              <LoadingElements
                height={150}
                textToDisplay={languages.loadingBlogs}
              />
            )}

            {!isLoading && hasNetError && (
              <ErrorDisplayer height={150} textToDisplay={errorMessage} />
            )}

            {!isLoading && !hasNetError && lstLastBlogs.length === 0 && 
              <div className='flex flex-col py-4 gap-2 relative w-full max-w-[550px] mx-auto'>
                <h3 className='text-xl font-semibold text-center'>{languages.noBlogFoundMsgV2}</h3>
                <button className='btn btn-secondary' onClick={() => populateBlogsList()}>{languages.reload}</button>
              </div>
            }

            {!isLoading && !hasNetError && lstLastBlogs.length > 0 && (
              <>
                <BlogWrapperElt
                  classes="selected-blog basis-full md:basis-[47.10%]"
                  eltPros={lstLastBlogs[0]}
                  isMainBlog={true}
                />
                <div className="list-blogs basis-full md:basis-[52.87%]">
                  <div className="p-[10px]">
                    <div className="flex flex-wrap">
                      {lstLastBlogs.slice(1).map((oElt) => (
                        <BlogWrapperElt
                          key={oElt.id.toString()}
                          classes="not-selected-blog basis-full sm:basis-[50%]"
                          eltPros={oElt}
                          isMainBlog={false}
                        />
                      ))}
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </section>
  );
}

export const BlogWrapperElt = ({eltPros, isMainBlog, classes, hasBgColor}: ILastBlogElt) => {
  const {color,id, href, imagePath, postContent, postDate, postTitle} = eltPros;
  const formattedDate = moment(postDate).format('MMMM Do, YYYY');
  const bBcolor = hasBgColor ?? false;
  const finalColor = bBcolor ? '#f5f5f5': '#FFF';


  return (
    <div id={id.toString()} className={classes}>
      <div className="p-[10px]">
        <div className="pb-[8px]">
          <article className="blog-entry" style={{'--blog-entry-color': finalColor} as InnerCss}>
            <div className="entry-thumb overflow-hidden">
              <a href={href} target="_blank" rel="noreferrer" className='overflow-hidden'>
                <img src={imagePath} alt="" className='w-full max-h-[247px] object-cover object-top hover:scale-125 transition-transform duration-300' />
              </a>
            </div>
            <div className="entry-meta-grp">
              <div className="entry-date" style={{
                '--entry-date-color': color
              } as InnerCss}>
                <i className="fa fa-calendar"></i>&nbsp;
                <span>{formattedDate}</span>
              </div>
            </div>
            <div className="entry-title">
              <h4 style={{
                display: 'block',
                textOverflow: 'ellipsis',
                wordWrap: 'break-word',
                lineHeight: '1.8em'
              }}>
                <a href={href} target="_blank" rel="noreferrer" title={postTitle}>
                  {postTitle}
                </a>
              </h4>
            </div>
            {isMainBlog &&
              <div className="entry-body">
                <p style={{
                  display: 'block',
                  textOverflow: 'ellipsis',
                  wordWrap: 'break-word',
                  overflow: 'hidden',
                  maxHeight: '7.2em',
                  lineHeight: '1.8em'
                }}>
                  {postContent}
                </p>
              </div>
            }
          </article>
        </div>
      </div>
    </div>
  );
}
/* eslint-disable react-hooks/exhaustive-deps */
import generalFx from 'src/utils/general_fx';

const appFxs = generalFx();

interface IRecaptchaFld {
    startingValue: string,
    onChange: (eVal: string) => void,
    disabled?: boolean,
}

export const RecaptaField = ({disabled, startingValue, onChange}: IRecaptchaFld) => {

    const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
        appFxs.strictlyNbr(e)
    }

    return (
      <div className="recaptcha-box flex py-6 items-center justify-end">
        <div className="flex justify-end cover">
          <input
            type="text"
            id="captchaField"
            name="captchaField"
            autoComplete="off"
            maxLength={5}
            minLength={5}
            className="number"
            onChange={(e) => onChange(e.target.value)}
            onKeyPress={handleKeyPress}
            aria-label='Recaptcha'
            disabled={disabled ?? false}
          />
          <div className="cap-photo">
            <span className='recap-val'>{startingValue}</span>
            <div className="w-hide"></div>
          </div>
        </div>
      </div>
    );
}

import { Link } from "react-router-dom"
import { IPageTitleSection } from "src/models/smarttypes"
import getLanguages from 'src/utils/language';
import generalFxs from 'src/utils/general_fx';

const languages = getLanguages();
const appFxs = generalFxs();

export const MainTitleWrapper = ({classes, currentText, pageTitle,subPageTitle, subPageLink }: IPageTitleSection) => {
  const bHasLinks = !appFxs.wiseStrings(subPageTitle).isEmpty &&
  !appFxs.wiseStrings(subPageLink).isEmpty

  return (
    <section className={classes + " main-title-wrapper text-center relative"}>
        <div className="main-title-bg" />
        <div className="w-container">
            <div className="main-title-section">
                <h1 className="py-4">{pageTitle}</h1>
            </div>
            {!bHasLinks && <div className="breadcrumb">
                <Link to={'/'}>{languages.home}</Link>
                <span className="fa fa-caret-right"></span>
                <span className="current">{currentText}</span>
            </div>}
            
            {bHasLinks && <div className="breadcrumb">
                <Link to={'/'}>{languages.home}</Link>
                <span className="fa fa-caret-right"></span>
                <Link to={subPageLink!}>{subPageTitle!}</Link>
                <span className="fa fa-caret-right"></span>
                <span className="current">{currentText}</span>
            </div>}
        </div>
      </section>    
  )
}

/* eslint-disable react-hooks/exhaustive-deps */

import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';


import getLanguages from 'src/utils/language';
import generalFxs from 'src/utils/general_fx';
import { ICartOperationProd, IContactInfo, IContactInfoError, IFieldRecord, IPageTitleSection, IRadioRecord } from 'src/models/smarttypes';
import { MainTitleWrapper } from 'src/components/pages/MainTitleWrapper';
import { LoadingElements } from 'src/components/utils/LoadingElements';
import { FormInputElt, FormSpecGroupRadio } from 'src/components/elements/forminputelt/FormInputElt';
import { LinkAchorRound } from 'src/components/elements/linkanchorround956/LinkAchorRound';
import appDeclarations from 'src/utils/declarations';
import NetServices from 'src/utils/netservices';


const languages = getLanguages();
const appFxs = generalFxs();

const oElt: IPageTitleSection = {
  classes: "shop-app-cart",
  currentText: languages.loading,
  pageTitle: languages.loading,
  subPageLink: '/shop/cart',
  subPageTitle: languages.cart
};
const paytTypes:IRadioRecord [] = [
  {
    value: '0',
    label: languages.momoPayt, 
    detail: languages.momoPaytMsg,
  },
  {
    value: '1',
    label: languages.directBankTransf, 
    detail: languages.directBankTransfMsg,
  },
  {
    value: '2',
    label: languages.checkPayt, 
    detail: languages.checkPaytMsg,
  },
  {
    value: '3',
    label: languages.cashDelivery, 
    detail: languages.cashDeliveryMsg,
  },
]
const defaultPaytData:IContactInfo = {
  firstName: '',
  lastName: '',
  companyName: '',
  province: 'Kigali',
  city: 'Kigali',
  phone: '',
  email: '',
  roadNbr: '',
  orderNotes: '',
  typePaiement: 0,
  paytDetail: '',
}

export const ShopCheckout = () => {
    document.title = `${languages.checkout} | ${languages.schoolName}`;
    const [pageFeature, setPageFeatures] = useState<IPageTitleSection>(oElt);
    const [shopPaytDetail, setShopPaytDetail] = useState<IContactInfo>(defaultPaytData);
    const [formErros, setFormErrors] = useState<IContactInfoError>({});
    
    const payDetailNote = languages.paytDetails;
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const cartRecord = appFxs.getCartData();
    const isEng = appFxs.getLocalLanguage() === 'en';
    const navigate = useNavigate();

    const prepareDataForCheckout = () => {
      setPageFeatures({
        ...pageFeature,
        currentText: languages.checkout, 
        pageTitle: languages.checkout,
      });
      setIsLoading(false);
    }

    const onChangeField = (oRec: IFieldRecord) => {
      const f = {
        ...shopPaytDetail, [oRec.field]: oRec.value,
      } as IContactInfo;

      setShopPaytDetail(f);
    }

    
    const onSubmitForm = async ()  => {
      const errors:IContactInfoError = {};

      if(!shopPaytDetail.firstName || shopPaytDetail.firstName.length === 0){
        errors.firstName = languages.fldRequired;
      }
      if(!shopPaytDetail.lastName || shopPaytDetail.lastName.length === 0){
        errors.lastName = languages.fldRequired;
      }
      if(!shopPaytDetail.province || shopPaytDetail.province.length === 0){
        errors.province = languages.fldRequired;
      }
      if(!shopPaytDetail.city || shopPaytDetail.city.length === 0){
        errors.city = languages.fldRequired;
      }
      if(!shopPaytDetail.phone || shopPaytDetail.phone.length === 0){
        errors.phone = languages.fldRequired;
      }else if(shopPaytDetail.phone.length !== 12){
        errors.phone = languages.phonenbrLengthMsg;
      }
      if(!shopPaytDetail.email || shopPaytDetail.email.length === 0){
        errors.email = languages.fldRequired;
      }else if(!appFxs.isEmailValid(shopPaytDetail.email)){
        errors.email = languages.emailFormat;
      }
      if(!shopPaytDetail.orderNotes || shopPaytDetail.orderNotes.length === 0){
        errors.orderNotes = languages.fldRequired;
      }
      if(!shopPaytDetail.paytDetail || shopPaytDetail.paytDetail.length === 0){
        errors.paytDetail = languages.fldRequired;
      }


      setFormErrors(errors);
      if(Object.keys(errors).length >0) return;


      saveFinalRecord();
    }
    const saveFinalRecord = async () => {
      const bAsk = await appFxs.showConfirm(languages.save, languages.saveRecordSalesOpQst);
      if(!bAsk.isConfirmed)return;

      console.log('shopPaytDetail', shopPaytDetail);
      console.log('cartRecord', cartRecord);

      const data = new FormData();

      data.append('shopPaytDetail', JSON.stringify(shopPaytDetail));
      data.append('cartRecord', JSON.stringify(cartRecord));

      const oper = await NetServices.requestPostWithFileAxios('web/savesalesoper', data, true);

      if(!oper.bReturn){
        appFxs.showAlert(oper.msgTitle, oper.msgBody);
        return;
      }

      appFxs.setLocalStorageByKey(appDeclarations.cartZoneArea, '{}');

      navigate('/shop');
    }

    useEffect(() => {
        window.scrollTo(0,0);
        prepareDataForCheckout();
    }, []);

  if(isLoading) return <LoadingElements />
  return (
    <>
      <MainTitleWrapper {...pageFeature} />
      {isLoading && <LoadingElements />}
      {!isLoading && (
        <section className="py-[70px]">
          <div className="w-container">
            <form className="mt-[20px]">
              <div className="checkout-form-fields">
                <div className="fields-area">
                  <h3>{languages.billingDetails}</h3>
                  <div className="fields-wrapper flex flex-wrap md:justify-between">
                    {/* firstName */}
                    <div className="field-area-row basis-full md:basis-[49%]">
                      <label htmlFor="firstName">
                        {languages.firstName}
                        <abbr className="required" title={languages.required}>
                          *
                        </abbr>
                      </label>
                      <div className="form-input-wrapper flex flex-col">
                        <FormInputElt
                          type="text"
                          name="firstName"
                          value={shopPaytDetail.firstName}
                          placeholder={languages.firstName.toLowerCase()}
                          onChange={onChangeField}
                        />
                        {formErros.firstName && <div className='py-1 text-sm text-red-500 -mt-5'>{formErros.firstName}</div>}
                      </div>
                    </div>

                    {/* last_name */}
                    <div className="field-area-row basis-full md:basis-[49%]">
                      <label htmlFor="lastName">
                        {languages.lastName}
                        <abbr className="required" title={languages.required}>
                          *
                        </abbr>
                      </label>
                      <div className="form-input-wrapper flex flex-col">
                        <FormInputElt
                          type="text"
                          name="lastName"
                          value={shopPaytDetail.lastName}
                          placeholder={languages.lastName.toLowerCase()}
                          onChange={onChangeField}
                        />
                        {formErros.lastName && 
                          <div className='py-1 text-sm text-red-500 -mt-5'>{formErros.lastName}</div>
                        }
                      </div>
                    </div>

                    {/* last_name */}
                    <div className="field-area-row basis-full md:basis-[49%]">
                      <label htmlFor="companyName">
                        {languages.companyName}
                      </label>
                      <div className="form-input-wrapper flex flex-col">
                        <FormInputElt
                          type="text"
                          name="companyName"
                          value={shopPaytDetail.companyName}
                          placeholder={languages.companyName.toLowerCase()}
                          onChange={onChangeField}
                        />
                        {formErros.companyName && 
                          <div className='py-1 text-sm text-red-500 -mt-5'>{formErros.companyName}</div>
                        }
                      </div>
                    </div>

                    {/* province */}
                    <div className="field-area-row basis-full md:basis-[49%]">
                      <label htmlFor="province">
                        {languages.province}
                        <abbr className="required" title={languages.required}>
                          *
                        </abbr>
                      </label>
                      <div className="form-input-wrapper flex flex-col">
                        <FormInputElt
                          type="text"
                          name="province"
                          value={shopPaytDetail.province}
                          placeholder={languages.province.toLowerCase()}
                          onChange={onChangeField}
                        />
                        {formErros.province && 
                          <div className='py-1 text-sm text-red-500 -mt-5'>{formErros.province}</div>
                        }
                      </div>
                    </div>

                    {/* province */}
                    <div className="field-area-row basis-full md:basis-[49%]">
                      <label htmlFor="city">
                        {languages.city}
                        <abbr className="required" title={languages.required}>
                          *
                        </abbr>
                      </label>
                      <div className="form-input-wrapper flex flex-col">
                        <FormInputElt
                          type="text"
                          name="city"
                          value={shopPaytDetail.city}
                          placeholder={languages.city.toLowerCase()}
                          onChange={onChangeField}
                        />
                        {formErros.city && 
                          <div className='py-1 text-sm text-red-500 -mt-5'>{formErros.city}</div>
                        }
                      </div>
                    </div>

                    {/* phone */}
                    <div className="field-area-row basis-full md:basis-[49%]">
                      <label htmlFor="phone">
                        {languages.phone}
                        <abbr className="required" title={languages.required}>
                          *
                        </abbr>
                      </label>
                      <div className="form-input-wrapper flex flex-col">
                        <FormInputElt
                          type="text"
                          name="phone"
                          value={shopPaytDetail.phone}
                          placeholder={languages.phone.toLowerCase()}
                          maxLength={12}
                          onChange={onChangeField}
                        />
                        {formErros.phone && 
                          <div className='py-1 text-sm text-red-500 -mt-5'>{formErros.phone}</div>
                        }
                      </div>
                    </div>

                    {/* email */}
                    <div className="field-area-row basis-full md:basis-[49%]">
                      <label htmlFor="email">
                        {languages.email}
                        <abbr className="required" title={languages.required}>
                          *
                        </abbr>
                      </label>
                      <div className="form-input-wrapper flex flex-col">
                        <FormInputElt
                          type="text"
                          name="email"
                          value={shopPaytDetail.email}
                          placeholder={languages.email.toLowerCase()}
                          onChange={onChangeField}
                        />
                        {formErros.email && 
                          <div className='py-1 text-sm text-red-500 -mt-5'>{formErros.email}</div>
                        }
                      </div>
                    </div>

                    {/* roadNbr */}
                    <div className="field-area-row basis-full md:basis-[49%]">
                      <label htmlFor="roadNbr">
                        {languages.roadNbr}
                      </label>
                      <div className="form-input-wrapper flex flex-col">
                        <FormInputElt
                          type="text"
                          name="roadNbr"
                          value={shopPaytDetail.roadNbr}
                          placeholder={languages.roadNbr.toLowerCase()}
                          onChange={onChangeField}
                        />
                        {formErros.roadNbr && 
                          <div className='py-1 text-sm text-red-500 -mt-5'>{formErros.roadNbr}</div>
                        }
                      </div>
                    </div>

                    <div className="field-area-row basis-full">
                      <h3>{languages.additionalInfo}</h3>
                    </div>

                    {/* roadNbr */}
                    <div className="field-area-row basis-full">
                      <label htmlFor="orderNotes">
                        {languages.orderNotes}
                        <abbr
                          className="required w-hidden"
                          title={languages.required}
                        >
                          *
                        </abbr>
                      </label>
                      <div className="form-input-wrapper flex-col">
                        <FormInputElt
                          type="textarea"
                          name="orderNotes"
                          value={shopPaytDetail.orderNotes}
                          placeholder={languages.notesAbtOrder.toLowerCase()}
                          rows={6}
                          onChange={onChangeField}
                        />
                        {formErros.orderNotes && 
                          <div className='py-1 text-sm text-red-500 -mt-5'>{formErros.orderNotes}</div>
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="checkout-order-review">
                <div className="fields-area pb-7">
                  <h3>{languages.yourOrder}</h3>
                  <div className="order-review-list">
                    <div className="review-wrapper">
                      <div className="wrapper-body flex flex-col">
                        {cartRecord.products.map((oProd, idx) => (
                          <ProductReviewRow 
                            {...oProd} 
                            key={idx}
                             />
                        ))}
                      </div>
                      <div className="wrapper-footer">
                        <div className="flex flex-col">
                          {/* sub_total */}
                          <div className="review-row subtotal-row flex items-center">
                            <div className="grow flex flex-wrap">
                              <span className="checkout-review-title basis-full md:basis-[50%] ">
                                <span className="review-product-name">
                                  <strong>{languages.subtotal}</strong>
                                </span>
                              </span>
                              <div className="review-product-total md:text-right  basis-full md:basis-[50%]">
                                <span>
                                  <span className="bdi">
                                    <span className="price-currency-symbol">
                                      Rwf
                                    </span>
                                    <strong>
                                      {appFxs.formatNumbers(
                                        cartRecord.subtotal
                                      )}
                                    </strong>
                                  </span>
                                </span>
                              </div>
                            </div>
                          </div>

                          {/* total */}
                          <div className="review-row subtotal-row flex items-center">
                            <div className="grow flex flex-wrap">
                              <span className="checkout-review-title basis-full md:basis-[50%] ">
                                <span className="review-product-name">
                                  <strong>{languages.total}</strong>
                                </span>
                              </span>
                              <div className="review-product-total md:text-right  basis-full md:basis-[50%]">
                                <span>
                                  <span className="bdi">
                                    <span className="price-currency-symbol">
                                      Rwf
                                    </span>
                                    <strong>
                                      {appFxs.formatNumbers(cartRecord.total)}
                                    </strong>
                                  </span>
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="check-out-payt-details pb-7">
                    <FormSpecGroupRadio
                      name="paytType"
                      value="0"
                      groupRadioValues={paytTypes}
                      onChange={onChangeField}
                    />
                    <div className="form-input-wrapper flex flex-col">
                        <FormInputElt
                          type="text"
                          name="paytDetail"
                          value={shopPaytDetail.paytDetail}
                          placeholder={payDetailNote}
                          onChange={onChangeField}
                        />
                        {formErros.paytDetail && 
                          <div className='py-1 text-sm text-red-500 -mt-5'>{formErros.paytDetail}</div>
                        }
                      </div>
                  </div>

                  <div className="checkout-order-place pt-7 pb-7">
                    <div className='px-7'>
                      {isEng && (
                        <p>
                          Your personal data will be used to process your order,
                          support your experience throughout this website, and
                          for other purposes described in our 
                          <span className='pl-2'>
                            <a href="/legal/privacy" target="_blank">
                              privacy policy
                            </a>
                          </span>
                          .
                        </p>
                      )}
                      {!isEng && (
                        <p>
                          Vos données personnelles seront utilisées pour traiter
                          votre commande, soutenir votre expérience sur ce site
                          Web et à d'autres fins décrites dans notre 
                          <span className='pl-2'>
                            <a href="/legal/privacy" target="_blank">
                              politique de confidentialité
                            </a>
                          </span>
                          
                          .
                        </p>
                      )}
                      <LinkAchorRound type='button' 
                        text={languages.placeOrder}
                        bgColor='#000'
                        hoverBgcolor='#f1aa00'
                        fullWidth={true} 
                        onClick={onSubmitForm}/>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </section>
      )}
    </>
  );
}

const ProductReviewRow = ({imagePath, productName,quantity, subtotal}: ICartOperationProd) => {
  return (
    <div className="review-row flex items-center">
      <img
        src={imagePath}
        className="w-[80px] h-[80px] grow-0"
        alt=""
      />
      <div className="grow flex flex-wrap">
        <span className="checkout-review-title basis-full md:basis-[50%] ">
          <span className="review-product-name">
            {productName}
          </span>
          <strong className="review-product-qty">x {quantity}</strong>
        </span>
        <div className="review-product-total md:text-right  basis-full md:basis-[50%]">
          <span>
            <span className="bdi">
              <span className="price-currency-symbol">Rwf</span>
              {appFxs.formatNumbers(subtotal)}
            </span>
          </span>
        </div>
      </div>
    </div>
  );
}
import { IPageTitleSection } from "src/models/smarttypes";
import { MainTitleWrapper } from "src/components/pages/MainTitleWrapper";

import getLanguages from 'src/utils/language';
import generalFxs from 'src/utils/general_fx';

import { useEffect } from "react";
import { TitleSeparator } from "src/components/elements/titleseparator/TitleSeparator";

const languages = getLanguages();
const appFxs = generalFxs();
const isEn = appFxs.getLocalLanguage() === 'en';

export default function PrivacyPolicyPage() {
  document.title = `${languages.priv_policy} | ${languages.schoolName}`;
  const oElt: IPageTitleSection = {
    classes: "legal-pages",
    currentText: languages.priv_policy,
    pageTitle: languages.priv_policy,
  };

  useEffect(() => {
    window.scrollTo(0,0);
  }, []);

  return (
    <>
      <MainTitleWrapper {...oElt} />
      <section className="py-[25px] md:py-[70px]">
        <div className="w-container">
          <div className="box-inner">
            <div className="mb:20px md:mb-[60px] text-center">
              {isEn && (
                <h2 className="text-[30px] md:text-[46px] leading-[1.2]">
                  Privacy Policy for La Colombière School
                </h2>
              )}
              {!isEn && (
                <h2 className="text-[30px] md:text-[46px] leading-[1.2]">
                  Politique de confidentialité - Ecole La Colombière
                </h2>
              )}
              <TitleSeparator titleColor="#ff6d9d" />
            </div>
          </div>

          <div className="policy-body">
            <div className="box-inner">
              {isEn ? <EnglishPolicyContent /> : <FrenchPolicyContent/>}
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

const EnglishPolicyContent = () => {
  return (
    <div className="lang-en">
      <div className="flex flex-col">
        <h4>Privacy Policy for La Colombière Website</h4>
        <p>
          This Privacy Policy outlines how <strong>La Colombière</strong> collects, uses, discloses, and 
          protects personal information obtained through our website. By accessing and using 
          our website, you consent to the practices outlined in this policy.
        </p>

        <h4>1. Information We Collect</h4>
        <ul>
          <li>
            <strong>Informations personnelles</strong>: nous pouvons collecter des informations 
            personnelles telles que des noms, des coordonnées et d'autres 
            identifiants lorsqu'elles sont volontairement fournies par les 
            utilisateurs via des formulaires ou d'autres interactions.
          </li>
          <li>
            <strong>Automatically Collected Information</strong>: We may collect non-personal 
            information, such as IP addresses, browser types, and device information, 
            to enhance your experience and improve our website.
          </li>
        </ul>


        <h4>2. How We Use Your Information</h4>
        <p>We may use the collected information for the following purposes:</p>
        <ul>
          <li>To provide and personalize our services.</li>
          <li>To communicate with users and respond to inquiries.</li>
          <li>To improve our website and enhance user experience.</li>
          <li>For administrative and internal business purposes.</li>
        </ul>

        <h4>3. Information Sharing</h4>
        <p>
          We do not sell, trade, or otherwise transfer personal information 
          to third parties without user consent. However, we may share 
          information with trusted third-party service providers who assist 
          us in operating our website or conducting school-related activities.
        </p>

        <h4>4. Cookies and Tracking Technologies</h4>
        <p>
          Our website may use cookies and similar technologies to enhance user 
          experience and gather information about how our site is used. Users 
          can manage cookie preferences through their browser settings.
        </p>

        <h4>5. Security Measures</h4>
        <p>
          We implement reasonable security measures to protect against unauthorized 
          access, alteration, disclosure, or destruction of personal information. 
          However, no method of transmission over the internet or electronic 
          storage is 100% secure.
        </p>

        <h4>6. Links to External Sites</h4>
        <p>
          Our website may contain links to external sites. We are not responsible 
          for the content or privacy practices of these sites. Users should review 
          the privacy policies of external sites before providing personal information.
        </p>

        <h4>7. Children's Privacy</h4>
        <p>
          Our website is not directed toward children under the age of 13. We do not knowingly 
          collect personal information from children. If you believe a child has provided us 
          with personal information, please contact us, and we will take appropriate steps to 
          delete such information.
        </p>

        <h4>8. Changes to this Privacy Policy</h4>
        <p>
          We reserve the right to update or modify this Privacy Policy at any time. Changes will 
          be effective upon posting on our website. Users are encouraged to review this Privacy 
          Policy periodically for updates.
        </p>

        <h4>9. Contact Information</h4>
        <p>
          If you have questions or concerns regarding this Privacy Policy, please contact 
          us at <a href="mailto:info@lacolombiere.ac.rw">info@lacolombiere.ac.rw</a>.
        </p>        
      </div>
    </div>
  );
}
const FrenchPolicyContent = () => {
  return (
    <div className="lang-en">
      <div className="flex flex-col">
        <h4>Confidentialité -  La Colombière</h4>
        <p>
          Cette politique de confidentialité décrit comment <strong>La Colombière</strong> collecte, 
          utilise, divulgue et protège les informations personnelles obtenues via notre 
          site Web. En accédant et en utilisant notre site Web, vous consentez aux 
          pratiques décrites dans cette politique.
        </p>

        <h4>1. Informations que nous collectons</h4>
        <ul>
          <li>
            <strong>Informations personnelles</strong>: nous pouvons collecter des informations 
            personnelles telles que des noms, des coordonnées et d'autres 
            identifiants lorsqu'elles sont volontairement fournies par les 
            utilisateurs via des formulaires ou d'autres interactions.
          </li>
          <li>
            <strong>Informations collectées automatiquement</strong>: nous pouvons collecter 
            des informations non personnelles, telles que des adresses IP, des types de 
            navigateur et des informations sur les appareils, pour améliorer votre expérience 
            et améliorer notre site Web.
          </li>
        </ul>


        <h4>2. Comment nous utilisons vos informations</h4>
        <p>Nous pouvons utiliser les informations collectées aux fins suivantes:</p>
        <ul>
          <li>Pour fournir et personnaliser nos services.</li>
          <li>Pour communiquer avec les utilisateurs et répondre aux demandes de renseignements.</li>
          <li>Pour améliorer notre site Web et améliorer l'expérience utilisateur.</li>
          <li>À des fins administratives et commerciales internes.</li>
        </ul>

        <h4>3. Partage d'information</h4>
        <p>
          Nous ne vendons, n'échangeons ni ne transférons de toute autre manière des 
          informations personnelles à des tiers sans le consentement de l'utilisateur. 
          Cependant, nous pouvons partager des informations avec des prestataires de 
          services tiers de confiance qui nous aident à exploiter notre site Web ou à 
          mener des activités liées à l'école.
        </p>

        <h4>4. Cookies et technologies de suivi</h4>
        <p>
          Notre site Web peut utiliser des cookies et des technologies similaires pour 
          améliorer l'expérience utilisateur et recueillir des informations sur la façon 
          dont notre site est utilisé. Les utilisateurs peuvent gérer leurs préférences 
          en matière de cookies via les paramètres de leur navigateur.
        </p>

        <h4>5. Mesures de sécurité</h4>
        <p>
          Nous mettons en œuvre des mesures de sécurité raisonnables pour nous protéger 
          contre l'accès non autorisé, la modification, la divulgation ou la destruction 
          des informations personnelles. Cependant, aucune méthode de transmission sur 
          Internet ou de stockage électronique n'est sécurisée à 100 %.
        </p>

        <h4>6. Liens vers des sites externes</h4>
        <p>
          Notre site Web peut contenir des liens vers des sites externes. Nous ne sommes 
          pas responsables du contenu ou des pratiques de confidentialité de ces sites. 
          Les utilisateurs doivent consulter les politiques de confidentialité des sites 
          externes avant de fournir des informations personnelles.
        </p>

        <h4>7. Confidentialité des enfants</h4>
        <p>
          Notre site Web n'est pas destiné aux enfants de moins de 13 ans. Nous ne 
          collectons pas sciemment d'informations personnelles auprès des enfants. 
          Si vous pensez qu'un enfant nous a fourni des informations personnelles, 
          veuillez nous contacter et nous prendrons les mesures appropriées pour supprimer 
          ces informations.
        </p>

        <h4>8. Modifications de cette politique de confidentialité</h4>
        <p>
          Nous nous réservons le droit de mettre à jour ou de modifier cette politique de 
          confidentialité à tout moment. Les modifications entreront en vigueur dès leur 
          publication sur notre site Web. Les utilisateurs sont encouragés à consulter 
          périodiquement cette politique de confidentialité pour les mises à jour.
        </p>

        <h4>9. Informations de contact</h4>
        <p>
          Si vous avez des questions ou des préoccupations concernant cette politique 
          de confidentialité, veuillez nous contacter à <a href="mailto:info@lacolombiere.ac.rw">info@lacolombiere.ac.rw</a>.
        </p>        
      </div>
    </div>
  );
}
import { IGeneralType } from 'src/models/smarttypes';
import styles from './SectionTitle.module.css';


export const SectionTitle = (
  {title, textColor, fontSize, leading, fontName,textAlign }: IGeneralType) => {
  const oColor = textColor ?? '#000';

  const fSize = fontSize ?? 36;
  const oSize = `${fSize}px`;

  const oLeading = leading ?? '1';

  const oFontName = fontName ?? 'Raleway';

  const tAlign = textAlign ?? 'left';



  return (
    <div className='mb-5 relative'>
        <div className='mb-[10px]'>
            <h2 className={styles.title}
            style={{
              color: oColor,
              fontSize: oSize,
              lineHeight: oLeading,
              fontFamily: oFontName,
              textAlign: tAlign
            }}>{title}</h2>
        </div>
    </div>
  )
}


export const SectionParagraph = ({message}: IGeneralType) => {
    return (
      <div className='mb-5 relative'>
          <div>
              <p style={{marginBottom: '0.75rem'}}>{message}</p>
          </div>
      </div>
    )
  }
import { useEffect } from "react";

import { IPageTitleSection } from "src/models/smarttypes";
import { MainTitleWrapper } from "src/components/pages/MainTitleWrapper";

import getLanguages from 'src/utils/language';
import generalFxs from 'src/utils/general_fx';

import { TitleSeparator } from "src/components/elements/titleseparator/TitleSeparator";

const languages = getLanguages();
const appFxs = generalFxs();
const isEn = appFxs.getLocalLanguage() === 'en';


export default function TermsPage() {
  document.title = `${languages.terms_serv} | ${languages.schoolName}`;
  const oElt: IPageTitleSection = {
    classes: "legal-pages",
    currentText: languages.terms,
    pageTitle: languages.terms_serv,
  };

  useEffect(() => {window.scrollTo(0,0)}, []);
  return (
    <>
      <MainTitleWrapper {...oElt} />
      <section className="py-[25px] md:py-[70px]">
        <div className="w-container">
          <div className="box-inner">
            <div className="mb:20px md:mb-[60px] text-center">
              {isEn && (
                <h2 className="text-[30px] md:text-[46px] leading-[1.2]">
                  Terms and Conditions for La Colombière School
                </h2>
              )}
              {!isEn && (
                <h2 className="text-[30px] md:text-[46px] leading-[1.2]">
                  Conditions d'uilisation - Ecole La Colombière
                </h2>
              )}
              <TitleSeparator titleColor="#ff6d9d" />
            </div>
          </div>

          <div className="policy-body">
            <div className="box-inner">
              {isEn ? <EnglishPolicyContent /> : <FrenchPolicyContent/>}
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

const EnglishPolicyContent = () => {
  return (
    <div className="lang-en">
      <div className="flex flex-col">
        <h4>1. Acceptance of Terms</h4>
        <p>
          By accessing and using the <strong>La Colombière</strong> website, you agree to comply with and be bound by these Terms and Conditions. If you do not agree with any part of these terms, you must not use this website.
        </p>

        <h4>2. Use of Content</h4>
        <p>
          All content on this website, including text, graphics, logos, 
          images, and other materials, is the property of <strong>La Colombière</strong>
          and is protected by copyright laws. You may only use this content 
          for personal, non-commercial purposes. Unauthorized use, reproduction, 
          or distribution of any content from this website is strictly prohibited.
        </p>

        <h4>3. User Conduct</h4>
        <p>
          You agree to use this website for lawful purposes only. You are prohibited 
          from engaging in any activity that could damage, disable, or impair the 
          website's functionality or interfere with other users' access.
        </p>

        <h4>4. Privacy Policy</h4>
        <p>
          Our Privacy Policy outlines how we collect, use, disclose, and manage 
          your personal information. By using this website, you agree to the 
          terms outlined in our Privacy Policy.
        </p>

        <h4>5. Third-Party Links</h4>
        <p>
          This website may contain links to third-party websites. 
          <strong>La Colombière</strong> is not responsible for the content or practices 
          of these websites. The inclusion of any link does not imply 
          endorsement by <strong>La Colombière</strong>.
        </p>

        <h4>6. Changes to Terms and Conditions</h4>
        <p>
          <strong>La Colombière</strong> reserves the right to modify or replace these Terms 
          and Conditions at any time. It is your responsibility to check these 
          terms periodically for changes. Your continued use of the website 
          following the posting of any changes constitutes acceptance of 
          those changes.
        </p>

        <h4>7. Disclaimer of Warranties</h4>
        <p>
          This website is provided "as is" without any warranties, expressed or 
          implied. <strong>La Colombière</strong> makes no representations or warranties 
          regarding the accuracy, completeness, or reliability of the content on 
          this website.
        </p>

        <h4>8. Limitation of Liability</h4>
        <p>
          In no event shall <strong>La Colombière</strong> be liable for any damages arising 
          out of or in connection with the use or inability to use this website.
        </p>

        <h4>9. Governing Law</h4>
        <p>
          These Terms and Conditions are governed by and construed in accordance 
          with the laws of <strong>La Colombière</strong>. Any disputes relating to 
          these terms shall be subject to the exclusive jurisdiction of the courts 
          in <strong>Kigali City, Rwanda</strong>.
        </p>

        <h4>10. Contact Information</h4>
        <p>
          If you have any questions about these Terms and Conditions, please 
          contact us at <strong>La Colombière</strong>.
        </p>
        <p>
          By using this website, you acknowledge that you have read, understood, 
          and agree to be bound by these Terms and Conditions.
        </p>

      </div>
    </div>
  );
}
const FrenchPolicyContent = () => {
  return (
    <div className="lang-en">
      <div className="flex flex-col">
        <h4>1. Acceptation des conditions</h4>
        <p>
          En accédant et en utilisant le site Internet de <strong>La Colombière</strong>, 
          vous acceptez de respecter et d'être lié par les présentes Conditions Générales. 
          Si vous n'êtes pas d'accord avec une partie de ces conditions, vous ne devez pas utiliser ce site Web.
        </p>

        <h4>2. Utilisation du contenu</h4>
        <p>
          Tout le contenu de ce site Web, y compris les textes, graphiques, logos, 
          images et autres éléments, est la propriété de <strong>La Colombière</strong>
          et est protégé par les lois sur le droit d'auteur. Vous ne pouvez utiliser ce 
          contenu qu'à des fins personnelles et non commerciales. L'utilisation, 
          la reproduction ou la distribution non autorisée de tout contenu de ce site 
          Web est strictement interdite.
        </p>

        <h4>3. Conduite de l'utilisateur</h4>
        <p>
          Vous acceptez d'utiliser ce site Web uniquement à des fins licites. Il vous est 
          interdit de vous livrer à toute activité susceptible d'endommager, de désactiver 
          ou d'altérer la fonctionnalité du site Web ou d'interférer avec l'accès d'autres 
          utilisateurs.
        </p>

        <h4>4. Politique de confidentialité</h4>
        <p>
          Notre politique de confidentialité décrit la manière dont nous collectons, 
          utilisons, divulguons et gérons vos informations personnelles. En utilisant 
          ce site Web, vous acceptez les conditions décrites dans notre politique de 
          confidentialité.
        </p>

        <h4>5. Liens tiers</h4>
        <p>
          Ce site Internet peut contenir des liens vers des sites Internet de tiers. 
          <strong>La Colombière</strong> n'est pas responsable du contenu ou des pratiques de ces sites 
          Internet. L'inclusion de tout lien n'implique pas l'approbation 
          de <strong>La Colombière</strong>.
        </p>

        <h4>6. Modifications des conditions générales</h4>
        <p>
          <strong>La Colombière</strong> se réserve le droit de modifier ou de remplacer les présentes 
          Conditions Générales à tout moment. Il est de votre responsabilité de vérifier 
          périodiquement ces conditions pour détecter les modifications. Votre utilisation 
          continue du site Web après la publication de toute modification constitue une 
          acceptation de ces modifications.
        </p>

        <h4>7. Exclusion de garanties</h4>
        <p>
          Ce site Web est fourni «tel quel» sans aucune garantie, expresse ou 
          implicite. <strong>La Colombière</strong> ne fait aucune représentation ou garantie 
          concernant l'exactitude, l'exhaustivité ou la fiabilité du 
          contenu de ce site Web.
        </p>

        <h4>8. Limitation de responsabilité</h4>
        <p>
          In no event shall <strong>La Colombière</strong> be liable for any damages arising out 
          of or in connection with the use or inability to use this website.
        </p>

        <h4>9. Loi applicable</h4>
        <p>
          Les présentes Conditions générales sont régies et interprétées conformément 
          aux lois de <strong>La Colombière</strong>. Tout litige relatif aux présentes conditions 
          sera soumis à la compétence exclusive des tribunaux de <strong>la ville de Kigali, au Rwanda</strong>.
        </p>

        <h4>10. Informations de contact</h4>
        <p>
          Si vous avez des questions concernant ces Conditions Générales, veuillez nous contacter à 
          <strong>La Colombière</strong>.
        </p>
        <p>
          En utilisant ce site Web, vous reconnaissez avoir lu, compris et accepté d'être 
          lié par ces termes et conditions.
        </p>

      </div>
    </div>
  );
}
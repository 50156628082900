import studentWithLaptop from 'src/assets/images/schoolimgs/no_bg.png';
import { SubscribeForm } from 'src/components/elements/subscribeform/SubscribeForm';

import getLanguages from 'src/utils/language';
import generalFxs from 'src/utils/general_fx';

const languages = getLanguages();
const appFxs = generalFxs();
const isEn = appFxs.getLocalLanguage() === 'en';

export const NewWorldSchool = () => {
  return (
    <section className="new-world-area z-10">
      <div className="w-container">
        <div className="p-[10px] relative">
          <div className="world-inner">
            <div className="flex flex-col items-start justify-center min-[1024px]:flex-row min-[1024px]:items-start">
              <div className="basis-full min-[1024px]:basis-[59%]">
                <div className='p-[10px] mb-[30px]'>
                  <h2>{isEn ? 
                    "With us there is a chance to make your children succeed in life.": 
                    "Avec nous il y a une chance de faire réussir vos enfants dans la vie."}</h2>
                </div>
                <div className='mb-[20px]'>
                  <div className='mb-[30px]'>
                    <h4 className='ml-2'>
                      <span className='label-headline'>
                        <span className='label-inner'>{isEn ? "School gets funnier every day":"L'école devient chaque jour plus drôle"}</span>
                      </span>
                      <span className='label-spacer hidden'>&nbsp;</span>
                      <span className='label-headline lbl-second'>
                        <span className='label-inner'>{isEn ? "Every year, a new performance.": "Chaque année, une nouvelle performance."}</span>
                      </span>
                    </h4>
                  </div>
                </div>
                <div className=''>
                  <SubscribeForm  
                    placeholder={languages.yourEmailHere}
                    submitText={languages.subscribe}/>
                </div>
              </div>
              <div className="basis-full min-[1024px]:basis-[41%]">
                <div className="p-[10px]">
                  <img src={studentWithLaptop} alt="" />
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </section>
  );
}

import {CSSProperties} from 'react';

export type SayingType = {
    say: string;
    author: string;
}
export type BgType  = {
    ['background-image']: string;
}
export type PadLeftStType  = {
    ['padding-left']: string;
}
export type WidthCssStyle  = {
    width: string;
}
export type SayElt = {
    saying: SayingType;
    bg: BgType;
}
export type PasswordStyle = {
    width: string;
    bgMeterClass: string;
    infoClass: string;
}
export type PasswordStrengthType = {
    value: number;
    message: string;
    length: number;
}

export type HeaderType = {
    'user-access-key': string;
    lang: string;
    'company-code': string;
    'client-operation-date': string;
    'year-exercise-app': string;
    'default-sms-account': string;
    'current-devise-val': string;
    'current-branch-val': string;
    'user-email-value': string;
    'user-password-value': string;
    'app-type': string;
}

export type TelCode = {
    name: string;
    dial_code: string;
    code: string;
}

export type CurrencyCode = {
    symbol: string;
    name: string;
    symbol_native: string;
    decimal_digits: number;
    rounding: number;
    code: string;
    name_plural: string;
}

export type TimeZoneCode = {
    value: string;
    abbr: string;
    offset: number;
    isdst: boolean;
    text: string;
    utc: string[];
}

export type ShortData = {
    id: number;
    label: string;
}

export enum DateOperation {
    Day,
    Month,
    Year,
}
export enum RightFitEnum {
    Brain,
    Infra,
    Book,
}

export type JoinRecord = {
    JoinKey: string;
    Password: string;
    ConfirmPassword: string;
}

export type PrintRecType = {
    ReportTitle: string;
    TypeSoft: number;
    ReportToPrint: number;
    StylePrint: number;
    EmailPrint: string;
    LangPrint: string;
    EmailReceiver: string;
    EmailSubject: string;
    EmailMessage: string;
};
export interface ILoginDataTransfer {
    userEmail: string,
    userPassword: string,
    userRole: string,
}

export interface ISocialIcon {
    label: string,
    href: string,
    color: string,
    id: string,
    icon: string
}
export interface IMenuColomb {
    label: string,
    href: string,
    color: string,
    id: string,
    selected?: boolean,
}
export interface IMenuColombElt {
    menu: IMenuColomb,
    onClick?: (oMenu: IMenuColomb) => void,
}
export interface IRecentPost {
    postTitle: string,
    href: string,
    imagePath: string,
    postDate: Date,
    id: string,
}

export interface IRecentPostElt {
    postElt: IRecentPost,
}
export interface ISocialIconElt {
    socialElt: ISocialIcon,
}
export interface ISliderCompElt {
    children: React.ReactNode,
    classes?: string,
    hidden?: boolean,
    onClick?: () => void,
}
export enum HomeSpecType {
    StaffTeam,
    ReadingKid,
    AlumniTeam,
    SchoolAmenities,
}
export enum CurricularType {
    higher,
    medium,
    lower,
    daycare,
    education,
    internation,
}
export interface IHomeSpec {
    label: string,
    iconComponent: HomeSpecType,
    color: string,
    href: string,
}
export interface IHomeSpecElt {
    specElt: IHomeSpec,
}

export interface ICurricularData {
    label: string,
    description: string,
    iconType: CurricularType,
    bgColor: string,
    linkColor: string,
    href: string,
    id: number,
    typeView?: 'normal'|'circle',
    circleColor?: string,
    hasLink?: boolean,
}
export interface ICurricularElt {
    currElt: ICurricularData,
}

export interface IRightFit {
    label: string,
    iconType: RightFitEnum,
    iconColor: string,
    id: number
}
export interface IRightFitElt {
    rightElt: IRightFit,
}

export type ISelectOptionType = {
    id: string;
    label :string;
}
export interface IImageElt {
    id: number,
    image: any,
    href: string;
    label: string;
}
export interface IStaffStatusElt {
    id: number,
    staffName: string,
    staffJob?: string,
    phoneNbr?: string,
    twitterAcct?: string,
    fbAcct?: string,
    linkedInAcct?: string,
    instAcct?: string,
    color: string,
    staffImage?: string,

    isPhoneVis?: boolean,
    colorSubtitle?: string,
    colorTitle?: string,
    phoneBgColor?: string,
    phoneHoverBgColor?: string,
    phoneTextColor?: string,
    phoneHoverTextColor?: string,
}
export type TSocialIcon = {
  href: string;
  type: 'facebook'|'instagram'|'linkedin'|'twitter';
}
export interface IStaffSocialIcon {
    id: number,
    label?: string,
    href?: string,
    color: string,
    iconType: 'twitter' | 'linkedin' | 'instagram'| 'facebook'| 'whatsapp'
}


export interface IParentTestimonial {
    id: number,
    label?: string,
    color: string,
    parentName: string,
    testimonial: string,
    image: any,
    selected: boolean,
}
export interface IParentTestimonialElt {
    testimonialElt: IParentTestimonial,
    onSelected: () => void
}

export interface InnerCss extends CSSProperties {
    '--testimonial-color'?: string,
    '--qualif-color'?: string,
    '--parent-color'?: string,
    '--entry-date-color'?: string,
    '--title-color'?: string,
    '--title-width'?: string,
    '--sub-title-width'?: string,
    '--blog-entry-color'?: string,
    '--strangeImageBg'?: string,
    '--special-color'?: string,
    '--bgImage'?: string,
    '--bgColor'?: string,
    '--bgHoverColor'?: string,
    '--textColor'?: string,
    '--textHoverColor'?: string,
    '--iconColor'?: string,
    '--width'?: string,
    '--i'?: number,
}

export interface ITestimonialQualif{
    eltNbr: number,
    label: string,
    color: string,
    image: any,
    id: number,
}
export interface ILastBlog {
    postTitle: string,
    href: string,
    imagePath: string,
    postDate: Date,
    postContent: string,
    id: number,
    color: string,

}

export interface ILastBlogElt {
    eltPros: ILastBlog,
    isMainBlog: boolean,
    classes: string,
    hasBgColor?: boolean,
}
export interface IRadioRecord {
    label: string,
    value: string,
    detail: string,
}
export interface IInputData {
    type?: 'text'|'email'|'tel'|'url'|'number'|'select'|'textarea'|'date',
    placeholder?: string,
    maxLength?: number,
    disabled?: boolean,
    selectOptions?:{id: string, label:string }[],
    name: string,
    value: undefined | string | number | Date,
    rows?: number,
    label?: string,
    otherClasses?: string,
    errorMessage?: string,
    onChange?: (e: IFieldRecord) => void

    step?: number, 
    min?: number,
    max?: number,

    displayClass?: 'block'|'inline-block',
    layoutStyle?: 'row'|'column',

    groupRadioValues?: IRadioRecord[]
}


export interface IPageTitleSection {
    pageTitle: string,
    currentText: string,
    classes: string,
    subPageTitle?: string,
    subPageLink?: string,
}

export interface ICurricular {
    id: number,
    title: string,
    detail: string,
    type: 'book' | 'guitar' | 'certificate' | 'staff',
}

export interface IFaq{
    id: number,
    title: string,
    message: string,
}
export interface IFaqItem {
    showDescription: string,
    fontWeightBold: string,
    ariaExpanded: "true" | "false",
    item: IFaq,
    index: number,
    onClick: () => void,
}
export interface IFacqElt {
    title: string,
    lstQuest: IFaq[],
}
export interface IFacilityAmenity {
    id: number,
    iconType: 'aircondition'|'infrastructure'|'transport'|'foodstay'|'networked',
    title: string,
    subTitle: string,
}
export interface IFacilityFeature {    
    id: number,
    type: 'brightclass' | 'multimedia' | 'playground' |'lablibrary' | 'meetinghall' |'sportsclub',
    title: string,
    detail: string,
    href: string,
}

export interface ITestiomonialElt {
    lstElts: IParentTestimonial[],
    textColor?:string,
}

export interface IGeneralType {
    title?: string,
    message?: string,
    href?: string,
    fawesomeIcon?: string,
    iconColor?: string,
    textColor?: string,
    leading?: string,
    fontSize?: number,
    fontName?: 'Quicksand' | 'Raleway',
    placeholder?: string,
    submitText?: string,
    isHtml?: boolean,
    textAlign?: 'left'|'center'|'right'
}
export interface IFacVideoVign {
    id: number,
    href: string,
    img: any,
    label: string,
} 
export interface IRealAbtUsElt {
    ytbLink: string,
    parentClasses: string,
    hasTopBody?: boolean,
    topBodyText?: string,
    bottomBodyText?: string,
    hasButtonLink?: boolean,
    navigationLinkRef?: string,
    linkType?: 'dark'| 'light',
    hideImgBg?: string,
    linkText?: string,
}
export interface ITypeAddr {
    type: 'text' | 'email' | 'phone',
    text: string,
    label: string,
}
export interface IContactAddress {
    id: number,
    title: string,
    iconClass: string,
    iconColor: string,
    lstDetails: ITypeAddr[],
}
export interface IContactAddressForm {
    contactKey: string,
    firstName: string,
    lastName: string,
    mailAddress: string,
    phoneAddress: string,
    lookingForWhat: number,
    priorityType: number,
    contactMessage: string,
    captchaValue: string,
}
export interface IContactAddressFormError {
    contactKey?: string,
    firstName?: string,
    lastName?: string,
    mailAddress?: string,
    phoneAddress?: string,
    lookingForWhat?: string,
    priorityType?: string,
    contactMessage?: string,
    captchaValue?: string,
}


export interface IShopMainData {
    typeDisplay: 'grid'|'list',
    gridDisplay: 'oneline'|'twoline'|'threeline'|'fourline'|'imageleft'|'imageright',
    items: IWebShopProduct[],
}


export interface IWebShopProduct {
    typeDisplay: 'oneline'|'twoline'|'threeline'|'fourline'|'imageleft'|'imageright',
    item: IShopProductItem,
}
export interface IProductVariant {
    variantName: string, 
    values: {id: string, label: string}[]
}
export interface IFieldRecord {
    field: string,
    value: any,
}

export interface IProductVariantField {
    variant: IProductVariant,
    fieldName: string,
    onChange: (fo: IFieldRecord) => void,
    value: any,
}


export interface IShopProductItem {
    productName: string,
    productPrice: number,
    discountedPrice: number,
    productId: string,
    imagePath: any[],


    productDescript?: string,
    sku?: string,
    category?: string,
    variants? : IProductVariant[]
}
export interface ICartOperationProd {
    productName: string,
    productId: string,
    imagePath: string,
    productPrice: number,
    quantity: number,
    subtotal: number,
    variants?: {label: string, value: string}[]
}
export interface ICartOperation {
    operationDate: string,
    operationKey: string,
    products: ICartOperationProd[],
    subtotal: number,
    total: number,
}
export interface ICartItemOper {
    product: ICartOperationProd,
    inlistPos: number,
    onItemQtyChanged: (listPos: number, newQty: number) => void,
    onItemRemoved: (listPos: number) => void,
}


export interface IProductWithPagination {
    items: IShopProductItem[], 
    gridDisplay: 'oneline'|'twoline'|'threeline'|'fourline'|'imageleft'|'imageright',
}
export interface IProductWithPaginationAll {
    itemsPerPage: number,
    items: IShopProductItem[],
    gridDisplay: 'oneline'|'twoline'|'threeline'|'fourline'|'imageleft'|'imageright',
    onChange?: (iPage: number) => void,
}

export interface IContactInfo {
    firstName: string,
    lastName: string,
    companyName?: string,
    province: string,
    city: string,
    phone: string,
    email: string,
    roadNbr?: string,
    orderNotes?: string,
    typePaiement: number,
    paytDetail: string,
}
export interface IContactInfoError {
    firstName?: string,
    lastName?: string,
    companyName?: string,
    province?: string,
    city?: string,
    phone?: string,
    email?: string,
    roadNbr?: string,
    orderNotes?: string,
    typePaiement?: number,
    paytDetail?: string,
}

export interface IAlumniRegister {
    firstName: string,
    lastName: string,
    dateOfBirth: Date,
    yearCompleted: string,
    sex?: string,
    phoneNbr: string,
    emailAddress: string,
    addressLine: string,
    completedDegree: string,
    captcha: string,

    activeJob?: string,
    twitterAcct?: string
    facebookAcct?: string
    linkedInAcct?: string
    instagramAcct?: string
}
export interface IAlumniRegisterError {
    firstName?: string,
    lastName?: string,
    dateOfBirth?: string,
    yearCompleted?: string,
    sex?: string,
    phoneNbr?: string,
    emailAddress?: string,
    addressLine?: string,
    completedDegree?: string,
    captcha?: string,
    fileImage?: string,
    
    activeJob?: string,
    facebookAcct?: string,
    twitterAcct?: string,
    linkedInAcct?: string,
    instagramAcct?: string,
}


export interface IAskAboutKid {
    userName: string,
    emailAddress: string,
    telNumber: string,
    studies: string,
    message: string,
    captcthaVal: string,
}

export interface IAskAboutKidSend {
    operationKey: string,
    userName: string,
    emailAddress: string,
    telNumber: string,
    studies: string,
    message: string,
    captcthaVal: string,
}


  export interface IAskAboutKidError {
    userName?: string,
    emailAddress?: string,
    telNumber?: string,
    studies?: string,
    message?: string,
    captcthaVal?: string,
  }
export interface IStaffTeam {
    id: number,
    title: string,
    headerContent: string,
    subheaderContent: string,
    quotationText: string,
    signatureData?: any,
    staffImage: any,
    staffName: string,
    staffJob: string,
    showQuotation: boolean,
    showSignature: boolean,
}

export interface IManagtBlog {
    userEmail: string,
    blogKey: string,
    blogTitle: string,
    blogDescription: string,
    blogDate: Date,
    blogLang: string,
    blogAuthorEmail: string,
    blogImageUrl: string,
    blogPathUrl: string,
    published: boolean,
    blogColor: string,
}


export interface IManagtUser {
    firstName: string,
    lastName: string,
    dateOfBirth: Date,
    sex?: string,
    phoneNbr: string,
    emailAddress: string,
    password?: string,
    addressLine: string,
    userActive: boolean,
    profilePicturePath?: string,
    mainAdmin: boolean,
    admin: boolean,
}











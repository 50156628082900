import React, { useEffect } from "react";

import getLanguages from "src/utils/language";
import generalFxs from "src/utils/general_fx";
import { IPageTitleSection } from "src/models/smarttypes";
import { MainTitleWrapper } from "src/components/pages/MainTitleWrapper";
import { TitleSeparator } from "src/components/elements/titleseparator/TitleSeparator";

import founderImg from "src/assets/images/schoolimgs/founde_img_1.png";
import imgHistory1 from "src/assets/images/schoolimgs/school_history_img_1.jpg";
import imgHistory2 from "src/assets/images/schoolimgs/school_history_img_2.jpg";
import imgHistory3 from "src/assets/images/schoolimgs/school_history_img_3.jpg";

import { COLORS } from "src/utils/wcolors";

const languages = getLanguages();
const appFxs = generalFxs();
const isEn = appFxs.getLocalLanguage() === "en";

export const SchoolHistoryPage = () => {
  document.title = `${languages.schoolHistory} | ${languages.schoolName}`;
  const oElt: IPageTitleSection = {
    classes: "school-history",
    currentText: languages.schoolHistory,
    pageTitle: languages.schoolHistory,
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <MainTitleWrapper {...oElt} />
      <section className="our-teachers-zone qualified-staff py-[70px] relative">
        <div className="w-container">
          {/* Header */}
          <div className="mb-[20px] text-center relative z-0">
            <h2 className="mb-[20px]">{languages.schoolHistory}</h2>
            <TitleSeparator titleColor={COLORS.airForceBlue} />
          </div>

          <div className="px-5 pt-5 pd:5 md:pb-28 bg-special">
            <div className="special-hist">
              {/* paragraph 1 */}
              <div className="flex flex-row flex-wrap">
                <div className="w-full md:w-[60%]">
                  <div className="p-3">
                    <p className="text-lg p-3 mb-3 text-justify">
                      {isEn && (
                        <React.Fragment>
                          The history of La Colombière School starts with a
                          passion of teaching. The founder&nbsp;
                          <strong>Mrs. NYIRANTAGORAMA Françoise</strong> had an
                          experience of 33 years when she started the school the
                          October 1st 1990 back in exile, in Bujumbura the
                          capital city of Burundi. Before teaching in Bujumbura,
                          in the sixties, she had taught in Rwanda: Nyanza,
                          Kabgayi and Kansi in the southern parts of Rwanda.
                        </React.Fragment>
                      )}
                      {!isEn && (
                        <React.Fragment>
                          L'histoire de l'École La Colombière commence par une
                          passion pour l'enseignement. La fondatrice&nbsp;
                          <strong>Mrs. NYIRANTAGORAMA Françoise</strong> avait
                          une expérience de 33 ans lorsqu'elle a ouvert l'école
                          le 1er octobre 1990 en exil, à Bujumbura la capitale
                          du Burundi. Avant d'enseigner à Bujumbura, dans les
                          années soixante, elle avait enseigné au Rwanda :
                          Nyanza, Kabgayi et Kansi dans le sud du Rwanda.
                        </React.Fragment>
                      )}
                    </p>
                  </div>
                </div>
                <div className="w-full md:w-[40%]">
                  <div className="p-3">
                    <img
                      src={founderImg}
                      alt=""
                      className="img-rotate w-full max-h-[250px] object-cover object-top border border-solid border-slate-400/40 rounded-lg"
                    />
                  </div>
                </div>
              </div>

              {/* paragraph 2 */}
              <div className="flex flex-row flex-wrap mt-4">
                <div className="w-full order-1 md:w-[60%] md:order-2">
                  <div className="p-3">
                    <p className="text-lg p-3 mb-3 text-justify">
                      {isEn && (
                        <React.Fragment>
                          In Bujumbura, she excelled in two different private
                          Schools: Stella Matutina and Ecole Indépendante de
                          Bujumbura, to which she was its principal.
                        </React.Fragment>
                      )}
                      {!isEn && (
                        <React.Fragment>
                          À Bujumbura, elle a excellé dans deux écoles privées
                          différentes : Stella Matutina et l'Ecole Indépendante
                          de Bujumbura, dont elle était la directrice.
                        </React.Fragment>
                      )}
                    </p>
                  </div>
                </div>
                <div className="w-full order-2 md:w-[40%] md:order-1 relative">
                  <div className="p-3 relative">
                    <img
                      src={imgHistory1}
                      alt=""
                      className="img-rotate w-full max-h-[250px] object-cover object-top border border-solid border-slate-400/40 rounded-lg transition-transform duration-150"
                    />
                  </div>
                </div>
              </div>

              {/* paragraph 3 */}
              <div className="flex flex-row flex-wrap mt-4 md:mt-20">
                <div className="w-full order-2 md:w-[60%] md:order-1">
                  <div className="p-3">
                    <p className="text-lg p-3 mb-3 text-justify">
                      {isEn && (
                        <React.Fragment>
                          It is after all those years in Education that she
                          decided to start her own school in 1990. After the
                          1994 Genocide against the Tutsi, Rwandans were
                          repatriated, Mrs. Nyirantagorama shifted the school
                          which opened the doors on 13th February 1995 with
                          almost 2000 pupils in Nursery, Primary and Secondary
                          levels. It was among few schools which had a big
                          population at that time due to its originality: the
                          medium of instruction was English and French.
                        </React.Fragment>
                      )}

                      {!isEn && (
                        <React.Fragment>
                          C'est après toutes ces années dans l'Éducation qu'elle
                          décide de créer sa propre école en 1990. Après le
                          Génocide contre les Tutsi en 1994, les Rwandais furent
                          rapatriés, Mme Nyirantagorama déménagea l'école qui
                          ouvrit les portes le 13 février 1995 avec près de 2000
                          élèves en Niveaux Maternelle, Primaire et Secondaire.
                          C'était l'une des rares écoles à avoir une population
                          importante à cette époque en raison de son originalité
                          : la langue d'enseignement était l'anglais et le
                          français.
                        </React.Fragment>
                      )}
                    </p>
                  </div>
                </div>
                <div className="w-full order-1 md:w-[40%] md:order-2 relative">
                  <div className="p-3 relative">
                    <img
                      src={imgHistory2}
                      alt=""
                      className="img-rotate w-full max-h-[250px] object-cover object-top border border-solid border-slate-400/40 rounded-lg transition-transform duration- hover:"
                    />
                  </div>
                </div>
              </div>

              {/* paragraph 4 */}
              <div className="flex flex-row flex-wrap mt-4 md:mt-20">
                <div className="w-full order-1 md:w-[60%] md:order-2">
                  <div className="p-3">
                    <p className="text-lg p-3 mb-3 text-justify">
                      {isEn && (
                        <React.Fragment>
                          In 1995, La Colombière School was renting its premises
                          in Remera. In 1998, La Colombière School was relocated
                          to Kacyiru in its own premises where it is today with
                          1385 pupils in 2023 in 43 streams of pre-primary and
                          primary. The beautiful journey continues: The School
                          will leave the marsh land this coming year 2024 and
                          move in new and modern premises in Gaculiro.
                        </React.Fragment>
                      )}
                      {!isEn && (
                        <React.Fragment>
                          En 1995, l'école La Colombière louait ses locaux à
                          Remera. En 1998, l'école La Colombière a été
                          transférée à Kacyiru dans ses propres locaux où elle
                          se trouve aujourd'hui avec 1385 élèves en 2023 dans 43
                          filières du pré-primaire et du primaire. Le beau
                          voyage continue : l'école quittera les marais cette
                          année 2024 et emménagera dans de nouveaux locaux
                          modernes à Gaculiro.
                        </React.Fragment>
                      )}
                    </p>
                  </div>
                </div>
                <div className="w-full order-2 md:w-[40%] md:order-1 relative">
                  <div className="p-3 relative">
                    <img
                      src={imgHistory3}
                      alt=""
                      className="img-rotate w-full max-h-[250px] object-cover object-top border border-solid border-slate-400/40 rounded-lg transition-transform duration- hover:"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

import { Link } from 'react-router-dom';
import styles from './LinkAchorRound.module.css';
import { InnerCss } from 'src/models/smarttypes';
import getLanguages from 'src/utils/language';

const languages = getLanguages();

export interface ILinkAnchor {
    href?: string,
    text: string,
    textColor?: string,
    bgColor?: string,
    hoverTextcolor?: string,
    hoverBgcolor?: string,
    type?: 'link' | 'button',
    onClick?: () => void,
    title?: string,
    disabled?: boolean,
    fullWidth?: boolean,
}

export const LinkAchorRound = ({
    type, href, text, bgColor, 
    hoverBgcolor, hoverTextcolor, 
    textColor, onClick,
    title, disabled,
    fullWidth}: ILinkAnchor) => {
    const oType = type ?? 'link';
    const btnBgColor = bgColor ?? '#ff236c';
    const btnBgHoverColor = hoverBgcolor ?? '#000000';
    const btnTextColor = textColor ?? '#ffffff';
    const btnTextHoverColor = hoverTextcolor ?? '#ffffff';
    const oHRef = href ?? '/';
    const fWidth = fullWidth ?? false;

    const isDisabled = disabled ?? false;

    const fClick = () => {
        if(isDisabled) return;
        if(!onClick) return;
        onClick!();
    }

  return (
    <>
        {
            oType==='link' && <Link to={oHRef} className={styles.linkAnchorBtn} style={{
                '--bgColor': btnBgColor,
                '--bgHoverColor': btnBgHoverColor,
                '--textColor': btnTextColor,
                '--textHoverColor': btnTextHoverColor,
                '--width': fWidth ? '100%': 'auto',
            } as InnerCss}
            title={title ?? languages.clickHere}>
                <span className={styles.btnTextWrapper}>
                    <span className={styles.btnText}>
                        {text}
                    </span>
                </span>
            </Link>
        }
        {
            oType==='button' && 
            <button className={styles.linkAnchorBtn} style={{
                '--bgColor': btnBgColor,
                '--bgHoverColor': btnBgHoverColor,
                '--textColor': btnTextColor,
                '--textHoverColor': btnTextHoverColor,
                '--width': fWidth ? '100%': 'auto',
            } as InnerCss} type='button' onClick={fClick} disabled={isDisabled}>
                <span className={styles.btnTextWrapper}>
                    <span className={styles.btnText}>{text}</span>
                </span>
            </button>
        }
    
    </>    
  )
}

interface IWiseSimpleButton{
    text: string,
    onClickBtn: () => void,
}

export const WiseSimpleButton = ({text, onClickBtn}: IWiseSimpleButton) => {
    return (
        <button onClick={onClickBtn} type='button' className='border border-blue-300 rounded-md px-4 py-3 bg-lime-300 hover:bg-lime-400'>{text}</button>
    );
}
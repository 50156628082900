/* eslint-disable react-hooks/exhaustive-deps */

import { useEffect, useRef, useState } from "react";
import {
  IStaffSocialIcon,
  IStaffStatusElt,
  InnerCss,
} from "src/models/smarttypes";
import getLanguages from "src/utils/language";

import { TitleSeparator } from "src/components/elements/titleseparator/TitleSeparator";
import { listOfStaffsDb } from "src/utils/tempodata";

import alumniImg from "src/assets/images/schoolimgs/alumniimg.png";

import appDecl from "src/utils/declarations";

const languages = getLanguages();

export const QualifiedStaffs = () => {
  const ourstaffs = listOfStaffsDb.slice(0, 4);

  return (
    <section className="qualified-staff pt-[95px] pb-[65px] relative">
      <div className="overlay"></div>
      <div className="w-container">
        <div className="wrapper flex flex-col">
          <div className="mb-[20px] text-center relative z-0">
            <h2 className="text-white mb-[20px]">{languages.ourQualStaff}</h2>
            <TitleSeparator titleColor="#ffffff" />
          </div>
          <div className="flex flex-wrap">
            {ourstaffs.map((oStaff) => (
              <StaffInstuctorcomp
                id={oStaff.id}
                phoneNbr={oStaff.phoneNbr}
                staffJob={oStaff.staffJob}
                staffName={oStaff.staffName}
                fbAcct={oStaff.fbAcct}
                key={oStaff.id}
                linkedInAcct={oStaff.linkedInAcct}
                twitterAcct={oStaff.twitterAcct}
                instAcct={oStaff.instAcct}
                color="#000"
                staffImage={oStaff.staffImage}
                phoneBgColor="#fff"
                phoneHoverBgColor={oStaff.color}
              />
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export const StaffInstuctorcomp = ({
  staffImage,
  phoneNbr,
  color,
  staffName,
  staffJob,
  fbAcct,
  linkedInAcct,
  twitterAcct,
  instAcct,

  colorSubtitle,
  colorTitle,
  isPhoneVis,
  phoneBgColor,
  phoneHoverBgColor,
  phoneTextColor,
  phoneHoverTextColor,
}: IStaffStatusElt) => {
  const [oSocials, setOSocials] = useState<IStaffSocialIcon[]>([]);
  const linkRef = useRef<HTMLAnchorElement>(null);

  const bPhoneArea = isPhoneVis ?? true;
  const oColorTitle = colorTitle ?? "#fff";
  const oColorSubTitle = colorSubtitle ?? "#fff";
  const oPhoneBColor = phoneBgColor ?? "#fff";
  const oPhoneHoverBColor = phoneHoverBgColor ?? "#000";

  const oTextcolor = phoneTextColor ?? "#000";
  const oTextHoverColor = phoneHoverTextColor ?? "#fff";

  const prepareIcons = () => {
    const lstSocials: IStaffSocialIcon[] = [];
    if (fbAcct)
    lstSocials.push({
      id: 0,
      color: color,
      href: `https://api.whatsapp.com/send?phone=${phoneNbr!.trim()}`,
      iconType: "whatsapp",
      label: "",
    });
    if (fbAcct)
      lstSocials.push({
        id: 1,
        color: color,
        href: fbAcct,
        iconType: "facebook",
        label: "Facebook",
      });
    if (linkedInAcct)
      lstSocials.push({
        id: 2,
        color: color,
        href: linkedInAcct,
        iconType: "linkedin",
        label: "LinkedIn",
      });
    if (twitterAcct)
      lstSocials.push({
        id: 3,
        color: color,
        href: twitterAcct,
        iconType: "twitter",
        label: "Twitter",
      });
    if (instAcct)
      lstSocials.push({
        id: 4,
        color: color,
        href: instAcct,
        iconType: "instagram",
        label: "Instagram",
      });
    setOSocials(lstSocials);
  };

  useEffect(() => {
    linkRef.current!.style.setProperty("--special-color", color);
    prepareIcons();
  }, []);

  return (
    <div className="staff-wrapper basis-full sm:basis-1/2 md:basis-1/4 relative">
      <div className="p-[15px]">
        <div className="flex flex-col">
          <div className="mb-[20px] relative">
            <div className="staff-inner-img">
              <div className="flex flex-col items-center justify-center">
                <div className="element-image rounded-[20px] mb-[25px] relative">
                  <div className="staff-img-cover">
                    <div className="social-covers">
                      {oSocials.map((oSoc) => (
                        <StaffSocialIcon
                          id={oSoc.id}
                          color={oSoc.color}
                          href={oSoc.href}
                          iconType={oSoc.iconType}
                          label={oSoc.label}
                          key={oSoc.id}
                        />
                      ))}
                    </div>
                  </div>
                  <figure className="figure-staff-img">
                    <img decoding="async" alt={staffName} src={staffImage} />
                  </figure>
                </div>
                <div className="element-details">
                  <h4 className="detail-title">
                    <span className="name" style={{ color: oColorTitle }}>
                      {staffName}
                    </span>
                  </h4>
                  <div className="detail-position">
                    <span style={{ color: oColorSubTitle }}>{staffJob}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* phone */}
          <div
            className="mb-5 relative text-center"
            style={{ display: bPhoneArea ? "block" : "none" }}
          >
            <a
              href={`tel:${phoneNbr}`}
              className="link-anchor-btn"
              ref={linkRef}
              style={
                {
                  "--bgColor": oPhoneBColor,
                  "--bgHoverColor": oPhoneHoverBColor,
                  "--textColor": oTextcolor,
                  "--textHoverColor": oTextHoverColor,
                } as InnerCss
              }
            >
              <span>
                <span className="icon">
                  <i className="fa fa-phone"></i>
                </span>
                <span className="text">{phoneNbr}</span>
              </span>
            </a>
          </div>
          <div>
            <div className="p-[10px]"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const StaffSocialIcon = ({
  href,
  iconType,
  color,
}: IStaffSocialIcon) => {
  const socWrapperRef = useRef<HTMLDivElement>(null);

  const fIcon =
    iconType === "facebook"
      ? "fa fa-facebook-f"
      : iconType === "twitter"
      ? "fa fa-twitter"
      : iconType === "linkedin"
      ? "fa fa-linkedin"
      : iconType === "instagram"
      ? "fa fa-instagram"
      : iconType === "whatsapp"
      ? "fa fa-whatsapp"
      : "fa fa-facebook-f";

  const fUrl =
    iconType === "facebook"
      ? `https://www.facebook.com/${href}`
      : iconType === "twitter"
      ? `https://twitter.com/${href}`
      : iconType === "linkedin"
      ? `https://www.linkedin.com/in/${href}/`
      : iconType === "instagram"
      ? `https://www.instagram.com/${href}/`
      : iconType === "whatsapp"
      ? href
      : `https://www.facebook.com/${href}`;

  useEffect(() => {
    socWrapperRef.current!.style.setProperty("--inner-color", color);
  }, []);

  return (
    <div className="social-element-icon">
      <a href={fUrl} target="_blank" rel="noreferrer">
        <div className="social-icon-wrapper">
          <div className="inner" ref={socWrapperRef}>
            <span className="social-icon-cover">
              <i className={fIcon}></i>
            </span>
          </div>
        </div>
      </a>
    </div>
  );
};

export const AlumniMemberComp = ({
  staffImage,
  phoneNbr,
  color,
  staffName,
  staffJob,
  fbAcct,
  linkedInAcct,
  twitterAcct,
  instAcct,

  colorSubtitle,
  colorTitle,
  isPhoneVis,
  phoneBgColor,
  phoneHoverBgColor,
  phoneTextColor,
  phoneHoverTextColor,
}: IStaffStatusElt) => {
  const [oSocials, setOSocials] = useState<IStaffSocialIcon[]>([]);
  const linkRef = useRef<HTMLAnchorElement>(null);

  const bPhoneArea = isPhoneVis ?? true;
  const oColorTitle = colorTitle ?? "#fff";
  const oColorSubTitle = colorSubtitle ?? "#fff";
  const oPhoneBColor = phoneBgColor ?? "#fff";
  const oPhoneHoverBColor = phoneHoverBgColor ?? "#000";

  const oTextcolor = phoneTextColor ?? "#000";
  const oTextHoverColor = phoneHoverTextColor ?? "#fff";

  const finalImg = !staffImage ? alumniImg: `${appDecl.apiUrlTest}/cloud_data/alumni_profiles/${staffImage}`;

  const prepareIcons = () => {
    const lstSocials: IStaffSocialIcon[] = [];
    if(phoneNbr)
    lstSocials.push({
      id: 0,
      color: color,
      href: `https://api.whatsapp.com/send?phone=${phoneNbr!.trim()}`,
      iconType: "whatsapp",
      label: "",
    });
    if (fbAcct)
      lstSocials.push({
        id: 1,
        color: color,
        href: fbAcct,
        iconType: "facebook",
        label: "Facebook",
      });
    if (linkedInAcct)
      lstSocials.push({
        id: 2,
        color: color,
        href: linkedInAcct,
        iconType: "linkedin",
        label: "LinkedIn",
      });
    if (twitterAcct)
      lstSocials.push({
        id: 3,
        color: color,
        href: twitterAcct,
        iconType: "twitter",
        label: "Twitter",
      });
    if (instAcct)
      lstSocials.push({
        id: 4,
        color: color,
        href: instAcct,
        iconType: "instagram",
        label: "Instagram",
      });
    setOSocials(lstSocials);
  };

  useEffect(() => {
    linkRef.current!.style.setProperty("--special-color", color);
    prepareIcons();
  }, []);

  return (
    <div className="staff-wrapper basis-full sm:basis-1/2 md:basis-1/4 relative">
      <div className="p-[15px]">
        <div className="flex flex-col">
          <div className="mb-[20px] relative">
            <div className="staff-inner-img">
              <div className="flex flex-col items-center justify-center">
                <div className="element-image rounded-[20px] mb-[25px] relative w-full">
                  <div className="staff-img-cover">
                    <div className="social-covers">
                      {oSocials.map((oSoc) => (
                        <StaffSocialIcon
                          id={oSoc.id}
                          color={oSoc.color}
                          href={oSoc.href}
                          iconType={oSoc.iconType}
                          label={oSoc.label}
                          key={oSoc.id}
                        />
                      ))}
                    </div>
                  </div>
                  <figure className="figure-staff-img">
                    <img
                      decoding="async"
                      alt={staffName}
                      src={finalImg}
                      className="max-h-[257px] w-full object-cover object-top"
                    />
                  </figure>
                </div>
                <div className="element-details">
                  <h4 className="detail-title">
                    <span className="name" style={{ color: oColorTitle }}>
                      {staffName}
                    </span>
                  </h4>
                  <div className="detail-position">
                    <span style={{ color: oColorSubTitle }}>{staffJob}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* phone */}
          <div
            className="mb-5 relative text-center"
            style={{ display: bPhoneArea ? "block" : "none" }}
          >
            <a
              href={`tel:${phoneNbr}`}
              className="link-anchor-btn"
              ref={linkRef}
              style={
                {
                  "--bgColor": oPhoneBColor,
                  "--bgHoverColor": oPhoneHoverBColor,
                  "--textColor": oTextcolor,
                  "--textHoverColor": oTextHoverColor,
                } as InnerCss
              }
            >
              <span>
                <span className="icon">
                  <i className="fa fa-phone"></i>
                </span>
                <span className="text">{phoneNbr}</span>
              </span>
            </a>
          </div>
          <div>
            <div className="p-[10px]"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

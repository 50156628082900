/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
import { Outlet, useNavigate } from "react-router-dom";
import MainHeader from "../components/pages/main/MainHeader";
import MainFooter from "../components/pages/main/MainFooter";
import { useEffect, useState } from "react";
import CookiesDisplayerAlert from "src/components/CookiesDisplayerAlert";

import generalFxs from "src/utils/general_fx";
import appDecl from "src/utils/declarations";
import AdmissionNotificationBar from "src/components/utils/admission/AdmissionNotification";
import { AdmissionNotificationDlg } from "src/components/utils/admission/col_modals";

const appFxs = generalFxs();

export default function MainPageLayout() {
  const [displayVal, setDisplayVal] = useState("none");
  const [displayPrivSett, setDisplayPrivSett] = useState(false);
  const [bottomHeight, setBottomHeight] = useState(132);
  const [adminDlgVis, setAdminDlgVis] = useState(false);

  const navigate = useNavigate();

  const onNavigateAdmiss = () => {
    setAdminDlgVis(false);
    navigate('/misc/enrollment');
  }

  const prepareValues = () => {
    const displaySt = appFxs.getLocalStorageByKey(appDecl.displayPrivSett, "0");
    const bCurrentStatus = appFxs.getLocalStorageByKey(appDecl.displayAdmissionDlg, "0") === "0";

    setDisplayPrivSett(displaySt === "0");
    setAdminDlgVis(bCurrentStatus);
  };

  const onFinalClose = (o: number) => {
    appFxs.setLocalStorageByKey(appDecl.displayPrivSett, o.toString());
    setDisplayPrivSett(false);
  };

  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      setBottomHeight(entries[0].target.clientHeight);
    });
    prepareValues();
    const resizeElt = document.getElementById("admission_div");
    if (resizeElt) {
      resizeObserver.observe(resizeElt);
    }
  }, []);

  window.addEventListener("scroll", (_: Event) => {
    const topPos = window.scrollY;
    if (topPos > 170) {
      setDisplayVal("inline");
    } else {
      setDisplayVal("none");
    }
  });
  const onScrollTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <div className="scrolnap">
      {/* <AdmissionNotificationBar />
      <div style={{
        height: bottomHeight
      }}></div> */}
      <MainHeader />
      <main>
        <Outlet />
      </main>
      <MainFooter />
      <a id="toTop" style={{ display: displayVal }} onClick={onScrollTop}>
        <span id="totopHover" style={{ opacity: 0 }}></span>
        To Top
      </a>
      {displayPrivSett && <CookiesDisplayerAlert onClose={onFinalClose} />}
      {/* <AdmissionNotificationDlg isOpen={adminDlgVis} onClose={() => setAdminDlgVis(false)} onNavigateAdmiss={onNavigateAdmiss} /> */}
    </div>
  );
}

import btnClose from 'src/assets/images/svg/close_btn.svg';
import appLang from 'src/utils/language';

const languages = appLang();

interface ICookiesDisplayer {
    onClose: (status: number) => void,
}
const CookiesDisplayerAlert = ({onClose}: ICookiesDisplayer) => {
    const isSettsSet = false;



  return (
    <div className="cookies-displ fixed z-[2147483645] left-0 right-0 bottom-0 overflow-hidden">
        <div className="alertdialog relative" aria-label="Privacy">
            <div className="ot-sdk-container">
                <div className="ot-sdk-row flex flex-wrap md:justify-between">
                    <div className="basis-full min-[953px]:basis-[54%]">
                        <div className="banner-logo hidden"></div>
                        <div className="colomb-policy overflow-hidden m-[1.25em]">
                            <p className="text-[#696969] text-[.813em] leading-[1.5] mb-[1em]">
                                {languages.privacyMsg}
                            </p>
                        </div>
                    </div>
                    <div className="basis-full min-[953px]:basis-[44%]">
                        <div className='pl-[2%] pr-[2%] flex justify-center'>
                            <div className='flex justify-between'>
                                {isSettsSet && <button className='cookpriv-btn cook-setts'>{languages.cookSetts}</button>}
                                <button onClick={() => onClose(0)} className='cookpriv-btn cook-reject cook-setts'>{languages.rejectAll}</button>
                                <button onClick={() => onClose(1)} className='cookpriv-btn cook-accept'>{languages.acceptAll}</button>
                            </div>
                        </div>
                    </div>
                </div>                
            </div>
            <div className="close-container absolute min-[953px]:right-0 min-[953px]:top-0 min-[953px]:bottom-0 min-[953px]:">
                <button className='privacy-close'
                onClick={() => onClose(0)}
                style={{backgroundImage: `url(${btnClose})`}}></button>
            </div>
        </div>
    </div>
  )
}

export default CookiesDisplayerAlert
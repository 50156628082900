import { useEffect, useState } from "react";
import { Lightbox } from "react-modal-image";

import { IFacVideoVign, IFacilityAmenity, IFacilityFeature, IPageTitleSection } from "src/models/smarttypes";
import { MainTitleWrapper } from "src/components/pages/MainTitleWrapper";

import getLanguages from 'src/utils/language';

import kidsInLab from 'src/assets/images/schoolimgs/excellence_school_bg.png';
import { TitleSeparator } from "src/components/elements/titleseparator/TitleSeparator";

import outdoorActImg from 'src/assets/images/schoolimgs/outdoor_activities_1.jpg';

import videoLayer1 from 'src/assets/images/schoolimgs/bg-title-alumni.jpg';
import videoLayer2 from 'src/assets/images/schoolimgs/room_playground_1.jpg';
import videoLayer3 from 'src/assets/images/schoolimgs/room_spoertsclub_1.jpg';
import videoLayer4 from 'src/assets/images/schoolimgs/outdoor_activities_1.jpg';
import videoLayer5 from 'src/assets/images/schoolimgs/bg-title-subscription.jpg';
import videoLayer6 from 'src/assets/images/schoolimgs/kid_playing_bask.jpg';

//import { TestimonialsComp } from "../home/ClientTestimonials";
import {SectionTitle, SectionParagraph} from "src/components/elements/sectiontitle/SectionTitle";
import { VideoSpaceComp } from "src/components/elements/ytbvideovign/YtbVideVignComp";
import { RealAboutUsComp } from "src/components/elements/realaboutus/RealAboutUs";
import { WiseListWithIcon } from "src/components/elements/ListWithIcon/WiseListWithIcon";
import { FacilityAmenityComp, FacilityFeatureComp } from "src/components/differ_components";


const languages = getLanguages();

const lstFacilityAmen:IFacilityAmenity[] = [
  {
    id: 0,
    iconType: "aircondition",
    title: 'Air Condition',
    subTitle: 'Weather Control'
  },
  {
    id: 1,
    iconType: "infrastructure",
    title: 'Infrastructure',
    subTitle: 'Campus Area'
  },
  {
    id: 2,
    iconType: "transport",
    title: 'Transport',
    subTitle: 'Vehicles'
  },
  {
    id: 3,
    iconType: "foodstay",
    title: 'Food & Stay',
    subTitle: 'Lunch Snacks'
  },
  {
    id: 4,
    iconType: "networked",
    title: 'Networked',
    subTitle: 'Communication'
  },
];

const lstFacFeatures: IFacilityFeature[] = [
  
  {
    id: 0,
    title: languages.multimedia_room,
    detail: languages.multimedia_room_msg,
    href: '/facilities/multimedia',
    type: 'multimedia',
  },
  {
    id: 1,
    title: languages.lablib_room,
    detail: languages.lablib_room_msg,
    href: '/facilities/lablibrary',
    type: 'lablibrary',
  },
  {
    id: 2,
    title: languages.meeting_hall,
    detail: languages.meeting_hall_msg,
    href: '/facilities/meetinghall',
    type: 'meetinghall',
  },
  {
    id: 3,
    title: languages.playgrounds,
    detail: languages.playgrounds_msg,
    href: '/facilities/playground',
    type: 'playground',
  },
  {
    id: 4,
    title: languages.sports_club,
    detail: languages.sports_club_msg,
    href: '/facilities/sportsclub',
    type: 'sportsclub',
  },
  {
    id: 5,
    title: languages.brights_class,
    detail: languages.brights_class_msg,
    href: '/facilities/brightclassroom',
    type: 'brightclass',
  },
];
const lstOfFacVideos: IFacVideoVign[] = [
  {
    id: 0,
    href: 'https://www.youtube.com/embed/GchuXH5KUeo',
    img: videoLayer1,
    label: '',
  },
  {
    id: 1,
    href: 'https://www.youtube.com/embed/qEsDTA8j8EA',
    img: videoLayer2,
    label: '',
  },
  {
    id: 2,
    href: 'https://www.youtube.com/embed/-X7okpS9Ufc',
    img: videoLayer3,
    label: '',
  },
  {
    id: 3,
    href: 'https://www.youtube.com/embed/AjgD3CvWzS0',
    img: videoLayer4,
    label: '',
  },
  {
    id: 4,
    href: 'https://www.youtube.com/embed/p1kcQdwBFog',
    img: videoLayer5,
    label: '',
  },
  {
    id: 5,
    href: 'https://www.youtube.com/embed/Nn5L-FoZ7ig',
    img: videoLayer6,
    label: '',
  },
]

export default function FacilitiesPage() {
  document.title = `${languages.facilities} | ${languages.schoolName}`;
  const [modalOpen, setModalOpen] = useState(false);
  const [modalImage, setModalImage] = useState<string | null>(null);

  const oElt: IPageTitleSection = {
    classes: "our-facilities",
    currentText: languages.facilities,
    pageTitle: languages.facilities,
  };

  const openModel = (e:string) => {
    setModalImage(e);
    setModalOpen(true);
  }

  

  useEffect(() => {
    window.scrollTo(0,0);
  }, []);
  
  return (
    <>
    {modalOpen && <Lightbox
      medium={modalImage!}
      large={modalImage!}
      small={modalImage!}
      alt={languages.playground}
      onClose={() => setModalOpen(false)}
    />}
      <MainTitleWrapper {...oElt} />
      <div className="pagebody-wrapper pt-28 hidden">
        <div className="w-container">
          <div className="flex flex-col">
            {/* top */}
            <div className="flex flex-wrap md:justify-between">
              <div className="basis-full md:basis-[42%]">
                <div className="box-inner">
                  <div className="section-title-w">
                    <div className="w-inner">
                      <h2>{languages.shool_exc}</h2>
                    </div>
                  </div>
                  <div className="mb-5">
                    {languages.shool_exc_msg}
                  </div>
                  <div className="section-list">
                    <ul>
                      <WiseListWithIcon 
                        iconColor="#a5c347"
                        fawesomeIcon='fa fa-bank'
                        message={languages.shool_exc_opt_1} />
                      <WiseListWithIcon 
                        iconColor="#a5c347"
                        fawesomeIcon='fa fa-bank'
                        message={languages.shool_exc_opt_2} />
                      <WiseListWithIcon 
                        iconColor="#a5c347"
                        fawesomeIcon='fa fa-bank'
                        message={languages.shool_exc_opt_3} />
                    </ul>
                  </div>
                </div>
              </div>
              <div className="basis-full md:basis-[57%]">
                <div className="box-inner">
                  <img alt="" src={kidsInLab} />
                </div>
              </div>
            </div>
            {/* Separator */}
            <div className="separator-c pt-4 pb-4">
              <span></span>
            </div>
            {/* facilities */}
          </div>
        </div>
      </div>
      <section className="facilities-pg-area relative pt-7">
        {/* image--1 */}
        <div className="w-jet-paralex-layout w-jet-scroll">
          <div className="w-je-paralex-img-section img-1"></div>
        </div>
        {/* image--2 */}
        <div className="w-jet-paralex-layout w-jet-scroll">
          <div className="w-je-paralex-img-section img-2"></div>
        </div>
        {/* image--3 */}
        <div className="w-jet-paralex-layout w-jet-scroll">
          <div className="w-je-paralex-img-section img-3"></div>
        </div>
        {/* image--4 */}
        <div className="w-jet-paralex-layout w-jet-scroll">
          <div className="w-je-paralex-img-section img-4"></div>
        </div>
        {/* content */}
        <div className="w-container">
          <div className="flex flex-col">
            <div className="p-[10px]">
              {/* Title */}
              <div className="section-title-w text-center">
                <div className="w-inner">
                  <h2>{languages.facilities}</h2>
                  <TitleSeparator titleColor="#ff6d9d" />
                </div>
              </div>
              {/* List facilities */}
              <div className="mt-5">
                <div className="hidden flex-wrap">
                  {lstFacilityAmen.map(oElt => <FacilityAmenityComp {...oElt} key={oElt.id} />)}
                </div>
              </div>
              {/* elements with details */}
              <div className="flex flex-col">
                {lstFacFeatures.map(oFeature => <FacilityFeatureComp key={oFeature.id} {...oFeature} />)}
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* testimonials */}
      {/* <section className="facilities-testimonials">
        <div className="overlay"></div>
        <div className="w-container">
          <div className="p-[10px]">
            <TestimonialsComp textColor="#ffffff" />
          </div>
        </div>
      </section> */}
      {/*  */}
      <section className="outdoor-facilities">
        <div className="w-container">
          <div className="flex flex-wrap md:justify-between">
            <div className="basis-full md:basis-[49%]">
              <RealAboutUsComp parentClasses="" 
                ytbLink="https://www.youtube.com/embed/KjNlG-GB5mg"
                hasButtonLink={true}
                navigationLinkRef="/contactus"
                hideImgBg={outdoorActImg}
                linkText={languages.scheduleVisit} />
            </div>
            <div className="basis-full md:basis-[49%]">
              <div className="box-inner">
                <div className="flex flex-col">
                  {/* title */}
                  <SectionTitle title={languages.outdoorPhysicalEd}   />
                  {/* message */}
                  <SectionParagraph message={languages.outdoorPhysicalEdMsg} />
                  {/* videos */}
                  <section className="facility-video-area">
                    <div className="p-[10px]">
                      <div className="flex flex-wrap">
                        {lstOfFacVideos.map(oVideo => <VideoSpaceComp onModal={openModel} {...oVideo} key={oVideo.id}/>)}
                      </div>
                    </div>
                  </section>
                </div>
              </div>
            </div>
          </div>
        </div>        
      </section>
    </>
  );
}






import getLanguages from "src/utils/language";

import {
  IParentTestimonial,
  IShopMainData,
  IStaffStatusElt,
} from "src/models/smarttypes";

//#region ShopImages
/* import shopImg1 from 'src/assets/images/tempshop/shop-img-1.jpg';
import shopImg2 from 'src/assets/images/tempshop/shop-img-2.jpg';
import shopImg3 from 'src/assets/images/tempshop/shop-img-3.jpg';
import shopImg4 from 'src/assets/images/tempshop/shop-img-4.jpg';
import shopImg5 from 'src/assets/images/tempshop/shop-img-5.jpg';
import shopImg6 from 'src/assets/images/tempshop/shop-img-6.jpg';
import shopImg7 from 'src/assets/images/tempshop/shop-img-7.jpg';
import shopImg8 from 'src/assets/images/tempshop/shop-img-8.jpg';
import shopImg9 from 'src/assets/images/tempshop/shop-img-9.jpg';
import shopImg10 from 'src/assets/images/tempshop/shop-img-10.jpg';
import shopImg11 from 'src/assets/images/tempshop/shop-img-11.jpg';
import shopImg12 from 'src/assets/images/tempshop/shop-img-12.jpg';
import shopImg13 from 'src/assets/images/tempshop/shop-img-13.jpg';
import shopImg14 from 'src/assets/images/tempshop/shop-img-14.jpg';
import shopImg15 from 'src/assets/images/tempshop/shop-img-15.jpg';
import shopImg16 from 'src/assets/images/tempshop/shop-img-16.jpg';
import shopImg17 from 'src/assets/images/tempshop/shop-img-17.jpg';
import shopImg18 from 'src/assets/images/tempshop/shop-img-18.jpg'; */
//#endregion

//#region Staff_images
import staff1 from "src/assets/images/staff/qualif_staff_1.jpg";
import staff2 from "src/assets/images/staff/qualif_staff_2.jpg";
import staff3 from "src/assets/images/staff/qualif_staff_3.jpg";
import staff4 from "src/assets/images/staff/qualif_staff_4.jpg";

import staff5 from "src/assets/images/staff/qualif_staff_5.jpg";
import staff6 from "src/assets/images/staff/qualif_staff_6.jpg";
import staff7 from "src/assets/images/staff/qualif_staff_7.jpg";
import staff8 from "src/assets/images/staff/qualif_staff_8.jpg";
//#endregion

//#region Parents
import parentTestimonial_umub from "src/assets/images/schoolimgs/parent_umub.jpg";
import parentTestimonial_musaniwabo from "src/assets/images/schoolimgs/parent_musaniwabo.jpg";
//#endregion

const languages = getLanguages();

export const listOfShopProductsDb: IShopMainData = {
  typeDisplay: "grid",
  gridDisplay: "twoline",
  items: [
    /* {
      typeDisplay: "twoline",
      item: {
        productId: '21211525233551',
        productName: 'Abacus',
        discountedPrice: 12.00,
        productPrice: 15.00,
        imagePath: [shopImg1, shopImg7],
        category: 'Music',
        sku: '212152185154121',
        productDescript: "The president has spoken, and we his followers must listen and respect his decision. We have asked for an audience with him tomorrow and we expect every CDCian to come and listen to the president,",
        variants: [
          {
            variantName: 'Color',
            values: [
              {id: 'grey',label: 'Grey'},                
              {id: 'red',label: 'Red'},             
              {id: 'blue',label: 'Blue'},
            ]
          },
          {
            variantName: 'Size',
            values: [
              {id: 'xL',label: 'XL'},                
              {id: 'small',label: 'Small'},   
            ]
          }
        ]
      }
    },
    {
      typeDisplay: "twoline",
      item: {
        productId: '21211525235551',
        productName: 'Blue Shirt',
        discountedPrice: 15.00,
        productPrice: 20.00,
        imagePath: [shopImg2, shopImg16,shopImg14],
      }
    },
    {
      typeDisplay: "twoline",
      item: {
        productId: '12121595235551',
        productName: 'Checked Dress',
        discountedPrice: 15.00,
        productPrice: 20.00,
        imagePath: [shopImg3, shopImg7, shopImg12],
      }
    },
    {
      typeDisplay: "twoline",
      item: {
        productId: '21291595235551',
        productName: 'Cotton Tshirt',
        discountedPrice: 15.00,
        productPrice: 20.00,
        imagePath: [shopImg4, shopImg12, shopImg18],
      }
    },
    {
      typeDisplay: "twoline",
      item: {
        productId: '21211595235551',
        productName: 'Cube',
        discountedPrice: 15.00,
        productPrice: 20.00,
        imagePath: [shopImg5, shopImg15, shopImg13],
      }
    },
    {
      typeDisplay: "twoline",
      item: {
        productId: '21288595235551',
        productName: 'Elephant Doll',
        discountedPrice: 15.00,
        productPrice: 20.00,
        imagePath: [shopImg6, shopImg4, shopImg6, shopImg17],
      }
    },
    {
      typeDisplay: "twoline",
      item: {
        productId: '21288999235551',
        productName: 'Hoodie',
        discountedPrice: 17.00,
        productPrice: 25.00,
        imagePath: [shopImg7, shopImg6, shopImg5],
      }
    },
    {
      typeDisplay: "twoline",
      item: {
        productId: '21288888235551',
        productName: 'Jeans Pant',
        discountedPrice: 17.00,
        productPrice: 25.00,
        imagePath: [shopImg8, shopImg15, shopImg14],
      }
    },
    {
      typeDisplay: "twoline",
      item: {
        productId: '21295123235551',
        productName: 'Lorry',
        discountedPrice: 17.00,
        productPrice: 30.00,
        imagePath: [shopImg9, shopImg13, shopImg15],
      }
    },
    {
      typeDisplay: "twoline",
      item: {
        productId: '21266699232222',
        productName: 'Metallophone',
        discountedPrice: 17.00,
        productPrice: 30.00,
        imagePath: [shopImg10, shopImg4, shopImg6, shopImg5],
      }
    },
    {
      typeDisplay: "twoline",
      item: {
        productId: '21266699299951',
        productName: 'Rattle',
        discountedPrice: 10.00,
        productPrice: 15.00,
        imagePath: [shopImg11, shopImg2],
      }
    },
    {
      typeDisplay: "twoline",
      item: {
        productId: '21333699235551',
        productName: 'Sleeveless Gown',
        discountedPrice: 10.00,
        productPrice: 15.00,
        imagePath: [shopImg12, shopImg1, shopImg16],
      }
    },
    {
      typeDisplay: "twoline",
      item: {
        productId: '21266699935551',
        productName: 'Sleeveless Top',
        discountedPrice: 10.00,
        productPrice: 15.00,
        imagePath: [shopImg13, shopImg2],
      }
    },
    {
      typeDisplay: "twoline",
      item: {
        productId: '21777699935551',
        productName: 'Tiny Midi',
        discountedPrice: 10.00,
        productPrice: 15.00,
        imagePath: [shopImg14, shopImg7, shopImg10, shopImg18],
      }
    },
    {
      typeDisplay: "twoline",
      item: {
        productId: '21777951935551',
        productName: 'Toddler',
        discountedPrice: 10.00,
        productPrice: 15.00,
        imagePath: [shopImg15, shopImg3, shopImg2],
      }
    },
    {
      typeDisplay: "twoline",
      item: {
        productId: '2177775335551',
        productName: 'Train Engine',
        discountedPrice: 10.00,
        productPrice: 15.00,
        imagePath: [shopImg16, shopImg17, shopImg4],
      }
    },
    {
      typeDisplay: "twoline",
      item: {
        productId: '666775335551',
        productName: 'Trumpet',
        discountedPrice: 10.00,
        productPrice: 15.00,
        imagePath: [shopImg17, shopImg12, shopImg15],
      }
    },
    {
      typeDisplay: "twoline",
      item: {
        productId: '6667444435551',
        productName: 'Water Duck',
        discountedPrice: 10.00,
        productPrice: 15.00,
        imagePath: [shopImg18, shopImg13, shopImg1],
      }
    }, */
  ],
};
export const listOfStaffsDb: IStaffStatusElt[] = [
  {
    id: 0,
    staffName: "John Parker",
    phoneNbr: "250 788 965 236",
    fbAcct: "https://www.facebook.com/jkayihura",
    linkedInAcct: "https://www.linkedin.com/in/james-kayihura-78680133/",
    twitterAcct: "https://twitter.com/jkayihura",
    staffJob: languages.instructorTeacher,
    color: "#64C7FE",
    staffImage: staff1,
  },
  {
    id: 1,
    staffName: "Salomon Ian",
    phoneNbr: "250 788 333 966",
    fbAcct: "https://www.facebook.com/salomon",
    linkedInAcct: "https://www.linkedin.com/in/salomon-78680133/",
    twitterAcct: "https://twitter.com/salomon",
    staffJob: languages.instructorTeacher,
    color: "#F0AA00",
    staffImage: staff2,
  },
  {
    id: 2,
    staffName: "Barbara Wakanda",
    phoneNbr: "250 788 444 666",
    fbAcct: "https://www.facebook.com/barbaraw",
    twitterAcct: "https://twitter.com/barbaraw",
    staffJob: languages.instructorTeacher,
    color: "#8700FF",
    staffImage: staff3,
  },
  {
    id: 3,
    staffName: "Shane Builboa",
    phoneNbr: "250 788 444 666",
    fbAcct: "https://www.facebook.com/shaneb",
    linkedInAcct: "https://www.linkedin.com/in/shaneb-78680133/",
    twitterAcct: "https://twitter.com/shaneb",
    staffJob: languages.instructorTeacher,
    color: "#FF3075",
    staffImage: staff4,
  },

  {
    id: 4,
    staffName: "John Parker (Phd)",
    phoneNbr: "250 788 666 666",
    fbAcct: "https://www.facebook.com/johnpark",
    linkedInAcct: "https://www.linkedin.com/in/johnpark-78680133/",
    twitterAcct: "https://twitter.com/johnpark",
    staffJob: languages.instructorTeacher,
    color: "#ff3d4a",
    staffImage: staff5,
  },
  {
    id: 5,
    staffName: "Linda Joans",
    phoneNbr: "250 852 666 666",
    fbAcct: "https://www.facebook.com/lindajones",
    linkedInAcct: "https://www.linkedin.com/in/lindajones-78680133/",
    twitterAcct: "https://twitter.com/lindajones",
    staffJob: languages.instructorTeacher,
    color: "#64c8ff",
    staffImage: staff6,
  },
  {
    id: 6,
    staffName: "Susan Pevensie",
    phoneNbr: "250 624 666 666",
    fbAcct: "https://www.facebook.com/susanp",
    linkedInAcct: "https://www.linkedin.com/in/susanp-78680133/",
    twitterAcct: "https://twitter.com/susanp",
    staffJob: languages.instructorTeacher,
    color: "#E80F87",
    staffImage: staff7,
  },
  {
    id: 7,
    staffName: "Aurora Lane",
    phoneNbr: "250 987 666 666",
    fbAcct: "https://www.facebook.com/auroralane",
    linkedInAcct: "https://www.linkedin.com/in/auroralane-78680133/",
    twitterAcct: "https://twitter.com/auroralane",
    staffJob: languages.instructorTeacher,
    color: "#3a65fd",
    staffImage: staff8,
  },
];
export const listOfTestMonialsDb: IParentTestimonial[] = [
  {
    id: 0,
    parentName: "Uwambayingabire Claudine ",
    testimonial: languages.parentMsgClaudine,
    label: "Uwambayingabire Claudine",
    color: "#f1aa00",
    image: parentTestimonial_umub,
    selected: true,
  },
  {
    id: 1,
    parentName: "Musaniwabo Alexia",
    testimonial: languages.parentMsgMusaniwabo,
    label: "Musaniwabo Alexia",
    color: "#a5c347",
    image: parentTestimonial_musaniwabo,
    selected: false,
  },
];

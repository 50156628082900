/* eslint-disable no-restricted-globals */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */

import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import schoolLogo from 'src/assets/images/new_logo_fav.png';
import WiseDropDown from 'src/components/elements/wsedropdown/WiseDropDown';
import { IMenuColomb, ISelectOptionType } from 'src/models/smarttypes';

import frFlag from 'src/assets/images/icons/svg/flag-fr.svg';
import enFlag from 'src/assets/images/icons/svg/flag-gb.svg';

import generalFx from 'src/utils/general_fx';
import getLanguages from 'src/utils/language';
import appDecl from 'src/utils/declarations'

import { MainMenuLink } from 'src/components/elements/mainmenulink/MainMenuLink';



const appFxs = generalFx();
const languages = getLanguages();

/* const appMenus: IMenuColomb[] = [
  {
    color: '#a5c347',
    href: '/home',
    label: languages.home,
    id: 'home',
    selected: false,
  },
  {
    color: '#ff236c',
    href: '/aboutus',
    label: languages.about,
    id: 'aboutus',
    selected: false,
  },
  {
    color: '#00d2dc',
    href: '/facilities',
    label: languages.facilities,
    id: 'facilities',
    selected: false,
  },
  {
    color: '#a5c347',
    href: '/events',
    label: languages.events,
    id: 'events',
    selected: false,
  },
  {
    color: '#f0aa00',
    href: '/alumni',
    label: languages.alumni,
    id: 'alumni',
    selected: false,
  },
  {
    color: '#5d58ef',
    href: '/shop',
    label: languages.shop,
    id: 'shop',
    selected: false,
  },
  {
    color: '#80f',
    href: '/contactus',
    label: languages.contactus,
    id: 'contactus',
    selected: false,
  },
] */

const appMenus: IMenuColomb[] = [
  {
    color: '#a5c347',
    href: '/home',
    label: languages.home,
    id: 'home',
    selected: false,
  },
  {
    color: '#ff236c',
    href: '/aboutus',
    label: languages.about,
    id: 'aboutus',
    selected: false,
  },
  {
    color: '#00d2dc',
    href: '/history',
    label: languages.historySch,
    id: 'history',
    selected: false,
  },
  {
    color: '#a5c347',
    href: '/team',
    label: languages.team,
    id: 'team',
    selected: false,
  },
  {
    color: '#f0aa00',
    href: '/events',
    label: languages.events,
    id: 'events',
    selected: false,
  },
  {
    color: '#5d58ef',
    href: '/facilities',
    label: languages.facilities,
    id: 'facilities',
    selected: false,
  },
  {
    color: '#80f',
    href: '/contactus',
    label: languages.contactus,
    id: 'contactus',
    selected: false,
  },
]

export default function MainHeader() {
  const [isMobileVis, setIsMobileVis] = useState(false);
  const [langName, setLangName] = useState(languages.english_m);
  const [lstMenus, setLstMenus] = useState<IMenuColomb[]>([])


  const [isEng, setIsEng] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const fLang = appFxs.getLocalLanguage();

  useEffect(() => {
    setIsEng(fLang === 'en');
    setLangName(fLang === 'en' ? languages.english_m: languages.french_m);
    prepareMenuReload();
    setIsLoading(false);
  }, []);


  
  const showMobile = () => {
    setIsMobileVis(true);
  }
  const hideMobile = () => {
    setIsMobileVis(false);
  }
  const onChangeLang = (e: ISelectOptionType) => {
    if(fLang === e.id)return;
    
    appFxs.setLocalLanguage(e.id);
    location.reload();
  }
  const onMenuclick = (oMenu:IMenuColomb) => {
    const fLists = lstMenus.map(oEl => {
      return {...oEl, selected: oEl.id === oMenu.id} as IMenuColomb;
    });

    setLstMenus(fLists);
  }
  const prepareMenuReload = () => {
    const fLists = appMenus.map(oEl => {
      return {...oEl, selected: document.location.href.includes(oEl.href)} as IMenuColomb;
    });
    setLstMenus(fLists);
  }

  

  
  if(isLoading) return <div></div>;
  return (
    <header className="w-full">
      {/* Social links */}
      <div className="bg-black">
        <div className="w-container">
          <div className="py-3 px-4 flex flex-col items-center md:flex-row md:justify-between md:px-0">
            {/* comm - email - tel */}
            <div className="flex flex-wrap">
              <a
                className="text-gray-400 hover:text-white basis-full text-center md:basis-1/2 one-line"
                href="mailto:info@lacolombiere.ac.rw"
                title={`${languages.sendEmailTo} info@lacolombiere.ac.rw`}
              >
                <i className="fa fa-envelope-o"></i>&nbsp;
                info@lacolombiere.ac.rw
              </a>
              <a
                className="text-gray-400 hover:text-white basis-full text-center mt-4 md:basis-1/2 md:mt-0 one-line"
                href="tel:+250788510589"
                title={`${languages.placePhoneCallTo} +${appDecl.mainPhoneNbr}`}
              >
                <i className="fa fa-phone"></i>&nbsp; +{appDecl.mainPhoneNbr}
              </a>
            </div>

            {/* comm - socila media */}
            <div className="flex items-center mt-7 md:mt-0">
              <a
                href="https://api.whatsapp.com/send?phone=250788510589"
                title={languages.contactUsWh}
                className="text-gray-400 hover:text-white"
                target="_blank"
                rel="noreferrer"
              >
                <i className="fa fa-whatsapp"></i>&nbsp;
              </a>
              <span className="mx-3"></span>
              <a
                href="https://twitter.com/lacolombiere_sc"
                className="text-gray-400 hover:text-white"
                title={languages.contactUsTwitter}
                target="_blank"
                rel="noreferrer"
              >
                <i className="fa fa-twitter"></i>&nbsp;
              </a>
              <span className="mx-3"></span>
              <a
                href="https://www.instagram.com/lacolombiere_sc/"
                className="text-gray-400 hover:text-white"
                title={languages.contactUsInst}
                target="_blank"
                rel="noreferrer"
              >
                <i className="fa fa-instagram"></i>&nbsp;
              </a>
              <span className="mx-3"></span>
              <a
                href="https://www.linkedin.com/in/la-colombi%C3%A8re-school-8a09b62a1/"
                className="text-gray-400 hover:text-white"
                title={languages.contactUsLI}
                target="_blank"
                rel="noreferrer"
              >
                <i className="fa fa-linkedin"></i>&nbsp;
              </a>
              <span className="mx-3"></span>
              <a
                href="https://vda5800.is.cc/roundcube/"
                className="text-gray-400 hover:text-white"
                title={languages.readYourInbox}
                target="_blank"
                rel="noreferrer"
              >
                <i className="fa fa-envelope"></i>&nbsp;
              </a>
              <span className="mx-3"></span>
              <WiseDropDown
                options={[
                  { id: "en", label: "English" },
                  { id: "fr", label: "French" },
                ]}
                onSelected={onChangeLang}
                position="right"
                hasDropdown={false}
              >
                <span className="text-white flex gap-1">
                  <img className="w-4" src={isEng ? enFlag : frFlag} alt="" />
                  <span>{langName}</span>
                </span>
              </WiseDropDown>
              <span className="mx-3"></span>
            </div>
          </div>
        </div>
      </div>
      {/* Menu */}
      <div className="bg-white">
        <div className="w-container mx-0 ">
          <div className="py-0 px-4 flex flex-col md:flex-row md:items-center md:justify-between md:px-0">
            {/* Logo */}
            <Link
              to={"/home"}
              className="grow-0 text-center"
              onClick={() => onMenuclick(appMenus[0])}
            >
              <div className="flex justify-center md:justify-start">
                <img
                  src={schoolLogo}
                  alt="La colombiere Logo"
                  className="h-[35px]"
                />
                &nbsp;
                <div className="text-[20px]">
                  <span className="text-black">
                    {!isEng && <span>Ecole&nbsp;</span>}<span className='font-semibold'>La Colombière</span>{isEng && <span>&nbsp;School</span>}
                  </span>
                </div>
              </div>
            </Link>

            {/* Menu */}
            <div className="grow hidden lg:flex items-center main-menu">
              <ul className="w-full flex justify-end space-x-7">
                {lstMenus.map((oMenu) => (
                  <MainMenuLink
                    menu={oMenu}
                    key={oMenu.id.toString()}
                    onClick={onMenuclick}
                  />
                ))}
              </ul>
            </div>
            <div className="grow lg:hidden flex justify-center md:justify-end items-center">
              <div
                className="trigger flex items-center cursor-pointer"
                onClick={showMobile}
              >
                <i className="fa fa-bars"></i>&nbsp;&nbsp;
                <span className="align-middle inline-block px-1">Menu</span>
              </div>
              <div
                className={`mobile-menu ${isMobileVis ? "nav-is-visible" : ""}`}
                id="master-mobile-menu"
              >
                <ul className="main-menu-mobile" id="real-menu-mobile">
                  <li className="close-nav" onClick={hideMobile}></li>
                  {lstMenus.map((oMenu) => (
                    <li key={oMenu.id}>
                      <Link to={oMenu.href} onClick={hideMobile}>
                        {oMenu.label}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
              <div
                onClick={hideMobile}
                className={`mobile-overlay ${isMobileVis ? "is-visible" : ""}`}
                id="master-mobile-overlay"
              ></div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}

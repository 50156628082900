import meetingHallImg from 'src/assets/images/schoolimgs/school_rounded.jpg';
import { WiseListWithIcon } from 'src/components/elements/ListWithIcon/WiseListWithIcon';
import { SectionParagraph, SectionTitle } from 'src/components/elements/sectiontitle/SectionTitle';

import getLang from 'src/utils/language';

const languages = getLang();


export const FacilitiesMeetingHall = () => {
  return (
    <div className="flex flex-col">
      {/* image */}
      <div className="mb-[20px]">
        <div className="mb-[30px]">
          <img className="rounded-[10px]" src={meetingHallImg} alt="" />
        </div>
      </div>
      <SectionTitle title={languages.meetingHall} />
      <SectionParagraph message={languages.meetingHallLongMsg} />
      <div>
        <ul>
          <WiseListWithIcon
            fawesomeIcon="fa fa-university"
            message={languages.meetingHallChar1}
            isHtml
          />
          <WiseListWithIcon
            fawesomeIcon="fa fa-graduation-cap"
            message={languages.meetingHallChar2}
            isHtml
          />
          <WiseListWithIcon
            fawesomeIcon="fa fa-book"
            message={languages.meetingHallChar3}
            isHtml
          />
        </ul>
      </div>
            
    </div>
  );
}

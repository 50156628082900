import meetingHallImg from 'src/assets/images/schoolimgs/room_spoertsclub_1.jpg';
import { WiseListWithIcon } from 'src/components/elements/ListWithIcon/WiseListWithIcon';
import { SectionParagraph, SectionTitle } from 'src/components/elements/sectiontitle/SectionTitle';



import getLang from 'src/utils/language';

const languages = getLang();



export const FacilitiesSportsClub = () => {
  return (
    <div className="flex flex-col">
      {/* image */}
      <div className="mb-[20px]">
        <div className="mb-[30px]">
          <img className="rounded-[10px]" src={meetingHallImg} alt="" />
        </div>
      </div>
      <SectionTitle title={languages.sportsClub} />
      <SectionParagraph message={languages.sportsClubLongMsg} />
      <div>
        <ul>
          <WiseListWithIcon
            fawesomeIcon="fa fa-university"
            message={languages.sportsClubChar1} 
            isHtml
          />
          <WiseListWithIcon
            fawesomeIcon="fa fa-graduation-cap"
            message={languages.sportsClubChar2} 
            isHtml
          />
          <WiseListWithIcon
            fawesomeIcon="fa fa-book"
            message={languages.sportsClubChar3} 
            isHtml
          />
        </ul>
      </div>
      
    </div>
  );
}

/* eslint-disable react-hooks/exhaustive-deps */
import generalFx from 'src/utils/general_fx';

import rightSchoolChildren from 'src/assets/images/schoolimgs/bright_student.jpg';

import {ReactComponent as RedBrainIcon} from 'src/assets/images/icons/svg/red-brain.svg';
import {ReactComponent as SchoolInfraIcon} from 'src/assets/images/icons/svg/sschool-infra.svg';
import {ReactComponent as BookSearchIcon} from 'src/assets/images/icons/svg/book-search.svg';
import {  IRightFit, IRightFitElt, RightFitEnum } from 'src/models/smarttypes';

const appFxs = generalFx();
const isEn = appFxs.getLocalLanguage() === 'en';


const lsrights: IRightFit[] = [
    {
        iconColor: '#FF236C',
        iconType: RightFitEnum.Brain,
        id: 0,
        label: isEn ? 
            'Well designed curriculum which suits Rwandan children': 
            "Un programme bien conçu qui convient aux enfants rwandais",
    },
    {
        iconColor: '#64C8FF',
        iconType: RightFitEnum.Infra,
        id: 1,
        label: isEn? 'Conducive environment for studies.': "Environnement propice aux études.",
    },
    {
        iconColor: '#FFC259',
        iconType: RightFitEnum.Book,
        id: 2,
        label: isEn? 
            'Excellent performance every academic year.': 
            "Excellente performance chaque année académique.",
    },
]

export const RightSchool = () => {
  return (
    <div className='right-scool-area py-[110px]  max-[1024px]:py-[60px]'>
        <div className="overlay"></div>
        <div className="school-column">
            <div className='w-container px-2 sm:px-0'>
                <div className="flex flex-col min-[1024px]:flex-row">
                    <div className="basis-1 md:basis-1/2">
                        <div className="px-[15px]">
                            <div className="flex flex-col">
                                <div className="mb-5">
                                    {isEn && <h2 className="text-4xl text-white font-bold">
                                        Why <span>La Colombière</span> is the Right Fit for Your Children?
                                    </h2>} 
                                    {!isEn && <h2 className="text-4xl text-white font-bold">
                                        Pourquoi <span>La Colombière</span> est la solution idéale pour vos enfants ?
                                    </h2>
                                    }
                                    
                                </div>
                                <p className='text-white text-xl'>
                                {isEn?
                                    <>With La Colombière: an ideal environment, an unequivocal experience and qualified staff. That's the reason.</>:
                                    <>Avec La Colombière : un environnement idéal, une expérience sans équivoque et un personnel qualifié. C'est la raison.</>
                                }
                                </p>

                                <section className='flex flex-col py-12'>
                                    {lsrights.map(oRight => <SchoolRightEltComp key={oRight.id} rightElt={oRight} />)}
                                </section>

                            </div>
                        </div>
                    </div>
                    <div className="basis-1 md:basis-1/2">
                        <div className='p-[10px]'>
                            <div className='flex items-center justify-center'>
                                <div className='w-[524px] h-[524px] rounded-full overflow-hidden'>
                                <img className='w-full h-full' src={rightSchoolChildren} alt='' />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}


export const SchoolRightEltComp = ({rightElt}:IRightFitElt) => {
    const {iconColor, iconType, label} = rightElt;
    const OIcon = iconType === RightFitEnum.Book ? BookSearchIcon:
    iconType === RightFitEnum.Brain ? RedBrainIcon:
    iconType === RightFitEnum.Infra ? SchoolInfraIcon:
    RedBrainIcon;


    return (
        <div className='py-1 px-6 flex flex-row items-center justify-center rounded-[50px] bg-white mb-4'>
            <div className='grow-0 mr-3 mt-1'>
                <span className='inline-block w-[50px] h-[50px]'>
                    <OIcon className='w-[50px] h-[50px]' fill={iconColor}/>
                </span>
            </div>
            <div className='grow flex items-center'>
                <h5 className='text-[16px] font-semibold'>
                    <span>{label}</span>
                </h5>
            </div>
        </div>
    );
}
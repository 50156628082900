import { useRef, useState } from 'react';
import { IFacVideoVign } from 'src/models/smarttypes';

import getLanguages from 'src/utils/language';

const languages = getLanguages();

export const YtbVideVignComp = ({href, img}: IFacVideoVign) => {
    const iFrame = useRef<HTMLIFrameElement>(null);
    const [isPlaying, setIsPlaying] = useState(false);
    
  const startPlaying = () => {
    iFrame.current!.src = iFrame.current!.src + '&autoplay=1'
    setIsPlaying(true);
  }

    return (
        <div className='video-vignette basis-full md:basis-[33.333%]'>
            <div className='p-[15px]'>
                <div className='relative'>
                    <div className='ytb-video-inner'>
                        <iframe
                            ref={iFrame}
                            className="ytb-video-iframe"
                            src={href + '?mute=1'}
                            allowFullScreen
                            title="youtube Video Player"></iframe>
                        <div className={`ytb-video-overlay  ${isPlaying ? 'hidden': 'flex'}`}
                            style={{backgroundImage: `url(${img})`}}>
                                <div className='ytb-video-overlay-btn' onClick={startPlaying}>
                                    <span className='ytb-video-play-btn-icon'>
                                        <i className='fa fa-play'></i>
                                    </span>
                                    <span className='screen-only'>{languages.playVideo}</span>
                                </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export const VideoSpaceComp = ({img, onModal}: {img: string, onModal: (imf: string) => void,}) => {
    return (
        <div className='video-vignette basis-full md:basis-[33.333%]'>
            <div className='p-[15px]'>
                <div className='relative'>
                    <div className='ytb-video-inner'>
                        <div className={`ytb-video-overlay small  flex`}
                            style={{backgroundImage: `url(${img})`}}
                            onClick={() => onModal(img)}>
                                {/* <div className='ytb-video-overlay-btn' onClick={startPlaying}>
                                    <span className='ytb-video-play-btn-icon'>
                                        <i className='fa fa-play'></i>
                                    </span>
                                    <span className='screen-only'>{languages.playVideo}</span>
                                </div> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { IAlumniRegister, IAlumniRegisterError, IFieldRecord, IPageTitleSection } from "src/models/smarttypes";
import { MainTitleWrapper } from "src/components/pages/MainTitleWrapper";

import getLanguages from 'src/utils/language';
import getFxs from 'src/utils/general_fx';


import { useEffect, useRef, useState } from "react";
import { TitleSeparator } from "src/components/elements/titleseparator/TitleSeparator";
import { AlumniMemberComp } from "src/pages/home/QualifiedStaffs";
import { TestimonialsComp } from "../home/ClientTestimonials";
import { FormInputElt } from "src/components/elements/forminputelt/FormInputElt";

import tempoImage from 'src/assets/images/tempo_profile.png';
import alumniPost1 from 'src/assets/images/alumni-post-1.jpg';
import { LinkAchorRound } from "src/components/elements/linkanchorround956/LinkAchorRound";
import { RecaptaField } from "src/components/elements/recaptafield/RecaptaField";
import NetServices from "src/utils/netservices";
import { IAlumniUser, IBlogRecord, ISocialEventCplx, ISocialEventDetail } from "src/models/alumni_models";
import { LoadingElements } from "src/components/utils/LoadingElements";
import appDeclarations from "src/utils/declarations";
import { Link } from "react-router-dom";

const languages = getLanguages();
const appFxs = getFxs();
const oRegisterData: IAlumniRegister = {
  addressLine: '',
  completedDegree: '',
  dateOfBirth: new Date(),
  emailAddress: '',
  firstName: '',
  lastName: '',
  phoneNbr: '',
  yearCompleted: '2020',
  sex: '0',
  captcha: appFxs.getRandomDigits(5),
  activeJob: '',
  facebookAcct: '',
  linkedInAcct: '',
  twitterAcct: '',
}
const oElt: IPageTitleSection = {
  classes: "alumni-app",
  currentText: languages.alumni,
  pageTitle: languages.alumniMeet,
};
const colorsStr:string[] = [
  '#64C7FE',
  '#F0AA00',
  '#8700FF',
  '#FF3075',
  '#FF3D4A',
  '#64C8FF',
  '#E80F87',
  '#3A65FD',
];

export default function AlumniPage() {
  document.title = `${languages.alumniMeet} | ${languages.schoolName}`;

  useEffect(() => {
    window.scrollTo(0,0);
  }, []);

  return (
    <>
      <MainTitleWrapper {...oElt} />
      <div className="pagebody-wrapper py-5 md:pb-16">
        {/* Blogs */}
        <AlumniBlogsComp />

        {/* Social Events */}
        <AlumniSocialEventsComp />



        {/* Liste des posts */}
        {/* <ListOfAlumniPosts /> */}
        {/* MeMebers */}
        <AlumniMembersArea />

        {/* Registration */}
        <AlumniRegistration />

        {/* Testimonials */}
        {/* <AlumniTestimonials /> */}
      </div>
    </>
  );
}

export const AlumniTestimonials = () => {
  return (
    <section className="al-client-testimonials py-[20px] md:py-[70px]">
      <div className="overlay"></div>
      <div className="w-container">
        <TestimonialsComp textColor="#fff" />
      </div>
    </section>
  );
}

const AlumniRegistration = () => {
  return (
    <section className="alumni-registration py-[20px] md:py-[70px] relative">
          <div className="w-container">
            <div className="md:px-[15%]">
              <div className="mb-[60px] mt-[10px] text-center">
                <h2 className="mb-6">{languages.alumniRegist}</h2>
                <TitleSeparator titleColor="#ff6d9d" />
              </div>
              <div className="registration-al-form-wrapper">
                <div className="mx-auto flex w-full">
                  <div className="box-inner w-full">
                    <div className="al-form-reg-wrapper">
                      <div className="form-wrapper">
                        <AlumniRegForm />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
  );
}
const AlumniMembersArea = () => {
  const [isLoading, setLoading] = useState(true);
  const [hasNetError, setHasNetError] = useState(false);
  const [netError, setNetError] = useState('');

  const [alumniMembers, setAlumniMembers] = useState<IAlumniUser[]>([]);

  const bLoaded = useRef(false);

  const populateAlMembers = async () => {
    setNetError('');
    setHasNetError(false);
    setLoading(true);

    const oper = await NetServices.requestGet('web/getlistalmembers', false);
    if(!oper.bReturn){
      setHasNetError(true);
      setNetError(oper.msgBody);
      setLoading(false);
      return;
    }

//
    setAlumniMembers(oper.lstAlumniUsers!);

    setNetError('');
    setHasNetError(false);
    setLoading(false);
  }


  useEffect(() => {
    if(bLoaded.current)return;
    populateAlMembers();
    bLoaded.current = true;    
  }, [])
  


  
  return (
    <section className="alumni-members py-[50px] bg-gray-400/10" >
      <div className="w-container">
        <div className="mb-[60px] text-center">
          <h2 className="mb-6">{languages.alumniMembers}</h2>
          <TitleSeparator titleColor="#ff6d9d" />
        </div>

        {isLoading && (
          <LoadingElements
            height={150}
            textToDisplay={languages.loadAlumniMembers}
          />
        )}
        {!isLoading && hasNetError && (
          <div className="flex flex-col items-center justify-center py-4 my-3 max-w-[450px] mx-auto">
            <p className="text-center text-lg my-3">{netError}</p>
            <button
              className="btn btn-primary"
              onClick={() => populateAlMembers()}
            >
              {languages.reload}
            </button>
          </div>
        )}

        {!isLoading && !hasNetError && alumniMembers.length === 0 && (
          <div className="flex flex-col items-center justify-center py-4 my-3 max-w-[450px] mx-auto">
            <p className="text-center text-lg my-3">
              {languages.alumniMemberNoRegis}
            </p>
            <button
              className="btn btn-warning"
              onClick={() => populateAlMembers()}
            >
              {languages.reload}
            </button>
          </div>
        )}

        {!isLoading && !hasNetError && alumniMembers.length > 0 && (
          <div className="flex flex-wrap">
            {alumniMembers.map((oStaff, idx) => (
              <AlumniMemberComp
                id={idx}
                phoneNbr={oStaff.phoneNbr}
                staffJob={oStaff.activeJob ?? languages.unemployed}
                staffName={`${oStaff.firstName} ${oStaff.lastName}`}
                fbAcct={oStaff.facebookAcct}
                key={idx}
                linkedInAcct={oStaff.linkedInAcct}
                twitterAcct={oStaff.twitterAcct}
                instAcct={oStaff.instagramAcct}
                color={appFxs.randomElement(colorsStr)}
                staffImage={oStaff.profilePicturePath}
                isPhoneVis={false}
                colorSubtitle="#808080"
                colorTitle="#000"
              />
            ))}
          </div>
        )}
      </div>
    </section>
  );
};


export const ListOfAlumniPosts = () => {
  return (
    <section className="py-[70px]">
      <div className="w-container">
        <div className="flex flex-col">
          <div className="mb-[60px] mt-[10px] text-center">
            <h2 className="mb-6">{languages.alumniNews}</h2>
            <TitleSeparator titleColor="#ff6d9d" />
          </div>
          <div className="flex flex-col">
            <AlumniNewsBlog />
          </div>
        </div>
      </div>
    </section>
  );
}

const AlumniNewsBlog = () => {
  return (
    <div className="box-inner">
      <div className="flex flex-wrap">
        <div className="basis-full md:basis-[50%]">
          <div className="p-[10px]">
            <div className="pr-[30px]">
              <div className="mb-[20px] leading-[1]">
                <h2>An investment in knowledge pays the best interest.</h2>
              </div>
              <div className="mb-[20px]">
                <div className="mb-[20px]">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec
                  ac vehicula nunc. Ut pulvinar ipsum eget congue mollis.
                  Quisque interdum aliquet arcu, cursus dignissim tellus luctus
                  nec. Fusce pellentesque nulla ut eros pulvinar ipsum eget
                  mollis tincidunt.
                </div>
                <div className="mb-[20px]">
                  Aliquam lorem ante, dapibus in, viverra quis, feugiat a,
                  tellus. Phasellus viverra nulla ut metus varius laoreet.
                  Quisque rutrum. Aenean imperdiet. Etiam ultricies nisi vel
                  augue.
                </div>
              </div>
              <ul className="alumni-event-details flex flex-wrap justify-between">
                <li className="event-elt-info basis-[45%]">
                  <span>{languages.author}&nbsp;:</span> Ram Karazani
                </li>
                <li className="event-elt-info basis-[45%]">
                  <span>{languages.venue}&nbsp;:</span> London
                </li>
                <li className="event-elt-info basis-[45%]">
                  <span>{languages.activity}&nbsp;:</span> Dance
                </li>
                <li className="event-elt-info basis-[45%]">
                  <span>{languages.website}&nbsp;:</span>
                  <a href="https://google.com" target="_blank" rel="noreferrer">
                    https://google.com
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="basis-full md:basis-[50%]">
          <div className="p-[10px]">
            <div className="rounded-[30px] overflow-hidden">
              <img alt="" src={alumniPost1} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const AlumniRegForm = () => {
  const [registerData, setRegisterData] = useState<IAlumniRegister>(oRegisterData);
  const [formErrors, setFormErrors] = useState<IAlumniRegisterError>({});
  const [doctImage, setDoctImage] = useState<string | null>(null);
  const [imageFile, setImageFile] = useState<File | null>(null);
  const [isSaving, setIsSaving] = useState(false);
  const [formSent, setFormSent] = useState(false);


  const fileInput = useRef<HTMLInputElement>(null);

  let captchaVal = "";
  let allErrors:IAlumniRegisterError = {}

  const changeRecaptchaVal = (e: string) => {
    captchaVal = e;
  }

  const minYear = 2000, maxYear = 2023;
  const lstYears = Array.from({length: maxYear - minYear + 1}, (_, i) => {
    return {id: (i + minYear).toString(), label: (i + minYear).toString()};
  });
  const lstOfSexes = [
    {id:'0', label: languages.male},    
    {id:'1', label: languages.female}
  ]

  const onChageRegister = (e: IFieldRecord) => {
    const dData = {...registerData, [e.field]: e.value} as IAlumniRegister;
    setRegisterData(dData);
  }

  
  const validateForm = () => {
    const errors:IAlumniRegisterError = {};

    if(!imageFile){
      errors.fileImage = `${languages.userProfileImg} ${languages.isRequired}`;
    }
    if(!registerData.firstName){
      errors.firstName = `${languages.firstName} ${languages.isRequired}`;
    }
    if(!registerData.lastName){
      errors.lastName = `${languages.lastName} ${languages.isRequired}`;
    }
    if(!registerData.yearCompleted){
      errors.yearCompleted = `${languages.yearCompleted} ${languages.isRequired}`;
    }
    if(!registerData.sex){
      errors.sex = `${languages.sex} ${languages.isRequired}`;
    }
    if(!registerData.phoneNbr){
      errors.phoneNbr =  `${languages.phoneNbr} ${languages.isRequired}`;
    }else if(registerData.phoneNbr.length !== 12){
      errors.phoneNbr = `${languages.phoneNbr} ${languages.requires12Dig}`;
    }

    if(!registerData.emailAddress){
      errors.emailAddress =  `${languages.emailAddress} ${languages.isRequired}`;
    } else if(!appFxs.isEmailValid(registerData.emailAddress)){
      errors.emailAddress = `${languages.emailAddress} ${languages.hasWrongEmFormat}`;
    }
    if(!registerData.addressLine){
      errors.addressLine = `${languages.addressLine} ${languages.isRequired}`;
    }
    if(!registerData.completedDegree){
      errors.completedDegree = `${languages.completedDegree} ${languages.isRequired}`;
    }
    if(!captchaVal){
      errors.captcha = `${languages.fldRequired}`;
    }else if(captchaVal !== registerData.captcha){
      errors.captcha = `${languages.secCheckError}`;
    }

    setFormErrors(errors);
    allErrors = {...errors};
  }
  const openProfileselector = () => {
    if(isSaving)return;
    fileInput!.current?.click();
  }
  const onImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.currentTarget.files![0]!;
    const reader = new FileReader();
    setImageFile(file);

    reader.onload = () => {
      setDoctImage(reader.result as string);
    };

    reader.readAsDataURL(file);
  };
  const onRemoveFile = () => {
    setImageFile(null);
  }
  const registerAlumniRecord = async () => {
    validateForm();
    if (Object.keys(allErrors).length > 0) return;

    const fData = {
      ...registerData,
      dateOfBirth: appFxs.dateToUtcStr(registerData.dateOfBirth),
    };
    const finalData = appFxs.getFormData(fData);
    finalData.append("alumniProfile", imageFile!);

    setIsSaving(true);

    const retData = await NetServices.requestPostWithFile(
      `web/registeralumniuser`,
      finalData
    );

    if (!retData.bReturn) {
      appFxs.showAlert(retData.msgTitle, retData.msgBody);
      setIsSaving(false);
      return;
    }

    setIsSaving(false);
    setFormSent(true);
  };

  
  



  return (
    <>{!formSent ? 
      <form className="flex flex-col">
        <div className="w-full overflow-hidden flex flex-col rounded-tl-md rounded-tr-md">
            <div className="py-3 px-3 bg-lime-500 flex flex-col w-full">
              <h3 className="text-white font-bold mb-2">{languages.socialAccts}</h3>
              <div className="flex justify-start flex-col mb-2">
                <span className="text-white">
                  <strong>Facebook</strong>: https://www.facebook.com/johnpark
                </span>
                <span>
                  {languages.fieldValue}: <strong>johnpark</strong>                  
                </span>
              </div>

              <div className="flex justify-start flex-col mb-2">
                <span className="text-white">
                  <strong>LinkedIn</strong>: https://www.linkedin.com/in/lindajones-78680133/
                </span>
                <span>
                  {languages.fieldValue}: <strong>lindajones-78680133</strong>                  
                </span>
              </div>

              <div className="flex justify-start flex-col mb-2">
                <span className="text-white">
                  <strong>Twitter</strong>: https://twitter.com/lindajones
                </span>
                <span>
                  {languages.fieldValue}: <strong>lindajones</strong>                  
                </span>
              </div>
              

              <div className="flex justify-start flex-col mb-2">
                <span className="text-white">
                  <strong>Instagram</strong>: https://instagram.com/lindajones
                </span>
                <span>
                  {languages.fieldValue}: <strong>lindajones</strong>                  
                </span>
              </div>
            </div>

          </div>


        <div className="photo-profile-pic py-4 flex items-center justify-center">
          <div className="relative h-48 w-[160px] border border-solid border-sky-500 rounded-2xl flex items-center">
            <div className="absolute p-2 top-0 left-0 bottom-0 h-full w-full z-10 rounded-2xl">
              <div className="relative w-full h-full  rounded-2xl">
                {imageFile && (
                  <img
                    src={doctImage ?? tempoImage}
                    alt=""
                    id="alumniPic"
                    className="rounded-2xl absolute cursor-pointer z-0 top-0 left-0 bottom-0 h-[98%] w-[98%]"
                  />
                )}
                {!imageFile && (
                  <img
                    src={tempoImage}
                    alt=""
                    id="alumniPic"
                    className="rounded-2xl absolute cursor-pointer z-0 top-0 left-0 bottom-0 h-[98%] w-[98%]"
                    onClick={openProfileselector}
                  />
                )}
              </div>
              {imageFile && (
                <div className="w-5 h-5 flex items-center justify-center absolute top-1 right-1">
                  <a
                    className="c-pointer text-lime-600 hover:text-lime-900"
                    onClick={onRemoveFile}
                  >
                    <i className="fa fa-times-circle"></i>
                  </a>
                </div>
              )}
            </div>

            <input
              type="file"
              className="absolute top-0 left-0 bottom-0 h-full w-full z-20 cursor-pointer"
              id="alumniProfile"
              accept="image/*"
              onChange={onImageChange}
              ref={fileInput}
            />
          </div>
        </div>
        {formErrors.fileImage && 
          <div className="p-2 text-center text-red-500">
            <span>{formErrors.fileImage}</span>
          </div>
        }
        <div className="flex justify-between flex-wrap">
          {/* First Name */}
          <FormInputElt
            type="text"
            name="firstName"
            placeholder={languages.firstName.toLowerCase()}
            value={registerData.firstName}
            onChange={onChageRegister}
            maxLength={25}
            displayClass="block"
            otherClasses="basis-full md:basis-[49%]"
            errorMessage={formErrors.firstName}
            disabled={isSaving}
          />
          {/* Last Name */}
          <FormInputElt
            type="text"
            name="lastName"
            placeholder={languages.lastName.toLowerCase()}
            value={registerData.lastName}
            onChange={onChageRegister}
            maxLength={25}
            otherClasses="basis-full md:basis-[49%]"
            errorMessage={formErrors.lastName}
            disabled={isSaving}
          />
          {/* dateOfBirth */}
          <FormInputElt
            type="date"
            name="dateOfBirth"
            placeholder={languages.dateOfBirth}
            value={registerData.dateOfBirth}
            onChange={onChageRegister}
            otherClasses="basis-full md:basis-[49%]"
            disabled={isSaving}
          />
          {/* sex */}
          <FormInputElt
            type="select"
            name="sex"
            placeholder={languages.chooseSex.toLowerCase()}
            value={registerData.sex}
            onChange={onChageRegister}
            otherClasses="basis-full md:basis-[49%]"
            selectOptions={lstOfSexes}
            errorMessage={formErrors.sex}
            disabled={isSaving}
          />
          {/* yearCompleted */}
          <FormInputElt
            type="select"
            name="yearCompleted"
            placeholder={languages.yearCompleted.toLowerCase()}
            value={registerData.yearCompleted}
            onChange={onChageRegister}
            otherClasses="basis-full md:basis-[49%]"
            selectOptions={lstYears}
            errorMessage={formErrors.yearCompleted}
            disabled={isSaving}
          />
          {/* completedDegree */}
          <FormInputElt
            type="text"
            name="completedDegree"
            placeholder={languages.completedDegree.toLowerCase()}
            value={registerData.completedDegree}
            onChange={onChageRegister}
            maxLength={150}
            displayClass="block"
            otherClasses="basis-full md:basis-[49%]"
            errorMessage={formErrors.completedDegree}
            disabled={isSaving}
          />
          {/* phoneNbr */}
          <FormInputElt
            type="tel"
            name="phoneNbr"
            placeholder={languages.phoneNbr.toLowerCase()}
            value={registerData.phoneNbr}
            onChange={onChageRegister}
            maxLength={12}
            displayClass="block"
            otherClasses="basis-full md:basis-[49%]"
            errorMessage={formErrors.phoneNbr}
            disabled={isSaving}
          />
          {/* emailAddress */}
          <FormInputElt
            type="email"
            name="emailAddress"
            placeholder={languages.emailAddress.toLowerCase()}
            value={registerData.emailAddress}
            onChange={onChageRegister}
            maxLength={50}
            displayClass="block"
            otherClasses="basis-full md:basis-[49%]"
            errorMessage={formErrors.emailAddress}
            disabled={isSaving}
          />

          {/* emailAddress */}
          <FormInputElt
            type="text"
            name="activeJob"
            placeholder={languages.job.toLowerCase()}
            value={registerData.activeJob}
            onChange={onChageRegister}
            maxLength={50}
            displayClass="block"
            otherClasses="basis-full md:basis-[49%]"
            errorMessage={formErrors.activeJob}
            disabled={isSaving}
          />

          {/* facebook */}
          <FormInputElt
            type="text"
            name="facebookAcct"
            placeholder={languages.facebookAcct.toLowerCase()}
            value={registerData.facebookAcct}
            onChange={onChageRegister}
            maxLength={50}
            displayClass="block"
            otherClasses="basis-full md:basis-[49%]"
            errorMessage={formErrors.facebookAcct}
            disabled={isSaving}
          />

          {/* twitter */}
          <FormInputElt
            type="text"
            name="twitterAcct"
            placeholder={languages.twitterAcct.toLowerCase()}
            value={registerData.twitterAcct}
            onChange={onChageRegister}
            maxLength={50}
            displayClass="block"
            otherClasses="basis-full md:basis-[49%]"
            errorMessage={formErrors.twitterAcct}
            disabled={isSaving}
          />

          {/* linkedIn */}
          <FormInputElt
            type="text"
            name="linkedInAcct"
            placeholder={languages.linkedInAcct.toLowerCase()}
            value={registerData.linkedInAcct}
            onChange={onChageRegister}
            maxLength={50}
            displayClass="block"
            otherClasses="basis-full md:basis-[49%]"
            errorMessage={formErrors.linkedInAcct}
            disabled={isSaving}
          />
          {/* instagram */}
          <FormInputElt
            type="text"
            name="instagramAcct"
            placeholder={languages.instagramAcct.toLowerCase()}
            value={registerData.instagramAcct}
            onChange={onChageRegister}
            maxLength={50}
            displayClass="block"
            otherClasses="basis-full md:basis-[49%]"
            errorMessage={formErrors.instagramAcct}
            disabled={isSaving}
          />




          {/* addressLine */}
          <FormInputElt
            type="textarea"
            name="addressLine"
            placeholder={languages.addressLine.toLowerCase()}
            value={registerData.addressLine}
            onChange={onChageRegister}
            maxLength={2500}
            displayClass="block"
            otherClasses="basis-full"
            rows={4}
            errorMessage={formErrors.addressLine}
            disabled={isSaving}
          />
          



          <div className="w-full flex justify-end">
            <RecaptaField
              startingValue={registerData.captcha}
              onChange={changeRecaptchaVal}
              disabled={isSaving}
            />
          </div>
          {formErrors.captcha && (
            <div className="w-full pb-2 text-end">
              <span className="text-red-800">{formErrors.captcha}</span>
            </div>
          )}
          <div className="text-center w-full py-4">
            <LinkAchorRound
              type="button"
              text={languages.submitNow}
              bgColor="#f0aa00"
              hoverBgcolor="#ff236c"
              onClick={registerAlumniRecord}
            />
          </div>
        </div>
      </form>:
      <div className="py-3 text-center">
        <span className="text-green-500">{languages.alumniAfterRegisterMsg}</span>
      </div>
      }
    </>
  );
}

const AlumniBlogsComp = () => {
  const [isLoading, setLoading] = useState<boolean>(true);
  const [lstAlumniPosts, setLstAlumniPosts] = useState<IBlogRecord[]>([]);
  const popped = useRef(false);

  const popAlumniPosts = async () => {
    const oOperBlogs = await NetServices.requestGet(
      "alumni/getlistofallblogswithlimit/6",
      false
    );

    if (oOperBlogs.bReturn) {
      setLstAlumniPosts(oOperBlogs.lstOfBlogRecords!);
      setLoading(false);
    } else {
      setLoading(false);
    }

    popped.current = true;
  };

  useEffect(() => {
    if (popped.current) return;
    popAlumniPosts();
  }, []);

  return (
    <section className="py-[70px] bg-gray-300 -mt-10">
      <div className="w-container">
        <div className="flex flex-col">
          <div className="mb-[60px] mt-[10px] text-center">
            <h2 className="mb-6">{languages.alumniBlogs}</h2>
            <TitleSeparator titleColor="#ff6d9d" />
          </div>
          {isLoading ? (
            <LoadingElements />
          ) : (
            <ListOfBlogComp lstBlogs={lstAlumniPosts} />
          )}
        </div>
      </div>
    </section>
  );
};
const ListOfBlogComp = ({ lstBlogs }: { lstBlogs: IBlogRecord[] }) => {
  return (
    <>
      {lstBlogs.length === 0 ? (
        <div className="flex flex-col items-center justify-center">
          <p>{languages.noBlogFound}</p>
          <p>{languages.noBlogFoundMsg}</p>
          <p>
            <button>{languages.reloadBlogs}</button>
          </p>
        </div>
      ) : (
        <div className="flex flex-col">
          <div className="flex justify-end">
            <Link className="text-blue-500 hover:text-blue-700" to={'/'}>{languages.displayAll}</Link>
          </div>
          <div className="py-4 flex items-center flex-wrap gap-5 justify-between">
            {lstBlogs.map((oBlog, idx) => (
              <AlumniBlogElt key={idx} {...oBlog} />
            ))}
          </div>
        </div>
      )}
    </>
  );
};

const AlumniBlogElt = ({post}: IBlogRecord) => {
  const bgImg = post.featuredImageType === 'url' ? 
    post.featuredImage: 
    `${appDeclarations.apiUrlTest}/cloud_data/alumni_blogs_images/${post.featuredImage}`;

  return (
    <section className="card w-96 bg-base-100 shadow-xl flex-none snap-always snap-center">
      <figure className="w-full aspect-video max-h-[201px]">
        <img
          src={bgImg}
          alt="Shoes"
          className="object-cover"
        />
      </figure>
      <div className="card-body">
        <h2 className="card-title">
          <Link to={`/alumni/blog/${post.postKey}`} className="whitespace-nowrap text-blue-500 hover:text-blue-600 overflow-hidden">
            {post.postSubject}
          </Link>
          {/* <div className="badge badge-secondary">NEW</div> */}
        </h2>
        <p>{post.postTitle}</p>
        <div className="card-actions justify-end">
          {/* <div className="badge badge-outline">Fashion</div>
          <div className="badge badge-outline">Products</div> */}
          {post.tags.map((oTag, idx) => 
            <div key={idx} className="badge badge-outline">
              #{oTag}
            </div>)
          }
        </div>
      </div>
    </section>
  );
};




const AlumniSocialEventsComp = () => {
  const [isLoading, setLoading] = useState<boolean>(true);
  const [lstSocialEvents, setSocialEvents] = useState<ISocialEventCplx[]>([]);
  const popped = useRef(false);

  const popAlumniPosts = async () => {
    const oOperBlogs = await NetServices.requestGet(
      "alumni/getlistofsocialeventsforweb",
      false
    );
    
    if (oOperBlogs.bReturn) {
      setSocialEvents(oOperBlogs.lstAllSocialEvents!);
      setLoading(false);
    } else {
      setLoading(false);
    }

    popped.current = true;
  };
  const reloadEvents = () => {
    setLoading(true);
    popAlumniPosts();
  }

  useEffect(() => {
    if (popped.current) return;
    popAlumniPosts();
  }, []);

  return (
    <section className="py-[70px]">
      <div className="w-container">
        <div className="flex flex-col">
          <div className="mb-[60px] mt-[10px] text-center">
            <h2 className="mb-6">{languages.socialEvents}</h2>
            <TitleSeparator titleColor="#ff6d9d" />
          </div>

          
          {isLoading ? (
            <LoadingElements />
          ) : (
            <ListOfSocEventsComp lstEvents={lstSocialEvents} reloadEvents={reloadEvents} />
          )}
        </div>
      </div>
    </section>
  );
}


const ListOfSocEventsComp = ({ lstEvents, reloadEvents }: { 
  lstEvents: ISocialEventCplx[],
  reloadEvents: () => void
 }) => {

  return (
    <>
      {lstEvents.length === 0 ? (
        <div className="flex flex-col items-center justify-center">
          <h3 className="font-extrabold py-2 mb-3 text-3xl">{languages.noSocEvtsFound}</h3>
          <p className="text-lg">{languages.noSocEvtsFoundMsg}</p>
          <div className="my-5">
            <LinkAchorRound type="button"
              text={languages.reloadScoEvts}
              onClick={reloadEvents} />
          </div>
        </div>
      ) : (
        <div className="py-4 flex items-center flex-nowrap gap-5 overflow-x-auto snap-x snap-mandatory no-scrollbar">
          {lstEvents.map((oEvt, idx) => (
            <SocialEventComp key={idx} {...oEvt} />
          ))}
        </div>
      )}
    </>
  );
};

const SocialEventComp = ({socialEvent,lstDetails}:ISocialEventCplx) => {
 return (
    <div className="flex flex-col">
      <div className="w-64  carousel rounded-box">
        {lstDetails.map(oEvt => <ImgElt {...oEvt} key={oEvt.eventDetailKey}/> )}
      </div>
      <div className="py-3">
        <h3 className="whitespace-nowrap overflow-hidden font-semibold text-sm">
          <Link className="text-blue-500 hover:text-blue-700" to={`/alumni/socialevent/${socialEvent.eventKey}`}>{socialEvent.eventTitle}</Link>
        </h3>
        <p className="whitespace-nowrap overflow-hidden text-[12px]">
          <span className="fa fa-map"></span>&nbsp;{socialEvent.eventLocation}</p>
      </div>
    </div>
  );
}

const ImgElt = ({ eventPhotoName, eventKey }: ISocialEventDetail) => {
  const photoPath = `${appDeclarations.apiUrlTest}/cloud_data/alumni_social_events/${eventKey}/${eventPhotoName}`;
  return (
    <div className="carousel-item w-full">
      <img
        src={photoPath}
        className="w-full h-[171px] object-cover object-top"
        alt={eventPhotoName}
      />
    </div>
  );
};
import appDeclarations from "./declarations";

const getAppLang = (): string => {
  const elt = localStorage.getItem(appDeclarations.currentLang);
  return elt ?? "en";
};

const retLang = (
  enCont: string,
  frCont: string,
  rwCont: string = ""
): string => {
  const lang = getAppLang();

  return lang === "en"
    ? enCont
    : lang === "fr"
    ? frCont
    : lang === "rw"
    ? rwCont
    : enCont;
};
function GetLanguage() {
  return {
    loading: retLang("Loading", "Chargement", ""),
    login: retLang("Login", "Connection", ""),
    loggin: retLang("Logging In", "Connection", ""),
    enter: retLang("Enter", "Entrez", ""),
    your: retLang("Your", "Votre", ""),
    email: retLang("Email", "Email", ""),
    and: retLang("And", "Et", ""),
    registering: retLang("Registering", "Enregistrement", ""),
    password: retLang("Password", "Mot de passe", ""),
    signInTo: retLang("Sign in to", "Connectez-vous à", ""),
    signIn: retLang("Sign in", "Connection", ""),
    signOut: retLang("Sign out", "Se deconnecter", ""),
    rememberMe: retLang("Remember me", "Souviens-toi de moi", ""),
    forgotPassWord: retLang("Forgot password?", "Mot de passe oublié?", ""),
    appName: retLang("La colombière Website", "Siteweb La Colombière", ""),
    enterYour: retLang("Enter your", "Entrez votre", ""),
    emailEx: retLang("you@example.com", "vous@exemple.com", ""),
    reset: retLang("Reset", "Réinitialiser", ""),
    logInInstead: retLang(
      "I have account, log in instead",
      "J'ai déja un compte. Veuillez me connecter",
      ""
    ),
    resetPassword: retLang(
      "Reset your password",
      "Modifier votre mot de passe",
      ""
    ),
    setPasswordWithNew: retLang(
      "Set your password with a new one",
      "Configurez votre mot de passe avec un nouveau",
      ""
    ),
    confirmPasword: retLang(
      "Confirm your password",
      "Confirmer votre mot de passe",
      ""
    ),
    weak: retLang("Weak", "Faible", ""),
    good: retLang("Good", "Bon", ""),
    strong: retLang("Strong", "Fort", ""),
    very_strong: retLang("Very strong", "Très fort", ""),
    passwordReqEmailSent: retLang(
      "Waiting for password reset",
      "En attente de la réinitialisation du mot de passe",
      ""
    ),
    checkUrEmail: retLang(
      "Check your email",
      "Vérifiez votre courrier électronique.",
      ""
    ),
    checkUrEmailPasswordReset: retLang(
      "Check your email for password reset process.",
      "Vérifiez votre courrier électronique pour le processus de réinitialisation du mot de passe.",
      ""
    ),
    goToBySite: retLang(
      "Go to Byihuse website",
      "Visitez le website de Byihuse",
      ""
    ),
    networkError: retLang("Network error", "Erreur de réseau", ""),
    networkErrorMsg: retLang(
      "There is a network error on this device. Check this issue and try again.",
      "Il y a une erreur réseau sur cet appareil. Vérifiez ce problème et réessayez.",
      ""
    ),
    fldRequired: retLang(
      "This field is required",
      "Ce champ est obligatoire",
      ""
    ),
    emailFormat: retLang(
      "The email format seems wrong.",
      "Le format de l'e-mail semble incorrect.",
      ""
    ),
    passLengthMsg: retLang(
      "Password must be at least 8 characters.",
      "Le mot de passe doit comporter au moins 8 caractères.",
      ""
    ),
    passValidMsg: retLang(
      "The password strength is too weak to be valid.",
      "Le niveau de sécurité du mot de passe est trop faible pour être valide.",
      ""
    ),
    sameAsPasswordMsg: retLang(
      "You are unable to confirm your password correctly.",
      "Vous n'arrivez pas à confirmer votre mot de passe correctement.",
      ""
    ),
    learnMore: retLang("Learn more", "Apprendre encore plus", ""),
    phonenbrLengthMsg: retLang(
      "The phone number must be 12 caracters long.",
      "Le numéro de téléphone doit être de 12 caractères.",
      ""
    ),

    waitingforServerResp: retLang(
      "Waiting fro server response.",
      "En attente du serveur.",
      ""
    ),
    save: retLang("Save", "Enregistrer", ""),
    saveRecordSalesOpQst: retLang(
      "Do you want to save this sales operation?",
      "Voulez-vous enregistrer cette opération d'achat?",
      ""
    ),

    jan_lng: retLang("January", "Janvier", "Mutarama"),
    feb_lng: retLang("February", "Février", "Gashyantare"),
    mar_lng: retLang("March", "Mars", "Werurwe"),
    apr_lng: retLang("April", "Avril", "Mata"),
    may_lng: retLang("May", "Mai", "Gicurasi"),
    jun_lng: retLang("Jun", "Juin", "Kamena"),
    jul_lng: retLang("July", "Juillet", "Nyakanga"),
    aug_lng: retLang("August", "Août", "Kanama"),
    sept_lng: retLang("September", "Septembre", "Nzeri"),
    oct_lng: retLang("October", "Octobre", "Ukwakira"),
    nov_lng: retLang("November", "Novembre", "Ugushyingo"),
    dec_lng: retLang("December", "Decembre", "Ukuboza"),

    jan_shrt: retLang("Jan", "Janr", "Muta"),
    feb_shrt: retLang("Feb", "Fév", "Gashy"),
    mar_shrt: retLang("Mar", "Mars", "Weru"),
    apr_shrt: retLang("Apr", "Avr", "Mata"),
    may_shrt: retLang("May", "Mai", "Gicu"),
    jun_shrt: retLang("Jun", "Juin", "Kame"),
    jul_shrt: retLang("July", "Juil", "Nyaka"),
    aug_shrt: retLang("Aug", "Août", "Kana"),
    sept_shrt: retLang("Sept", "Sept", "Nzeri"),
    oct_shrt: retLang("Oct", "Oct", "Ukwa"),
    nov_shrt: retLang("Nov", "Nov", "Ugus"),
    dec_shrt: retLang("Dec", "Dec", "Uku"),

    monday_lng: retLang("Monday", "Lundi", ""),
    tuesday_lng: retLang("Tuesday", "Mardi", ""),
    wednday_lng: retLang("Wednesday", "Mercredi", ""),
    thursday_lng: retLang("Thursday", "Jeudi", ""),
    friday_lng: retLang("Friday", "Vendredi", ""),
    saturday_lng: retLang("Saturday", "Samedi", ""),
    sunday_lng: retLang("Sunday", "Dimance", ""),

    monday_shrt: retLang("Mon", "Lun", ""),
    tuesday_shrt: retLang("Tue", "Mar", ""),
    wednday_shrt: retLang("Wed", "Mer", ""),
    thursday_shrt: retLang("Thu", "Jeu", ""),
    friday_shrt: retLang("Fri", "Ven", ""),
    saturday_shrt: retLang("Sat", "Sam", ""),
    sunday_shrt: retLang("Sun", "Dim", ""),

    other: retLang("Other", "Autre", ""),
    activate: retLang("Activate", "Activer", ""),
    deactivate: retLang("Disable", "Désactiver", ""),
    activate_qst: retLang(
      "Would you like to activate this user?",
      "Voudriez-vous activer cet utilisateur?",
      ""
    ),
    deactivate_qst: retLang(
      "Would you like to Disable this user?",
      "Voudriez-vous désactiver cet utilisateur?",
      ""
    ),
    activated: retLang("Activated", "Activé", ""),
    deactivated: retLang("Disabled", "Désactivé", ""),
    active: retLang("Active", "Actif", ""),
    inactive: retLang("Inactive", "Inactif", ""),
    status: retLang("Status", "Etat", ""),

    sign_up: retLang("Sign Up", "S'inscrire", ""),
    sign_out: retLang("Sign out", "Se déconnecter", ""),

    print_prev: retLang("Print preview", "Aperçu avant impression", ""),
    just_print: retLang("Just print", "Imprimer", ""),
    export_to_msword: retLang("Export to Ms Word", "Exporter vers Ms Word", ""),
    export_to_msexcel: retLang(
      "Export to Ms Excel",
      "Exporter vers Ms Excel",
      ""
    ),
    export_to_pdf: retLang("Export to PDF", "Exporter vers PDF", ""),
    export_to_image: retLang("Export to Image", "Exporter vers l'image", ""),
    compose_email_content: retLang(
      "Compose email content",
      "Ecrire le contenu du message",
      ""
    ),
    email_recipient: retLang("Email recipient", "Destinataire du courriel", ""),
    email_subject: retLang("Email subject", "Objet du message", ""),
    email_content: retLang("Email content", "Contenu du message", ""),
    compose_email: retLang("Compose email", "Composer un email", ""),
    add_recipient: retLang("Add recipient", "Ajouter destinataire", ""),
    from: retLang("From", "De", ""),
    subject: retLang("Subject", "Objet", ""),

    add_new_rec_email: retLang(
      "Add new recipient email",
      "Ajouter un nouvel e-mail de destinataire",
      ""
    ),
    add_new_rec_email_cc: retLang(
      "Add new recipient email (CC)",
      "Ajouter un nouvel e-mail de destinataire (CC)",
      ""
    ),
    add_new_rec_email_bcc: retLang(
      "Add new recipient email (BCC)",
      "Ajouter un nouvel e-mail de destinataire (BCC)",
      ""
    ),

    show_recipients_cc: retLang(
      "Show recipients input area (CC)",
      "Afficher la zone de saisie des destinataires (CC)",
      ""
    ),
    show_recipients_bcc: retLang(
      "Show recipients input area (BCC)",
      "Afficher la zone de saisie des destinataires (BCC)",
      ""
    ),
    hide_recipients_cc: retLang(
      "Hide recipients input area (CC)",
      "Masquer la zone de saisie des destinataires (CC)",
      ""
    ),
    hide_recipients_bcc: retLang(
      "Hide recipients input area (BCC)",
      "Masquer la zone de saisie des destinataires (BCC)",
      ""
    ),

    email_failure_sent: retLang(
      "Your email has not been sent successfully. Kindly check the related Email account and try again.",
      "Votre Email n'a pas été envoyé avec succès. Veuillez vérifier le compte Email associé et réessayer.",
      ""
    ),

    countr_miss_msg: retLang(
      "The related country seems missing from the database. Try again.",
      "Le pays concerné semble absent de la base de données. Essayer à nouveau.",
      ""
    ),
    success: retLang("Success", "Réussite", ""),
    sms_failure_sent: retLang(
      "Your SMS has not been sent successfully. Kindly check the related SMS account and try again.",
      "Votre SMS n'a pas été envoyé avec succès. Veuillez vérifier le compte SMS associé et réessayer.",
      ""
    ),
    sms_success_sent: retLang(
      "SMS sent with success",
      "SMS envoyé avec succès",
      ""
    ),
    email_sent_success: retLang(
      "Email sent with success",
      "Email envoyé avec succès",
      ""
    ),

    terms_m: retLang("Terms", "Termes", ""),
    cookies_m: retLang("Cookies", "Cookies", ""),
    privacy_m: retLang("Privacy", "Vie privée", ""),
    contactus_m: retLang("Contact Us", "Nous contacter", ""),
    terms_serv: retLang("Terms of Service", "Conditions d'utilisation", ""),
    priv_policy: retLang("Privacy Policy", "Politique de confidentialité", ""),
    all_rights_reserved: retLang(
      "All Rights Reserved",
      "Tous droits reservés",
      ""
    ),
    cookie_policy: retLang("Cookie Policy", "Politique de cookie", ""),
    waitServerResp: retLang(
      "Waiting for server response...",
      "Veuillez patienter la reponse du serveur...",
      ""
    ),

    english_m: retLang("English", "Anglais", ""),
    french_m: retLang("French", "Français", ""),
    rwanda_m: retLang("Kinyarwanda", "Kinyarwanda", ""),
    swahili_m: retLang("Swahili", "Swahili", ""),
    lingala_m: retLang("Lingala", "Lingala", ""),
    other_m: retLang("Other language", "Autre langue", ""),
    reload: retLang("Reload", "Recharger", ""),
    cancel: retLang("Cancel", "Annuler", ""),
    yes: retLang("Yes", "Oui", ""),
    messageDate: retLang("Message date", "Date message", ""),
    networkName: retLang("Network name", "Nom du réseau", ""),
    home: retLang("Home", "Acceuil", ""),
    search: retLang("Search", "Chercher", ""),
    saving: retLang("Saving", "Enregistrement", ""),
    ourPartners: retLang("Our partners", "Nos Partenaires", ""),
    clcickHereToSelect: retLang(
      "Click here to select",
      "Cliquez ici pour sélectionner",
      ""
    ),
    subscribe: retLang("Subscribe", "S'abonner", ""),
    yourEmailHere: retLang("Your email here", "Mettez votre email içi", ""),
    instructorTeacher: retLang(
      "Instructor/Teacher",
      "Instructeur/Enseignant",
      ""
    ),
    ourQualStaff: retLang(
      "Our Qualified Staff",
      "Notre personnel qualifié",
      ""
    ),
    clientTestimonials: retLang(
      "Parents Testimonials",
      "Témoignages de parents",
      ""
    ),
    clientTestimonialsMsg: retLang(
      "Here is what parents say about the services we provide them by teaching their children. Our school infrastructure and our school curriculum attract praise from parents.",
      "Voici ce que disent les parents à propos des services que nous leur rendons en enseignant leurs enfants. Nos infrastructures scolaires, notre programme scolaire nous attirent des louanges, lesquels sont exprimés par des parents.",
      ""
    ),
    about: retLang("About", "A propos", ""),
    founderMsgTitle: retLang(
      "Teaching is a noble job",
      "Enseigner est un métier noble",
      ""
    ),
    principalMsgTitle: retLang(
      "We are a school of values",
      "Nous sommes une école de valeurs",
      ""
    ),
    facilities: retLang("Premises", "Infrastructures", ""),
    events: retLang("Events", "Infos", ""),
    alumni: retLang("Alumni", "Anc. élèves", ""),
    shop: retLang("Shop", "Magasin", ""),
    contactus: retLang("Contact", "Nous contacter", ""),
    schoolName: retLang("La Colombière School", "Ecole La Colombière", ""),
    aboutUs: retLang("About Us", "A propos de Nous", ""),
    eventsAndNews: retLang("Events & News", "Événements et actualités", ""),
    alumniMeet: retLang("Alumni Meet", "Rencontre des anciens", ""),
    contact: retLang("Contact", "Contacter", ""),
    terms: retLang("Terms", "Conditions", ""),
    playVideo: retLang("Play Video", "Lire la vidéo", ""),
    joinToday: retLang("Join Today", "Rejoignez-nous aujourd'hui", ""),
    viewmore: retLang("View More", "Voir plus", ""),
    scheduleVisit: retLang("Schedule a Visit", "Planifier Rendez-vous", ""),
    typeHere: retLang("Type here", "Saisissez içi", ""),
    submit: retLang("Submit", "Envoyer", ""),
    typeYourPlaceHere: retLang(
      "Type your place here",
      "Tapez votre lieu ici",
      ""
    ),
    searchHere: retLang("Search here", "Cherchez içi", ""),
    staff: retLang("Staff", "Employé", ""),
    student: retLang("Student", "Etudiant", ""),
    job: retLang("Job", "Emploi", ""),
    priorityType: retLang("Priority type", "Type priorité", ""),
    high: retLang("High", "Haute", ""),
    highPrior: retLang("High", "Urgent", ""),
    medium: retLang("Medium", "Moyen", ""),
    low: retLang("Low", "Faible", ""),
    message: retLang("Message", "Message", ""),
    sendMessage: retLang("Send Message", "Envoyer Message", ""),
    placePhoneCallTo: retLang(
      "Place your phone call to",
      "Passez votre appel téléphonique au",
      ""
    ),
    sendEmailTo: retLang("Send your email to", "Envoyez votre email au", ""),
    defaultSorting: retLang("Default sorting", "Tri par défaut", ""),
    sortByRating: retLang("Sort by rating", "Tri par note", ""),
    sortByDate: retLang("Sort by date", "Tri par date", ""),
    sortByLatest: retLang("Sort by latest", "Tri par plus récents", ""),
    sortByPriceLowHigh: retLang(
      "Sort by price (low - high)",
      "Trier par prix (bas - haut)",
      ""
    ),
    sortByPriceHighLow: retLang(
      "Sort by price (high - low)",
      "Trier par prix (haut - bas)",
      ""
    ),
    sale: retLang("Sale", "En Vente", ""),
    chooseOption: retLang("Choose an option", "Choisissez une option", ""),
    products: retLang("Products", "Produits", ""),
    color: retLang("Color", "Couleur", ""),
    size: retLang("Size", "Taille", ""),
    productNotFound: retLang(
      "No product has been found. Kindly make a new selection and try again.",
      "Aucun produit n'a été trouvé. Veuillez faire une nouvelle sélection et réessayer",
      ""
    ),

    addToCart: retLang("Add to cart", "Ajouter au panier", ""),
    clickHere: retLang("Click here", "Clicquez içi", ""),
    sku: retLang("SKU", "UGS", ""),
    category: retLang("Category", "Catégorie", ""),
    share: retLang("Share", "Partager", ""),
    viewCart: retLang("View Cart", "Consulter le panier", ""),
    deleteCart: retLang("Delete Cart", "Supprimer le panier", ""),
    yourCartDataDel: retLang(
      "Your cart data has been deleted.",
      "Les données de votre panier ont été supprimées.",
      ""
    ),
    deleteCartMsg: retLang(
      "Do you want to delete your cart data?",
      "Voulez-vous supprimer les données de votre panier?",
      ""
    ),
    cart: retLang("Cart", "Panier", ""),
    cartTotals: retLang("Cart totals", "Totaux du Panier", ""),
    subtotal: retLang("Subtotal", "Sous Total", ""),
    total: retLang("Total", "Total", ""),
    proceedToCheckout: retLang("Proceed to checkout", "Passer à la caisse", ""),
    product: retLang("Product", "Produit", ""),
    price: retLang("Price", "Prix", ""),
    quantity: retLang("Quantity", "Quantité", ""),
    removeThisItem: retLang("Remove this item", "Enlever cet article", ""),
    removeThisItemMsg: retLang(
      "Do you want to remove this item from your cart?",
      "Voulez-vous supprimer ce produit de votre panier?",
      ""
    ),
    checkout: retLang("Checkout", "Caisse", ""),
    billingDetails: retLang("Billing details", "Détails de la facturation", ""),
    firstName: retLang("First name", "Prénom", ""),
    lastName: retLang("Last name", "Nom de famille", ""),
    required: retLang("Required", "Obligatoire", ""),
    companyName: retLang("Company name", "Nom entreprise", ""),
    province: retLang("Province", "Province", ""),
    city: retLang("City", "Ville", ""),
    phone: retLang("Phone", "Téléphone", ""),
    roadNbr: retLang("Road number", "N° de la rue", ""),
    additionalInfo: retLang(
      "Additional Information",
      "Informations Complémentaires",
      ""
    ),
    orderNotes: retLang("Order notes", "Notes de commande", ""),
    notRequired: retLang("Optional", "Facultatif", ""),
    notesAbtOrder: retLang(
      "Notes about your order, eg. special notes for delivery",
      "Notes sur votre commande, par exemple. notes spéciales pour la livraison",
      ""
    ),
    yourOrder: retLang("Your order", "Votre commande", ""),
    grid: retLang("Grid", "Grille", ""),
    list: retLang("List", "Lists", ""),
    next: retLang("Next", "Suivant", ""),
    previous: retLang("Previous", "Précédent", ""),
    noProductFound: retLang(
      "No products have been found.",
      "Aucun produit n'a été trouvé.",
      ""
    ),
    prodBeenAdded: retLang(
      "has been added into your cart.",
      "a été ajouté dans votre panier.",
      ""
    ),
    prodBeenUpdate: retLang(
      "has been updated in your cart.",
      "a été mis à jour dans votre panier.",
      ""
    ),
    noDescription: retLang("No description", "Pas de description.", ""),
    viewCartDetails: retLang(
      "View cart details",
      "Afficher les détails du panier",
      ""
    ),
    remove: retLang("Remove item", "Enlever un produit", ""),
    noVariants: retLang(
      "No product variants",
      "Aucunes variantes du produit",
      ""
    ),
    deletingCartDetails: retLang(
      "Bear with us while we're deleting your cart details.",
      "Veuillez nous patienter pendant que nous supprimons les détails de votre panier.",
      ""
    ),
    deletingCartItem: retLang(
      "Bear with us while we're deleting this item from your cart details.",
      "Veuillez nous patienter pendant que nous supprimons ce produit des détails de votre panier.",
      ""
    ),
    updatingDataWait: retLang(
      "Updating data... Please wait...",
      "Mise à jour des données... Patientez...",
      ""
    ),
    noOptionsFound: retLang(
      "No options have been provided for this field.",
      "Aucune option n'a été fournie pour ce champ.",
      ""
    ),

    directBankTransf: retLang(
      "Direct bank transfer",
      "Virement bancaire direct",
      ""
    ),
    directBankTransfMsg: retLang(
      "Make your payment directly into our bank account. Please use your Order ID as the payment reference. Your order will not be shipped until the funds have cleared in our account.",
      "Effectuez votre paiement directement sur notre compte bancaire. Veuillez utiliser votre numéro de commande comme référence de paiement. Votre commande ne sera pas expédiée tant que les fonds n'auront pas été crédités sur notre compte.",
      ""
    ),

    checkPayt: retLang("Check payments", "Paiements par chèque", ""),
    checkPaytMsg: retLang(
      "Please send a check to Store Name, Store Street, Store Town, Store State / County, Store Postcode.",
      "Veuillez envoyer un chèque au nom du magasin, à la rue du magasin, à la ville du magasin, à l'état/comté du magasin et au code postal du magasin.",
      ""
    ),

    cashDelivery: retLang("Cash on delivery", "Paiement à la livraison", ""),
    cashDeliveryMsg: retLang(
      "Pay with cash upon delivery.",
      "Payez en espèces à la livraison.",
      ""
    ),

    alumniMembers: retLang("Alumni Members", "Membres du Club des Ancies", ""),
    momoPayt: retLang("Mobile Money payment", "Paiement MoMo", ""),
    momoPaytMsg: retLang(
      "Send us the payment details such as the transaction ID and the amount paid.",
      "Envoyez-nous les détails du paiement comme l'identifiant de l'opération ainsi que le montant payé.",
      ""
    ),

    placeOrder: retLang("Place order", "Passer la commande", ""),
    paytDetails: retLang("Payment details", "Détails du paiement", ""),
    requiredFields: retLang("Required fields", "Champs obligatoires", ""),
    requiredFieldsMsg: retLang(
      "Some required fields appear empty of required information. Check and try again.",
      "Certains champs obligatoires semblent vides des informations requises. Vérifiez et réessayez.",
      ""
    ),
    alumniRegist: retLang(
      "Alumni Registration",
      "Inscription des anciens élèves",
      ""
    ),
    dateOfBirth: retLang("Date of birth", "Date de naissance", ""),
    submitNow: retLang("Submit now", "Envoyer maintenant", ""),
    yourName: retLang("Your name", "Votre nom", ""),
    emailAddress: retLang("Email address", "Adresse email", ""),
    phoneNbr: retLang("Phone number", "N° téléphone", ""),
    whatKnow: retLang(
      "What do you want to know?",
      "Que voulez-vous savoir?",
      ""
    ),
    yearCompleted: retLang("Year completed", "Année terminée", ""),
    sex: retLang("Sex", "Sexe", ""),
    male: retLang("Male", "Masculin", ""),
    female: retLang("Female", "Féminin", ""),
    completedDegree: retLang("Completed degree", "Diplôme obtenu", ""),
    whichYearComp: retLang("Choose year", "Choisir l'année", ""),
    chooseSex: retLang("Choose your sex", "Choisir votre sexe", ""),
    addressLine: retLang("Address line", "Adresse complète", ""),
    isRequired: retLang("is required.", "est obligatoire", ""),
    hasWrongEmFormat: retLang(
      "has a wrong email format.",
      "a un format incorrect.",
      ""
    ),
    requires12Dig: retLang(
      "requires 12 digit phone format",
      "nécessite un format de téléphone à 12 chiffres",
      ""
    ),
    alumniNews: retLang("Alumni news", "Journal des Anciens Eleves", ""),
    publisher: retLang("Publisher", "Editeur", ""),
    author: retLang("Auther", "Auteur", ""),
    venue: retLang("Venue", "Lieu", ""),
    activity: retLang("Activity", "Activité", ""),
    website: retLang("Website", "Site web", ""),
    ourTeachers: retLang("Our teachers", "Nos enseignants", ""),
    whoWeAre: retLang("Who we are", "Qui sommes-nous", ""),
    generalInfo: retLang("General Info", "Infos Générales", ""),
    schoolHistory: retLang("School history", "Histoire de l'école", ""),
    historySch: retLang("Sch. History", "Histoire Eco.", ""),
    specializedProgramme: retLang(
      "Specialized programme",
      "Programme spécialisé",
      ""
    ),
    languageTrain: retLang(
      "Language training",
      "Specialisation en langues",
      ""
    ),
    team: retLang("Team", "Equipe", ""),
    adminTeam: retLang("Administrative team", "Équipe administrative", ""),
    seniorMgtTeam: retLang("Senior management team", "Équipe de direction", ""),
    contactUsTwitter: retLang(
      "Contact us on Twitter",
      "Nous contact sur Twitter",
      ""
    ),
    contactUsFb: retLang(
      "Contact us on Facebook",
      "Nous contact sur Facebook",
      ""
    ),
    contactUsInst: retLang(
      "Contact us on Instagram",
      "Nous contact sur Instagram",
      ""
    ),

    readYourInbox: retLang(
      "Reach your Inbox from here",
      "Accédez à votre boîte de réception à partir d'ici",
      ""
    ),
    contactUsLI: retLang(
      "Contact us on LinkedIn",
      "Nous contact sur LinkedIn",
      ""
    ),
    contactUsWh: retLang(
      "Contact us on WhatsApp",
      "Nous contact sur WhatsApp",
      ""
    ),
    abtSchool: retLang("About school", "À propos de l'école", ""),
    latestNews: retLang("Latest news", "Dernières nouvelles", ""),
    keepInTouch: retLang("Keep in touch", "Rester en contact", ""),
    placeUrCallsHere: retLang(
      "Place your calls here",
      "Passez vos appels ici",
      ""
    ),
    leaderExcell: retLang(
      "Leader of excellence in nursery, primary and secondary education. Try us and you will never try another school.",
      "Leader d'excellence dans l'enseignement maternel, primaire et secondaire. Essayez-nous et vous n'essayerez jamais une autre école.",
      ""
    ),
    askAbtKidsActs: retLang(
      "Find out about activities for children",
      "Découvrez les activités pour les enfants",
      ""
    ),
    userName: retLang("User name", "Nom utilisateur", ""),
    sendRequest: retLang("Send request", "Envoie de la demande", ""),
    sendRequestMsg: retLang(
      "Do you want to send this request to La colombière School?",
      "Voudriez-vous envoyer cette demande à l'école La Colombière?",
      ""
    ),
    yrReqAskKidsSent: retLang(
      "Your request has been sent. Thanks for contacting us.",
      "Votre requête a été envoyée. Merci de nous avoir contactés.",
      ""
    ),
    unsubscribe: retLang("Unsubscribe", "Se désabonner", ""),
    loadingData: retLang("Loading data", "Chargement de données", ""),
    ourLocation: retLang("Our Location", "Notre Emplacement", ""),
    techDpt: retLang("Tech department", "Département Technique", ""),
    administration: retLang("Administration", "Administration", ""),
    lookingWhat: retLang("Looking for what", "Vous cherchez", ""),
    ourSchNearPlace: retLang(
      "Our School is the Right Fit for Your Child.. We are Near Your Place!",
      "Notre école est la solution idéale pour votre enfant. Nous sommes près de chez vous!",
      ""
    ),
    subscribeHereEmail: retLang(
      "Subscribe here with your email",
      "Enregistrez-vous avec votre email",
      ""
    ),
    sendMessageMsg: retLang(
      "Do you want to send this message to La Colombière School?",
      "Voulez-vous envoyer ce message à l'école La Colombière?",
      ""
    ),
    thxContactUs: retLang(
      "Thank you for contacting us.",
      "Merci de nous avoir contacté.",
      ""
    ),

    recaptchaError: retLang(
      "There's a security error in your form.",
      "Il y a une erreur de sécurité dans votre formulaire.",
      ""
    ),
    secCheckError: retLang(
      "Captcha error. Try again",
      "Erreur CAPTCHA. Essayer à nouveau",
      ""
    ),
    userProfileImg: retLang(
      "User profile image",
      "Image du profile utilisateur",
      ""
    ),
    alumniAfterRegisterMsg: retLang(
      "Your registration was successful. Please wait, you will have confirmation of approval in your email box.",
      "Votre enregistrement s'est passé avec succès. Veuillez patienter, vous aurez la confirmation de l'approbation dans votre boîte email.",
      ""
    ),
    cookSetts: retLang("Cookies Settings", "Paramètres des cookies", ""),
    rejectAll: retLang("Reject All", "Tout rejeter", ""),
    acceptAll: retLang("Accept All", "Accepter tout", ""),
    privacyMsg: retLang(
      "By clicking “Accept All Cookies”, you agree to the storing of cookies on your device to enhance site navigation, analyze site usage, and assist in our marketing efforts. ",
      "En cliquant sur “Accepter tous les cookies”, vous acceptez le stockage de cookies sur votre appareil pour améliorer la navigation sur le site, analyser l'utilisation du site et nous aider dans nos efforts de marketing.",
      ""
    ),
    by: retLang("By", "Par", ""),
    published: retLang("Published", "Publié", ""),
    leaveCmt: retLang("Leave a comment", "Laissez un commentaire", ""),
    cmtEmailWarn: retLang(
      "Your email address will not be published. Required fields are marked *",
      "Votre adresse email ne sera pas publiée. Les champs requis sont indiqués *",
      ""
    ),
    reloadBlogs: retLang("Reload blogs", "Recharger les articles", ""),
    noBlogFound: retLang("No blogs found", "Aucun blog trouvé", ""),
    noBlogFoundMsgV2: retLang(
      '"Oops! It looks like there are no published blogs yet. Stay tuned for exciting updates and insightful content coming soon!"',
      `"Oups ! Il semble qu'il n'y ait pas encore de blogs publiés. Restez à l'écoute pour des mises à jour passionnantes et du contenu perspicace à venir !"`,
      ""
    ),
    in: retLang("In", "Dans", ""),
    noProdFoundExplore: retLang(
      `"We're sorry, but it seems we're currently out of stock on all products. Please check back soon as we're constantly updating our inventory. In the meantime, feel free to explore other sections of our website!"`,
      `"Nous sommes désolés, mais il semble que nous soyons actuellement en rupture de stock sur tous les produits. Veuillez revenir bientôt car nous mettons constamment à jour notre inventaire. En attendant, n'hésitez pas à explorer d'autres sections de notre site Web !"`,
      ``
    ),

    missingData: retLang("Missing data", "Données non trouvées", ""),
    missingDataSocEvtRec: retLang(
      "It appears that the selected social event no longer exists on our server. Try again.",
      "Il semble que l'événement social sélectionné n'existe plus sur notre serveur. Essayer à nouveau.",
      ""
    ),

    reloadScoEvts: retLang(
      "Reload social events",
      "Recharger les événements sociaux",
      ""
    ),
    noSocEvtsFound: retLang(
      "No social events found",
      "Aucun événement social trouvé",
      ""
    ),
    noSocEvtsFoundMsg: retLang(
      "No Alumni students have posted a social event yet. Come visit later.",
      "Aucun étudiant Alumni n'a encore publié d'événement social. Venez visiter plus tard.",
      ""
    ),

    founder_fem: retLang("Founder", "Fondatrice", ""),
    founder: retLang("Founder", "Fondateur", ""),
    ms: retLang("Ms.", "Mme", ""),
    founder_message_fem: retLang(
      "Founder message",
      "Message de la fondatrice",
      ""
    ),
    founder_message_content: retLang(
      "I envisioned a nurturing environment where every child can thrive academically, socially, and emotionally.",
      "J'ai imaginé un environnement stimulant où chaque enfant peut s'épanouir sur les plans scolaire, social et émotionnel.",
      ""
    ),
    founder_message_subcontent: retLang(
      "Our mission is to provide quality education that empowers students to reach their full potential and become lifelong learners. Together, we are committed to fostering a community of excellence, compassion, and innovation.",
      "Notre mission est de fournir une éducation de qualité qui permet aux étudiants d'atteindre leur plein potentiel et de devenir des apprenants tout au long de leur vie. Ensemble, nous nous engageons à favoriser une communauté d'excellence, de compassion et d’innovation.",
      ""
    ),

    principal_message_fem: retLang(
      "Principal message",
      "Message de la directrice",
      ""
    ),
    principal_fem: retLang("Principal", "Directrice", ""),
    principal: retLang("Principal", "Directeur", ""),
    principal_message_content: retLang(
      "I am dedicated to fostering a supportive and inclusive learning environment where every student can succeed.",
      "Je me consacre à favoriser un environnement d'apprentissage favorable et inclusif où chaque élève peut réussir.",
      ""
    ),
    principal_message_subcontent: retLang(
      "Our team of educators is committed to providing high-quality education that inspires curiosity, creativity, and critical thinking. Together with parents and the wider community, we strive to empower our students to become confident, compassionate, and lifelong learners.",
      "Notre équipe d'éducateurs s'engage à fournir une éducation de haute qualité qui inspire la curiosité, la créativité et la pensée critique. En collaboration avec les parents et la communauté au sens large, nous nous efforçons de donner à nos élèves les moyens de devenir des apprenants confiants, compatissants et permanents.",
      ""
    ),

    tempo_staff_Name: retLang("Tempo Name", "Nom Temporaire", ""),
    tempo_staff_Job: retLang("Tempo Job", "Travail Temporaire", ""),
    strengthOurTeam: retLang(
      "The Strength of Our Team",
      "La force de notre équipe",
      ""
    ),

    strengthTeam: retLang(
      "The strength of La Colombière School lies in the cohesive and dedicated team of educators who are passionate about nurturing young minds. With diverse expertise, innovative teaching methods, and a shared commitment to student success, they create a supportive learning environment where every child can thrive. Their collaboration, empathy, and tireless efforts ensure that each student receives personalized attention and opportunities for growth, laying the foundation for a lifetime of achievement.",
      "La force de l'École La Colombière réside dans l'équipe soudée et dévouée d'éducateurs passionnés par l'éducation des jeunes esprits. Grâce à une expertise diversifiée, des méthodes d'enseignement innovantes et un engagement commun envers la réussite des élèves, ils créent un environnement d'apprentissage favorable où chaque enfant peut s'épanouir. Leur collaboration, leur empathie et leurs efforts inlassables garantissent que chaque élève reçoit une attention personnalisée et des opportunités de croissance, jetant ainsi les bases d'une vie de réussite.",
      ""
    ),

    founder_message: retLang("Founder message", "Message du fondateur", ""),
    name: retLang("Name", "Nom", ""),
    postComment: retLang("Post comment", "Poster commentaire", ""),
    commentConsentText: retLang(
      "Save my name, email, and website in this browser for the next time I comment.",
      "Enregistrez mon nom, mon adresse e-mail et mon site Web dans ce navigateur pour la prochaine fois que je commenterai.",
      ""
    ),
    noBlogFoundMsg: retLang(
      "No Alumni have published a blog post yet. Come visit later.",
      "Aucun ancien étudiant n'a encore publié d'article de blog. Venez visiter plus tard.",
      ""
    ),
    clear: retLang("Clear", "Effacer", ""),
    allblogs: retLang("All blogs", "Tous les posts", ""),
    socialEvents: retLang("Social events", "Evénements sociaux", ""),
    displayAll: retLang("Display all", "Afficher tout", ""),
    alumniBlogs: retLang("Alumni blogs", "Blogs - Anciens élèves", ""),
    alumniMemberNoRegis: retLang(
      "Currently, there are no registered alumni members in our database. We encourage all former students to join our alumni network to stay connected and receive updates on upcoming events and reunions. Register today to reconnect with old classmates and stay engaged with our community!",
      "Actuellement, aucun ancien membre n’est enregistré dans notre base de données. Nous encourageons tous les anciens étudiants à rejoindre notre réseau d'anciens élèves pour rester connectés et recevoir des mises à jour sur les événements et réunions à venir. Inscrivez-vous aujourd'hui pour renouer avec d'anciens camarades de classe et rester engagé avec notre communauté !",
      ""
    ),
    tryAgain: retLang("Try again", "Essayez encore", ""),
    noRecFnd: retLang("No records Found", "Aucun enregistrement trouvé", ""),
    noNewsFound: retLang("No news found", "Aucune nouvelle publiée", ""),
    socialAccts: retLang("Social accounts", "Comptes sociaux", ""),
    fieldValue: retLang("Field value", "Valeur champs", ""),

    facebookAcct: retLang("Facebook account", "Compte Facebook", ""),
    twitterAcct: retLang("Twitter account", "Compte Twitter", ""),
    linkedInAcct: retLang("LinkedIn account", "Compte LinkedIn", ""),
    instagramAcct: retLang("Instagram account", "Compte Instagram", ""),
    loadAlumniMembers: retLang(
      "Loading alumni members",
      "Chargement des membres Alumni",
      ""
    ),
    noAlMembersFnd: retLang(
      "No Alumni members found",
      "Aucun membre Alumni n'a été trouvé.",
      ""
    ),
    unemployed: retLang("Unemployed", "Chaummeur.", ""),

    foodBev: retLang("Food & Beverages", "Nourriture et boissons", ""),
    schoolSupplStat: retLang(
      "School Supplies and Stationery",
      "Fournitures scolaires et papeterie",
      ""
    ),
    clothAccess: retLang(
      "Clothes & Styling Accessories",
      "Vêtements et accessoires de style",
      ""
    ),
    services: retLang("Services", "Services", ""),
    otherAweSome: retLang(
      "Other Awesome Things",
      "D'autres choses géniales",
      ""
    ),
    readMore: retLang("Read more", "En savoir plus", ""),
    funSchool: retLang("Fun in School", "Du plaisir à l'école", ""),
    creativeLearning: retLang("Creative Learning", "Apprentissage créatif", ""),
    creativeLessPlans: retLang(
      "Creative lesson plans",
      "Plans de cours créatifs",
      ""
    ),
    worksheetsCratfs: retLang(
      "1,000+ Worksheets & Craft Sheets",
      "Plus de 1 000 feuilles de travail et feuilles de bricolage",
      ""
    ),
    academTrKids: retLang(
      "School training for kids",
      "Formation scolaire pour les enfants",
      ""
    ),
    workshopCh: retLang(
      "Workshop for children",
      "Atelier pour les enfants",
      ""
    ),
    ourTeam: retLang("Our team", "Notre équipe", ""),
    kidsEnrollt: retLang("Students' Enrollment", "Inscription des élèves", ""),
    alumniClub: retLang("Alumni Club", "Club des anciens", ""),
    bestAmenities: retLang("Best Amenities", "Meilleurs équipements", ""),
    stepsToEnroll: retLang("Steps to Enroll", "Étapes pour s'inscrire", ""),

    classKind1: retLang("Kindergarten - 1", "Maternelle - 1", ""),
    classKind2: retLang("Kindergarten - 2", "Maternelle - 2", ""),
    classKind3: retLang("Kindergarten - 3", "Maternelle - 3", ""),
    classPrim1: retLang("Primary - 1", "Primaire - 1", ""),
    classPrim2: retLang("Primary - 2", "Primaire - 2", ""),
    classPrim3: retLang("Primary - 3", "Primaire - 3", ""),
    classPrim4: retLang("Primary - 4", "Primaire - 4", ""),
    classPrim5: retLang("Primary - 5", "Primaire - 5", ""),
    classPrim6: retLang("Primary - 6", "Primaire - 6", ""),

    faq: retLang(
      "Frequently Asked Questions?",
      "Questions fréquemment posées?",
      ""
    ),
    faqQstTitle1: retLang(
      "How do you manage to make sure that English and French are taught equally ?",
      "Comment parvenez-vous à garantir que l'anglais et le français soient enseignés de manière égale ?",
      ""
    ),
    faqQstMsg1: retLang(
      "The methodology used to teach both languages is well designed that it suits our students.",
      "La méthodologie utilisée pour enseigner les deux langues est bien conçue et convient à nos étudiants.",
      ""
    ),

    faqQstTitle2: retLang(
      "Is la Colombière School syllabus national or international ?",
      "Le programme de l'Ecole de la Colombière est-il national ou international ?",
      ""
    ),
    faqQstMsg2: retLang(
      "La Colombière School follows a national curriculum.",
      "L'école La Colombière suit un programme national.",
      ""
    ),

    faqQstTitle3: retLang(
      "What are the required documents during admission ?",
      "Quels sont les documents requis lors de l'admission ?",
      ""
    ),
    faqQstMsg3: retLang(
      "All the required documents can be found on the website",
      "Tous les documents requis sont disponibles sur le site Internet.",
      ""
    ),

    kids_care: retLang("Kids care", "Soins aux enfants", ""),
    kids_care_msg: retLang(
      "Our dedicated staff ensures each student feels valued, respected, and cared for, fostering a sense of belonging and promoting holistic development.",
      "Notre personnel dévoué veille à ce que chaque étudiant se sente valorisé, respecté et pris en charge, favorisant ainsi un sentiment d'appartenance et favorisant un développement holistique.",
      ""
    ),

    education: retLang("Education", "Education", ""),
    education_msg: retLang(
      "Through engaging curriculum and dedicated teachers, we empower students to explore their potential, develop essential skills, and cultivate a lifelong love of learning.",
      "Grâce à un programme d'études engageant et à des enseignants dévoués, nous permettons aux étudiants d'explorer leur potentiel, de développer des compétences essentielles et de cultiver un amour permanent de l'apprentissage.",
      ""
    ),

    internation_part: retLang("Partners", "Partenaires", ""),
    internation_part_msg: retLang(
      "We collaborate with esteemed partners in education to enrich our students' educational experience and promote global understanding.",
      "Nous collaborons avec des partenaires estimés dans le domaine de l'éducation pour enrichir l'expérience éducative de nos étudiants et promouvoir la compréhension mondiale.",
      ""
    ),

    shool_exc: retLang("School of excellence", "Ecole d'excellence", ""),
    shool_exc_msg: retLang(
      "Our primary school of excellence is committed to providing a transformative educational experience that nurtures academic excellence, fosters creativity, and cultivates character development. With innovative teaching methods, dedicated staff, and a supportive community, we empower students to achieve their full potential and become lifelong learners and leaders.",
      "Notre école primaire d'excellence s'engage à offrir une expérience éducative transformatrice qui nourrit l'excellence académique, favorise la créativité et cultive le développement du caractère. Grâce à des méthodes d'enseignement innovantes, un personnel dévoué et une communauté solidaire, nous donnons aux étudiants les moyens de réaliser leur plein potentiel et de devenir des apprenants et des leaders tout au long de leur vie.",
      ""
    ),

    shool_exc_opt_1: retLang(
      "We consistently demonstrate high academic standards and achievement, ensuring students receive quality education across all subjects.",
      "Nous démontrons constamment des normes académiques et des résultats élevés, garantissant que les étudiants reçoivent une éducation de qualité dans toutes les matières.",
      ""
    ),

    shool_exc_opt_2: retLang(
      "We prioritize the holistic development of students, nurturing their intellectual, emotional, social, and physical well-being through comprehensive programs and support services.",
      "Nous accordons la priorité au développement holistique des étudiants, en favorisant leur bien-être intellectuel, émotionnel, social et physique grâce à des programmes complets et des services de soutien.",
      ""
    ),

    shool_exc_opt_3: retLang(
      "We foster strong partnerships between teachers, students, parents, and the wider community, creating a supportive environment conducive to student success and growth.",
      "Nous favorisons des partenariats solides entre les enseignants, les élèves, les parents et la communauté au sens large, créant un environnement favorable à la réussite et à la croissance des élèves.",
      ""
    ),

    multimedia_room: retLang("Multimedia room", "Salle multimédia", ""),
    multimedia_room_msg: retLang(
      "Our multimedia room is a dynamic space equipped with state-of-the-art technology, offering students a stimulating environment for interactive learning experiences. With access to computers, digital resources, and audiovisual tools, students engage in collaborative projects, research activities, and multimedia presentations, enhancing their digital literacy skills and enriching their educational journey.",
      "Notre salle multimédia est un espace dynamique doté d'une technologie de pointe, offrant aux étudiants un environnement stimulant pour des expériences d'apprentissage interactives. Avec l'accès aux ordinateurs, aux ressources numériques et aux outils audiovisuels, les étudiants s'engagent dans des projets collaboratifs, des activités de recherche et des présentations multimédias, améliorant ainsi leurs compétences en littératie numérique et enrichissant leur parcours éducatif.",
      ""
    ),

    multimedia_room_long: retLang(
      "La Colombière's multimedia room is a vibrant and interactive space tailored to the educational needs of primary school students. Equipped with cutting-edge technology, including computers, interactive whiteboards, and multimedia resources, it provides a dynamic environment for immersive learning experiences. With colorful décor and comfortable seating, the room inspires creativity and collaboration, empowering students to explore various subjects through engaging multimedia presentations, digital projects, and interactive educational games. From virtual field trips to multimedia storytelling, La Colombière's multimedia room enriches the curriculum, fosters digital literacy skills, and ignites a passion for learning in young minds.",
      "La salle multimédia de La Colombière est un espace vivant et interactif adapté aux besoins pédagogiques des élèves du primaire. Équipé d'une technologie de pointe, notamment d'ordinateurs, de tableaux blancs interactifs et de ressources multimédias, il offre un environnement dynamique pour des expériences d'apprentissage immersives. Avec un décor coloré et des sièges confortables, la salle inspire la créativité et la collaboration, permettant aux étudiants d'explorer divers sujets à travers des présentations multimédias captivantes, des projets numériques et des jeux éducatifs interactifs. Des excursions virtuelles à la narration multimédia, la salle multimédia de La Colombière enrichit le programme, favorise les compétences en littératie numérique et suscite la passion pour l'apprentissage chez les jeunes esprits.",
      ""
    ),

    multimediOne: retLang(
      `<strong>Interactive Learning</strong>: A multimedia room in a primary school offers interactive learning experiences through technology such as interactive whiteboards, educational software, and multimedia resources. This fosters engagement and active participation among students, making learning more enjoyable and effective.`,
      `<strong>Apprentissage interactif</strong>: une salle multimédia dans une école primaire propose des expériences d'apprentissage interactives grâce à des technologies telles que des tableaux blancs interactifs, des logiciels éducatifs et des ressources multimédias. Cela favorise l’engagement et la participation active des étudiants, rendant l’apprentissage plus agréable et plus efficace.`,
      ``
    ),
    multimediTwo: retLang(
      `<strong>Versatility</strong>: A primary school multimedia room is versatile, accommodating a wide range of educational activities across different subjects and learning styles. It can be used for multimedia presentations, digital projects, research, virtual field trips, and collaborative learning exercises, allowing teachers to integrate technology seamlessly into the curriculum.`,
      `<strong>Polyvalence</strong>: une salle multimédia d'école primaire est polyvalente et peut accueillir un large éventail d'activités éducatives dans différentes matières et styles d'apprentissage. Il peut être utilisé pour des présentations multimédias, des projets numériques, des recherches, des sorties virtuelles sur le terrain et des exercices d'apprentissage collaboratif, permettant ainsi aux enseignants d'intégrer la technologie de manière transparente dans le programme.`,
      ``
    ),
    multimediThree: retLang(
      `<strong>Creativity Enhancement</strong>: The multimedia room stimulates creativity and innovation among students by providing them with tools and resources to express themselves in diverse ways. From multimedia storytelling to digital art projects, students can explore their interests, experiment with different mediums, and showcase their talents, fostering a sense of self-expression and confidence in their abilities.`,
      `<strong>Amélioration de la créativité</strong>: La salle multimédia stimule la créativité et l'innovation chez les étudiants en leur fournissant des outils et des ressources pour s'exprimer de diverses manières. De la narration multimédia aux projets d'art numérique, les étudiants peuvent explorer leurs intérêts, expérimenter différents médiums et mettre en valeur leurs talents, favorisant ainsi un sentiment d'expression personnelle et une confiance en leurs capacités.`,
      ``
    ),

    lablib_room: retLang("Lab & Library", "Labo & Bibliothèque", ""),
    lablib_room_msg: retLang(
      "Our primary school boasts a well-equipped lab and a serene library room, fostering a culture of exploration and literacy. The lab room invites young learners to dive into the wonders of science through hands-on experiments and practical learning, while the library provides a quiet haven filled with diverse books and resources, encouraging a love for reading and independent research. Together, these spaces support our students' academic growth and curiosity.",
      "Notre école primaire dispose d'un laboratoire bien équipé et d'une bibliothèque sereine, favorisant une culture d'exploration et d'alphabétisation. La salle de laboratoire invite les jeunes apprenants à plonger dans les merveilles de la science à travers des expériences pratiques et un apprentissage pratique, tandis que la bibliothèque constitue un havre de paix rempli de livres et de ressources divers, encourageant l'amour de la lecture et de la recherche indépendante. Ensemble, ces espaces soutiennent la croissance académique et la curiosité de nos étudiants.",
      ""
    ),

    meeting_hall: retLang("Meeting hall", "Salle de réunions", ""),
    meeting_hall_msg: retLang(
      "Our  meeting hall serves as a versatile space where the school community comes together for assemblies, performances, and events. Equipped with modern audio-visual technology and comfortable seating, it fosters a sense of unity and engagement, providing a venue for students, teachers, and parents to collaborate, celebrate achievements, and participate in a variety of educational activities.",
      "La salle de réunion sert d'espace polyvalent où la communauté scolaire se réunit pour des assemblées, des spectacles et des événements. Équipé d'une technologie audiovisuelle moderne et de sièges confortables, il favorise un sentiment d'unité et d'engagement, offrant un lieu permettant aux étudiants, aux enseignants et aux parents de collaborer, de célébrer leurs réalisations et de participer à une variété d'activités éducatives.",
      ""
    ),

    playgrounds: retLang("Playground", "Cours de récréation", ""),
    playgrounds_msg: retLang(
      "Our playground is vibrant and safe outdoor spaces designed to promote physical activity, social interaction, and the overall well-being of our students. Featuring a variety of play equipment and designated areas for different age groups, they offer endless opportunities for children to explore, develop motor skills, and build friendships in a fun and nurturing environment.",
      "Notre terrain de jeu est constitué d'espaces extérieurs dynamiques et sûrs conçus pour promouvoir l'activité physique, l'interaction sociale et le bien-être général de nos élèves. Dotés d'une variété d'équipements de jeu et d'espaces désignés pour différents groupes d'âge, ils offrent aux enfants des possibilités infinies d'explorer, de développer leurs capacités motrices et de nouer des amitiés dans un environnement amusant et stimulant.",
      ""
    ),

    sports_club: retLang("Sports club", "Club de sport", ""),
    sports_club_msg: retLang(
      "Our sports club provides an inclusive and supportive environment for students to develop their athletic abilities, teamwork skills, and sportsmanship. Through a range of organized activities and coached sessions, students have the opportunity to explore different sports, stay active, and foster a lifelong love for physical fitness and healthy competition.",
      "Notre club sportif offre un environnement inclusif et favorable aux étudiants pour développer leurs capacités athlétiques, leurs compétences en matière de travail d'équipe et leur esprit sportif. Grâce à une gamme d'activités organisées et de séances encadrées, les étudiants ont la possibilité d'explorer différents sports, de rester actifs et de cultiver un amour permanent pour la forme physique et la compétition saine.",
      ""
    ),

    brights_class: retLang("Bright Classroom", "Salle de classe moderne", ""),
    brights_class_msg: retLang(
      "In our modern classrooms, innovative teaching methods meet state-of-the-art technology to create dynamic learning environments. With flexible seating arrangements, and access to digital resources, students are empowered to engage deeply with curriculum content, collaborate with peers, and develop essential 21st-century skills in preparation for the challenges of tomorrow's world.",
      "Dans nos salles de classe modernes, les méthodes d'enseignement innovantes rencontrent la technologie de pointe pour créer des environnements d’apprentissage dynamiques. Grâce à une disposition flexible des sièges et à l'accès aux ressources numériques, les étudiants ont la possibilité de s'impliquer profondément dans le contenu du programme, de collaborer avec leurs pairs et de développer des compétences essentielles du 21e siècle en vue de se préparer aux défis du monde de demain.",
      ""
    ),

    outdoorPhysicalEd: retLang(
      "Outdoor and physical education in the onsite sports premises.",
      "Éducation physique et en plein air dans les installations sportives sur place.",
      ""
    ),

    outdoorPhysicalEdMsg: retLang(
      "At our school, outdoor activities are an integral part of the curriculum, providing students with opportunities to explore, play, and learn in nature. From organized sports and gardening to nature walks and team-building exercises, these activities promote physical fitness, social interaction, and environmental awareness, enriching the educational experience and fostering a lifelong appreciation for the outdoors.",
      "Dans notre école, les activités de plein air font partie intégrante du programme, offrant aux élèves la possibilité d'explorer, de jouer et d'apprendre dans la nature. Des sports organisés et du jardinage aux promenades dans la nature et aux exercices de consolidation d'équipe, ces activités favorisent la forme physique, l'interaction sociale et la sensibilisation à l'environnement, enrichissant l'expérience éducative et favorisant une appréciation permanente du plein air.",
      ""
    ),

    language_skills: retLang(
      "Language skills",
      "Compétences linguistiques",
      ""
    ),
    daf_financial_officer: retLang(
      "Director of Administration and Finance",
      "Directrice de l'Administration et des Finances",
      ""
    ),
    financial_officer: retLang("Financial Officer", "Chargée des Finances", ""),
    public_relations: retLang(
      "Public Relations Officer",
      "Chargé des Rélations publiques",
      ""
    ),
    prefet: retLang("Prefect of Studies", "Prefet des études", ""),
    certificate: retLang("Certificate", "Certificat", ""),
    vision_mission: retLang("Vision & Mission", "Vision & Mission", ""),
    curr_certificate: retLang(
      "At the culmination of our primary school curriculum, students emerge as confident learners equipped with a solid foundation in essential subjects such as language arts, mathematics, science, and social studies. Our comprehensive program instills critical thinking skills, fosters creativity, and prepares students for the challenges of further education and beyond.",
      "Au point culminant de notre programme d'études primaires, les élèves émergent comme des apprenants confiants, dotés de bases solides dans des matières essentielles telles que les arts du langage, les mathématiques, les sciences et les études sociales. Notre programme complet inculque des compétences de pensée critique, favorise la créativité et prépare les étudiants aux défis de la poursuite des études et au-delà.",
      ""
    ),
    teacher: retLang("Teacher", "Professeur/Enseignant", ""),

    curr_staff: retLang(
      "At our school, our dedicated staff comprises experienced educators who are passionate about nurturing young minds. With expertise in diverse teaching methodologies and a commitment to student success, they create a supportive learning environment where every child can thrive academically, socially, and emotionally.",
      "Dans notre école, notre personnel dévoué est composé d'éducateurs expérimentés et passionnés par l'éducation des jeunes esprits. Grâce à leur expertise dans diverses méthodologies d'enseignement et à leur engagement envers la réussite des élèves, ils créent un environnement d'apprentissage favorable où chaque enfant peut s'épanouir sur les plans scolaire, social et émotionnel.",
      ""
    ),

    curr_lang_skills: retLang(
      "Our primary school prioritizes language skills development by offering a dynamic curriculum that cultivates proficiency in reading, writing, listening, and speaking. Through engaging activities and personalized instruction, students not only acquire fluency in their native language but also gain appreciation for other languages (french and english) and cultures, fostering communication abilities essential for success in a globalized world.",
      "Notre école primaire donne la priorité au développement des compétences linguistiques en proposant un programme dynamique qui cultive la maîtrise de la lecture, de l'écriture, de l'écoute et de l'expression orale. Grâce à des activités engageantes et à un enseignement personnalisé, les étudiants acquièrent non seulement la maîtrise de leur langue maternelle, mais apprennent également à apprécier d'autres langues (français et anglais) et cultures, favorisant ainsi les capacités de communication essentielles au succès dans un monde globalisé.",
      ""
    ),

    studentPassed: retLang("Students passed", "Étudiants ayant réussi", ""),
    quickPrimarySchoolTitle: retLang(
      "We offer a High Quality National Curriculum Program, Activities, Sports and Academics.",
      "Nous proposons un programme d'études national, des activités, des sports et des universitaires de haute qualité.",
      ""
    ),
    aboutUsMsgShort: retLang(
      "At our primary school, we foster a nurturing environment where every child is encouraged to explore, learn, and grow. With dedicated teachers and engaging curriculum, we strive to inspire curiosity and instill a lifelong love of learning in our students.",
      "Dans notre école primaire, nous favorisons un environnement stimulant où chaque enfant est encouragé à explorer, apprendre et grandir. Avec des enseignants dévoués et un programme engageant, nous nous efforçons d’inspirer la curiosité et d'inculquer à nos étudiants un amour permanent de l'apprentissage.",
      ""
    ),

    quickPrimarySchoolDesc: retLang(
      "The national primary school curriculum provides a comprehensive framework for foundational education, encompassing essential subjects such as language arts, mathematics, science, and social studies. Designed to meet educational standards and promote holistic development, it equips students with fundamental knowledge and skills essential for their academic journey and future success.",
      "Le programme national de l'école primaire fournit un cadre complet pour l'éducation de base, englobant des matières essentielles telles que les arts du langage, les mathématiques, les sciences et les études sociales. Conçu pour répondre aux normes éducatives et promouvoir un développement holistique, il dote les étudiants de connaissances et de compétences fondamentales essentielles à leur parcours académique et à leur réussite future.",
      ""
    ),

    labLibrary: retLang("Lab & Library", "Labo. & Bibliothèque", ""),
    labLibraryLongMsg: retLang(
      "La Colombière's Lab & Library room is a dynamic space designed to ignite curiosity, foster exploration, and promote a love for learning among primary school students. Divided into two distinct sections, the Lab area is equipped with age-appropriate scientific equipment, interactive displays, and experiment stations, providing students with hands-on opportunities to engage in scientific inquiry and discovery. Meanwhile, the Library section offers a tranquil environment filled with an extensive collection of books, magazines, and digital resources, inviting students to immerse themselves in literature, conduct research, and cultivate a lifelong passion for reading and knowledge acquisition. With its blend of experiential learning and literary enrichment, La Colombière's Lab & Library room serves as a hub of intellectual curiosity and academic excellence, empowering students to explore their interests, expand their horizons, and thrive in their educational journey.",
      "La salle Lab & Bibliothèque de La Colombière est un espace dynamique conçu pour attiser la curiosité, favoriser l'exploration et promouvoir l'amour de l'apprentissage chez les élèves du primaire. Divisé en deux sections distinctes, l'espace laboratoire est équipé d'équipements scientifiques adaptés à l'âge, d'écrans interactifs et de stations d'expérimentation, offrant aux étudiants des opportunités pratiques de s'engager dans des recherches et des découvertes scientifiques. Pendant ce temps, la section Bibliothèque offre un environnement tranquille rempli d'une vaste collection de livres, de magazines et de ressources numériques, invitant les étudiants à se plonger dans la littérature, à mener des recherches et à cultiver une passion permanente pour la lecture et l'acquisition de connaissances. Avec son mélange d'apprentissage expérientiel et d'enrichissement littéraire, la salle Lab & Library de La Colombière sert de centre de curiosité intellectuelle et d'excellence académique, permettant aux étudiants d'explorer leurs intérêts, d'élargir leurs horizons et de s'épanouir dans leur parcours éducatif.",
      ""
    ),

    labLibraryChar1: retLang(
      `<strong>Versatility</strong>: A Lab & Library room in a primary school is a versatile space that accommodates both scientific exploration and literary enrichment. It offers opportunities for hands-on experimentation and research in the lab area, as well as quiet reading and study in the library section.`,
      `<strong>Polyvalence</strong>: une salle de laboratoire et de bibliothèque dans une école primaire est un espace polyvalent qui peut accueillir à la fois l'exploration scientifique et l'enrichissement littéraire. Il offre des possibilités d'expérimentation et de recherche pratiques dans le laboratoire, ainsi que de lecture et d'étude tranquilles dans la section bibliothèque.`,
      ``
    ),

    labLibraryChar2: retLang(
      `<strong>Resources and Equipment</strong>: The Lab & Library room is equipped with a wide range of resources and tools to support learning across various subjects. From scientific instruments and experiment kits to an extensive collection of books, magazines, and digital materials, students have access to resources that cater to diverse interests and learning styles.`,
      `<strong>Ressources et équipement</strong>: La salle Labo & Bibliothèque est équipée d'un large éventail de ressources et d'outils pour soutenir l'apprentissage dans diverses matières. Des instruments scientifiques et kits d'expérimentation à une vaste collection de livres, magazines et matériels numériques, les étudiants ont accès à des ressources qui répondent à divers intérêts et styles d'apprentissage.`,
      ``
    ),

    labLibraryChar3: retLang(
      `<strong>Multifunctional Learning Environment</strong>: The Lab & Library room provides a multifunctional learning environment that promotes interdisciplinary learning and collaboration. Students can seamlessly transition between conducting experiments, gathering information from books or online resources, and engaging in group discussions, fostering holistic development and critical thinking skills.`,
      `<strong>Environnement d'apprentissage multifonctionnel</strong>: la salle Labo & Bibliothèque offre un environnement d'apprentissage multifonctionnel qui favorise l'apprentissage interdisciplinaire et la collaboration. Les étudiants peuvent passer en toute transparence de la réalisation d'expériences à la collecte d'informations à partir de livres ou de ressources en ligne et à la participation à des discussions de groupe, favorisant ainsi le développement holistique et la pensée critique.`,
      ``
    ),

    captchaError: retLang(
      "Your form security captcha failded.",
      "Le captcha de sécurité de votre formulaire a échoué.",
      ""
    ),
    qualifiedStaff: retLang("Qualified staff", "Personnel qualifié", ""),
    courses: retLang("Courses", "Cours", ""),
    amenities: retLang("Amenities", "Installations", ""),
    loadingBlogs: retLang("Loading blogs", "Chargement des blogs", ""),
    apprenMsg: retLang(
      "The learner's perception of his emotions and the physiological manifestations that result from them have a very strong influence on learning and academic success.",
      "La perception que l'apprenant a de ses émotions et des manifestations physiologiques qui en découlent, a une influence très forte sur l'apprentissage et la réussite scolaire.",
      ""
    ),

    meetingHall: retLang("Meeting Hall", "Salle de réunion", ""),
    meetingHallLongMsg: retLang(
      "La Colombière's meeting hall is a spacious and inviting venue designed to facilitate gatherings, assemblies, performances, and community events within the primary school. With its adaptable layout, modern audiovisual equipment, and comfortable seating arrangements, the hall serves as a hub for fostering communication, collaboration, and celebration among students, teachers, and parents alike. Whether hosting school-wide assemblies, award ceremonies, or cultural performances, La Colombière's meeting hall provides a welcoming space where the school community can come together to share achievements, showcase talents, and strengthen bonds in an atmosphere of unity and support.",
      "La salle de réunion de La Colombière est un lieu spacieux et accueillant conçu pour faciliter les rassemblements, les assemblées, les spectacles et les événements communautaires au sein de l'école primaire. Avec son agencement adaptable, son équipement audiovisuel moderne et sa disposition des sièges confortables, la salle sert de plaque tournante pour favoriser la communication, la collaboration et la célébration entre les étudiants, les enseignants et les parents. Qu'il s'agisse d'assemblées scolaires, de cérémonies de remise de prix ou de spectacles culturels, la salle de réunion de La Colombière offre un espace accueillant où la communauté scolaire peut se réunir pour partager ses réalisations, mettre en valeur ses talents et renforcer ses liens dans une atmosphère d'unité et de soutien.",
      ""
    ),

    meetingHallChar1: retLang(
      `<strong>Versatility</strong>: A meeting hall room in a primary school is a versatile space that can accommodate a wide range of events and activities, including assemblies, performances, meetings, and celebrations. Its flexible layout and amenities make it suitable for various purposes, allowing the school community to utilize the space for diverse functions.`,
      `<strong>Polyvalence</strong>: Une salle de réunion dans une école primaire est un espace polyvalent pouvant accueillir un large éventail d’événements et d’activités, notamment des assemblées, des spectacles, des réunions et des célébrations. Son agencement flexible et ses équipements le rendent adapté à diverses fins, permettant à la communauté scolaire d'utiliser l'espace pour diverses fonctions.`,
      ``
    ),

    meetingHallChar2: retLang(
      `<strong>Community Engagement</strong>: The meeting hall serves as a central gathering place where students, teachers, parents, and staff can come together to interact, communicate, and collaborate. It fosters a sense of belonging and unity within the school community, providing opportunities for shared experiences and collective involvement in school events and initiatives.`,
      `<strong>Engagement communautaire</strong>: La salle de réunion sert de lieu de rassemblement central où les étudiants, les enseignants, les parents et le personnel peuvent se réunir pour interagir, communiquer et collaborer. Il favorise un sentiment d'appartenance et d'unité au sein de la communauté scolaire, offrant des opportunités de partage d'expériences et d'implication collective dans les événements et initiatives scolaires.`,
      ``
    ),

    meetingHallChar3: retLang(
      `<strong>Celebration of Achievements</strong>: The meeting hall provides a platform for celebrating student achievements, whether academic, artistic, or athletic. From award ceremonies to talent shows, it allows students to showcase their talents and accomplishments in front of their peers, teachers, and families, promoting recognition, encouragement, and pride in their accomplishments.`,
      `<strong>Célébration des réalisations</strong>: La salle de réunion offre une plate-forme pour célébrer les réalisations des étudiants, qu'elles soient académiques, artistiques ou sportives. Des cérémonies de remise de prix aux spectacles de talents, il permet aux étudiants de présenter leurs talents et leurs réalisations devant leurs pairs, leurs enseignants et leurs familles, favorisant ainsi la reconnaissance, l'encouragement et la fierté de leurs réalisations.`,
      ``
    ),

    multimedia: retLang("Multimedia", "Multimedia", ""),
    secretaire: retLang("Management Assistant", "Secrétaire", ""),
    bibliothecaire: retLang("Librarian", "Bibliothécaire", ""),
    comptable: retLang("Accountant", "Comptable", ""),
    depDirectorSt: retLang(
      "Deputy Director/Studies",
      "Directeur Adjoint/Etudes",
      ""
    ),
    depDirector: retLang("Deputy Director", "Directeur Adjoint", ""),

    playground: retLang("Playgrounds", "Cours de récréation", ""),
    playgroundLongMsg: retLang(
      "La Colombière's playground is a vibrant and inclusive outdoor space designed to inspire imagination, promote physical activity, and foster social interaction among primary school students. With its colorful play structures, safety-approved equipment, and spacious layout, the playground offers endless opportunities for fun and exploration. From climbing structures and swings to slides and interactive games, students can engage in active play, develop gross motor skills, and build friendships in a safe and supervised environment. Surrounded by lush greenery and equipped with shaded areas, benches, and water stations, La Colombière's playground provides a welcoming and refreshing retreat where students can unwind, recharge, and enjoy outdoor recreation during breaks and recess.",
      "Le terrain de jeu de La Colombière est un espace extérieur dynamique et inclusif conçu pour inspirer l'imagination, promouvoir l'activité physique et favoriser les interactions sociales entre les élèves du primaire. Avec ses structures de jeu colorées, ses équipements approuvés en matière de sécurité et son aménagement spacieux, l'aire de jeux offre des possibilités infinies de plaisir et d'exploration. Des structures d'escalade aux balançoires en passant par les toboggans et les jeux interactifs, les élèves peuvent participer à des jeux actifs, développer leur motricité globale et nouer des amitiés dans un environnement sûr et supervisé. Entourée d'une verdure luxuriante et équipée d'espaces ombragés, de bancs et de points d'eau, l'aire de jeux de La Colombière offre un refuge accueillant et rafraîchissant où les étudiants peuvent se détendre, se ressourcer et profiter des loisirs de plein air pendant les pauses et les récréations.",
      ""
    ),
    playgroundChar1: retLang(
      `<strong>Safety Features</strong>: A playground room in a primary school prioritizes the safety of students by incorporating appropriate safety features such as soft flooring, age-appropriate equipment, and sturdy structures. This ensures that children can engage in play activities without unnecessary risk of injury.`,
      `<strong>Sécurité</strong>: Une salle de jeux dans une école primaire donne la priorité à la sécurité des élèves en intégrant des caractéristiques de sécurité appropriées telles qu'un revêtement de sol souple, un équipement adapté à l'âge et des structures robustes. Cela garantit que les enfants peuvent participer à des activités ludiques sans risque inutile de blessure.`,
      ``
    ),
    playgroundChar2: retLang(
      `<strong>Stimulating Environment</strong>: The playground room provides a stimulating environment that encourages physical activity, creativity, and social interaction. With diverse play equipment, sensory experiences, and interactive elements, students have the opportunity to explore, experiment, and engage in imaginative play.`,
      `<strong>Environnement stimulant</strong>: La salle de jeux offre un environnement stimulant qui encourage l'activité physique, la créativité et l'interaction sociale. Grâce à divers équipements de jeu, expériences sensorielles et éléments interactifs, les élèves ont la possibilité d'explorer, d'expérimenter et de s'engager dans des jeux imaginatifs.`,
      ``
    ),
    playgroundChar3: retLang(
      `<strong>Developmental Benefits</strong>: A well-designed playground room offers developmental benefits for children, including the development of gross motor skills, coordination, and social skills. Through active play and collaboration with peers, students learn important life skills such as teamwork, communication, and problem-solving in a fun and engaging setting.`,
      `<strong>Avantages pour le développement</strong>: Une salle de jeux bien conçue offre des avantages pour le développement des enfants, notamment le développement de la motricité globale, de la coordination et des compétences sociales. Grâce au jeu actif et à la collaboration avec leurs pairs, les élèves acquièrent des compétences essentielles telles que le travail d'équipe, la communication et la résolution de problèmes dans un cadre amusant et engageant.`,
      ``
    ),
    sportsClub: retLang("Sports Club", "Club de sport", ""),
    sportsClubLongMsg: retLang(
      "La Colombière's sports club is a dynamic and inclusive program designed to promote physical fitness, teamwork, and sportsmanship among primary school students. Led by experienced coaches and instructors, the club offers a diverse range of sports activities and training sessions tailored to the interests and abilities of students. From traditional team sports like soccer and basketball to individual activities such as track and field, the sports club provides opportunities for students to develop their athletic skills, build confidence, and cultivate a healthy and active lifestyle. With its emphasis on participation, skill development, and friendly competition, La Colombière's sports club fosters a sense of camaraderie and belonging, encouraging students to excel both on and off the field.",
      "Le club sportif de La Colombière est un programme dynamique et inclusif visant à promouvoir la condition physique, le travail d'équipe et l'esprit sportif chez les élèves du primaire. Dirigé par des entraîneurs et des instructeurs expérimentés, le club propose une gamme diversifiée d'activités sportives et de séances de formation adaptées aux intérêts et aux capacités des étudiants. Des sports d'équipe traditionnels comme le football et le basket-ball aux activités individuelles comme l'athlétisme, le club sportif offre aux étudiants la possibilité de développer leurs compétences athlétiques, de renforcer leur confiance en eux et de cultiver un mode de vie sain et actif. En mettant l'accent sur la participation, le développement des compétences et la compétition amicale, le club sportif de La Colombière favorise un sentiment de camaraderie et d'appartenance, encourageant les étudiants à exceller sur et hors du terrain.",
      ""
    ),

    sportsClubChar1: retLang(
      `<strong>Inclusive Environment</strong>: A sports club room in a primary school promotes inclusivity by welcoming students of all skill levels and backgrounds to participate in sports activities. It fosters a supportive and encouraging atmosphere where every student feels valued and included, regardless of their athletic ability.`,
      `<strong>Environnement inclusif</strong>: Une salle de club sportif dans une école primaire favorise l’inclusion en accueillant des élèves de tous niveaux et de tous horizons pour participer à des activités sportives. Il favorise une atmosphère de soutien et d'encouragement dans laquelle chaque élève se sent valorisé et inclus, quelle que soit sa capacité sportive.`,
      ``
    ),

    sportsClubChar2: retLang(
      `<strong>Skill Development</strong>: The sports club room provides opportunities for students to develop their physical fitness, motor skills, and sportsmanship through structured training sessions and organized sports activities. Coaches and instructors offer guidance and instruction to help students improve their techniques and achieve their personal best.`,
      `<strong>Développement des compétences</strong>: La salle du club sportif offre aux étudiants la possibilité de développer leur forme physique, leur motricité et leur esprit sportif grâce à des séances d'entraînement structurées et des activités sportives organisées. Les entraîneurs et les instructeurs offrent des conseils et des instructions pour aider les étudiants à améliorer leurs techniques et à atteindre leur meilleur niveau personnel.`,
      ``
    ),

    sportsClubChar3: retLang(
      `<strong>Team Building</strong>: Through team sports and group activities, the sports club room facilitates the development of teamwork, cooperation, and communication skills among students. Team-building exercises and collaborative games promote camaraderie and solidarity, fostering strong bonds and friendships within the school community.`,
      `<strong>Travail d'équipe</strong>: Grâce à des sports d'équipe et à des activités de groupe, la salle du club sportif facilite le développement du travail d'équipe, de la coopération et des compétences de communication entre les étudiants. Les exercices de consolidation d'équipe et les jeux collaboratifs favorisent la camaraderie et la solidarité, favorisant des liens et des amitiés solides au sein de la communauté scolaire.`,
      ``
    ),

    modernClassroom: retLang(
      "Modern class room",
      "Salle de classe moderne",
      ""
    ),
    modernClassroomLongMsg: retLang(
      "La Colombière's modern classroom is a dynamic learning space designed to inspire creativity, collaboration, and critical thinking among primary school students. Equipped with state-of-the-art technology, ergonomic furniture, and flexible seating arrangements, the classroom offers a comfortable and stimulating environment for engaging in diverse learning activities. Some customised tools enhance lessons and promote interactive learning experiences, while versatile seating options accommodate different teaching methods and student preferences. With its emphasis on innovation, adaptability, and student-centered learning, La Colombière's modern classroom prepares students to thrive in a rapidly evolving world.",
      "La salle de classe moderne de La Colombière est un espace d'apprentissage dynamique conçu pour inspirer la créativité, la collaboration et la pensée critique chez les élèves du primaire. Équipée d'une technologie de pointe, d'un mobilier ergonomique et d'une disposition flexible des sièges, la salle de classe offre un environnement confortable et stimulant pour participer à diverses activités d'apprentissage. Certains outils personnalisés améliorent les cours et favorisent les expériences d'apprentissage interactives, tandis que les options de sièges polyvalentes s'adaptent aux différentes méthodes d'enseignement et préférences des étudiants. En mettant l'accent sur l'innovation, l'adaptabilité et l'apprentissage centré sur l'étudiant, la salle de classe moderne de La Colombière prépare les étudiants à s'épanouir dans un monde en évolution rapide.",
      ""
    ),
    modernClassroomShar1: retLang(
      `<strong>Technology Integration</strong>: A modern classroom in a primary school seamlessly integrates technology into the learning environment, providing students with access to digital devices, interactive whiteboards, and educational software. This allows for engaging multimedia presentations, interactive lessons, and personalized learning experiences that cater to diverse learning styles and preferences.`,
      `<strong>Intégration de la technologie</strong>: une salle de classe moderne dans une école primaire intègre de manière transparente la technologie dans l'environnement d'apprentissage, offrant aux élèves un accès à des appareils numériques, des tableaux blancs interactifs et des logiciels éducatifs. Cela permet des présentations multimédias attrayantes, des leçons interactives et des expériences d'apprentissage personnalisées qui répondent à divers styles et préférences d'apprentissage.`,
      ``
    ),
    modernClassroomShar2: retLang(
      `<strong>Flexible Learning Spaces</strong>: A modern classroom features flexible seating arrangements and adaptable learning spaces that accommodate different teaching methods and student needs. Versatile furniture, such as adjustable desks and collaborative seating options, enables students to work individually, in small groups, or as a whole class, fostering collaboration, creativity, and independent learning.`,
      `<strong>Espaces d'apprentissage flexibles</strong>: une salle de classe moderne dispose d'une disposition flexible des sièges et d'espaces d'apprentissage adaptables qui s'adaptent aux différentes méthodes d'enseignement et aux besoins des élèves. Des meubles polyvalents, tels que des bureaux réglables et des options de sièges collaboratifs, permettent aux étudiants de travailler individuellement, en petits groupes ou en classe entière, favorisant ainsi la collaboration, la créativité et l'apprentissage indépendant.`,
      ``
    ),
    modernClassroomShar3: retLang(
      `<strong>Student-Centered Approach</strong>: A modern classroom prioritizes student engagement, empowerment, and agency in the learning process. Teachers adopt a student-centered approach that emphasizes inquiry-based learning, critical thinking, and problem-solving skills, allowing students to take ownership of their learning and become active participants in their education.`,
      `<strong>Approche centrée sur l'étudiant</strong>: une salle de classe moderne donne la priorité à l'engagement, à l'autonomisation et à l'action des étudiants dans le processus d'apprentissage. Les enseignants adoptent une approche centrée sur l'élève qui met l'accent sur l'apprentissage basé sur l'investigation, la pensée critique et les compétences en résolution de problèmes, permettant aux élèves de s'approprier leur apprentissage et de devenir des participants actifs dans leur éducation.`,
      ``
    ),
    parentMsgClaudine: retLang(
      `
      I am Claudine Uwambayingabire and I am one of the parents who have raised children in La Colombière for more than 17 years. What I have seen in the education of La Colombière is that a child who has finished primary school can continue well without any obstacles, whether in international or local schools. After finishing primary school, they can speak 2 languages, French and English, and at a satisfactory level. La Colombière offers a variety of skills so that the child who completes the basic level knows different things and has Christian values, which are also the specialty of La Colombière.
Each child is monitored for specific treatment depending on their level of knowledge.
      `,
      `Je m'appelle Claudine Uwambayingabire et je fais partie des parents qui élèvent des enfants à La Colombière depuis plus de 17 ans. Ce que j'ai vu dans l'éducation de La Colombière, c'est qu'un enfant qui a terminé l'école primaire peut bien continuer sans aucun obstacle, que ce soit dans les écoles internationales ou locales. Après avoir terminé l'école primaire, ils parlent 2 langues, le français et l'anglais, et à un niveau satisfaisant. La Colombière propose des compétences variées pour que l'enfant qui termine le niveau de base connaisse des choses différentes et possède des valeurs chrétiennes, qui sont aussi la spécialité de La Colombière.
      Chaque enfant est suivi pour un traitement spécifique en fonction de son niveau de connaissances.`,
      `Njye ndi Claudine Uwambayingabire ndumwe muba byeyi barereye abana muri La Colombiere igihe kinini  kirenga imyaka 17 abana bakuru ba biri bageze muri university umwaka wa kabiri abandi babiri bato baracyari La Colombière bagiye gusoza primaire. Icyo nabonye muburezi bwa La Colombière nuko umwana uharangije primaire aho ageze abasha gukomeza neza ntambogamizi, haba mu mashuri ari internarional cyangwa locale basoza primaire babasha kuvuga indimi 2 igifaransa nicyongereza kandi ku rwego rushimishije. La Colombière itanga ubumenyi bwagutse kuburyo umwana uharangije icyiciro cya primaire aba azi ibintu bitandukanye ndetse   kandi aba afite indagagaciro ya gichristo icyo na cyo ni umwihariko wa la colombière. 
      Burimwana aba akurikiranwa kugirango afashwe byumwihariko bitewe nurwego rwubumenyi  arimo .`
    ),

    parentMsgMusaniwabo: retLang(
      `Our school: École La Colombière, we are proud of the education and knowledge that you teach our children.`,
      `Notre école : École La Colombière, nous sommes fiers de l'éducation et des connaissances que vous enseignez à nos enfants.`,
      `Shuri muturerera : La Colombiere twishimira  uburere n'ubumenyi muduhera abana musobanuye byinshi kuri twe tuharerera.Mukomereze aho.Turabashimira cyane.`
    ),

    organization_school: retLang(
      "School Organization",
      "Organisation de l'école",
      ""
    ),

    social_andscience_studeis: retLang(
      "Social and Science Studies",
      "Études sociales et scientifiques",
      ""
    ),
    infrastructure: retLang("Infrastructure", "Infrastructure", ""),
    nationalCurric: retLang("National curriculum", "Programme national", ""),
    admReqrts: retLang("Admission requirements", "Conditions d'admission", ""),
    firstKinderg: retLang("Nursery 1", "1ère maternelle", ""),
    sec_nurs_first_year: retLang(
      "Nursery 2 and 3 and P1",
      "2ème ,3ème maternelle et 1ère Année Primaire",
      ""
    ),
    primary_scool_det: retLang(
      "P 3, P4 and P5",
      "2ème ,2ème, 3ème, 4ème et 5ème année",
      ""
    ),
    school_uniform: retLang("School uniform", "Uniforme scolaire", ""),
    bus_transport: retLang("Transport par bus", "Transport par bus", ""),
    premises: retLang("Premises", "Les locaux", ""),
    dt_show_next_time: retLang(
      "Don't show next time",
      "Ne pas afficher la prochaine fois",
      ""
    ),
    letstart: retLang("Let's start", "Commençons", ""),
    lilited_places: retLang(
      "LIMITED PLACES: Save with this notification",
      "PLACES LIMITÉES : Économisez avec cette notification",
      ""
    ),
    admissions_entr: retLang("Admissions", "Inscriptions", ""),
    admissions_entr_s: retLang("Admission", "Inscription", ""),
    admin_start: retLang("Admissions start", "Début des inscriptions", ""),
    soon: retLang("Soon", "Bientôt", ""),
    enrollment: retLang("Enrollement", "Inscription", ""),
    day: retLang("Day", "Jour", ""),
    month: retLang("Month", "Mois", ""),
    year: retLang("Year", "Année", ""),
    trusted_by_m: retLang(
      "Trusted by parents, students and education professionals",
      "Reconnue par les parents, les étudiants et les professionnels de l'éducation",
      ""
    ),

    online_admiss_not_start: retLang(
      "Online registration has not yet started",
      "L'inscription en ligne n'a pas encore commencé",
      ""
    ),

    list_scholast_date: retLang(
      "List of scholastic material for each class will be available starting <strong>August 19th</strong> on the website and at School.",
      "La liste du matériel scolaire pour chaque classe sera disponible à partir du <strong>19 août</trong> sur le site internet et à l'école.",
      ""
    ),

    purchase_scholast_date: retLang(
      "For those who would like to purchase scholastic material at School , the prices will be posted on <strong>19th August 2024</strong> as well.",
      "Pour ceux qui souhaitent acheter du matériel scolaire à l'école, les prix seront également affichés le <strong>19 août 2024</strong>.",
      ""
    ),
  };
}

export default GetLanguage;

import { useState } from 'react';

import { IGeneralType } from 'src/models/smarttypes';
import './subscribe_form.css';
import getLangugaes from 'src/utils/language';
import generalFxs from 'src/utils/general_fx';
import NetServices from 'src/utils/netservices';
import { SenderData } from 'src/models/netreturn';

const languages = getLangugaes();
const appFxs = generalFxs();
const isEn = appFxs.getLocalLanguage() === 'en';

export const SubscribeForm = ({ placeholder, submitText }: IGeneralType) => {
    const oSubmitText = submitText ?? languages.submit;
    const oPlaceholder = placeholder ?? languages.typeHere;

    const [emailSubscriber, setEmailSubscriber] = useState('');
    const [btnDisabled, setBtnDisabled] = useState(true);
    const [formSent, setFormSent] = useState(false);
    const [isSending, setIsSending] = useState(false);

    const isChecked = (o: string) => {
        return (o !== null && o.length > 0) && appFxs.isEmailValid(o);
    }
    const emailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setEmailSubscriber(e.target.value);
        setBtnDisabled(!isChecked(e.target.value));
    }
    const handleClick = async () => {
        const bAsk = await appFxs.showConfirm(
          languages.sendRequest,
          languages.sendRequestMsg
        );
        if (!bAsk.isConfirmed) return;
        setIsSending(true);

        const senderData: SenderData = {
          subscriberEmail: emailSubscriber,
        };

        const oRes = await NetServices.requestPost(
          "web/maillistsubscribe",
          senderData
        );

        if (!oRes.bReturn) {
          setIsSending(false);
          appFxs.showAlert(oRes.msgTitle, oRes.msgBody);
          return;
        }

        setIsSending(false);
        setFormSent(true);
    }


    return (
      <div className="relative">
        <form className="d-form">
          {!formSent ? (
            <div className="d-form-elt-grp flex flex-col md:flex-row md:items-center">
              <div className="input-wrapper md:grow">
                <input
                  type="email"
                  name="email"
                  placeholder={oPlaceholder}
                  value={emailSubscriber}
                  onChange={emailChange}
                  disabled={isSending}
                />
              </div>
              <div className="grow text-center mt-5 md:grow-0 md:mt-0">
                {isSending ? (
                  <i className="fa fa-spin fa-spinner fa-2x ml-2"></i>
                ) : (
                  <button
                    type="button"
                    disabled={btnDisabled || isSending}
                    onClick={handleClick}
                  >
                    <span>{oSubmitText}</span>
                  </button>
                )}
              </div>
            </div>
          ) : (
            <div className="d-form-message w-full flex items-center justify-center">
              <div className="d-form-message-inner text-center text-white">
                {isEn ? (
                  <span className="text-center text-lg">
                    You've been registered by this email (
                    <strong>{emailSubscriber}</strong>).
                  </span>
                ) : (
                  <span className="text-center text-lg">
                    Vous vous êtes enreistré par cet email (
                    <strong>{emailSubscriber}</strong>).
                  </span>
                )}
              </div>
            </div>
          )}
        </form>
      </div>
    );
};

import { InnerCss } from 'src/models/smarttypes';
import './titleseparator.css';
interface ITitleSep {
    titleColor?: string,
    titleWidth?: number,
    subTitleWidth?: number,
}
export const TitleSeparator = ({subTitleWidth, titleColor, titleWidth}: ITitleSep) => {
    const wTitleColor = titleColor ?? '#ffffff';
    const wTitleWidth = titleWidth ? `${titleWidth}px`: '180px';
    const wSubTitleWidth = subTitleWidth ? `${subTitleWidth}px`: '60px';

    return (
        <span className='wise-title-separator' style={{
            '--title-color':wTitleColor,
            '--title-width': wTitleWidth,
            "--sub-title-width": wSubTitleWidth,
        } as InnerCss}></span>
    );
}
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";

import { MainTitleWrapper } from "src/components/pages/MainTitleWrapper";
import { LoadingElements } from "src/components/utils/LoadingElements";
import { IFieldRecord, IPageTitleSection, IProductVariantField, IWebShopProduct, IShopProductItem } from "src/models/smarttypes";

import searchIcon from 'src/assets/images/icons/svg/search.svg';


import getLanguages from 'src/utils/language';
import generalFxs from 'src/utils/general_fx';
import appDeclarations from 'src/utils/declarations';

import { listOfShopProductsDb } from "src/utils/tempodata";
import { FormInputElt, FormInputNumber } from "src/components/elements/forminputelt/FormInputElt";
import { LinkAchorRound } from "src/components/elements/linkanchorround956/LinkAchorRound";
import NetServices from "src/utils/netservices";


const languages = getLanguages();
const appFxs = generalFxs();

const oElt: IPageTitleSection = {
    classes: "shop-app",
    currentText: languages.loading,
    pageTitle: languages.loading,
    subPageLink: '/shop',
    subPageTitle: languages.shop
};


export const ShopProductPage = () => {
    document.title = `${languages.product} | ${languages.schoolName}`;
    const [pageFeature, setPageFeatures] = useState<IPageTitleSection>(oElt);
    const [currentProduct, setCurrentProduct] = useState<IWebShopProduct>(listOfShopProductsDb.items[0]);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const {productId} = useParams();

    const navigation = useNavigate();


    const populateSelectedProduct = async () => {
      setIsLoading(true);
      const oper = await NetServices.requestGet(
        `web/getoneproductforweb/${productId}`,
        false
      );
      if (!oper.bReturn) {
        await appFxs.showAlert(oper.msgTitle, oper.msgBody);
        navigation("/shop");
        return;
      }

      const oTempo = appFxs.transformOneShoProd(oper.productVarShopRecord!);
      setPageFeatures({
        ...pageFeature,
        currentText: oTempo.item.productName,
        pageTitle: oTempo.item.productName,
      });
      setCurrentProduct(oTempo);
      setIsLoading(false);
    };

    useEffect(() => {
        window.scrollTo(0,0);
        populateSelectedProduct()
    }, []);

    if(isLoading) return <LoadingElements />
    return (
        <>
            <MainTitleWrapper {...pageFeature} />
            {isLoading && <LoadingElements />}
            {!isLoading && 
            <section className="py-[70px]">
                <ProductSelectorViewer {...currentProduct.item} />
            </section>}
        </>
    )
}
interface IFormRecord {
  itemsQuantity: number,
  subtotal: number,
  variants: {label:string, value: string}[]
}

const ProductSelectorViewer = (
  {
    variants, category, sku,productDescript, 
    imagePath, productName, productPrice, 
    discountedPrice, productId,
  }: IShopProductItem) => {
    const lstImages:any[] = imagePath;
    const [selectedImg, setSelectedImg] = useState<number>(0);
    const [isLoading, setIsLoading] = useState(true);
    const [hasCartRecs, setHasCartRecs] = useState(false);
    const [cartDetailText, setCartDetailText] = useState(languages.viewCartDetails);
    const prodPrice = discountedPrice > 0 ? discountedPrice: productPrice;
    
    const [formRecord, setFormRecord] = useState<IFormRecord>(
      {itemsQuantity: 1, subtotal: (1 * prodPrice), variants: []})
    const lstVariants = variants ?? [];


    const onSelectImg = (iSel: number) => {
        setSelectedImg(iSel);
    }

    const onCheckCartData = () => {
      const isDataReady = appFxs.isCartDataExists();
      setHasCartRecs(isDataReady);

      if(variants && variants!.length >0){
        const pVars = variants.map(oVar => {
          return {
            label: oVar.variantName,
            value: oVar.values[0].id,
          }
        });
        setFormRecord({...formRecord, variants: pVars})
      }

      setIsLoading(false);
    }

    const onAddToAcart = () => {
      const cartOper = appFxs.getCartData();
      const prodNameVar = formRecord.variants.length === 0 ? '': 
      formRecord.variants.map(o => `[${o.label}-${o.value}]`).join('-');
      const prodName = prodNameVar.length > 0 ? `${productName} ${prodNameVar}`:productName;
      const prodIndex = cartOper.products.findIndex(o => o.productName === prodName);
      const cartHasProd = prodIndex >= 0;

      if(cartHasProd){
        const xProd = cartOper.products[prodIndex];
        xProd.quantity += formRecord.itemsQuantity;
        const subTotal = xProd.quantity * prodPrice;
        xProd.subtotal = subTotal;

        cartOper.products[prodIndex] = xProd;
      }else{
        cartOper.products.push({
          productName: prodName,
          productId: productId,
          imagePath: imagePath[0],
          productPrice: prodPrice,
          quantity: formRecord.itemsQuantity,
          subtotal: formRecord.subtotal,
          variants: formRecord.variants,
        });        
      }

      const oSubTotal = cartOper.products.reduce((a, curr) => a + curr.subtotal, 0);
      const oTotal = cartOper.products.reduce((a, curr) => a + curr.subtotal, 0);

      cartOper.subtotal = oSubTotal;
      cartOper.total = oTotal;

      const finalStrCart = JSON.stringify(cartOper);
      appFxs.setLocalStorageByKey(appDeclarations.cartZoneArea, finalStrCart);

      setCartDetailText(cartHasProd ? 
        `${prodName} ${languages.prodBeenUpdate}`: 
        `${prodName} ${languages.prodBeenAdded}`);
      setHasCartRecs(true);
    }
    const onDeleteCart = async () => {
      const bAsk = await appFxs.showConfirm(languages.deleteCart, languages.deleteCartMsg);
      if(!bAsk.isConfirmed) return;
      deleteFinalCart();
    }
    const deleteFinalCart = () => {
      appFxs.showAjaxLoader(languages.deletingCartDetails);
      appFxs.setLocalStorage(appDeclarations.cartZoneArea, '{}');

      setTimeout(()=>{
        appFxs.showAlert(languages.cart, languages.yourCartDataDel);
        setHasCartRecs(false);
        Swal.close();
      }, 2000);
    }
    const onVariantChange = (fo: IFieldRecord) => {
      const varPos = formRecord.variants.findIndex(o => o.label === fo.field);
      if(varPos<0)return;
      const fVar = {...formRecord} as IFormRecord;
      fVar.variants[varPos].value = fo.value;
      setFormRecord(fVar);
    }
    const onChangeQuantity = (e: {field: string;value: any;}) => {
      setFormRecord(
        {
          ...formRecord, 
          itemsQuantity: Number(e.value),
          subtotal: Number(e.value) * (discountedPrice > 0? discountedPrice: productPrice)
        });
    }

    useEffect(() => {
      onCheckCartData();
    }, []);

    if(isLoading) return <LoadingElements />
    return (
      <div className="w-container">

        {hasCartRecs && <div className="product-main-alert">
          <div className="cart-message">
            <span className="button-forward">
              <a className="inline-block mr-2 cursor-pointer" onClick={onDeleteCart}>{languages.deleteCart}</a>&nbsp;
              <Link className="inline-block mr-2" to={'/shop/cart'}>{languages.viewCart}</Link>&nbsp;
            </span>
            {/* “Blue Tshirt” has been added to your cart. */}
            {cartDetailText}
          </div>
        </div>}

        <div className="product-main-info">
          <div className="flex flex-wrap">
            <div className="basis-full md:basis-[50%]">
              <div className="box-inner relative">
                <div className="images-prod-info flex flex-col">
                  <a className="gallery-trigger cursor-pointer w-full">
                    <img src={searchIcon} alt="" className="emoji object-cover object-center w-full max-w-full" />
                  </a>
                  <div className="flex-viewport">
                    <figure className="galler-wrapper">
                      {lstImages.map((oImg, idx) => (
                        <GalleryImageComp
                          isHidden={idx !== selectedImg}
                          shopImg={oImg}
                          key={idx}
                        />
                      ))}
                    </figure>
                  </div>
                  <ol className="flex-control-nav">
                    {lstImages.map((oImg, idx) => (
                      <li key={idx}>
                        <img
                          src={oImg}
                          alt=""
                          onClick={() => onSelectImg(idx)}
                        />
                      </li>
                    ))}
                  </ol>
                </div>
              </div>
            </div>
            <div className="basis-full md:basis-[50%]">
              <div className="p-[10px] md:ml-[5%] relative">
                <div className="md:pl-[5%] mb-[2em]">
                  <div className="flex flex-col">
                    <div className="py-[8px]">
                      <h1 className="product-title">{productName}</h1>
                    </div>
                    <div className="product-price">
                      <span className="price">
                        {discountedPrice > 0 && <del>
                          <span className="price-amount">
                            <span className="bdi">
                              <span className="price-currency-symbol">Rwf</span>
                              {appFxs.formatNumbers(productPrice)}
                            </span>
                          </span>
                        </del>}
                        <ins>
                          <span className="price-amount">
                            <span className="bdi">
                              <span className="price-currency-symbol">Rwf</span>
                              {appFxs.formatNumbers(discountedPrice > 0 ? discountedPrice: productPrice)}
                            </span>
                          </span>
                        </ins>
                      </span>
                    </div>
                    <div className="product-descript leading-[28px] my-[8px]">
                      {productDescript && <p className="mb-0">
                        {productDescript}
                      </p>}
                      {!productDescript && <div className="py-2">
                        <p className="mb-0 line-through">
                          {languages.noDescription}
                        </p>  
                      </div>}
                    </div>

                    
                    <div className="my-[8px]">
                      <div className="form-wrapper m-0">
                        {/* Variants */}
                        {formRecord.variants.length > 0 ? <ul className="my-[8px]">
                          {formRecord.variants.map((oVar, idx) => <VariantSelector
                          variant={lstVariants[idx]}
                          fieldName={oVar.label}
                          value={oVar.value}
                          onChange={onVariantChange}
                          key={idx} /> )}
                        </ul>:
                        <div className="py-2 mb-4">
                            <p className="mb-0 line-through">
                              {languages.noVariants}
                            </p>  
                          </div>}

                        <div>
                            <FormInputNumber name="prodQty" 
                              value={formRecord.itemsQuantity.toString()} 
                              max={100} 
                              step={1}
                              min={1}
                              onChange={onChangeQuantity} />   
                            <LinkAchorRound 
                              type="button" 
                              text={languages.addToCart} 
                              bgColor="#000"
                              hoverBgcolor="#f1aa00"
                              onClick={onAddToAcart}/>                         
                        </div>
                      </div>
                    </div>
                    
                    {/* Sku */}
                    {sku && <div className="product-meta-wrapper py-[8px]">
                      <div className="pt-[10px]">
                        <span className="flex items-center">
                          <strong className="label">{languages.sku}:</strong>
                          <span className="value">{sku}</span>
                        </span>
                      </div>
                    </div>}

                    {/* Category */}
                    {category &&  <div className="product-meta-wrapper py-[8px]">
                      <div className="pt-[10px]">
                        <span className="flex items-center">
                          <strong className="label">{languages.category}:</strong>
                          <span className="value">{category}</span>
                        </span>
                      </div>
                    </div>}


                    <div className="product-meta-wrapper py-[8px]">
                      <div className="pt-[10px]">
                        <span className="flex items-center">
                          <strong className="label">{languages.share}:</strong>
                          <ul className="share-prod-list flex just-center" style={{marginBottom: 0}}>
                            <li>
                              <a href="https://facebook.com"
                                title="Facebook" target="_blank" rel="noreferrer">
                                  <i className="fa fa-facebook"></i>
                              </a>
                            </li>
                            <li>
                              <a href="//twitter.com/"
                                title="Twitter" target="_blank" rel="noreferrer">
                                  <i className="fa fa-twitter"></i>
                              </a>
                            </li>
                            <li>
                              <a href="https://pinterest.com"
                                title="Twitter" target="_blank" rel="noreferrer">
                                  <i className="fa fa-pinterest"></i>
                              </a>
                            </li>
                          </ul>
                        </span>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="product-main-details"></div>
        <div className="product-main-related"></div>
      </div>
    );
}


const GalleryImageComp = ({shopImg, isHidden}:{shopImg: any, isHidden: boolean}) => {
    return (
        <div className={`gallery-image ${isHidden && 'hidden'}`}>
            <a href={shopImg}>
                <img width={1000} 
                    height={1000} 
                    src={shopImg}
                    alt="" />                                        
            </a>
            <img src={shopImg} 
            alt="" 
            style={{position: 'absolute',top: 0,left: 0,opacity: 0,width: '100%',height: '100%',border: 'none',maxWidth: 'none',maxHeight: 'none'}}/>
        </div>
    );
}

const VariantSelector = ({variant, fieldName, onChange, value}: IProductVariantField) => {
  const onfieldChanged = (e: {field: string;value: any;}) => {
    if(!onChange)return;
    onChange(e);
  }
  return (
    <li className="mb-3 flex items-center gap-3">
      <div className="labelv basis-1/3 md:basis-1/5">
        <label htmlFor="prodColor">
          {variant.variantName}
        </label>
      </div>
      <div className="valuev basis-2/3 md:basis-2/5">
        <FormInputElt
          value={value}
          placeholder=""
          selectOptions={variant.values}
          name={fieldName}
          type="select"
          onChange={onfieldChanged}
        />
      </div>
      <div className="valuev hidden md:flex md:basis-2/5"></div>
    </li>
  )
}
import { IContactAddress, ITypeAddr, InnerCss } from 'src/models/smarttypes'

export const ContactAddressComp = ({title, iconClass, iconColor,lstDetails}: IContactAddress) => {
  return (
    <div className='mb-5 relative'>
      <div className='mb-[30px]'>
        <div className='flex flex-col md:flex-row'>
          <div className='addr-box-icon grow-1 text-center mb-5 md:grow-0 md:mr-[40px] md:mb-0 md:text-left'>
            <span className='addr-icon' style={{'--iconColor': iconColor} as InnerCss}>
              <i className={iconClass}></i>
            </span>
          </div>
          <div className='addr-box-details grow-1'>
            <h4 className='-mt-1 mb-[25px] text-center md:text-left'>{title}</h4>
            <ul>
              {lstDetails.map((o, idx) => <AddressElt {...o} key={idx} />)}
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}
const AddressElt = ({label,text,type}: ITypeAddr) => {
  const mailTo = 'mailto:' + text;
  const telTo = 'tel:' + text;
  return(
    <li className='pb-0 add-det-color  text-center md:text-left'>
      {type === 'text' && text}
      {type === 'email' && <a href={mailTo} title={label}>{text}</a>}
      {type === 'phone' && <a href={telTo} title={label}>{text}</a>}
    </li>
  );
}
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import getLanguages from "src/utils/language";
//import generalFxs from 'src/utils/general_fx';
import { MainTitleWrapper } from "src/components/pages/MainTitleWrapper";
import { IFaq, IGeneralType, IPageTitleSection } from "src/models/smarttypes";

import { FacilitiesBrightClass } from "./features/FacilitiesBrightClass";
import { FacilitiesMultimedia } from "./features/FacilitiesMultimedia.tsx";
import { FacilitiesSportsClub } from "./features/FacilitiesSportsClub";
import { FacilitiesPlayGrounds } from "./features/FacilitiesPlayGrounds";
import { FacilitiesMeetingHall } from "./features/FacilitiesMeetingHall";
import { FacilitiesLabLibrary } from "./features/FacilitiesLabLibrary";
import { LoadingElements } from "src/components/utils/LoadingElements.tsx";
import { WiseAccordion } from "src/components/utils/wiseaccordion/WiseAccordion.tsx";

const languages = getLanguages();
//const appFxs = generalFxs();

const oElt: IPageTitleSection = {
  classes: "our-facilities",
  currentText: languages.facilities,
  pageTitle: languages.facilities,
  subPageTitle: languages.facilities,
  subPageLink: "/facilities",
};

const lstFaqs: IFaq[] = [
  {
    id: 0,
    title: languages.faqQstTitle1,
    message: languages.faqQstMsg1,
  },
  {
    id: 1,
    title: languages.faqQstTitle2,
    message: languages.faqQstMsg2,
  },
  {
    id: 2,
    title: languages.faqQstTitle3,
    message: languages.faqQstMsg3,
  },
];

interface typePage {
  page:
    | "multimedia"
    | "lablibrary"
    | "playground"
    | "meetinghall"
    | "sportsclub"
    | "brightclassroom";
}
const lstFeatures: IGeneralType[] = [
  {
    title: languages.multimedia,
    href: "/facilities/multimedia",
  },
  {
    title: languages.labLibrary,
    href: "/facilities/lablibrary",
  },
  {
    title: languages.meetingHall,
    href: "/facilities/meetinghall",
  },
  {
    title: languages.playground,
    href: "/facilities/playground",
  },
  {
    title: languages.sportsClub,
    href: "/facilities/sportsclub",
  },
  {
    title: languages.modernClassroom,
    href: "/facilities/brightclassroom",
  },
];

export const FacilityTypePage = () => {
  const navigation = useNavigate();
  let { facilityType } = useParams();

  document.title = `${languages.facilities} | ${languages.schoolName}`;
  const [pageFeature, setPageFeatures] = useState<IPageTitleSection>(oElt);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const finalType: typePage = {
    page:
      facilityType === "multimedia"
        ? "multimedia"
        : facilityType === "playground"
        ? "playground"
        : facilityType === "lablibrary"
        ? "lablibrary"
        : facilityType === "meetinghall"
        ? "meetinghall"
        : facilityType === "sportsclub"
        ? "sportsclub"
        : facilityType === "brightclassroom"
        ? "brightclassroom"
        : "multimedia",
  };
  const pageTitle =
    finalType.page === "multimedia"
      ? languages.multimedia
      : finalType.page === "playground"
      ? languages.playground
      : finalType.page === "lablibrary"
      ? languages.labLibrary
      : finalType.page === "meetinghall"
      ? languages.meetingHall
      : finalType.page === "sportsclub"
      ? languages.sportsClub
      : finalType.page === "brightclassroom"
      ? languages.modernClassroom
      : "";

  const PageElement =
    finalType.page === "multimedia"
      ? FacilitiesMultimedia
      : finalType.page === "playground"
      ? FacilitiesPlayGrounds
      : finalType.page === "lablibrary"
      ? FacilitiesLabLibrary
      : finalType.page === "meetinghall"
      ? FacilitiesMeetingHall
      : finalType.page === "sportsclub"
      ? FacilitiesSportsClub
      : finalType.page === "brightclassroom"
      ? FacilitiesBrightClass
      : FacilitiesBrightClass;

  document.title = `${pageTitle} | ${languages.facilities} | ${languages.schoolName}`;

  const onChangePage = (href: string) => {
    navigation(href);
  };
  const hasSelection = (href: string) => {
    return document.location.href.includes(href);
  };

  const preparePage = () => {
    setPageFeatures({
      ...pageFeature,
      pageTitle: pageTitle,
      currentText: pageTitle,
    });
    setIsLoading(false);
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    preparePage();
  }, [document.location.href]);

  return (
    <>
      <MainTitleWrapper {...pageFeature} />

      {isLoading && <LoadingElements height={300} />}

      {!isLoading && (
        <section className="py-[100px]">
          <div className="w-container px-5 md:py-0">
            <div className="flex flex-wrap md:justify-between">
              <div className="basis-full md:basis-[25.7%] mb-5">
                <div className="md:pr-[30px]">
                  <div className="feature-navbar">
                    <ul className="side-nav">
                      {lstFeatures.map((oFeature, idx) => (
                        <li
                          key={idx}
                          className={`${
                            hasSelection(oFeature.href!) && "cur-page-item"
                          }`}
                        >
                          <a
                            className="c-pointer"
                            onClick={() => onChangePage(oFeature.href!)}
                          >
                            {oFeature.title!}
                          </a>
                        </li>
                      ))}
                    </ul>
                  </div>
                  <div className="feature-programs"></div>
                </div>
              </div>
              <div className="basis-full md:basis-[73%]">
                <PageElement />
                <div className="feature-freq-quest pt-6">
                  <WiseAccordion
                    title="Frequently Asked Questions?"
                    lstQuest={lstFaqs}
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
    </>
  );
};

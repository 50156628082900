import labLibraryImg from 'src/assets/images/schoolimgs/bg-title-teachers.jpg';
import { WiseListWithIcon } from 'src/components/elements/ListWithIcon/WiseListWithIcon';
import { SectionParagraph, SectionTitle } from 'src/components/elements/sectiontitle/SectionTitle';

import getLang from 'src/utils/language';

const languages = getLang();

export const FacilitiesLabLibrary = () => {
  return (
    <div className="flex flex-col">
      {/* image */}
      <div className="mb-[20px]">
        <div className="mb-[30px]">
          <img className="rounded-[10px]" src={labLibraryImg} alt="" />
        </div>
      </div>
      <SectionTitle title={languages.labLibrary} />
      <SectionParagraph message={languages.labLibraryLongMsg} />
      <div>
        <ul>
          <WiseListWithIcon
            fawesomeIcon="fa fa-university"
            message={languages.labLibraryChar1}
            isHtml={true}
          />
          <WiseListWithIcon
            fawesomeIcon="fa fa-graduation-cap"
            message={languages.labLibraryChar2}
            isHtml={true}
          />
          <WiseListWithIcon
            fawesomeIcon="fa fa-book"
            message={languages.labLibraryChar3}
            isHtml={true}
          />
        </ul>
      </div>
    </div>
  );
}

import { Link } from "react-router-dom";

import { HomeSpecType, IHomeSpec, IHomeSpecElt } from "src/models/smarttypes";

import groupTeamp from "src/assets/images/schoolimgs/group_users.png";
import readingBook from "src/assets/images/schoolimgs/reading_book.png";
import groupUsers from "src/assets/images/schoolimgs/group_users.png";
import globeAmenities from "src/assets/images/schoolimgs/school_building.png";

import getLanguages from "src/utils/language";

const languages = getLanguages();

const specs: IHomeSpec[] = [
  {
    color: "#f0aa00",
    iconComponent: HomeSpecType.StaffTeam,
    label: languages.ourTeam,
    href: "/misc/ourteachers",
  },
  {
    color: "#ff236c",
    iconComponent: HomeSpecType.ReadingKid,
    label: languages.kidsEnrollt,
    href: "/misc/enrollment",
  },
  /* {
        color: '#64c8ff',
        iconComponent: HomeSpecType.AlumniTeam,
        label: languages.alumniClub,
        href: '/alumni',
    }, */
  {
    color: "#A5C347",
    iconComponent: HomeSpecType.SchoolAmenities,
    label: languages.premises,
    href: "/facilities",
  },
];

export const HomeSpecs = () => {
  return (
    <div className="relative">
      <section className="home-specs">
        <div className="w-container">
          <div className="overlay"></div>
          <div>
            <div className="flex flex-row flex-wrap items-center justify-between">
              {specs.map((oSpec) => (
                <SpecItemElt specElt={oSpec} key={oSpec.iconComponent} />
              ))}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

const SpecItemElt = ({ specElt }: IHomeSpecElt) => {
  const { color, iconComponent, label, href } = specElt;

  const imgSrc =
    iconComponent === HomeSpecType.StaffTeam
      ? groupTeamp
      : iconComponent === HomeSpecType.SchoolAmenities
      ? globeAmenities
      : iconComponent === HomeSpecType.AlumniTeam
      ? groupUsers
      : iconComponent === HomeSpecType.ReadingKid
      ? readingBook
      : groupTeamp;

  return (
    <Link to={href} className="basis-full sm:basis-2/4 lg:basis-1/4">
      <div className="group relative flex flex-col items-center justify-center rounded-lg hover:bg-white p-[20px] border border-solid border-neutral-400/40 m-2">
        <div className="mb-[65px] grow-0 shrink-0">
          <span
            className="font-bold icon-elt flex items-center justify-center"
            style={{ backgroundColor: color }}
          >
            <img
              src={imgSrc}
              alt=""
              className="transition-all w-[80px] group-hover:scale-125"
            />
          </span>
        </div>
        <div>
          <h4 className="mb-[25%] text-[20px] line-height-simple text-center">
            <span className="font-bold text-center w-full">{label}</span>
          </h4>
        </div>
      </div>
    </Link>
  );
};
